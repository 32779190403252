import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";

import DataTable from "../../components/dataTable";
import ModalPopup from "../../components/modal.popup/modal.popup";

import { fetchAppSettingsData, updateAppSettingsData } from "./apiUtils";


const AppSettings = ({ setErrorList, errorList }) => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [updateKey, setUpdateKey] = useState({});
    const [show, setShow] = useState(false);
    const [currentSetting, setCurrentSetting] = useState(null)


    const handleClose = () => {
        setShow(false);
        setCurrentSetting(null)
    }

    const showPopup = (row) => {
        setShow(true);
        setCurrentSetting(row)
    }

    const ActionButtons = (row) => (
        row.isEdit ?
            <div>
                <Button
                    variant="secondary"
                    className="btnSecondary"
                    disabled={row.isLoading}
                    onClick={() => updateSettingKeyData(row.name, { isEdit: false })}
                >
                    CANCEL
                </Button>
                <Button
                    className="btnPrimary"
                    type="submit"
                    disabled={row.isLoading}
                    onClick={() => showPopup(row.name)}
                >
                    SAVE
                </Button>


            </div>
            :
            <div>
                <Button
                    className="btnPrimary"
                    type="submit"
                    onClick={() => updateSettingKeyData(row.name, { isEdit: true })}
                >

                    EDIT
                </Button>
            </div>
    )

    const RowWrapper = (row) => (<p style={{ color: "black" }}>{row.name}</p>)

    const KeyValue = (row) => (
        row.isEdit ?
            <Form.Control
                style={{ width: '100%', color: "black" }}
                type="text"
                name={row.name}
                defaultValue={row.value}
                disabled={row.isLoading}
                onChange={(e) => handleKeyChange(e, row.name)}
            />
            :
            <p style={{ color: "black" }}>{row.value}</p>
    )

    const tableColumn = [{
        name: "keyName",
        selector: (row) => <RowWrapper {...row} />,
        maxWidth: "30%",
    },
    {
        name: "KeyValue",
        cell: (row) => <KeyValue {...row} />,
        allowOverflow: true
    },
    {
        name: "Description",
        cell: (row) => (row.description),
        allowOverflow: true
    },
    {
        name: "action",
        selector: (row) => <ActionButtons {...row} />
    }];

    const updateSettingKeyData = (key, data) => {
        setShow(false);
        const settings = [...tableData];
        const pos = settings.findIndex((setting) => setting.name === key)
        settings[pos] = {
            ...settings[pos],
            ...data,
        }

        setTableData(settings);
    }

    const saveKey = async (key) => {
        updateSettingKeyData(key, { isLoading: true })
        let data = {};
        data[key] = updateKey[key];
        data["keyToUpdate"] = key
        data["value"] = updateKey[key];
        let response = await updateAppSettingsData(dispatch, data)
        let settingData = {
            isLoading: false,
            isEdit: false
        }
        if (!response?.hasError) {
            settingData.value = response.value
            updateSettingKeyData(key, settingData)
        } else {
            setErrorList({
                ...errorList,
                appsettingError: response.message ?? "Error occured while updating appsettings",
            });
        }
    }

    const handleKeyChange = (e, key) => {
        updateKey[key] = e.target.value;
        setUpdateKey(updateKey)
    }

    useEffect(() => {
        const fetchApiSettings = async () => {
            let response = await fetchAppSettingsData(dispatch)
            if (!response?.hasError) {
                const data = response
                const dataValues = data.map((item) => {
                    return {
                        name: item.key,
                        value: item.value,
                        description: <p style={{ color: "black" }}>{item.description}</p>,
                        isEdit: false,
                        isLoading: false
                    };
                })
                setTableData(dataValues)
            } else {
                setErrorList({
                    ...errorList,
                    appsettingError: { message: response.message ?? "Error occured while fetching appsettings" },
                });
            }
        }

        fetchApiSettings()
    }, [dispatch])

    return (
        <div className="table-sec mb-3 p-0">
            <DataTable
                fixedHeaderScrollHeight="550px"
                columns={tableColumn}
                data={tableData}
            />
            <ModalPopup
                show={show}
                name={"Are you want to edit?"}
                hide={handleClose}
                okClick={() => saveKey(currentSetting)}
                hideFooter
                okText='Yes'
                cancelText='No'
            ></ModalPopup>
        </div>
    );
};

export default AppSettings