

import { useAppContext } from "../../../../components/context/app.context";
import Styles from "../styles/metabaseReports.module.scss";
import { useLayoutEffect, useRef, useState } from "react";

const MetabaseReports = ({ url }) => {
  const { sidebarCollapsed } = useAppContext();
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const updateSize = () => {
      setTimeout(() => {
        if (targetRef.current) {
          setDimensions({
            width: targetRef.current.offsetWidth - 60,
            height: targetRef.current.offsetHeight - 60
          });
        }
      }, 200)
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [sidebarCollapsed]);

  return (
    <div className={Styles.metabaseReports} ref={targetRef}>
      <iframe
        title="dashboard"
        src={url}
        frameBorder={0}
        width={dimensions.width}
        height={dimensions.height}
        allowTransparency
      />
    </div>
  );
};

export default MetabaseReports;
