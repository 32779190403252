import { ReactComponent as DiningIcon } from "../../assets/images/dining.svg";
import { ReactComponent as ShoppingIcon } from "../../assets/images/shopping-cart.svg";
import { ReactComponent as ExperienceIcon } from "../../assets/images/experience.svg";

export const BREADCRUMB_MERCHANTS = (t)=>[
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("MERCHANTS")
  }
];

export const BREADCRUMB_MERCHANT_CREATE = (t, isEdit)=>[
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("MERCHANTS")
  }
];

export const MERCHANT_CATEGORY_ICONS = {
  1: DiningIcon,
  2: ShoppingIcon,
  3: ExperienceIcon,
};

