import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from 'react-toastify';
import constant from '../config/constant';

const baseURL = constant.config.REACT_API_HOST;

const initialState = {
    appSettings: {},
    AppSettingsList: [],
    success: '',
    error: '',
    loading: false,
};

const showErrorToast = (message) => {
    toast.error(message, {
        position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true,
        pauseOnHover: true, draggable: true, progress: undefined,
    });
};

export const getAppSettings = createAsyncThunk(
    "appSettings/getAppSettings",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(baseURL + `admin/app-settings/`);
            return response.data;
        } catch (error) {
            showErrorToast("Error: Something went wrong. Please contact admin!. " + error.message);
            return rejectWithValue(error.response.data);
        }
    }
);

export const getPublicAppSettings = createAsyncThunk(
    "appSettings/getAppSettings",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${baseURL}profiles/app-settings`);
            return response.data;
        } catch (error) {
            showErrorToast("Error: Something went wrong. Please contact admin!. " + error.message);
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateAppSettings = createAsyncThunk(
    "appSettings/updateAppSettings",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(baseURL + `admin/app-settings/${data.keyToUpdate}`, data);
            return response.data;
        } catch (error) {
            showErrorToast("Error: Something went wrong. Please contact admin!. " + error.message);
            return rejectWithValue(error.response.data);
        }
    }
);

const appSettingsSlice = createSlice({
    name: "appSettings", initialState, reducer: {}, extraReducers: {
        [getAppSettings.pending]: (state, action) => {
            return { ...state, success: 'pending', error: '', loading: true, }
        },
        [getAppSettings.fulfilled]: (state, action) => {
            return { ...state, AppSettingsList: action.payload, success: 'success', error: '', loading: false, }
        },
        [getAppSettings.rejected]: (state, action) => {
            return { ...state, success: 'reject', error: action.payload, loading: false, }
        },
        [updateAppSettings.pending]: (state, action) => {
            return { ...state, success: "pending", error: "", loading: true, };
        },
        [updateAppSettings.fulfilled]: (state, action) => {
            return { ...state, appSettings: action.payload, ...state.appSettings, success: "success", error: "", loading: false, };
        },
        [updateAppSettings.rejected]: (state, action) => {
            return { ...state, success: "reject", error: action.payload, loading: false, };
        },
    }
});

export default appSettingsSlice.reducer;
