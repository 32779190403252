import React from "react";
import { default as Table } from "react-data-table-component";

import "./styles/dataTable.css";

const DataTable = (props) => {
  const customStyles = {
    headRow: {
      style: {
        minHeight: "56px",
        borderRadius: "12px 12px 0px 0px"
      },
    },
    headCells: {
      style: {
        paddingLeft: "12px",
        paddingRight: "12px",
      },
    },
    cells: {
      style: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  };

  return (
    <div className={`datatable-wrapper ${props?.tableClass}`}>
      <Table
      
        customStyles={customStyles}
        // customStyles={props.styles}
        fixedHeader
        fixedHeaderScrollHeight="400px"
        persistTableHead
        progressComponent={<p className="mt-5">Loading please wait...</p>}
        {...props}
      />
    </div>
  );
};

export default DataTable;
