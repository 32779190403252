import React, { useState } from 'react'
import './index.css'
import { Accordion, Card } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useProfileContext } from '../context/profile.context';
import { checkUserRolePermissions } from '../../utils/helper';
import { useAppContext } from '../context/app.context';
import { ROLE_PERMISSIONS } from '../../utils/constants';


const ExtraRoleRowFields = ({
    role,
    onClickEditRole,
    onClickCreateRole,
    onClickDeleteRole
}) => {
    const { currentUserRole } = useAppContext()

    return (
        <>
            <div className="col-2 d-flex justify-content-center">
                <i className="bi bi-people pr-1" />
                {role.numMembers}
            </div>
            <div className="col-2 d-flex justify-content-center">
                <i className="bi bi-file-text pr-1" />
                {role.numSubRoles}
            </div>
            {checkUserRolePermissions(currentUserRole, ROLE_PERMISSIONS.MANAGEROLES) &&
                <div className="col-2 d-flex justify-content-center">
                    <span>
                        <button
                            title="Edit"
                            type="button"
                            className="text-black bg-transparent ml-4 pr-3"
                            onClick={() => onClickEditRole(role)}
                        >
                            <i className="bi bi-pencil" />
                        </button>
                    </span>
                    <span>
                        <button
                            title="Create new child role"
                            type="button"
                            className="text-black bg-transparent pr-3"
                            onClick={() => onClickCreateRole(role)}
                        >
                            <i className="bi bi-person-plus" />
                        </button>
                    </span>
                    <span>
                        <button
                            title="Delete"
                            type="button"
                            className="text-black bg-transparent"
                            onClick={() => onClickDeleteRole(role)}
                        >
                            <i className="bi bi-trash" />
                        </button>
                    </span>
                </div>
            }
        </>
    )
}

const RoleRow = ({
    topRoleId,
    role,
    level,
    onClickEditRole,
    onClickCreateRole,
    onClickDeleteRole,
    editing
}) => {
    const { selectedRole } = useProfileContext()
    const { currentUserRole } = useAppContext()
    const [open, setOpen] = useState(role.id === topRoleId)
    const decoratedOnClick = useAccordionButton(role.id, () => {
        if (editing) {
            onClickEditRole(role)
        } else {
            setOpen(!open)
        }
    });
    const hasChildRoles = role.children.length > 0

    return (
        <div className={`row py-1 ${(selectedRole && role.id === selectedRole.id) ? "selected" : ""}`}>
            <button
                type="button"
                className={`text-black bg-transparent d-flex ${editing ? "col-8" : "col-6"}`}
                style={{ paddingLeft: 10 + (level * 30) }}
                onClick={decoratedOnClick}
            >
                {level > 0 && <i className="bi bi-arrow-return-right pr-1" />}
                {!editing && hasChildRoles && role.id != topRoleId && <span className="pr-1">
                    {open ?
                        <i className="bi bi-caret-down-fill pr-1" />
                        :
                        <i className="bi bi-caret-right-fill pr-1" />
                    }
                </span>}
                <i className="bi bi-file-person pr-1" />
                {(selectedRole && role.id === selectedRole.id) ? selectedRole.name : role.name}
            </button>
            {
                !editing
                &&
                <ExtraRoleRowFields
                    role={role}
                    onClickEditRole={onClickEditRole}
                    onClickCreateRole={onClickCreateRole}
                    onClickDeleteRole={onClickDeleteRole}
                />
            }
            {
                editing
                &&
                checkUserRolePermissions(currentUserRole, ROLE_PERMISSIONS.MANAGEROLES)
                &&
                <div className="col-4">
                    {role.id !== "new" &&
                        <span>
                            <button
                                title="Create new child role"
                                type="button"
                                className="text-black bg-transparent pr-4"
                                onClick={() => onClickCreateRole(role)}
                            >
                                <i className="bi bi-person-plus" />
                            </button>
                        </span>
                    }
                    <span>
                        <button
                            title="Delete"
                            type="button"
                            className="text-black bg-transparent"
                            onClick={() => onClickDeleteRole(role)}
                        >
                            <i className="bi bi-trash" />
                        </button>
                    </span>
                </div>
            }
        </div>
    );
}

export const RoleAccordionItem = ({
    topRoleId,
    role,
    level,
    onClickEditRole,
    onClickCreateRole,
    onClickDeleteRole,
    editing
}) => {

    const { selectedRole } = useProfileContext()
    const hasNewChildRole = (selectedRole && selectedRole.parentRoleId === role.id && !role.children.find(childRole => childRole.id === selectedRole.id))
    return (
        <Card className={`rounded-0 border-left-0 border-right-0 ${level === 0 ? "border-top-0" : ""}`}>
            <Card.Header
                className="bg-white"
            >
                <RoleRow
                    topRoleId={topRoleId}
                    role={role}
                    level={level}
                    onClickEditRole={onClickEditRole}
                    onClickCreateRole={onClickCreateRole}
                    onClickDeleteRole={onClickDeleteRole}
                    editing={editing}
                >
                </RoleRow>
            </Card.Header>
            <Accordion.Collapse eventKey={role.id}>
                <Card.Body className="p-0">
                    <Accordion
                        alwaysOpen={true}
                        activeKey={editing ? role.children.map(childRole => childRole.id) : undefined} // Have all roles be open when in edit mode
                    >
                        {
                            hasNewChildRole
                            &&
                            <RoleAccordionItem
                                role={selectedRole}
                                key={selectedRole.id}
                                level={level + 1}
                                onClickEditRole={onClickEditRole}
                                onClickCreateRole={onClickCreateRole}
                                onClickDeleteRole={onClickDeleteRole}
                                editing={editing}
                            />
                        }
                        {role.children.map(childRole =>
                            <RoleAccordionItem
                                role={childRole}
                                key={childRole.id}
                                level={level + 1}
                                onClickEditRole={onClickEditRole}
                                onClickCreateRole={onClickCreateRole}
                                onClickDeleteRole={onClickDeleteRole}
                                editing={editing}
                            />
                        )}
                    </Accordion>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}