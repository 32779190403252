import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Input, Form } from "antd";
import { Modal, Button } from "../customAntd";
import { getRandomString } from "../../utils/helper";

import styles from "./styles/deleteConfirmation.module.scss";

const { Item } = Form;

const DeleteConfirmation = ({ isOpen, title, text, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) setCode(getRandomString(6));
  }, [isOpen]);

  return (
    <Modal
      title={`${t("DELETE")} ${title}?`}
      open={isOpen}
      onFinish={onConfirm}
      closeIcon={null}
      footer={[
        <Button disabled={loading} type="default" onClick={() => onCancel()}>
          {t("CANCEL")}
        </Button>,
        <Button type="primary" loading={loading} onClick={() => form.submit()}>
          {t("CONFIRM")}
        </Button>,
      ]}
      destroyOnClose
      className={styles["delete-confirmation"]}
    >
      <p>{text}</p>
      <p>{t("DELETE_CONFIRMATION_MODAL.ENTER_CODE")}</p>
      <strong>{code}</strong>
      <Form
        layout="vertical"
        onFinish={() => {
          setLoading(true);
          onConfirm();
        }}
        form={form}
        preserve={false}
      >
        <Item
          name="code"
          rules={[
            {
              required: true,
              message: t("DELETE_CONFIRMATION_MODAL.CODE_REQUIRED"),
            },
            {
              validator: async (_, val) => {
                if (val) {
                  if (val === code.substring(0, val.length)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      t("DELETE_CONFIRMATION_MODAL.INVALID_CODE")
                    );
                  }
                }
              },
            },
          ]}
        >
          <Input />
        </Item>
      </Form>
    </Modal>
  );
};

export default DeleteConfirmation;
