import { useState, useEffect, Fragment } from "react";
import {
  Form,
  Input,
  Upload,
  List,
  message,
  Row,
  Col,
  Select,
  Divider,
  DatePicker,
  Image,
  Popconfirm,
} from "antd";
import dayjs from "dayjs";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { t } from "i18next";

import { Radio, Switch, Button } from "../../../../../components/customAntd";
import {
  uploadFiles,
  getLocalesFromDb,
} from "../../../../../utils/common/apiUtils";
import { useAppContext } from "../../../../../components/context/app.context";

const { Item } = Form;
const { TextArea } = Input;
const { Dragger } = Upload;

const Step3 = ({
  form,
  onChange,
  files,
  isSingleFile,
  isEdit,
  setIsImageLoading,
  heroImage,
  setHeroImage,
  heroImages,
  setHeroImages,
  editData,
}) => {
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [defaultLocale] = useState("en_US");
  const [availableLocales, setAvailableLocales] = useState([]);
  const [isHeroLoading, setIsHeroLoading] = useState([]);
  const [isHeroPreviewVisible, setHeroPreviewVisible] = useState(false);
  const [isHeroImagesPreviewVisible, setIsHeroImagesPreviewVisible] = useState(
    []
  );

  const { categories } = useAppContext();

  const watchLocales = Form.useWatch("locales", form);
  const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);
  const watchCategories = Form.useWatch("primary_category", form);

  useEffect(() => {
    const getLocales = async () => {
      const data = await getLocalesFromDb();
      if (data) {
        const localesData = data.map((locale) => ({
          value: locale.localeId,
          label: `${locale.language.language} (${locale.country.country})`,
          isSelected: isEdit
            ? !!editData.locales.find(
                (localeEl) => localeEl.localeId === locale.localeId
              ) || editData.defaultLocaleId === locale.localeId
            : locale.localeId === defaultLocale,
        }));
        setAvailableLocales(localesData);
      }
    };
    getLocales();
  }, [defaultLocale]);

  // Updated locals state if someone changed language in the form
  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
              ...item,
              isSelected: true,
            }
          : {
              ...item,
              isSelected: item.value === prevValue ? false : item.isSelected,
            }
      );
    });
  };

  const handleImageChange = (data, index) => {
    setHeroImages((prev) => {
      prev[index] = [...data];
      return [...prev];
    });
  };
  // Performing validation on an image before it is uploaded to the API
  const handleBeforeUpload = async (file, index, type) => {
    setIsImageLoading(true);
    const extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.ONLY")} JPG, JPEG and PNG ${t(
          "FILE_UPLOAD.EXTENSIONS_ALLOWED"
        )}`,
      });
      if (type === "heroImage") {
        setHeroImages([index, undefined]);
      } else {
        form.setFieldValue("hero_image", undefined);
      }
      return Upload.LIST_IGNORE;
    } else if (currentFileIndexOf !== -1) {
      message.open({
        type: "error",
        content: t("FILE_UPLOAD.EXISTS"),
      });
      return Upload.LIST_IGNORE;
    } else if (file.size > 5000000) {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.SIZE_ERROR")} 5${t("MB")}`,
      });
      return Upload.LIST_IGNORE;
    } else {
      if (type === "heroImage") {
        setIsHeroLoading((prev) => {
          prev[index] = true;
          return [...prev];
        });
      } else {
        setIsFileLoading(true);
      }
      const uploadFile = await uploadFiles({
        fileFor: "benefit",
        isUploadIpfs: false,
        files: file,
      });
      if (uploadFile?.status === 200) {
        const uploadArray = uploadFile.data.map((item) => {
          return {
            name: item.s3Url.split("/").reverse()[0],
            resourceUrl: item.s3Url,
            type: "heroImage",
          };
        });
        if (type === "heroImage") {
          handleImageChange(uploadArray, index);
        } else {
          onChange(uploadArray);
        }
      }
      setIsFileLoading(false);
      setIsImageLoading(false);
      if (type === "heroImage") {
        setIsHeroLoading((prev) => {
          prev[index] = false;
          return [...prev];
        });
      }
    }
  };

  // Removing an image from the hero image array using the delete button
  const handleDeleteClick = (fileName) => {
    setHeroImage([]);
    form.setFieldValue("hero_image", undefined);
  };

  //Deleting hero images in language options
  const handleDeleteHeroImages = (index) => {
    setHeroImages((prev) => {
      prev[index] = undefined;
      return [...prev];
    });
    setIsHeroImagesPreviewVisible((prev) => {
      prev[index] = undefined;
      return [...prev];
    });
  };

  return (
    <>
      <h3>{t("ADD_DETAILS")}</h3>
      <Item
        label={`${t("DEFAULT_LANGUAGE")}:`}
        name="defaultLocaleId"
        initialValue={defaultLocale}
        rules={[
          {
            required: true,
            message: t("DEFAULT_LANGUAGE_ERROR"),
            whitespace: true,
          },
        ]}
      >
        <Select
          options={availableLocales.map((localEl) => {
            return {
              ...localEl,
              disabled: localEl.isSelected,
            };
          })}
          onChange={(value) => updateLocales(value, watchDefaultLanguage)}
        />
      </Item>
      <Row>
        <Item
          label={`${t("BENEFIT_CATEGORIES.LABEL")} :`}
          name="primary_category"
          rules={[
            {
              required: true,
              message: t("BENEFIT_CATEGORIES.SELECT_CATEGORY"),
            },
          ]}
        >
          <Select
            style={{ width: 200 }}
            className="mr-2"
            placeholder={t("MERCHANT.SELECT_PRIMARY")}
            onChange={() => {
              form.setFieldValue("subcategory", null);
            }}
            options={[
              ...categories?.map((category) => ({
                value: category.id,
                label: t(`${category["name"].toUpperCase()}`),
              })),
            ]}
          />
        </Item>
        <Item label=" " name="subcategory">
          <Select
            style={{ width: 200 }}
            placeholder={t("MERCHANT.SELECT_SUB")}
            allowClear
            options={
              watchCategories > 0
                ? [
                    ...categories
                      ?.find((category) => category.id === watchCategories)
                      ?.subCategories.map((category) => ({
                        value: category.id,
                        label: category.name,
                      })),
                  ]
                : [{ value: 0, label: t("MERCHANT.SUB_CAT_OPTIONAL") }]
            }
            disabled={!watchCategories || watchCategories === 0}
          />
        </Item>
      </Row>
      <Item
        label={`${
          isSingleFile
            ? t("AUDIO.TITLE")
            : `${t("VIDEO.SERIES")}/${t("VIDEO.PLAYLIST_TITLE")}`
        }:`}
        name="album_title"
        rules={[
          {
            required: true,
            message: isSingleFile
              ? t("VIDEO.TITLE_ERROR")
              : t("VIDEO.PLAYLIST_TITLE_ERROR"),
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={
            isSingleFile
              ? t("VIDEO.TITLE_PLACEHOLDER")
              : t("VIDEO.PLAYLIST_PLACEHOLDER")
          }
        />
      </Item>
      <Item
        label={`${t("VIDEO.TITLE_PREFIX")}:`}
        name="code_short_description"
        rules={[
          {
            validator: (_, value) => {
              if (value && value.length > 10) {
                return Promise.reject(
                  new Error(t("VIDEO.CODE_PREFIX_VALIDATION_ERROR"))
                );
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <TextArea
          placeholder={t("PREFIX_TITLE_VALIDATION_ERROR")}
          style={{ resize: "none" }}
        />
      </Item>

      <Item
        label={`${
          isSingleFile
            ? t("VIDEO.DESC")
            : `${t("VIDEO.SERIES")}/${t("VIDEO.PLAYLIST_DESC")}`
        }:`}
        name="album_description"
        rules={[
          {
            required: true,
            message: t("VIDEO.DESC_ERROR"),
            whitespace: true,
          },
        ]}
      >
        <MDEditor
          overflow={false}
          placeholder={t("VIDEO.DESC_PLACEHOLDER")}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
        {/* <Input.TextArea placeholder={t("VIDEO.DESC_PLACEHOLDER")} /> */}
      </Item>
      <Item
        label={`${t("COVER_ART")}:`}
        name="hero_image"
        extra={`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG. ${t(
          "MAX_SIZE"
        )}: 5${t("MB")}`}
        rules={[
          {
            required: isEdit && files.length !== 0 ? false : true,
            message: t("SELECT_COVER_ART"),
          },
        ]}
      >
        <Dragger
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          disabled={files.length !== 0 || isFileLoading}
          accept="image/jpg, image/jpeg, image/png"
          customRequest={() => null}
          fileList={files}
          maxCount={1}
        >
          {isFileLoading ? (
            <>
              <LoadingOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
              <p className="ant-upload-hint">{`${t("UPLOADING")}...`}</p>
            </>
          ) : (
            <>
              <UploadOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
              <p className="ant-upload-hint">{t("UPLOAD")}</p>
            </>
          )}
        </Dragger>
      </Item>

      {files.length !== 0 && (
        <Item label={`${t("IMAGE")}:`}>
          <List
            dataSource={files}
            className="upload-list"
            style={{ marginBottom: 20 }}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <DeleteOutlined
                    onClick={() => handleDeleteClick(item.name)}
                  />,
                  <EyeOutlined
                    onClick={() => setHeroPreviewVisible(!isHeroPreviewVisible)}
                  />,
                ]}
              >
                <div>
                  <span className="item-index">{index + 1}.</span>{" "}
                  <span className="item-name">{item.name}</span>
                </div>
              </List.Item>
            )}
          />
        </Item>
      )}
      {heroImage[0]?.resourceUrl && (
        <Image
          style={{ display: "none" }}
          src={heroImage[0].resourceUrl}
          preview={{
            visible: isHeroPreviewVisible,
            onVisibleChange: (visible, prevVisible) =>
              setHeroPreviewVisible(visible),
          }}
        />
      )}
      <Item
        label={`${t("STATUS")}:`}
        name="status"
        rules={[
          {
            required: true,
            message: t("SELECT_STATUS"),
          },
        ]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value="draft">
                {t("DRAFT")}
              </Radio>
            </Col>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value="active">
                {t("ACTIVE")}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Item>
      <Item label={`${t("ACTIVE_DATE")}:`} name="active_date">
        <DatePicker
          style={{ width: "100%" }}
          format="YYYY-MM-DD HH:mm"
          disabledDate={(current) => {
            return current && current < dayjs().subtract(1, "day").endOf("day");
          }}
          placeholder={[t("ACTIVE_DATE")]}
          showTime={{ format: "HH:mm" }}
        />
      </Item>
      <Item label={`${t("ALLOW_DOWNLOAD")}:`} name="allow_download">
        <Switch />
      </Item>
      <Form.List name="locales">
        {(fields, { add, remove }) => (
          <div className="languageOptions">
            <h3 className="heading">{t("LANGUAGE_OPTIONS.HEADING")}</h3>
            <p className="info">{t("LANGUAGE_OPTIONS.DESCRIPTION")}</p>
            {fields.map(({ key, name }, index) => (
              <Fragment key={key}>
                <Item
                  label={`${t("LANGUAGE")} :`}
                  name={[name, "localeId"]}
                  rules={[
                    {
                      required: true,
                      message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                    },
                  ]}
                >
                  <Select
                    options={availableLocales.map((localEl) => ({
                      ...localEl,
                      disabled: localEl.isSelected,
                    }))}
                    onChange={(value) =>
                      updateLocales(value, watchLocales[index].localeId)
                    }
                    placeholder={t("SELECT")}
                  />
                </Item>
                <Item
                  label={`${t("LANGUAGE_OPTIONS.LOCALISED_TITLE")} :`}
                  name={[name, "title"]}
                  rules={[
                    {
                      required: true,
                      message: t("LANGUAGE_OPTIONS.LOCALISED_TITLE_ERROR"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      "LANGUAGE_OPTIONS.LOCALISED_TITLE_PLACEHOLDER"
                    )}
                  />
                </Item>
                <Item
                  label={
                    isSingleFile
                      ? t("LANGUAGE_OPTIONS.DESC")
                      : `${t("VIDEO.LOCALISED_DESC")}:`
                  }
                  name={[name, "description"]}
                  rules={[
                    {
                      required: true,
                      message: t("AUDIO.DESC_ERROR"),
                      whitespace: true,
                    },
                  ]}
                >
                  <MDEditor
                    overflow={false}
                    placeholder={t("VIDEO.DESC_PLACEHOLDER")}
                    previewOptions={{
                      rehypePlugins: [[rehypeSanitize]],
                    }}
                  />
                  {/* <Input.TextArea placeholder={t("AUDIO.DESC_PLACEHOLDER")} /> */}
                </Item>
                <Item
                  label={`${t("LANGUAGE_OPTIONS.LOCALISED_COVER_ART")}:`}
                  extra={`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG. ${t(
                    "MAX_SIZE"
                  )}: 5${t("MB")}`}
                >
                  <Dragger
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleBeforeUpload(file, index, "heroImage");
                    }}
                    disabled={
                      isHeroLoading?.[index] || heroImages?.[index]?.resourceUrl
                    }
                    accept="image/jpg, image/jpeg, image/png"
                    customRequest={() => null}
                    fileList={heroImages?.[index]}
                    maxCount={1}
                  >
                    {isHeroLoading?.[index] ? (
                      <>
                        <LoadingOutlined
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                        <p className="ant-upload-hint">
                          {`${t("UPLOADING")}...`}.
                        </p>
                      </>
                    ) : (
                      <>
                        <UploadOutlined
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                        <p className="ant-upload-hint">{t("UPLOAD")}</p>
                      </>
                    )}
                  </Dragger>
                </Item>

                {heroImages?.[index]?.length > 0 && (
                  <Item label={`${t("IMAGE")}:`}>
                    <List
                      dataSource={heroImages?.[index]}
                      className="upload-list"
                      style={{ marginBottom: 20 }}
                      renderItem={(item, itemIndex) => (
                        <List.Item
                          actions={[
                            <DeleteOutlined
                              onClick={() => handleDeleteHeroImages(index)}
                            />,
                            <EyeOutlined
                              onClick={() =>
                                setIsHeroImagesPreviewVisible((prev) => {
                                  prev[index] = true;
                                  return [...prev];
                                })
                              }
                            />,
                          ]}
                        >
                          <div>
                            <span className="item-index">{itemIndex + 1}.</span>{" "}
                            <span className="item-name">
                              {item.name ??
                                t("lANGUAGE_OPTIONS.LOCALISED_COVER_IMAGE")}
                            </span>
                          </div>
                        </List.Item>
                      )}
                    />
                  </Item>
                )}
                {heroImages?.[index]?.[0]?.resourceUrl &&
                  isHeroImagesPreviewVisible[index] && (
                    <Image
                      style={{ display: "none" }}
                      src={heroImages[index][0].resourceUrl}
                      preview={{
                        visible: isHeroImagesPreviewVisible[index],
                        onVisibleChange: (visible, prevVisible) =>
                          setIsHeroImagesPreviewVisible((prev) => {
                            prev[index] = visible;
                            return [...prev];
                          }),
                      }}
                    />
                  )}
                {fields.length > 0 && (
                  <Divider orientation="center">
                    <Popconfirm
                      title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                      disabled={isHeroLoading[index]}
                      onConfirm={() => {
                        setHeroImages((prev) => {
                          prev.splice(index, 1);
                          return [...prev];
                        });
                        setIsHeroImagesPreviewVisible((prev) => {
                          prev.splice(index, 1);
                          return [...prev];
                        });
                        remove(index);
                        setAvailableLocales((prev) => {
                          return prev.map((item) =>
                            item.value === watchLocales[index].localeId
                              ? {
                                  ...item,
                                  isSelected: false,
                                }
                              : item
                          );
                        });
                        remove(name);
                      }}
                      onCancel={() => {}}
                      okText={t("YES")}
                      cancelText={t("NO")}
                    >
                      <DeleteOutlined
                        className="deleteIcon"
                        style={{ fontSize: 24 }}
                      />
                    </Popconfirm>
                  </Divider>
                )}
              </Fragment>
            ))}

            <Button
              type="primary"
              className="add-locale-button"
              onClick={fields.length >= 3 ? () => {} : () => add()}
              icon={<PlusOutlined />}
              disabled={fields.length >= 3}
            >
              {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
            </Button>
          </div>
        )}
      </Form.List>
    </>
  );
};

export default Step3;
