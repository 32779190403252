import { useTranslation } from "react-i18next";

import Collections from "../../assets/images/collection.svg";
import Utilities from "../../assets/images/program.svg";
import Benefits from "../../assets/images/benefit.svg";
import { HomepageCard } from "../../components/cards/index";
import { useAppContext } from "../../components/context/app.context";

import Styles from "./styles/homepage.module.scss";
import { Row } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getMetabaseUrl } from "./apiUtils";

const Homepage = () => {
  const { currentUserData, sidebarCollapsed } = useAppContext();
  const { t } = useTranslation();
  const [url, setUrl] = useState('')
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const updateSize = () => {
      setTimeout(() => {
        if (targetRef.current) {
          setDimensions({
            width: targetRef.current.offsetWidth - 60,
            height: targetRef.current.offsetHeight - 60
          });
        }
      }, 200)
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [sidebarCollapsed]);

  useEffect(() => {
    fetchUrl()
  }, [])

  const fetchUrl = async () => {
    const res = await getMetabaseUrl()
    setUrl(res.data.url)
  }

  const cardDetails = [
    {
      icon: Utilities,
      title: t("HOMEPAGE_UI.UTILITIES.TITLE"),
      description: t("HOMEPAGE_UI.UTILITIES.DESCRIPTION"),
      buttonText: t("HOMEPAGE_UI.UTILITIES.BTN_TEXT"),
      buttonLink: "/launchpad/programs",
      disable: currentUserData.company._count.programs > 0
    },
    {
      icon: Benefits,
      title: t("HOMEPAGE_UI.BENEFITS.TITLE"),
      description: t("HOMEPAGE_UI.BENEFITS.DESCRIPTION"),
      buttonText: t("HOMEPAGE_UI.BENEFITS.BTN_TEXT"),
      buttonLink: "/launchpad/benefits",
      disable: currentUserData.company._count.benefits > 0
    }
  ];

  return (
    currentUserData.company._count.programs > 0 && currentUserData.company._count.benefits > 0 && currentUserData.company._count.collections > 0 ?
      <div className={Styles.homepage} ref={targetRef}>
        <iframe
          title="dashboard"
          src={url}
          frameBorder={0}
          width={dimensions.width}
          height={dimensions.height}
          allowTransparency
        />
      </div>
      :
      <div className={Styles.homepage}>
        <h1>{t("HOMEPAGE_UI.GET_STARTED")}</h1>
        <h2>{`${t("HOMEPAGE_UI.WELCOME")} ${currentUserData?.name}!`}</h2>
        {cardDetails.map((card, index) => {
          return (
            <Row className={Styles.row}>
              <HomepageCard
                icon={card.icon}
                title={card.title}
                description={card.description}
                buttonText={card.buttonText}
                buttonLink={card.buttonLink}
                tooltipText={card.tooltipText}
                disable={card.disable}
                md={24}
                lg={24}
                key={index}
              ></HomepageCard>
            </Row>
          );
        })}
      </div>
  );
};

export default Homepage;
