import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Searchbar from "../../components/searchBar/Searchbar";
import SearchableDropdown from '../dropdown/dropdown.searchable';
import ConfirmationModal from '../modal.popup/modal.confirmation';
import { Modal, Button, Form } from "react-bootstrap";
import { deleteMember, getRoleMembers } from '../../redux/role.slice';
import { getUsers } from '../../redux/user.slice';
import { addRoleMembers, deleteRoleMembers } from '../../pages/profile/apiUtils';
import { useProfileContext } from '../context/profile.context';
import './index.css'

const AddMembersModal = ({
    show,
    hide,
}) => {
    const [allUsers, setAllUsers] = useState([])
    const [addableUsers, setAddableUsers] = useState([])
    const [selected, setSelected] = useState([])
    const { roleMembers, setRoleMembers, selectedRole } = useProfileContext()

    const closeModal = () => {
        setSelected([])
        hide()
    }

    const onAddMembers = (members) => {
        if (members && members.length > 0) {
            addRoleMembers(selectedRole.id, members).then(response => {
                const updatedMembers = response.data.roleUsers.map(item => ({
                    id: item.userId,
                    name: item.user.name,
                    email: item.user.email,
                    phone: item.user.phone
                }))
                setRoleMembers(prev => ({
                    ...prev,
                    [response.data.id]: updatedMembers
                }))
                closeModal()
            })
        } else {
            closeModal()
        }
    }

    // Load users on first render
    const dispatch = useDispatch()
    useEffect(() => {
        let mounted = true
        if (mounted) {
            dispatch(getUsers()).then(response => setAllUsers(response.payload))
        }
        return () => mounted = false
    }, [])

    // Only display users that have are not already members for this role
    useEffect(() => {
        let mounted = true
        if (mounted && allUsers && selectedRole && roleMembers[selectedRole.id]) {
            const currentMemberIds = roleMembers[selectedRole.id].map(member => member.id)
            setAddableUsers(allUsers.filter(user => !currentMemberIds.includes(user.id)))
        }
        return () => mounted = false
    }, [allUsers, roleMembers, selectedRole])

    return (
        <Modal
            centered
            backdrop={true}
            show={show}
            onHide={closeModal}
            className="modalStyle"
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-black">
                    Add Members
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-black">
                <Form.Label>
                    Select users to add as members for this role
                </Form.Label>
                <SearchableDropdown
                    options={addableUsers}
                    searchPrompt="Search for a user"
                    emptyPrompt="No more users can be added to this role"
                    selected={selected}
                    setSelected={setSelected}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="btnSecondary" onClick={closeModal}>Cancel</Button>
                <Button
                    className="btnPrimary"
                    type="submit"
                    onClick={() => onAddMembers(selected)}
                >
                    Add Members
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const MemberRowItem = ({
    member,
    onClickDelete
}) => {
    return (
        <div className="row" style={{ height: "40px" }}>
            <div className="d-flex align-items-center">
                <div className='col-4'>
                    <i className="bi bi-person-circle pr-2"></i>
                    {member.name}
                </div>
                <div className='col-4'>
                    {member.email ? member.email : "----------------------"}
                </div>
                <div className='col-3'>
                    {member.phone ? member.phone : "----------------------"}
                </div>
                <div className='col-2'>
                    <button
                        className="bg-transparent text-black"
                        onClick={() => onClickDelete(member)}
                    >
                        <i className="bi bi-trash"></i>
                    </button>

                </div>

            </div>
        </div>
    )
}

export const RoleMembers = ({
}) => {

    const dispatch = useDispatch()
    const { roleMembers, setRoleMembers, selectedRole } = useProfileContext()
    const [displayedMembers, setDisplayedMembers] = useState([])

    const [searchString, setSearchString] = useState("")
    const [isSearchString, setIsSearchString] = useState(false);
    const [showAddMembersModal, setShowAddMembersModal] = useState(false)
    const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false)
    const [deletingMember, setDeletingMember] = useState()

    // Load members for this role if not yet loaded
    useEffect(() => {
        let mounted = true
        if (mounted && selectedRole.id !== "new" && !roleMembers[selectedRole.id]) {
            dispatch(getRoleMembers({ roleId: selectedRole.id })).then(res => {
                setRoleMembers(prev => ({ ...prev, ...res.payload }))
            })
        }
        return () => mounted = false
    }, [selectedRole])

    // Update displayed members when changes in the search bar, roleState, or role
    useEffect(() => {
        let mounted = true
        if (mounted) {
            const allMembers = roleMembers[selectedRole.id] ? [...roleMembers[selectedRole.id]].sort((a, b) => a.name - b.name) : []
            setDisplayedMembers(allMembers.filter(m => m.name.toLowerCase().startsWith(searchString.toLowerCase())))
        }
    }, [searchString, selectedRole, roleMembers])

    const onClickAddMembers = () => {
        setShowAddMembersModal(true)
    }
    const onClickDeleteMember = (member) => {
        setShowDeleteMemberModal(true)
        setDeletingMember(member)
    }
    const onDeleteMember = async () => {
        console.log(deletingMember.id)
        const response = await deleteRoleMembers(selectedRole.id, [deletingMember.id])
        const updatedMembers = [...roleMembers[selectedRole.id]].filter(member => member.id !== deletingMember.id)
        setRoleMembers(prev => ({
            ...prev,
            [selectedRole.id]: updatedMembers
        }))
    }

    return (
        <div className="table-sec mb-3 p-0">
            <AddMembersModal
                show={showAddMembersModal}
                hide={() => setShowAddMembersModal(false)}
            />
            <ConfirmationModal
                show={showDeleteMemberModal}
                hide={() => setShowDeleteMemberModal(false)}
                title={`Removing "${deletingMember ? deletingMember.name : ""}"`}
                bodyText={`Are you sure you want to remove ${deletingMember ? deletingMember.name : ""} from ${selectedRole.name}?`}
                confirmText={"Yes, Remove"}
                action={onDeleteMember}
            />
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <Searchbar
                            setValue={setSearchString}
                            setIsSearchString={setIsSearchString}
                            placeholder="Search Members"
                            width={"100%"}
                        />
                    </div>
                    <div className='col-6'>
                        <Button
                            className="btnPrimary"
                            type="submit"
                            onClick={onClickAddMembers}
                        >
                            Add Members
                        </Button>
                    </div>
                </div>

                <br />
                <div className="row" style={{ height: "40px" }}>
                    <div className="d-flex align-items-center">
                        <div className='col-4'>
                            Name
                        </div>
                        <div className='col-4'>
                            Email
                        </div>
                        <div className='col-3'>
                            Phone Number
                        </div>
                        <div className='col-2'>
                            Action
                        </div>
                    </div>
                </div>
                {
                    displayedMembers.map((member, i) =>
                        <MemberRowItem
                            member={member}
                            key={i}
                            onClickDelete={onClickDeleteMember}
                        />
                    )
                }
            </div>
        </div>
    )
}