import { useState } from "react";
import { Modal } from "antd";
import {t} from "i18next";

import { Button } from "../customAntd";

import Styles from "./styles/trigger.module.scss";

const Trigger = ({ trigger, text, buttonClassName, style }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Button
        type="link"
        onClick={() => {
          setOpenModal(true);
        }}
        className={`${Styles["trigger-button"]} ${buttonClassName}`}
        style={style || {}}
      >
        {trigger}
      </Button>
      <Modal
        centered
        closable={false}
        open={openModal}
        className={Styles["full-text-modal"]}
        onOk={() => {
          setOpenModal(false);
        }}
        okText={t("OK")}
      >
        {text}
      </Modal>
    </>
  );
};

export default Trigger;
