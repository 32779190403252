import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalHeader,
  UploadFile,
} from "../../../../components/customAntd";
import { Tabs, message } from "antd";

import { getMetabaseUrl } from "../apiUtils";
import { BREADCRUMB_STOREFRONT_MEMBER_ID_UPLOAD } from "../../../../utils/storeFront/constant";

import styles from "./styles.module.scss";

const MemberIdCsvUpload = ({ isOpen, onClose, store }) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [iframeUrls, setIframeUrls] = useState();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [tab, setTab] = useState("");

  const targetRef = useRef();

  const { t } = useTranslation();

  const fetchUrl = async () => {
    if (store?.id) {
      const res = await getMetabaseUrl(store.id);
      setIframeUrls(res.data);
    }
  };

  useEffect(() => {
    fetchUrl();
  }, [isOpen]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setTimeout(() => {
        if (targetRef.current) {
          setDimensions({
            width: dimensions.width !== 0 ? dimensions.width : targetRef.current.offsetWidth,
            height: dimensions.height !== 0 ? dimensions.height : targetRef.current.offsetHeight,
          });
        }
      }, 200);
    };

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [isOpen, tab]);

  const iframeUrl = (type) => {
    let url
    switch (type) {
      case "success":
        url = iframeUrls?.successUrl
        break;
      case "failed":
        url = iframeUrls?.failedUrl
        break;
      case "unregistered":
        url = iframeUrls?.unregisteredUrl
        break;
      default:
        break;
    }

    return url
  }

  const renderIframe = (type) => {
    return (
      <div ref={targetRef} style={{ width: "100%", height: "43vh" }}>
        <iframe
          src={iframeUrl(type)}
          frameBorder={0}
          width={dimensions.width}
          height={dimensions.height}
          allowTransparency
        />
      </div>
    );
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        if (!uploadingFile) onClose();
      }}
      title={
        <ModalHeader
          breadcrumbs={BREADCRUMB_STOREFRONT_MEMBER_ID_UPLOAD(t)}
          title={t("MEMBER_ID.UPLOAD_DATA")}
        />
      }
      width={1000}
      destroyOnClose
      className={styles["upload-modal"]}
      footer={null}
    >
      <Tabs
        onChange={(key) => {
          if (["success", "failed"].includes(key)) setTab(key);
        }}
        items={[
          {
            label: "Upload",
            key: "upload",
            children: (
              <>
                <UploadFile
                  fileFor="preRegisterUsers"
                  showUploadList={false}
                  placeholder={t("MEMBER_ID.SELECT_CSV_FILE")}
                  fileSize={20}
                  accept=".csv"
                  onLoading={(value) => setUploadingFile(value)}
                  onChange={() => {
                    fetchUrl();
                    message.success({
                      content: t("MEMBER_ID.FILE_UPLOAD_SUCCESSFULLY"),
                      key: "success",
                      duration: 3,
                    });
                  }}
                  storeFrontId={store?.id}
                />
              </>
            ),
          },
          {
            label: "Successful",
            key: "success",
            children: renderIframe("success"),
          },
          {
            label: "Failed",
            key: "failed",
            children: renderIframe("failed"),
          },
          {
            label: "Unregistered User",
            key: "unregistered",
            children: renderIframe("unregistered"),
          },
        ]}
      />
    </Modal>
  );
};

export default MemberIdCsvUpload;
