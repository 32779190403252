import { Card as CardAntd } from "antd";
import Tag from "../tag";

import Styles from "./styles/card.module.scss";

const { Meta } = CardAntd;

const Card = ({
  size,
  title,
  tag,
  className,
  tagDisabled,
  autoHeight,
  children,
  selectedCard,
  isActiveDate,
  ...props
}) => {
  const sizeClass = size === "small" ? Styles.small : "";
  const cardTitle = tag ? (
    <>
      <Tag className="test" isDisabled={tagDisabled}>
        {tag}
      </Tag>{" "}
      {title}
    </>
  ) : (
    title
  );
  return (
    <CardAntd
      title={cardTitle}
      className={`${Styles.card} ${sizeClass}${
        className ? ` ${className}` : ""
      }${selectedCard ? Styles.selectedCard : ""} ${
        autoHeight ? Styles.autoHeight : ""
      } ${title || tag ? Styles["has-header"] : ""} ${
        isActiveDate ? Styles["show-active-date"] : ""
      }`}
      {...props}
    >
      {children}
    </CardAntd>
  );
};

Card.Meta = Meta;

export default Card;
