import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Html5QrcodeScanner } from "html5-qrcode";
import { MdQrCodeScanner, MdClear } from "react-icons/md";
import "./scan.css";

import ValidResult from "./scan.result";
import InvalidCard from "./invalidCard";
import { usePosition } from "./scan.hooks";
import { scanPass } from "./apiUtils";
import { useTranslation } from "react-i18next";

const modes = {
  NEW_SCAN: "NEW_SCAN",
  LOADING_SCANNER: "LOADING_SCANNER",
  SCANNING: "SCANNING",
  SUBMITTING: "SBUMITTING",
  RESULT: "RESULT",
};

function Scan() {
  const [validResponse, setValidResponse] = useState(null);
  const [invalidMessage, setInvalidMessage] = useState(null);
  const [mode, setMode] = useState(modes.NEW_SCAN);
  const [position, error] = usePosition();
  const [curPassId, setCurPassId] = useState(null);
  const { t } = useTranslation();

  const reset = () => {
    setMode(modes.NEW_SCAN);
    setCurPassId(null);
    setValidResponse(null);
    setInvalidMessage(null);
  };

  const scan = async (passId) => {
    setMode(modes.SUBMITTING);
    try {
      const scanResponse = await scanPass(passId, position);
      setCurPassId(passId);
      setValidResponse(scanResponse);
      setMode(modes.RESULT);
    } catch (error) {
      setInvalidMessage(getInvalidMessage(error));
      setMode(modes.RESULT);
    }
  };

  const getInvalidMessage = (error) => {
    const status =
      error.response && error.response.status ? error.response.status : 500;
    if (status == 500) {
      return t("SCAN.INVALID.UNKNOWN_REASON");
    } else {
      return error.response.data.message;
    }
  };

  const handleClick = () => {
    switch (mode) {
      case modes.NEW_SCAN:
        setMode(modes.LOADING_SCANNER);
        break;
      case modes.SCANNING:
        setMode(modes.NEW_SCAN);
        break;
      case modes.RESULT:
        setMode(modes.LOADING_SCANNER);
        break;
    }
  };
  console.log(mode, "MODE condition");

  return (
    <div className="d-flex flex-column h-100">
      <h1 className="scan-heading">{t("SCAN.SCAN")}</h1>
      <div className="scan-container">
        {mode === modes.NEW_SCAN ? (
          <div className="d-flex h-100 flex-column justify-content-center align-items-center">
            <Button
              className="scan-button ml-2"
              onClick={handleClick}
              disabled={mode === modes.SUBMITTING}
            >
              <MdQrCodeScanner size={"50px"} />
            </Button>
            <p className="mt-2">{t("SCAN.TAP_TO_SCAN")}</p>
          </div>
        ) : mode === modes.LOADING_SCANNER || mode === modes.SCANNING ? (
          <QrScannerNew scan={scan} mode={mode} setMode={setMode} />
        ) : mode === modes.SUBMITTING ? (
          <LoadingSpinner />
        ) : (
          mode === modes.RESULT && (
            <Response
              validResponse={validResponse}
              passId={curPassId}
              invalidMessage={invalidMessage}
              reset={reset}
            />
          )
        )}
      </div>
    </div>
  );
}

const LoadingSpinner = () => {
  const { t } = useTranslation();
  return (
    <div
      className="spinner-border absolute-center"
      role="status"
      style={{ color: "#c62cf1" }}
    >
      <span className="sr-only">{t("LOADING")}...</span>
    </div>
  );
};

const Response = ({ validResponse, invalidMessage, reset, passId }) => {
  const { t } = useTranslation();
  return (
    <>
      {validResponse ? (
        <ValidResult validResponse={validResponse} passId={passId} />
      ) : invalidMessage ? (
        <InvalidCard invalidMessage={invalidMessage} />
      ) : (
        <div>{t("ERROR")}</div>
      )}
      <div className="back-button-container">
        <Button className="btnPrimary back-button" onClick={reset}>
          {t("SCAN.BACK_TO_SCAN")}
        </Button>
      </div>
    </>
  );
};

const QrScannerNew = ({ scan, mode, setMode }) => {
  let scanned = false;
  const config = {
    fps: 10,
    qrbox: 250,
    qrCodeSuccessCallback: (decodedText) => {
      if (decodedText && !scanned) {
        scan(decodedText);
        scanned = true;
      }
      if (mode === modes.LOADING_SCANNER && mode !== modes.SCANNING) {
        console.log("Setting scanning mode to scanning");
        setMode(modes.SUBMITTING);
      }
    },
    qrCodeErrorCallback: (err) => {},
    disableFlip: false,
  };

  useEffect(() => {
    const verbose = false;
    // Suceess callback is required.
    if (!config.qrCodeSuccessCallback) {
      throw "qrCodeSuccessCallback is required callback.";
    }
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "qrCodeScanner",
      config,
      verbose
    );
    html5QrcodeScanner.render(
      config.qrCodeSuccessCallback,
      config.qrCodeErrorCallback
    );

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, []);

  return (
    <div>
      <MdClear
        size={"25px"}
        color={"#c62cf1"}
        onClick={() => {
          setMode(modes.NEW_SCAN);
        }}
      />
      <div id="qrCodeScanner" />
    </div>
  );
};

export default Scan;
