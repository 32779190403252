import Breadcrumb from "../../breadCrumb";

import Styles from "./styles/modalHeader.module.scss";

const ModalHeader = ({ title, breadcrumbs }) => {
  return (
    <>
      {breadcrumbs && <Breadcrumb items={breadcrumbs} />}
      <h2 className={Styles.title}>{title}</h2>
    </>
  )
}

export default ModalHeader;