import axios from "axios";

import constant from "../../config/constant";
const baseURL = constant.config.REACT_API_HOST;

export const userLogin = async (data) => {
  return axios
    .post(`${baseURL}users/login`, {
      ...data,
    })
    .then((resp) => resp)
    .catch((err) => Promise.reject(err.response));
};
