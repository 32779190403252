export const BREADCRUMB_PROGRAMS = (t)=>[
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("PROGRAMS"),
  },
];
export const BREADCRUMB_PROGRAMS_ATTACH_BENEFITS = (t) =>[
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("PROGRAMS"),
    link: "/launchpad/programs",
  },
  {
    title: t("BENEFITS"),
  },
];
export const BREADCRUMB_TIER = (t,bc) => {
  return ([
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("PROGRAMS"),
    link: "/launchpad/programs",

  },
  ...bc
])};
export const BREADCRUMB_PROGRAMS_CREATE = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("PROGRAMS"),
  },
];

export const BREADCRUMB_PROGRAMS_DETAIL_PAGE = (name, t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("PROGRAMS"),
    link: "/launchpad/programs",
  },
  {
    title: name,
  },
];

export const BREADCRUMB_TIER_CREATE_PUSH_NOTIFICATION = (programName, tierName, id, t) => [
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("PROGRAMS"),
    link: "/launchpad/program",
  },
  {
    title:  programName,
    link: `/launchpad/programs/${id}`
  },
  {
    title: tierName,
    link: ""
  }

]

export const BREADCRUMB_PROGRAM_WAY_TO_EARN = (programName, t) => [
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("PROGRAMS"),
    link: "/launchpad/program",
  },
  {
    title:  programName,
    link: ""
  },
  {
    title: "Points",
  }
]