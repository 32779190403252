import { Empty } from "antd";

import Styles from "./styles/noData.module.scss";

const NoData = ({ className, ...props }) => {
  return (
    <div className={`${Styles["no-data"]} ${className ? className : ""}`}>
      <Empty {...props} />
    </div>
  );
};

export default NoData;
