import axios from "axios";

import constant from "../../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const getAllProgramList = async (params) => {
  const { limit = 10, offset = 0 } = params;
  const filterConditionString = "&status=Live&storefront=null";
  return axios
    .get(
      `${baseURL}programs?limit=${limit}&offset=${offset}${filterConditionString}`
    )
    .then((response) => response)
    .catch((err) => {
      return err.response?.data?.message ?? err.response;
    });
};

export const getAllStorefrontList = async (params) => {
  // const { limit = 10, offset = 0 } = params;
  // let filterConditionString = "";
  // for (const [key, value] of Object.entries(
  //   params.filterCondition
  // )) {
  //   if (value) {
  //     filterConditionString += `&filterCondition[${key}]=${value}`;
  //   }
  // }
  return axios
    .post(`${baseURL}storefront/all`, params)
    .then((response) => response)
    .catch((err) => {
      return err.response?.data?.message ?? err.response;
    });
};

export const addStorefront = async (data) => {
  return axios
    .post(`${baseURL}storefront`, { ...data })
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const updateStorefront = async (data, id) => {
  return axios
    .put(`${baseURL}storefront/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getStorefront = async (id) => {
  return axios
    .get(`${baseURL}storefront/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const deleteStorefront = async (id) => {
  return axios
    .delete(`${baseURL}storefront/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getAllFAQ = async (id, data = null) => {
  return axios
    .post(`${baseURL}storefront/${id}/faqs/all`, { ...data })
    .then((response) => response.data)
    .catch((err) => {
      return err.response;
    });
};

export const updateFAQ = async (data, id) => {
  return axios
    .put(`${baseURL}storefront/${id}/faqs`, [...data])
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const unarchiveStorefront = async (id) => {
  return axios
    .put(`${baseURL}storefront/${id}/unarchive`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getMetabaseUrl = async (id) => {
  return axios
    .get(`${baseURL}storefront/${id}/pre-register-users/report-urls`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getUsersList = async (id, filterCondition) => {
  return axios
    .post(`${baseURL}storefront/${id}/users/all`, filterCondition)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const changeUserStatus = async (storeId, userId, isActive) => {
  return axios
    .put(`${baseURL}storefront/${storeId}/users/${userId}`, { isActive })
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const resetUserPassword = async (storeId, userId, password) => {
  return axios
    .put(`${baseURL}storefront/${storeId}/users/${userId}/changepassword`, {
      password,
    })
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
