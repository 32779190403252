import { useState } from "react";
import {
  Table,
  Row,
  Col,
  Form,
  message,
  Popconfirm,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";

import { PageHeader, Button, Micons } from "../../../../components/customAntd";
import Styles from "../styles/pointsSettings.module.scss";
import ProgramDetailStyles from "../styles/programDetails.module.scss";
import WayToSpendForm from "./wayToSpendForm";
import {
  attachBenefitToTier,
  detachBenefitFromTier,
} from "../apiUtils";
// import { disconnectTierFromNft } from "../../../nftDetail/apiUtils";
import {
  capitalize,
  getRewardId,
} from "./utils";

const SpendPointsSettings = ({
  allTiers,
  merchantData,
  benefitData,
  rewards,
  setRewards,
  programDetails,
}) => {
  const [rewardModalOpen, setRewardModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rewardIdEdit, setRewardIdEdit] = useState(null);

  const BENEFIT_TYPE_TO_TITLE = {
    coupon: "Online Discount Code",
    video: "Video",
    audio: "Audio",
    digitalbook: "Digital eBook",
  };

  const columns = [
    {
      title: t("NAME"),
      className: Styles["table-column"],
      render: (reward) => <h4>{reward.name}</h4>,
    },
    {
      title: t("TYPE"),
      className: Styles["table-column"],
      render: (reward) => (
        <h2>{`${capitalize(reward.rewardType)} ${
          reward.rewardType === "benefit"
            ? `: ${BENEFIT_TYPE_TO_TITLE[reward.benefitType]}`
            : ""
        }`}</h2>
      ),
    },
    {
      title: t("PROGRAM_UI.SPEND_POINTS_PAGE.POINTS_PRICE"),
      className: Styles["table-column"],
      render: (reward) => <h2>{reward.pointsNeeded}</h2>,
    },
    {
      title: t("TIERS"),
      className: Styles["table-column"],
      render: (reward) => {
        const tiers = allTiers.filter((tier) =>
          reward.tierIds.some((id) => id === tier.id)
        );
        return (
          <>
            {tiers.length > 0 ? (
              tiers.map((tier) => <h2>{tier.name}</h2>)
            ) : (
              <h2>All</h2>
            )}
          </>
        );
      },
    },
    {
      title: " ",
      render: (reward) => (
        <EditDelete
          reward={reward}
          handleEditClick={handleEditClick}
          handleDelete={handleDelete}
        />
      ),
    },
  ];

  const handleFormSubmit = async (formData) => {
    try {
      setIsSubmitting(true);
      if (!isEdit) {
        let reward;
        if (formData.way_to_spend_type === "benefit") {
          reward = await addBenefitReward(formData, isEdit);
        } 
        // else if (formData.way_to_spend_type === "nft") {
        //   reward = await addNFTReward(formData);
        // }
        if (!rewards.find((r) => r.id == reward.id)) {
          setRewards((prev) => [...prev, reward]);
        }
      } else {
        // Get position of existing reward
        let pos = rewards.findIndex((r) => r.id === rewardIdEdit);
        // Delete existing reward
        await handleDelete(rewards[pos]);
        // Create updated reward
        let reward;
        if (formData.way_to_spend_type === "benefit") {
          reward = await addBenefitReward(formData, isEdit);
        } 
        // else {
        //   reward = await addNFTReward(formData, isEdit);
        // }
        // Insert back into original position
        setRewards((prev) => {
          const newRewards = [...prev];
          newRewards.splice(pos, 0, reward);
          return newRewards;
        });
      }
      handleClose();
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      message.error(err.message);
    }
  };

  // const addNFTReward = async (formData) => {
  //   const data = {
  //     body: {
  //       nftId: Number(formData.nft_select),
  //       pointPrice: Number(formData.point_price),
  //     },
  //     programId: programDetails?.id,
  //     tierId: Number(formData.tier_select),
  //   };
  //   let response = await connectTierToCollectionOrNFt(data);
  //   if (response.message) {
  //     throw new Error(response.message);
  //   }

  //   let updatedNft = response.data;
  //   setNftData((prev) => {
  //     let pos = prev.findIndex((nft) => nft.id === updatedNft.id);
  //     return [
  //       ...prev.slice(0, pos),
  //       { ...prev[pos], ...updatedNft },
  //       ...prev.slice(pos + 1),
  //     ];
  //   });
  //   let reward = getNftRewardsFromNfts([updatedNft], collections)[0];
  //   return reward;
  // };

  const addBenefitReward = async (formData, isEdit) => {
    // Only attach benefit to the given tiers if provided, otherwise attach to all
    let tierIdsToLink = formData.tier_select
      ? formData.tier_select
      : allTiers.map((tier) => tier.id);

    if (!isEdit) {
      // Verify that we are not trying to overwrite any existing TierBenefits
      let rewardsWithSameBenefit = rewards.filter(
        (r) => r.benefitId === formData.benefit_select
      );
      let overwriting = tierIdsToLink.some((tierIdA) =>
        rewardsWithSameBenefit.some((reward) =>
          reward.tierIds.some((tierIdB) => tierIdA === tierIdB)
        )
      );
      if (overwriting) {
        throw new Error(
          "You already have a reward with this benefit and at least one of these tiers. Please delete or edit that reward first."
        );
      }
    }

    // Create TierBenefits for this reward
    for (let tierId of tierIdsToLink) {
      const response = await attachBenefitToTier(
        {
          benefits: [
            {
              benefitId: formData.benefit_select,
              pointPrice: formData.point_price,
            },
          ],
        },
        programDetails?.id,
        tierId
      );
      if (response?.status !== 200) {
        throw new Error(
          response.message ??
            JSON.stringify(response.data.message) ??
            "Something went wrong while adding a new reward"
        );
      }
    }
    let benefit = benefitData.find((b) => b.id === formData.benefit_select);
    let reward = {
      name: benefit.label,
      rewardType: "benefit",
      benefitType: benefit.type,
      benefitId: benefit.id,
      pointsNeeded: formData.point_price,
      tierIds: tierIdsToLink,
    };
    return { ...reward, id: getRewardId(reward) };
  };

  // Handler for the edit reward button
  const handleEditClick = (reward) => {
    const formData = {
      way_to_spend_type: reward.rewardType,
      benefit_type: reward.benefitType,
      benefit_select: reward.benefitId,
      collection_select: reward.collection,
      nft_select: reward.nftId,
      point_price: reward.pointsNeeded,
      tier_select: reward.tierIds,
    };
    form.resetFields();
    setRewardIdEdit(reward.id);
    setIsEdit(true);
    form.setFieldsValue(formData);
    setRewardModalOpen(true);
  };

  const handleDelete = async (reward) => {
    try {
      if (reward.rewardType === "benefit") {
        for (let tierId of reward.tierIds) {
          const response = await detachBenefitFromTier(
            { benefits: [reward.benefitId] },
            programDetails?.id,
            tierId
          );
          if (response?.status !== 200) {
            throw new Error(
              response.message ??
                JSON.stringify(response.data.message) ??
                "Something went wrong while deleting a reward"
            );
          }
        }
       } 
      setRewards((prev) => prev.filter((item) => item.id !== reward.id));
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleClose = () => {
    form.resetFields();
    setRewardModalOpen(false);
    setIsEdit(false);
  };

  return (
    <div>
      <div className={Styles["earn-points"]}>
        <PageHeader
          title={
            <div className={Styles["header"]}>
              <h2>{t("PROGRAM_UI.SPEND_POINTS")}</h2>
              <h3>{t("PROGRAM_UI.SPEND_POINTS_PAGE.ADD_BENEFIT_AND_NFTS")}</h3>
            </div>
          }
          extra={[
            <Row gutter={20} className={Styles["buttons"]}>
              <Col>
                <Button type="primary" onClick={() => setRewardModalOpen(true)}>
                  <span
                    className={`material-icons-round ${Styles["add-icon"]}`}
                  >
                    add
                  </span>{" "}
                  {t("PROGRAM_UI.SPEND_POINTS_PAGE.ADD_WAY_TO_SPEND")}
                </Button>
              </Col>
            </Row>,
          ]}
          extrasClassName={ProgramDetailStyles["page-header-button-cont"]}
        />
        <Table columns={columns} dataSource={rewards} />
      </div>
      <WayToSpendForm
        form={form}
        isOpen={rewardModalOpen}
        onSubmit={handleFormSubmit}
        onClose={handleClose}
        merchantData={merchantData}
        benefitData={benefitData}
        tierData={allTiers.map((item) => {
          return {
            label: item.name,
            value: item.id,
            id: item.id,
            isSelected: false,
          };
        })}
        loading={isSubmitting || isDataLoading}
        isEdit={isEdit}
        programDetails={programDetails}
      />
    </div>
  );
};

const EditDelete = ({ reward, handleEditClick, handleDelete }) => {
  return (
    <Row className={Styles["space-between"]}>
      <Button type="link">
        <Tooltip title="Edit">
          <Micons
            icon="edit"
            className="table-action-icon"
            onClick={() => handleEditClick(reward)}
          />
        </Tooltip>
      </Button>
      <Popconfirm
        title="Are you sure to delete this way to earn?"
        onConfirm={() => handleDelete(reward)}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
        placement="topRight"
        arrowPointAtCenter
      >
        <Button type="link">
          <Tooltip title="Delete">
            <Micons icon="delete" className="table-action-icon" />
          </Tooltip>
        </Button>
      </Popconfirm>
    </Row>
  );
};

export default SpendPointsSettings;
