import { Form, Input } from "antd";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

import { FormWrapper } from "../../../../components/customAntd";

const { Item } = Form;

const MarketingSplashScreen = ({ t }) => {
  return (
    <FormWrapper heading={t("STOREFRONT_UI.MARKETING_SPLASH_SCREEN")}>
      <Item
        label={`${t("STOREFRONT_UI.HEADER_TEXT")} :`}
        name="marketingHeadline"
        rules={[
          {
            required: true,
            message: t("STOREFRONT_UI.HEADER_TEXT_VALIDATION"),
            whitespace: true,
          },
        ]}
      >
        <Input placeholder={t("STOREFRONT_UI.HEADER_TEXT_PLACEHOLDER")} />
      </Item>
      <Item
        label={`${t("STOREFRONT_UI.BODY_TEXT")} :`}
        name="marketingDesc"
        rules={[
          {
            required: true,
            message: t("STOREFRONT_UI.BODY_TEXT_VALIDATION"),
            whitespace: true,
          },
        ]}
      >
        <MDEditor
          overflow={false}
          placeholder={t("STOREFRONT_UI.BODY_TEXT_PLACEHOLDER")}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />

        {/* <TextArea
        placeholder={t("STOREFRONT_UI.BODY_TEXT_PLACEHOLDER")}
        rows={3}
      /> */}
      </Item>
    </FormWrapper>
  );
};

export default MarketingSplashScreen;
