import {
  Form,
  Select,
  Radio,
  Row,
  Col,
  Input,
  DatePicker,
  Divider,
  Popconfirm,
} from "antd";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import dayjs from "dayjs";

import {
  FormWrapper,
  UploadFile,
  Button,
} from "../../../../components/customAntd";
import {
  AnimeTemplateName,
  CommercialTemplateName,
} from "../../../../utils/constants";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const { Item } = Form;
const { RangePicker } = DatePicker;

const HeroSection = ({
  t,
  defaultLocale,
  availableLocales,
  updateLocales,
  watchDefaultLanguage,
  themeId,
  heroImage,
  setIsHeroImageLoading,
  setHeroImage,
  heroImages,
  setHeroImages,
}) => {
  return (
    <FormWrapper heading={t("STOREFRONT_UI.HERO_SECTION")}>
      <Item
        label={`${t("DEFAULT_LANGUAGE")}:`}
        name="defaultLocaleId"
        initialValue={defaultLocale}
        rules={[
          {
            required: true,
            message: t("DEFAULT_LANGUAGE_ERROR"),
            whitespace: true,
          },
        ]}
      >
        <Select
          options={availableLocales.map((localEl) => ({
            ...localEl,
            disabled: localEl.isSelected,
          }))}
          onChange={(value) => {
            updateLocales(value, watchDefaultLanguage, "defaultLanguage");
          }}
        />
      </Item>
      {themeId === CommercialTemplateName ? (
        <Form.List name="heroImages">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name }, index) => {
                  return (
                    <>
                      <Row gutter={20}>
                        <Col sm={12}>
                          <Item
                            label={t("STOREFRONT_UI.UPLOAD_HERO_IMAGE")}
                            name="heroImageUrl"
                            extra={
                              <>
                                <p>{`${t("FILE_UPLOAD.RECOMMENDED_SIZE")} - ${
                                  themeId !== AnimeTemplateName
                                    ? "1200x340"
                                    : "562x582"
                                }`}</p>
                                <p>
                                  {`${t(
                                    "FILE_TYPES_SUPPORTED"
                                  )}: JPG, JPEG, PNG. ${t("MAX_SIZE")}: 5${t(
                                    "MB"
                                  )}`}
                                </p>
                              </>
                            }
                            valuePropName=""
                            rules={[
                              {
                                required: !heroImages?.[index],
                                message: t(
                                  "STOREFRONT_UI.HERO_IMAGE_VALIDATION"
                                ),
                              },
                            ]}
                          >
                            <UploadFile
                              showUploadList={false}
                              placeholder={
                                themeId !== AnimeTemplateName
                                  ? "1200x340"
                                  : "1024x1024 (1:1)"
                              }
                              fileFor="collection"
                              fileSize={5}
                              accept=".jpg, .jpeg, .png"
                              style={{ width: "144px" }}
                              height={144}
                              image={heroImages?.[index] || ""}
                              imageFit="contain"
                              onLoading={(value) =>
                                setIsHeroImageLoading(value)
                              }
                              onChange={(image) => {
                                // setHeroImage(image.s3Url);
                                setHeroImages((prev) => {
                                  const arr = [...prev];
                                  arr[index] = image.s3Url;
                                  return arr;
                                });
                              }}
                              dimensions={{
                                height: 340,
                                width: 1200,
                              }}
                            />
                          </Item>
                        </Col>
                        <Col sm={12}>
                          <Row>
                            <Col sm={24}>
                              <Item
                                name={[name, "range"]}
                                label={`${t(
                                  "PROGRAM_UI.WAY_TO_EARN_MODAL.START_DATE"
                                )} / ${t(
                                  "PROGRAM_UI.WAY_TO_EARN_MODAL.END_DATE"
                                )}`}
                              >
                                <RangePicker
                                  disabledDate={(date) => {
                                    return date.isBefore(dayjs());
                                  }}
                                  placeholder={[t("START_DATE"), t("END_DATE")]}
                                />
                              </Item>
                            </Col>
                            <Col sm={24}>
                              <Item
                                name={[name, "status"]}
                                label={t("STATUS")}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select status",
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  <Radio value="active">{t("ACTIVE")}</Radio>
                                  <Radio value="inActive">
                                    {t("INACTIVE")}
                                  </Radio>
                                </Radio.Group>
                              </Item>
                            </Col>
                            <Col sm={24}>
                              <Item
                                name={[name, "redirectUrl"]}
                                label={t("REDIRECT_URL")}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Please select status",
                                //   },
                                // ]}
                              >
                                <Input type="url" />
                              </Item>
                            </Col>
                            <Popconfirm
                              title={t("HERO_IMAGE_CONFIRM_DELETE")}
                              onConfirm={() => {
                                setHeroImages((prev) => {
                                  prev.splice(index, 1);
                                  return [...prev];
                                });
                                remove(name);
                              }}
                              onCancel={() => {}}
                              okText={t("YES")}
                              cancelText={t("NO")}
                            >
                              <Button
                                type="link"
                                icon={<DeleteOutlined />}
                                style={{
                                  color: "var(--color-form-error)",
                                  marginLeft: "auto",
                                  marginTop: 60,
                                }}
                              >
                                {t("DELETE_HERO_IMAGE")}
                              </Button>
                            </Popconfirm>
                          </Row>
                        </Col>
                      </Row>
                      {fields.length !== 0 && (
                        <Divider
                          style={{
                            marginTop: 0,
                            marginBottom: 20,
                            borderColor: "#D9D9D9",
                          }}
                        />
                      )}
                    </>
                  );
                })}
                <Item style={{ marginBottom: 0 }}>
                  <Button
                    type="link"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    style={{
                      color: "var(--color-brand-primary)",
                      marginBottom: 30,
                    }}
                  >
                    {t("ADD_HERO_IMAGE")}
                  </Button>
                </Item>
              </>
            );
          }}
        </Form.List>
      ) : (
        <Item
          label={t("STOREFRONT_UI.UPLOAD_HERO_IMAGE")}
          name="heroImageUrl"
          extra={
            <>
              <p>{`${t("FILE_UPLOAD.RECOMMENDED_SIZE")} - ${
                themeId !== AnimeTemplateName ? "1922x565" : "562x582"
              }`}</p>
              <p>
                {`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG. ${t(
                  "MAX_SIZE"
                )}: 5${t("MB")}`}
              </p>
            </>
          }
          valuePropName=""
          rules={[
            {
              required: true,
              message: t("STOREFRONT_UI.HERO_IMAGE_VALIDATION"),
            },
          ]}
        >
          <UploadFile
            showUploadList={false}
            placeholder={
              themeId !== AnimeTemplateName ? "1922x565" : "1024x1024 (1:1)"
            }
            fileFor="collection"
            fileSize={5}
            accept=".jpg, .jpeg, .png"
            style={{ width: "144px" }}
            height={144}
            image={heroImage}
            imageFit="contain"
            onLoading={(value) => setIsHeroImageLoading(value)}
            onChange={(image) => {
              setHeroImage(image.s3Url);
            }}
          />
        </Item>
      )}
      <Item
        label={`${t("STOREFRONT_UI.HEADLINE")} :`}
        name="heroHeadline"
        rules={[
          {
            required: true,
            message: t("STOREFRONT_UI.HEADLINE_VALIDATION"),
            whitespace: true,
            validateTrigger: "onSubmit",
          },
        ]}
      >
        <Input placeholder={t("STOREFRONT_UI.HEADLINE_PLACEHOLDER")} />
      </Item>
      <Item
        label={`${t("STOREFRONT_UI.BODY")} :`}
        name="heroDesc"
        rules={[
          {
            required: true,
            message: t("STOREFRONT_UI.BODY_VALIDATION"),
            whitespace: true,
            validateTrigger: "onSubmit",
          },
        ]}
      >
        <MDEditor
          overflow={false}
          placeholder={t("STOREFRONT_UI.BODY_PLACEHOLDER")}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
        {/* <TextArea
            placeholder={t("STOREFRONT_UI.BODY_PLACEHOLDER")}
            rows={3}
          /> */}
      </Item>
      {themeId !== AnimeTemplateName ? (
        <Item
          label={t("STOREFRONT_UI.SHOW_PROGRAM_NAME")}
          name="showProgramName"
        >
          <Radio.Group style={{ width: "100%" }}>
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <Radio style={{ width: "100%" }} value={true}>
                  {t("YES")}
                </Radio>
              </Col>
              <Col xs={24} md={12}>
                <Radio style={{ width: "100%" }} value={false}>
                  {t("NO")}
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Item>
      ) : (
        <>
          <Item
            label={`${t("STOREFRONT_UI.SHOW_BENEFITS_CAROUSEL")}:`}
            name="showBenefitsCarousel"
          >
            <Radio.Group style={{ width: "100%" }}>
              <Row gutter={20}>
                <Col xs={24} md={12}>
                  <Radio style={{ width: "100%" }} value={true}>
                    {t("YES")}
                  </Radio>
                </Col>
                <Col xs={24} md={12}>
                  <Radio style={{ width: "100%" }} value={false}>
                    {t("NO")}
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Item>
          <Item
            label={`${t("STOREFRONT_UI.BENEFITS_HEADLINE")} :`}
            name="heroBenefitsHeadline"
            rules={[
              {
                required: true,
                message: t("STOREFRONT_UI.BENEFITS_HEADLINE_VALIDATION"),
                whitespace: true,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input
              placeholder={t("STOREFRONT_UI.BENEFITS_HEADLINE_PLACEHOLDER")}
            />
          </Item>
          <Item
            label={`${t("STOREFRONT_UI.BENEFITS_DESCRIPTION")} :`}
            name="heroBenefitsDescription"
            rules={[
              {
                required: true,
                message: t("STOREFRONT_UI.BENEFITS_DESCRIPTION_VALIDATION"),
                whitespace: true,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <MDEditor
              overflow={false}
              placeholder={t("STOREFRONT_UI.BENEFITS_DESCRIPTION_PLACEHOLDER")}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
            />
            {/* <Input
            placeholder={t(
              "STOREFRONT_UI.BENEFITS_DESCRIPTION_PLACEHOLDER"
            )}
          /> */}
          </Item>
        </>
      )}
    </FormWrapper>
  );
};

export default HeroSection;
