import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { removeRoleByIdBFS, findRoleByIdBFS } from "../utils/rolesHelper";
import axios from "axios";
import { toast } from "react-toastify";
import constant from "./../config/constant";

const baseURL = constant.config.REACT_API_HOST;

const showErrorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const DUMMY_USER_ROLES = [
  "General Admin",
  "Museum Admin",
  "Art Museum Admin",
  "History Museum Admin",
];

const DUMMY_ROLES = {
  name: "General Admin",
  id: "General Admin",
  numMembers: 1,
  numChildRoles: 3,
  children: [
    {
      name: "Museum Admin",
      id: "Museum Admin",
      numMembers: 2,
      numChildRoles: 2,
      children: [
        {
          name: "Art Museum Admin",
          id: "Art Museum Admin",
          numMembers: 3,
          numChildRoles: 0,
          children: [],
        },
        {
          name: "History Museum Admin",
          id: "History Museum Admin",
          numMembers: 2,
          numChildRoles: 0,
          children: [],
        },
      ],
    },
  ],
};

const DUMMY_PERMISSIONS = [
  {
    description: "Create, edit, and delete roles",
    id: 0,
  },
  {
    description: "Create, edit, and delete benefits",
    id: 1,
  },
  {
    description: "Import an NFT collection",
    id: 2,
  },
  {
    description: "Create new programs",
    id: 3,
  },
];

const initialState = {
  roleTree: DUMMY_ROLES,
  userRoles: DUMMY_USER_ROLES,
  roleMembers: {},
  permissionsMetadata: [],
  success: "",
  error: "",
  loading: false,
};

export const getRoleTree = createAsyncThunk(
  "role/getRoleTree",
  async (data, { rejectWithValue }) => {
    console.log("GETTING ROLE TREE");
    try {
      const response = await axios.get(
        `${baseURL}admin/role/${
          !!localStorage.getItem("proxyCompanyAdminRoleId")
            ? localStorage.getItem("proxyCompanyAdminRoleId")
            : data.currentRoleId
        }/tree`
      );
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      throw error;
    }
  }
);

export const getPermissionsMetadata = createAsyncThunk(
  "role/getPermissionsMetadata",
  async (data, { rejectWithValue }) => {
    console.log("GETTING PERMISSIONS METADATA");
    try {
      const response = await axios.get(`${baseURL}admin/permissions-metadata`);
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRolePermissions = createAsyncThunk(
  "role/getRolePermissions",
  async (data, { rejectWithValue }) => {
    console.log("GETTING ROLE PERMISSIONS");
    try {
      const response = await axios.get(`${baseURL}admin/role/${data.roleId}`);
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRoleMembers = createAsyncThunk(
  "role/getRoleMembers",
  async (data, { rejectWithValue }) => {
    console.log("GETTING ROLE MEMBERS");
    try {
      const response = await axios.get(
        `${baseURL}admin/role/users/${data.roleId}`
      );
      const members = response.data.map((roleMember) => ({
        id: roleMember.userId,
        name: roleMember.user.name,
        email: roleMember.user.email,
        phone: roleMember.user.phone,
      }));
      return { [data.roleId]: members };
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRole = createAsyncThunk(
  "role/updateRole",
  async (data, { rejectWithValue }) => {
    console.log("UPDATING ROLE");
    try {
      const response = await axios.put(`${baseURL}admin/role/${data.id}`, data);
      return data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const createRole = createAsyncThunk(
  "role/createRole",
  async (data, { rejectWithValue }) => {
    console.log("CREATING ROLE");
    try {
      const response = await axios.post(`${baseURL}admin/role/`, data);
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "role/deleteRole",
  async (data, { rejectWithValue }) => {
    console.log("DELETING ROLE");
    try {
      const response = await axios.delete(`${baseURL}admin/role/${data.id}`);
      return data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    addMember(state, action) {
      state.roleMembers[action.payload.roleId] = [
        ...state.roleMembers[action.payload.roleId],
        {
          id: state.roleMembers[action.payload.roleId].length,
          name: action.payload.name,
          email: action.payload.email,
          phone: action.payload.phone,
        },
      ];
    },
    deleteMember(state, action) {
      state.roleMembers[action.payload.roleId] = state.roleMembers[
        action.payload.roleId
      ].filter((member) => member.id != action.payload.memberId);
    },
    editMember(state, action) {
      state.roleMembers[action.payload.roleId] = state.roleMembers[
        action.payload.roleId
      ].map((member) => {
        if (member.id == action.payload.memberId) {
          return {
            id: member.id,
            name: action.payload.name,
            email: action.payload.email,
            phone: action.payload.phone,
          };
        } else {
          return member;
        }
      });
    },
  },
  extraReducers: {
    [getRoleTree.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [getRoleTree.fulfilled]: (state, action) => {
      return {
        ...state,
        roleTree: action.payload,
        success: "success",
        error: "",
        loading: false,
      };
    },
    [getRoleTree.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [getPermissionsMetadata.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [getPermissionsMetadata.fulfilled]: (state, action) => {
      return {
        ...state,
        permissionsMetadata: action.payload,
        success: "success",
        error: "",
        loading: false,
      };
    },
    [getPermissionsMetadata.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [getRolePermissions.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [getRolePermissions.fulfilled]: (state, action) => {
      const roleToUpdate = findRoleByIdBFS(action.payload.id, state.roleTree);
      roleToUpdate.rolePermissions = action.payload.rolePermissions;
      state.success = "success";
      state.error = "";
      state.loading = false;
    },
    [getRolePermissions.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [getRoleMembers.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [getRoleMembers.fulfilled]: (state, action) => {
      return {
        ...state,
        roleMembers: { ...state.roleMembers, ...action.payload },
        success: "success",
        error: "",
        loading: false,
      };
    },
    [getRoleMembers.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [updateRole.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [updateRole.fulfilled]: (state, action) => {
      const roleToUpdate = findRoleByIdBFS(action.payload.id, state.roleTree);
      roleToUpdate.name = action.payload.name;
      roleToUpdate.rolePermissions = action.payload.rolePermissions;
      state.success = "success";
      state.error = "";
      state.loading = false;
    },
    [updateRole.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [createRole.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [createRole.fulfilled]: (state, action) => {
      findRoleByIdBFS(
        action.payload.parentRoleId,
        state.roleTree
      ).children.unshift({ ...action.payload, children: [] });
      state.success = "success";
      state.error = "";
      state.loading = false;
    },
    [createRole.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [deleteRole.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.roleTree = removeRoleByIdBFS(action.payload.id, state.roleTree);
      state.success = "success";
      state.error = "";
      state.loading = false;
    },
    [deleteRole.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
  },
});

export const { addMember, deleteMember, editMember } = roleSlice.actions;
export default roleSlice.reducer;
