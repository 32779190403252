import axios from "axios";

import constant from "../../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const getAllMerchantsFromDb = async (filters) => {
    return axios
        .post(`${baseURL}merchant/all`, filters)
        .then((response) => response)
        .catch((err) => {
            console.log(
                `Get all merchants from DB error: \n\n${err.response?.data?.message}`
            );
            return err.response;
        });
};

export const addMerchantToDb = async (data) => {
    return axios
        .post(`${baseURL}merchant`, data)
        .then((response) => response)
        .catch((err) => {
            console.log(
                `Add merchant to DB error: \n\n${err.response?.data?.message}`
            );
            return err.response;
        });
};

export const updateMerchantToDb = async (data, id) => {
    return axios
        .put(`${baseURL}merchant/${id}`, data)
        .then((response) => response)
        .catch((err) => {
            console.log(
                `Update benefit to DB error: \n\n${err.response?.data?.message}`
            );
            return err.response;
        });
};

export const deleteMerchantFromDb = async (id) => {
    return axios
        .delete(`${baseURL}merchant/${id}`)
        .then((response) => response)
        .catch((err) => {
            console.log(
                `Delete benefit from DB error: \n\n${err.response?.data?.message}`
            );
            return err.response;
        });
};

