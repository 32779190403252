import Styles from "./styles/filters.module.scss";

const FilterItem = ({ children }) => {
  return <div className={Styles.filter}>{children}</div>;
};

const Filters = ({ children, extra, wrapperStyle }) => {
  return (
    <div className={Styles["filters-wrapper"]} style={wrapperStyle || {}}>
      <div className={Styles.filters}>
        {children}
      </div>
      <div className={Styles.extra}>
        {extra}
      </div>
    </div>
  );
};

Filters.Item = FilterItem;

export default Filters;