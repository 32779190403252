import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Row, Form, Input, Button } from "antd";
import { t } from "i18next";

import { updateUserData } from "../../pages/profile/apiUtils";
import EditableProfileImage from "./user.profile.image";
import { useAppContext } from "../context/app.context";

import "./user.profile.details.css";

const { Item } = Form;

const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const showErrorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const UserProfileDetails = ({ setErrorList, errorList }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { currentUserData, setCurrentUserData } = useAppContext();
  const [changesNeedSaving, setChangesNeedSaving] = useState(false);

  const handleUpdateProfile = (values) => {
    setLoading(true);
    const data = {
      id: currentUserData.id,
      name: values.name,
    };
    updateUserData(dispatch, data).then((response) => {
      if (!response?.hasError) {
        setCurrentUserData((prev) => ({ ...prev, ...response }));
        setChangesNeedSaving(false);
      } else {
        showErrorToast(t("SETTING.UPDATE_ERROR"));
        setErrorList({
          errorList,
          appsettingError: {
            message: response.message ?? t("SETTING.UPDATE_ERROR"),
          },
        });
      }
      setLoading(false);
    });
  };

  const name = Form.useWatch("name", form);
  useEffect(() => {
    setChangesNeedSaving(form.isFieldsTouched());
  }, [name]);

  return (
    <>
      <div className="profile-details-container">
        <div className="mr-4">
          <EditableProfileImage
            showErrorToast={showErrorToast}
            showSuccessToast={showSuccessToast}
            setErrorList={setErrorList}
          />
        </div>

        <Form
          form={form}
          onFinish={handleUpdateProfile}
          layout="vertical"
          disabled={loading}
          requiredMark={false}
          className="flex-grow-1"
        >
          <Row>
            <Item
              label={`${t("NAME")} :`}
              name="name"
              className="w-100"
              rules={[
                {
                  required: true,
                  message: t("PROFILE.NAME_REQUIRED"),
                },
              ]}
            >
              <Input
                defaultValue={currentUserData.name}
                value={currentUserData.name}
              />
            </Item>
          </Row>
          <Row>
            <Item label={`${t("EMAIL")} :`} name="email" className="w-100">
              <Input
                className="text-normal"
                defaultValue={currentUserData.email}
                disabled
              />
            </Item>
          </Row>
          <Row>
            <Item
              label={`${t("PROFILE.ACCOUNT_TYPE")} :`}
              name="account_type"
              className="w-100 text-normal"
            >
              {currentUserData.company ? (
                <p>{t("PROFILE.COMPANY_USE")}</p>
              ) : (
                <p>{t("PROFILE.PERSONAL_USE")}</p>
              )}
            </Item>
          </Row>
          {currentUserData.company && (
            <Row>
              <Item
                label={`${t("PROFILE.COMPANY")} :`}
                name="company"
                className="w-100"
              >
                <Input
                  className="text-normal"
                  defaultValue={currentUserData.company.name}
                  disabled
                />
              </Item>
            </Row>
          )}
          {changesNeedSaving && (
            <Row className="d-flex justify-content-end">
              <Button
                type="default"
                className="default-background mr-2"
                onClick={() => {
                  form.resetFields();
                }}
              >
                {t("CANCEL")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("PROFILE.SAVE")}
              </Button>
            </Row>
          )}
        </Form>
      </div>
    </>
  );
};
