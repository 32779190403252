import Icon from "@ant-design/icons";

import { ReactComponent as ProxyIcon } from "../../assets/images/proxy-icon.svg";
import { Button } from "../customAntd";
import { useAppContext } from "../context/app.context";

import Styles from "./styles/proxyAccountIndicator.module.scss";

const ProxyAccountIndicator = () => {
  const { setCurrentUserRole, currentUserData, setProxyCompany, setReload } =
    useAppContext();
  const handleClick = () => {
    localStorage.removeItem("proxyCompanyId");
    localStorage.removeItem("proxyCompanyAdminRoleId");
    localStorage.removeItem("proxyCompanyName");
    setCurrentUserRole(currentUserData.roles[0]);
    setProxyCompany(null);
    setReload(true);
  };

  return (
    <div className={Styles["proxy-account-indicator"]}>
      <p>
        <Icon component={ProxyIcon} style={{ fontSize: 16 }} />
        <strong>Proxy Mode:</strong>
        {` You're viewing ${localStorage.getItem("proxyCompanyName")}`}
      </p>
      <Button
        type="link"
        className={Styles["exit-proxy-button"]}
        onClick={handleClick}
      >
        Exit Proxy Mode
      </Button>
    </div>
  );
};
export default ProxyAccountIndicator;
