import React, { useState } from "react";
import { Tabs as CustomTab } from "antd";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../components/context/app.context";
import ProfileContext from "../../components/context/profile.context";

import { AuditHistory } from "../../components/auditHistory";
import { UserProfileDetails } from "../../components/user.profile/user.profile.details";
import { ErrorBox } from "../../components/form/error";
import Users from "./users";
import AppSettings from "./appSettings";
import { Companies } from "./companies";
import { Roles } from "../../components/roles";
import { Micons, PageHeader } from "../../components/customAntd";

import { checkUserRolePermissions } from "../../utils/helper";
import { ROLE_PERMISSIONS } from "../../utils/constants";
import { BREADCRUMB_PROFILE } from "../../utils/profile/constant";

const UserProfile = () => {
  const [key, setKey] = useState("profile");
  const { currentUserRole, currentUserData } = useAppContext();
  const [usersCount, setUsersCount] = useState(0);
  const [companyCount, setCompanyCount] = useState(0);
  const [errorList, setErrorList] = useState({});
  const { t } = useTranslation();

  const passUsersCount = (count) => {
    setUsersCount(count);
  };

  // Roles state for ProfileContext
  const [selectedRole, setSelectedRole] = useState();
  const [roleTree, setRoleTree] = useState();
  const [roleMembers, setRoleMembers] = useState({});

  return (
    <ProfileContext.Provider
      value={{
        selectedRole: selectedRole,
        setSelectedRole: setSelectedRole,
        roleTree: roleTree,
        setRoleTree: setRoleTree,
        roleMembers: roleMembers,
        setRoleMembers: setRoleMembers,
      }}
    >
      <div className="">
        {/* {Object.keys({ ...errorList }).length ? (
          <ErrorBox
            errors={{ ...errorList }}
            errorsHeading={"Please review and try again"}
            setParentErrors={setErrorList}
          />
        ) : (
          ""
        )} */}

        <PageHeader breadcrumbs={BREADCRUMB_PROFILE(t)} title={t("SETTINGS")} />
        <CustomTab
          defaultActiveKey="1"
          tabBarGutter={47}
          animated={{ inkBar: true, tabPane: true }}
          className="tabs"
          items={[
            {
              label: (
                <div className="tab-label">
                  <Micons
                    icon="person"
                    type="filled"
                    style={{ fontSize: 18 }}
                  />
                  {t("PROFILE.TITLE")}
                </div>
              ),
              key: 1,
              children: <UserProfileDetails />,
            },
            {
              label: (
                <div className="tab-label">
                  <Micons
                    icon="format_list_bulleted"
                    type="filled"
                    style={{ fontSize: 18 }}
                  />
                  {t("ACTIVITY.TITLE")}
                </div>
              ),
              key: 2,
              children: <AuditHistory />,
            },
            {
              label: (
                <div className="tab-label">
                  <Micons
                    icon="settings_suggest"
                    type="filled"
                    style={{ fontSize: 18 }}
                  />
                  Roles
                </div>
              ),
              key: 3,
              children: <Roles />,
            },
            {
              ...(checkUserRolePermissions(
                currentUserRole,
                ROLE_PERMISSIONS.MANAGEUSERS
              ) && {
                label: (
                  <div className="tab-label">
                    <Micons
                      icon="people_alt"
                      type="filled"
                      style={{ fontSize: 18 }}
                    />
                    All Users
                  </div>
                ),
                key: 4,
                children: (
                  <Users usersCount={passUsersCount} isSuperAdmin={true} />
                ),
              }),
            },
            {
              ...((checkUserRolePermissions(
                currentUserRole,
                ROLE_PERMISSIONS.VIEWCOMPANY
              ) && checkUserRolePermissions(
                currentUserRole,
                ROLE_PERMISSIONS.UPDATECOMPANY
              ) && checkUserRolePermissions(
                currentUserRole,
                ROLE_PERMISSIONS.CREATECOMPANY
              ) &&
                currentUserData.company.companyType === "internal") && {
                label: (
                  <div className="tab-label">
                    <Micons
                      icon="business"
                      type="filled"
                      style={{ fontSize: 18 }}
                    />
                    {t("COMPANIES.TITLE")}
                  </div>
                ),
                key: 6,
                children: <Companies setCompanyCount={setCompanyCount} />,
              }),
            },
            {
              ...(checkUserRolePermissions(
                currentUserRole,
                ROLE_PERMISSIONS.MANAGEAPPSETTINGS
              ) && {
                label: (
                  <div className="tab-label">
                    <Micons
                      icon="settings"
                      type="filled"
                      style={{ fontSize: 18 }}
                    />
                    Settings
                  </div>
                ),
                key: 7,
                children: (
                  <AppSettings
                    errorList={errorList}
                    setErrorList={setErrorList}
                  />
                ),
              }),
            },
          ]}
        />

        {/* <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="profile" title="Profile">
            <div className="table-sec mb-3 p-0">
              <UserProfileDetails />
            </div>
          </Tab>
          <Tab eventKey="history" title="Activity">
            <div className="table-sec mb-3 p-0">
              <AuditHistory />
            </div>
          </Tab>
          <Tab eventKey="roles" title="Roles">
            <div className="table-sec mb-3 p-0">
              <Roles />
            </div>
          </Tab>
          {checkUserRolePermissions(
            currentUserRole,
            ROLE_PERMISSIONS.MANAGEUSERS
          ) && (
            <Tab eventKey="allUsers" title={`All Users (${usersCount})`}>
              <div className="table-sec mb-3 p-0">
                <Users usersCount={passUsersCount} isSuperAdmin={true} />
              </div>
            </Tab>
          )}
          {(checkUserRolePermissions(
            currentUserRole,
            ROLE_PERMISSIONS.VIEWCOMPANY
          ) ||
            currentUserData.company.companyType === "internal") && (
            <Tab eventKey="companies" title={`Companies (${companyCount})`}>
              <div className="table-sec mb-3 p-0">
                <Companies setCompanyCount={setCompanyCount} />
              </div>
            </Tab>
          )}
          {checkUserRolePermissions(
            currentUserRole,
            ROLE_PERMISSIONS.MANAGEAPPSETTINGS
          ) && (
            <Tab eventKey="setting" title="Settings">
              <div className="table-sec mb-3 p-0">
                <AppSettings
                  errorList={errorList}
                  setErrorList={setErrorList}
                />
              </div>
            </Tab>
          )}
        </Tabs> */}
      </div>
    </ProfileContext.Provider>
  );
};

export default UserProfile;
