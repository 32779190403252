import { useEffect, useState } from "react";
import { SketchPicker, CompactPicker } from "react-color";

import Styles from "./styles/colorPicker.module.scss";

const ColorPicker = ({ defaultColor, onChange, isCompactMode = false }) => {
  const [isColorPicker, setIsColorPicker] = useState(false);
  const [color, setColor] = useState(defaultColor);

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor])

  return (
    <div className={Styles["color-picker"]}>
      <div
        className={Styles.swatch}
        onClick={() => {
          setIsColorPicker(!isColorPicker);
        }}
      >
        <div className={Styles.color} style={{ backgroundColor: color }} />
      </div>
      {isColorPicker ? (
        <div className={Styles["color-popover"]}>
          <div
            className={Styles["cover"]}
            onClick={() => {
              setIsColorPicker(false);
            }}
          />
          {isCompactMode ? (
            <CompactPicker
            color={color}
            onChange={(color) => {
              setColor(color.hex);
              onChange(color.hex);
            }}
          />
          ) : ( 
            <SketchPicker
              color={color}
              onChange={(color) => {
                setColor(color.hex);
                onChange(color.hex);
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
