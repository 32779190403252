import axios from "axios";

import constant from "../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const getAllTypesBenefitsFromDb = async (url, filters, apiMethod) => {
  
  let requestConfig = {
    url: `${baseURL}${url}`,
    method: apiMethod === "post" ? "post" : "get",
  };

  if (apiMethod !== "post") {
    const queryParams = new URLSearchParams();
    Object.entries(filters.filterCondition).forEach(([key, value]) => {
      if (value) queryParams.append(`filterCondition[${key}]`, value);
    });
    requestConfig.url += `?${queryParams.toString()}`;
  } else {
    requestConfig.data = filters;
  }

  return axios(requestConfig)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Get all types benefits from DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};
