import { Modal as ModalAntd } from "antd";

import Styles from "./styles/modal.module.scss";

const Modal = ({ children, className, bodyStyle, ...props }) => {
  return (
    <ModalAntd
      className={`${Styles.modal} ${className ? className : ""}`}
      style={bodyStyle || {
        overflowX: "hidden",
        overflowY: "scroll",
        maxHeight: "calc(100vh - 200px)",
      }}
      {...props}
    >
      {children}
    </ModalAntd>
  );
};

export default Modal;
