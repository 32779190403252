import { useState } from "react";

import { BREADCRUMB_PROGRAMS_CREATE } from "../../../../utils/programs/constant";
import { useTranslation } from "react-i18next";

import { Modal, Button, ModalHeader } from "../../../../components/customAntd";
import TierBenefits from "./tierBenefits";

const Benefits = ({ isOpen, onCancel, programId, tierList }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  // Resetting the form step to 0 and clearing all the necessary data for completing the form
  const handleCancel = () => {
    setFormData({});
    onCancel();
  };

  // This function will advance the stepper form to the next step,
  // and once the final step has been reached, it will submit the form.
  const onFinish = (data) => {};

  return (
    <Modal
      title={
        <ModalHeader
          title={t("PROGRAM_UI.ASSIGN_BENEFITS")}
          breadcrumbs={BREADCRUMB_PROGRAMS_CREATE}
        />
      }
      open={isOpen}
      centered
      width={944}
      onCancel={handleCancel}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button buttonType="cancel" onClick={handleCancel} key="cancel-button">
          Cancel
        </Button>,
        <Button
          // onClick={onSubmitClick}

          key="next-button"
        >
          Next
        </Button>,
      ]}
    >
      <TierBenefits tierList={tierList} programId={programId} />
    </Modal>
  );
};

export default Benefits;
