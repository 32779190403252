import { Skeleton } from "antd";
import {t} from "i18next";

import qr from '../../../../assets/images/qr.png'
import Styles from "../styles/pass.module.scss"

export const Required = (children) => {
    return (<p>
        <span style={{ fontSize: "1.2rem", position: "relative", top: "5px", marginRight: ".2rem", color: "red" }}> *</span>
        {children}
    </p>)
}

export const IosPreview = ({ logoImage, stripImage, labelsColor, textColor, passColor, pointsEnabled }) => {
    return (<div style={{ backgroundColor: passColor }} className={Styles['pass-card-ios']} >
        <div className={Styles['passcard-header']}>
            <div> <img className={Styles["passcard-logo"]} src={logoImage}></img></div>
            <div>
                <div className={Styles['tierDetails']}>
                    <span className={Styles['tier-heading']} style={{ color: labelsColor }} >Tier</span>
                    <span className={Styles['tier-description']}>  <Skeleton.Button style={{ backgroundColor: textColor, height: "13px", opacity: "70%" }} size={"small"} /></span>
                </div>
            </div>

        </div>
        <div className={Styles['passcard-body']}  >
            <img className={Styles['passcard-strip-ios']} src={stripImage}></img>
            <div className={Styles['info-container']}>
                <div className={Styles['tier-benefits']}>
                    <span className='tier-benefit-title' style={{ color: labelsColor }}>Benefits</span>
                    <span className='tier-benefit-description'> <Skeleton.Button style={{ backgroundColor: textColor, height: "13px", opacity: "70%" }} size={"small"} /></span>
                </div>
                {pointsEnabled &&
                    <div className={Styles['points-info-ios']}>
                        <span className='tier-benefit-title' style={{ color: labelsColor }}>Points</span>
                        <span className='tier-benefit-description'>  <Skeleton.Button style={{ backgroundColor: textColor, height: "13px", opacity: "70%", width: "10px"}} size={"small"} /></span>
                    </div>
                }
            </div>


            <img className={Styles['passcard-qr']} style={{ backgroundColor: "white" }} src={qr}></img>
            <span style={{ fontSize: "10px", marginBottom: "3rem" }}>Scan for access</span>
        </div>

    </div>)
}
export const AndroidPreview = ({ logoImage, stripImage, labelsColor, textColor, passColor, companyName, programName, pointsEnabled }) => {
    return (<div style={{ backgroundColor: passColor }} className={Styles['pass-card-android']}>
        <div className={Styles['passcard-header']}>
            <div> <img className={Styles["passcard-logo"]} src={logoImage}></img><span style={{ marginLeft: "1rem" }}> {companyName} </span>  </div>
        </div>
        <hr style={{ marginTop: "2px" }}></hr>

        <div className={Styles['passcard-body']}>
            <div className={Styles['tier-benefits']} >
                <span style={{ marginLeft: ".6rem", marginBottom: ".5rem", fontSize: "1.3rem" }}>{programName} </span>
                <div className={Styles['info-container']}>
                    <div className={Styles['tier-info']}>
                        <span className={Styles['tier-heading']} style={{ color: labelsColor }} >Tier</span>
                        <span className={Styles['tier-description']}>  <Skeleton.Button style={{ backgroundColor: textColor, height: "13px", opacity: "70%" }} size={"small"} /></span>
                    </div>
                    {pointsEnabled &&
                        <div className={Styles['points-info-android']}>
                            <span className={Styles['points-amount']}>  <Skeleton.Button style={{ backgroundColor: textColor, height: "13px", opacity: "70%", width: "10px", marginTop: "5px" }} size={"small"} /></span>
                            <span className={Styles['tier-heading']} style={{ color: labelsColor }} >Points</span>
                        </div>
                    }
                </div>

                <span className='tier-benefit-title' style={{ color: labelsColor }}>Benefits</span>
                <span className='tier-benefit-description'> <Skeleton.Button style={{ backgroundColor: textColor, height: "13px", opacity: "70%" }} size={"small"} /></span>
            </div>
            <img className={Styles['passcard-qr']} style={{ backgroundColor: "white" }} src={qr}></img>
            <span style={{ fontSize: "10px", marginBottom: "2rem" }}>Scan for access</span>
        </div>

        <img className={Styles['passcard-strip']} src={stripImage}></img>
    </div>)
}

export const ExtraLabels = ({ items }) => {
    return (

        <>{
            items.map((item) => <span style={{ marginTop: '5px', fontSize: "14px" }}> {item} <br></br></span>)
        }
        </>
    )
}

export const RequireStep = (children) => {
    return (

        <>{
            <p>{children} <span style={{ color: "red", fontSize: "15px", fontStyle: "italic", marginLeft: "4px" }}> {`(${t("REQUIRED")})`}</span> </p>
        }
        </>
    )
}