import { useState } from "react";
import { Form, Row, Col, Collapse, Select, Switch } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { FormWrapper, Micons } from "../../../../components/customAntd";
import { useAppContext } from "../../../../components/context/app.context";
import { getCategoryName } from "../../../../utils/helper";

import Styles from "./styles/buildStorefront.module.scss";

const { Item } = Form;
const { Panel } = Collapse;

const CategorySort = ({ themeId, t, swap, setCategoryImages }) => {
  const [activeKey, setActiveKey] = useState("");

  const form = Form.useFormInstance();
  const { currentLocale } = useAppContext();

  const handleBenefitsDragEnd = (data) => {
    const { destination, source } = data;

    if (!!destination) {
      const arr = form.getFieldValue("benefitSortCriteria");
      swap(arr, destination.index, source.index);

      form.setFieldValue("benefitSortCriteria", arr);
      setCategoryImages((prev) => {
        swap(prev, destination.index, source.index);
        return [...prev];
      });
      if (activeKey === String(source.index)) {
        setActiveKey(String(destination.index));
      } else if (activeKey === String(destination.index)) {
        setActiveKey(String(source.index));
      }
    }
  };

  return (
    <FormWrapper heading={t("STOREFRONT_UI.CATEGORIES_SORT_ORDER")}>
      <DragDropContext onDragEnd={handleBenefitsDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Form.List name="benefitSortCriteria">
                  {(fields) =>
                    fields.map(({ key, name }, index) => {
                      return (
                        <Draggable
                          draggableId={`${key}`}
                          index={index}
                          key={`${key}`}
                        >
                          {(provided) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Collapse
                                  className={Styles["sort-option"]}
                                  activeKey={activeKey ? [activeKey] : []}
                                >
                                  <Panel
                                    forceRender
                                    key={`${index}`}
                                    collapsible="header"
                                    className={Styles["custom-panel"]}
                                    extra={
                                      <CaretRightOutlined
                                        rotate={
                                          activeKey === `${index}` ? 90 : 0
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setActiveKey((prev) =>
                                            prev === `${index}`
                                              ? ""
                                              : `${index}`
                                          );
                                        }}
                                      />
                                    }
                                    header={
                                      <>
                                        <Micons
                                          icon="drag_handle"
                                          isHover={false}
                                          style={{
                                            fontSize: 24,
                                            color: "var(--color-drag-icon)",
                                          }}
                                          wrapperStyle={{
                                            marginRight: 10,
                                          }}
                                          {...provided.dragHandleProps}
                                        />
                                        <span>
                                          {form.getFieldValue([
                                            "benefitSortCriteria",
                                            name,
                                            "name",
                                          ])}
                                          {getCategoryName(
                                            form.getFieldValue([
                                              "benefitSortCriteria",
                                              name,
                                              "category",
                                            ]),
                                            currentLocale
                                          )}
                                        </span>
                                      </>
                                    }
                                  >
                                    <Row gutter={20}>
                                      <Col sm={24} xs={24}>
                                        <Item
                                          className={Styles["promotional"]}
                                          name={[name, "isPromotional"]}
                                          label={t(
                                            "STOREFRONT_UI.PROMOTIONAL_CATEGORY"
                                          )}
                                          valuePropName="checked"
                                        >
                                          <Switch size="small" />
                                        </Item>
                                      </Col>
                                      <Col sm={12} xs={24}>
                                        <Item
                                          name={[name, "sortBy"]}
                                          label={`${t("SORT_BY")}:`}
                                        >
                                          <Select
                                            options={[
                                              {
                                                label: t("CREATED_DATE"),

                                                value: "CreatedDate",
                                              },
                                              {
                                                label: t("START_DATE"),
                                                value: "StartDate",
                                              },
                                              {
                                                label: t("ACTIVE_DATE"),
                                                value: "ActiveDate",
                                              },
                                            ]}
                                          />
                                        </Item>
                                      </Col>
                                      <Col sm={12} xs={24}>
                                        <Item
                                          name={[name, "sortOrder"]}
                                          label={`${t("SORT_ORDER")}:`}
                                        >
                                          <Select
                                            options={[
                                              {
                                                label: t("ASCENDING"),
                                                value: "asc",
                                              },
                                              {
                                                label: t("DESCENDING"),
                                                value: "desc",
                                              },
                                            ]}
                                          />
                                        </Item>
                                      </Col>
                                    </Row>
                                  </Panel>
                                </Collapse>
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })
                  }
                </Form.List>
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </FormWrapper>
  );
  // <DragDropContext onDragEnd={handleCollectionDragEnd}>
  //   <Droppable droppableId="droppable">
  //     {(provided) => {
  //       return (
  //         <div {...provided.droppableProps} ref={provided.innerRef}>
  //           {collectionsList?.map((item, index) => (
  //             <Draggable
  //               draggableId={`${item.id}`}
  //               index={index}
  //               key={`${item.id}`}
  //             >
  //               {(provided) => {
  //                 return (
  //                   <div ref={provided.innerRef} {...provided.draggableProps}>
  //                     <FormWrapper
  //                       heading={`${t("STOREFRONT_UI.COLLECTION")} ${
  //                         index + 1
  //                       }`}
  //                       headerStyles={{ display: "flex" }}
  //                       icon={
  //                         <Micons
  //                           icon="drag_handle"
  //                           isHover={false}
  //                           style={{
  //                             fontSize: 24,
  //                             color: "var(--color-drag-icon)",
  //                           }}
  //                           wrapperStyle={{
  //                             marginRight: 10,
  //                           }}
  //                           {...provided.dragHandleProps}
  //                         />
  //                       }
  //                     >
  //                       <Item
  //                         label={`${t("STOREFRONT_UI.COLLECTION_NAME")} :`}
  //                         name={item.name}
  //                       >
  //                         <Input
  //                           disabled
  //                           placeholder={t(
  //                             "STOREFRONT_UI.COLLECTION_NAME_PLACEHOLDER"
  //                           )}
  //                         />
  //                       </Item>
  //                     </FormWrapper>
  //                   </div>
  //                 );
  //               }}
  //             </Draggable>
  //           ))}
  //           {provided.placeholder}
  //         </div>
  //       );
  //     }}
  //   </Droppable>
  // </DragDropContext>
  // );
};

export default CategorySort;
