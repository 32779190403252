import { MERCHANT_CATEGORY_ICONS } from "./merchants/constant";

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const checkUserRolePermissions = (
  currentUserRole,
  permissionIdToCheck
) => {
  if (!currentUserRole?.rolePermissions) {
    return false;
  }
  return currentUserRole.rolePermissions.find(
    (rolePermission) =>
      rolePermission.permissionsMetadata.name === permissionIdToCheck
  );
};

export const hasRequiredPermissions = (
  userPermissions,
  requiredPermissions
) => {
  // If no permissions are required, grant access
  if (requiredPermissions?.length === 0) {
    return true;
  }

  // Otherwise, check if the user has any of the required permissions
  return !!userPermissions?.find((permission) =>
    requiredPermissions?.includes(permission?.permissionsMetadata?.name)
  );
};

export const getFileExtension = (imageUrl) => {
  const fileExtension = imageUrl.split(".").pop().toLowerCase();
  return fileExtension;
};

export const capitalizeLetter = (letter) => {
  return `${letter.charAt(0).toUpperCase()}${letter.slice(1, letter.length)}`;
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const getIconForMerchantCategory = (categories, id) => {
  let categoryId = 0;
  for (const category of categories) {
    if (category.id === id) {
      categoryId = category.id;
      break;
    }
  }

  if (categoryId === 0) {
    for (const category of categories) {
      for (const subCategory of category.subCategories) {
        if (subCategory.id === id) {
          categoryId = subCategory.parentCategoryId;
          break;
        }
      }
    }
  }
  return MERCHANT_CATEGORY_ICONS[categoryId];
};

export const getLocalStorage = (type) => {
  return localStorage.getItem(type);
};

export const getRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

//Need to remove or  modify for updated categories structure
export const getMerchantCategoryAndSubCategory = (categories, categoryId) => {
  let primaryCategoryId = null,
    subCategoryId = null,
    name = "";
  for (const category of categories) {
    if (category.id === categoryId) {
      primaryCategoryId = category.id;
      name = category.name;
      break;
    }
  }
  if (!primaryCategoryId) {
    for (const category of categories) {
      for (const subCategory of category.subCategories) {
        if (subCategory.id === categoryId) {
          primaryCategoryId = subCategory.parentCategoryId; //ont change this
          subCategoryId = subCategory.id;
          name = subCategory.name;
          break;
        }
      }
    }
  }
  return { primaryCategoryId, subCategoryId, name };
};

//Need to remove or  modify for updated categories structure
export const getCategoryIdFromName = (categories, categoryName) => {
  let categoryId = null;
  for (const category of categories) {
    if (category.name.toLowerCase() === categoryName.toLowerCase()) {
      categoryId = category.id;
      break;
    }
  }

  if (!categoryId) {
    for (const subCategory of categories.subCategories) {
      if (subCategory.name === categoryName.toLowerCase()) {
        categoryId = subCategory.id;
        break;
      }
    }
  }

  return categoryId;
};

export function buildCategoryHierarchy(categories, parentId = null) {
  // Filter categories based on the parentId
  const result = categories
    .filter((category) => category.parentCategoryId === parentId)
    .map((category) => ({
      ...category,
      // Recursively build the subCategories by passing the current category's ID as the parentId for the next level
      subCategories: buildCategoryHierarchy(categories, category.id),
    }));

  return result;
}

export function flatMapCategories(categories) {
  let result = [];

  categories.forEach((category) => {
    // Push the current category without its subCategories
    result.push({
      ...category,
      //Need subCategories to check the conditions fo rdeleting a category
      // subCategories: [], // Exclude subCategories from the result
    });

    // Recursively flatten subCategories and concatenate them to the result
    if (category.subCategories && category.subCategories.length > 0) {
      result = result.concat(flatMapCategories(category.subCategories));
    }
  });

  return result;
}

export function getCategoriesBetweenLevels(
  categories,
  startLevel,
  endLevel,
  currentLevel = 1
) {
  let result = [];

  categories.forEach((category) => {
    // If the current level is between startLevel and endLevel, include the category
    if (currentLevel >= startLevel && currentLevel <= endLevel) {
      let newCategory = {
        ...category,
        subCategories: [], // Initialize with empty subCategories
      };

      // If currentLevel is less than endLevel, explore subCategories
      if (category.subCategories && currentLevel < endLevel) {
        newCategory.subCategories = getCategoriesBetweenLevels(
          category.subCategories,
          startLevel,
          endLevel,
          currentLevel + 1
        );
      }

      result.push(newCategory);
    }

    // If the current level is below the startLevel, continue drilling down without adding the category itself
    if (category.subCategories && currentLevel < startLevel) {
      const subCategoryResult = getCategoriesBetweenLevels(
        category.subCategories,
        startLevel,
        endLevel,
        currentLevel + 1
      );

      // Only add subCategories at the required levels
      if (subCategoryResult.length > 0) {
        result = result.concat(subCategoryResult);
      }
    }
  });

  return result;
}

export function formatCategoriesForTreeSelect(
  categories,
  startLevel,
  endLevel,
  currentLocale,
  currentLevel = 1
) {
  return categories.reduce((result, category) => {
    // If we are within the range of levels, include this category
    if (currentLevel >= startLevel && currentLevel <= endLevel) {
      let formattedCategory = {
        title: getCategoryName(category, currentLocale), // The display name for the category
        value: category.id, // The value/key for the category (used for selection)
        key: category.id, // Antd TreeSelect uses 'key' as an identifier
      };

      // If not at the end level, explore the subCategories
      if (category.subCategories && currentLevel < endLevel) {
        const formattedChildren = formatCategoriesForTreeSelect(
          category.subCategories,
          startLevel,
          endLevel,
          currentLocale,
          currentLevel + 1
        );

        // Only include children if they exist
        if (formattedChildren.length > 0) {
          formattedCategory.children = formattedChildren;
        }
      }

      result.push(formattedCategory);
    }

    // If current level is below the startLevel, continue drilling down without including the current category
    if (category.subCategories && currentLevel < startLevel) {
      const formattedChildren = formatCategoriesForTreeSelect(
        category.subCategories,
        startLevel,
        endLevel,
        currentLocale,
        currentLevel + 1
      );

      result = result.concat(formattedChildren); // Append children directly, not the parent
    }

    return result;
  }, []);
}

export const getCategoryName = (category, locale) => {
  return locale === category?.defaultLocaleId
    ? category?.name
    : category?.locales?.find((el) => el.localeId === locale)?.name ||
        category?.name;
};

export const getDeleteCategoryErrorMessage = (category, t) => {
  const {
    _count: { benefits },
    subCategories,
  } = category;

  if (benefits > 0 && subCategories?.length > 0) {
    return t("CATEGORIES_UI.DELETE_ERROR");
  } else if (benefits > 0) {
    return t("CATEGORIES_UI.DELETE_ERROR_BENEFITS_ONLY");
  } else if (subCategories?.length > 0) {
    return t("CATEGORIES_UI.DELETE_ERROR_SUBCATEGORIES_ONLY");
  } else {
    return t("CATEGORIES_UI.DELETE_CONFIRMATION");
  }
};
