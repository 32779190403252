import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Form, Input, Button, message, Radio, Switch, InputNumber, Popconfirm, Divider, Select } from "antd";
import { useTranslation } from "react-i18next";
import { getPublicAppSettings, updateProgram } from '../apiUtils';
import { Required } from './utils';
import {
  DeleteOutlined,
  PlusOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { FormWrapper } from '../../../../components/customAntd';
import { getLocalesFromDb } from '../../../../utils/common/apiUtils';

export const ProgramForm = ({ form, isEdit, programIdEdit, setProgramDetails, editProgram }) => {
  const { t } = useTranslation();
  const { Item } = Form;
  const [isFormTouched, setIsFormTouched] = useState(false)
  const [defaultLocale] = useState("en_US");
  const [availableLocales, setAvailableLocales] = useState([]);
  const [liveProgramCreation, setLiveProgramCreation] = useState(false)
  const [programStatus, setProgramStatus] = useState(form.getFieldValue("program_status") || 'Draft')
  const [pointsEnabled, setPointsEnabled] = useState(form.getFieldValue("points_enabled") || false)
  const [pointsExpire, setPointsExpire] = useState(form.getFieldValue("points_expire") || 'yes')
  const [pointsExpirationPeriod, setPointsExpirationPeriod] = useState(form.getFieldValue("points_expiration_period") || 12)
  const watchLocales = Form.useWatch("program_locales", form);
  const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);

  useEffect(() => {
    const fetchLiveProgramCreationStatus = async () => {
      try {
        const response = await getPublicAppSettings()
        if (response.status !== 500 || 422) {
          setLiveProgramCreation(
            response.filter(
              (item) => item.key === "liveProgramCreation"
            )[0].value
          );
        } else {
          throw new Error("Error while fetching live program creation status")
        }
      } catch (err) {
        message.error(err.message)
      }

    }
    fetchLiveProgramCreationStatus()

  }, [])

  useEffect(() => {
    const getLocales = async () => {
      const data = await getLocalesFromDb();
      if (data) {
        const localesData = data.map((locale) => {
          return {
            value: locale.localeId,
            label: `${locale.language.language} (${locale.country.country})`,
            isSelected: isEdit ?
              editProgram?.defaultLocaleId === locale.localeId ||
              editProgram?.locales?.find((localeEl) => {
                return localeEl.localeId === locale.localeId;
              })
              : locale.localeId === defaultLocale,
          };
        });
        setAvailableLocales(localesData);
      }
    };
    getLocales();
  }, [defaultLocale, isEdit]);

  const handleProgramStatusChange = (e) => {
    setIsFormTouched(true)
    setProgramStatus(e.target.value)
  }
  const handlePointsEnabledChange = () => {
    setIsFormTouched(true)
    setPointsEnabled(!pointsEnabled)
  }
  const handlePointsExpireChange = (e) => {
    setIsFormTouched(true)
    setPointsExpire(e.target.value)
  }
  const handlePointsExpirationPeriodChange = (value) => {
    setIsFormTouched(true)
    setPointsExpirationPeriod(value)
  }

  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
            ...item,
            isSelected: true,
          }
          : {
            ...item,
            isSelected: item.value === prevValue ? false : item.isSelected,
          }
      );
    });
  };

  useEffect(() => {
    form.setFieldValue("program_status", programStatus)
  }, [programStatus])
  useEffect(() => {
    form.setFieldValue("points_enabled", pointsEnabled)
  }, [pointsEnabled])
  useEffect(() => {
    form.setFieldValue("points_expire", pointsExpire)
  }, [pointsExpire])
  useEffect(() => {
    form.setFieldValue("points_expiration_period", pointsExpirationPeriod)
  }, [pointsExpirationPeriod])



  const onSubmit = async () => {
    setIsFormTouched(false)
    try {
      const response = await updateProgram({
        name: form.getFieldValue("program_name"),
        description: form.getFieldValue("program_description"),
        status: form.getFieldValue("program_status"),
        pointsEnabled: form.getFieldValue("points_enabled"),
        pointsExpire: form.getFieldValue("points_expire") === 'yes',
        expirationPeriod: form.getFieldValue("points_expiration_period"),
        defaultLocaleId: form.getFieldValue('defaultLocaleId'),
        locales: form.getFieldValue('program_locales')?.map(locale => ({
          localeId: locale.localeId,
          name: locale.program_name,
          description: locale.program_description
        }))
      }, programIdEdit)
      if (response.status !== (500 || 422)) {
        message.success("Program updated succesfully");
        if (setProgramDetails) setProgramDetails(response.data);
      } else {
        throw new Error("Error ocurred while updating the prpgram please try again")
      }
    } catch (err) {
      message.error("Error Occured while updating program")
    }
  }

  return (
    <>
      <Row style={{ display: "flex", flexFlow: "column" }}>
        <Col span={18}>
          <h3>{t("PROGRAM_UI.DEFINE_PROGRAM")}</h3>
          <Item
            label={`${t("DEFAULT_LANGUAGE")}:`}
            name="defaultLocaleId"
            initialValue={defaultLocale}
            rules={[
              {
                required: true,
                message: t("DEFAULT_LANGUAGE_ERROR"),
                whitespace: true,
              },
            ]}
          >
            <Select
              options={availableLocales.map((localEl) => ({
                ...localEl,
                disabled: localEl.isSelected,
              }))}
              onChange={(value) => {
                updateLocales(value, watchDefaultLanguage);
                setIsFormTouched(true)
              }}
            />
          </Item>
          <Item
            label={Required(t('PROGRAM_UI.PROGRAM_NAME'))}
            name="program_name"
            rules={[{ required: true, message: 'Please enter a name!' },
            { max: 250, message: 'Name cannot be greater than 250 char' }
            ]}
          >
            <Input onChange={(e) => setIsFormTouched(true)} placeholder={t('ADD_NAME')} />
          </Item>
          <Item
            label={Required(t('PROGRAM_UI.PROGRAM_DESCRIPTION'))}
            name="program_description"
            rules={[{ required: true, message: 'Please enter a description!' },
            { max: 250, message: 'Description cannot be greater than 250 char' }
            ]}
          >
            <Input onChange={(e) => setIsFormTouched(true)} type='textarea' placeholder={t('ADD_DESCRIPTION')} />
          </Item>
          <Item
            label={Required(t('PROGRAM_UI.PROGRAM_STATUS'))}
            name="program_status"
          >

            <Radio.Group style={{ marginTop: ".8rem", marginBottom: ".8rem" }} onChange={handleProgramStatusChange} value={programStatus}>
              <Radio value={'Draft'}>{t("DRAFT")}</Radio>
              <Radio disabled={!liveProgramCreation} value={'Live'}>{t("LIVE")}</Radio>
            </Radio.Group>
          </Item>

          <h3><StarOutlined /> &nbsp; {t('PROGRAM_UI.POINTS_AND_REWARDS')}</h3>
          <Item
            name="points_enabled"
            extra={t('PROGRAM_UI.POINTS_AND_REWARDS_DESC')}
          >
            <Row>
              <Switch checked={pointsEnabled} onChange={handlePointsEnabledChange} /> &nbsp; {t('PROGRAM_UI.POINTS_AND_REWARDS')}
            </Row>
          </Item>
          {pointsEnabled && <Row>
            <Item
              label={t('PROGRAM_UI.DO_POINTS_EXPIRE')}
              name="points_expire"
              className='mr-4'
            >
              <Radio.Group style={{ marginTop: ".6rem", marginBottom: ".8rem" }} onChange={handlePointsExpireChange} value={pointsExpire}>
                <Radio value={'yes'}>{t("YES")}</Radio>
                <Radio value={'no'}>{t("NO")}</Radio>
              </Radio.Group>
            </Item>
            {pointsExpire === 'yes' && <Item
              label={t('PROGRAM_UI.POINTS_EXPIRATION_PERIOD')}
              name="points_expiration_period"
            >
              <InputNumber value={pointsExpirationPeriod} addonAfter="months" onChange={handlePointsExpirationPeriodChange} />
            </Item>}
          </Row>}

        </Col>
        <FormWrapper
          heading={t("LANGUAGE_OPTIONS.HEADING")}
          description={t("LANGUAGE_OPTIONS.DESCRIPTION")}
        >
          <Form.List name="program_locales">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }, index) => (
                  <Fragment key={key}>
                    <Row gutter={[13]}>
                      <Col span={12}>
                        <Item
                          label={Required(`${t("LANGUAGE")} :`)}
                          name={[name, "localeId"]}
                          rules={[
                            {
                              required: true,
                              message:t(
                                "LANGUAGE_OPTIONS.SELECT_LANGUAGE"
                              ),
                            },
                          ]}
                        >
                          <Select
                            options={availableLocales.map(
                              (localEl) => ({
                                ...localEl,
                                disabled: localEl.isSelected,
                              })
                            )}
                            onChange={(value) => {
                              updateLocales(
                                value,
                                watchLocales[index].localeId
                              )
                              setIsFormTouched(true)
                            }}
                            placeholder={t("SELECT")}
                          />
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item
                          label={Required(t('PROGRAM_UI.PROGRAM_NAME'))}
                          name={[name, "program_name"]}
                          rules={[{ required: true, message: 'Please enter a name!' },
                          { max: 250, message: 'Name cannot be greater than 250 char' }
                          ]}
                        >
                          <Input onChange={(e) => setIsFormTouched(true)} placeholder={t('ADD_NAME')} />
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item
                          label={Required(t('PROGRAM_UI.PROGRAM_DESCRIPTION'))}
                          name={[name, "program_description"]}
                          rules={[{ required: true, message: 'Please enter a description!' },
                          { max: 250, message: 'Description cannot be greater than 250 char' }
                          ]}
                        >
                          <Input onChange={(e) => setIsFormTouched(true)} type='textarea' placeholder={t('ADD_DESCRIPTION')} />
                        </Item>
                      </Col>
                    </Row>

                    <Popconfirm
                      title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                      onConfirm={() => {
                        setAvailableLocales((prev) => {
                          return prev.map((item) =>
                            item.value ===
                              watchLocales[index].localeId
                              ? {
                                ...item,
                                isSelected: false,
                              }
                              : item
                          );
                        });
                        remove(name);
                        setIsFormTouched(editProgram.locales?.find((localeEl) => {
                          return localeEl.localeId === watchLocales[index].localeId;
                        }))
                      }}
                      onCancel={() => { }}
                      okText={t("YES")}
                      cancelText={t("NO")}
                    >
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        style={{
                          color: "var(--color-form-error)",
                          placeContent: "flex-end",
                          marginTop: -20,
                          float: "right",
                        }}
                      >
                        {t("LANGUAGE_OPTIONS.DELETE_LOCALE")}
                      </Button>
                    </Popconfirm>
                    {fields.length !== 0 && (
                      <Divider
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          borderColor: "#D9D9D9",
                        }}
                      />
                    )}
                  </Fragment>
                ))}

                <Item style={{ marginBottom: 0 }}>
                  <Button
                    type="link"
                    onClick={
                      fields.length >= 3 ? () => { } : () => add()
                    }
                    icon={<PlusOutlined />}
                    style={{ color: "var(--color-brand-primary)" }}
                    disabled={fields.length >= 3}
                  >
                    {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
                  </Button>
                </Item>
              </>
            )}
          </Form.List>
        </FormWrapper>


        {isEdit && <Button type='primary' disabled={!isFormTouched} style={{ width: "10rem" }} onClick={onSubmit}>
          {t("SAVE")}
        </Button>}

      </Row>
    </>
  )
}
