import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, message, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
  createNotification,
  updateNotification,
} from "../../../benefits/apiUtils";
import {
  Modal,
  Button,
  ModalHeader,
} from "../../../../../components/customAntd";
import { getAllStorefrontList } from "../../../storefrontBuilder/apiUtils";

const { Item } = Form;

const NewNewsModal = ({
  isOpen,
  onCancel,
  currentRecord,
  getNews,
  loadingPressReleases,
  pressReleases,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const watchScheduleDate = Form.useWatch("scheduledDate", form);

  const disabledDate = (current, date = null) => {
    const day = date ? dayjs(date) : dayjs();
    return current && current < day.startOf("day");
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    data.expiryDate = dayjs(data.expiryDate).set("second", 0).format();
    data.scheduledDate = (
      dayjs() >= data.scheduledDate
        ? dayjs().add(3, "minutes").set("second", 0)
        : dayjs(data.scheduledDate).add(3, "minutes").set("second", 0)
    ).format();
    const finalData = {
      ...data,
      type: "Toast",
      level: "News",
      userCriteria: "AllUsers",
      storefrontId: data.storefrontId?.toString(),
    };

    const response = currentRecord
      ? await updateNotification(null, currentRecord.id, finalData)
      : await createNotification(null, finalData);
    if (response?.status === 200) {
      message.success({
        content: currentRecord
          ? t("UPDATED_SUCCESSFULLY")
          : t("CREATED_SUCCESSFULLY"),
        key: "success",
        duration: 2,
      });
      getNews();
      onCancel();
    } else {
      message.error({
        content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
        key: "error",
        duration: 2,
      });
    }
    setIsLoading(false);
    form.resetFields();
  };

  const getStoreFrontList = async () => {
    const getAllStores = await getAllStorefrontList({
      limit: 50,
      offset: 0,
      filterCondition: {
        isArchived: false,
      },
    });

    if (getAllStores?.status === 200) {
      setStoreList(() =>
        getAllStores.data.map((item) => {
          return {
            label: item.title,
            value: item.id,
          };
        })
      );
    }
  };

  useEffect(() => {
    if (currentRecord) {
      // Extract values from currentRecord
      const { scheduledDate, expiryDate, ...rest } = currentRecord;

      // Initialize form fields with values
      form.setFieldsValue({
        ...rest,
        scheduledDate: dayjs(scheduledDate),
        expiryDate: expiryDate && dayjs(expiryDate),
      });
    }
  }, [currentRecord, form]);

  // get All merchants initially
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getStoreFrontList();
    }
    return () => (mounted = false);
  }, []);

  const disabledTime = (current, date = null) => {
    const day = date ? dayjs(date) : dayjs();
    return {
      disabledHours: () =>
        current && current?.format("DD-MM-YYYY") === day.format("DD-MM-YYYY")
          ? Array.from({ length: day.get("hour") }, (_, index) => index)
          : [],
      disabledMinutes: (selectedHour) =>
        current &&
        current?.format("DD-MM-YYYY") === day.format("DD-MM-YYYY") &&
        day.get("hour") === selectedHour
          ? Array.from({ length: day.get("minute") }, (_, index) => index)
          : [],
    };
  };

  return (
    <>
      <Modal
        destroyOnClose
        onCancel={() => {
          onCancel();
        }}
        open={isOpen}
        centered
        title={<ModalHeader title={t("NEWS")} />}
        width={1200}
        footer={[
          <Button
            buttonType="cancel"
            onClick={() => {
              onCancel();
            }}
            key="cancel-button"
          >
            {t("CANCEL")}
          </Button>,
          <Button
            onClick={() => {
              form.submit();
            }}
            key="create-button"
            disabled={dayjs(currentRecord?.scheduledDate).isBefore(dayjs())}
            loading={isLoading}
          >
            {currentRecord ? t("UPDATE") : t("CREATE")}
          </Button>,
        ]}
      >
        <Row gutter={52}>
          <Col sm={20} md={12}>
            <Form
              form={form}
              onFinish={onSubmit}
              initialValues={{ type: "Toast" }}
              layout="vertical"
              requiredMark={false}
              preserve={false}
            >
              <Item
                label={t("SELECT_STOREFRONT")}
                name="storefrontId"
                rules={[
                  {
                    required: true,
                    message: t("STOREFRONT_REQUIRED"),
                  },
                ]}
              >
                <Select
                  options={storeList}
                  disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                    dayjs()
                  )}
                  placeholder={t("STOREFRONT_REQUIRED")}
                />
              </Item>

              <Item
                name="title"
                label={`${t("PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE")} :`}
                rules={[
                  {
                    required: true,
                    message: t(
                      "PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE_VALIDATION"
                    ),
                  },
                ]}
              >
                <Input
                  disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                    dayjs()
                  )}
                  placeholder={t(
                    "PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE_PLACEHOLDER"
                  )}
                />
              </Item>
              <Item
                name="message"
                label={`${t("PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE")} :`}
                rules={[
                  {
                    required: true,
                    message: t(
                      "PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE_VALIDATION"
                    ),
                  },
                ]}
              >
                <Input
                  disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                    dayjs()
                  )}
                  placeholder={t(
                    "PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE_PLACEHOLDER"
                  )}
                />
              </Item>
              <Item
                name="scheduledDate"
                label={`${t("BENEFIT_NOTIFICATION.SCHEDULED_DATE")}:`}
                rules={[
                  {
                    required: true,
                    message: t("BENEFIT_NOTIFICATION.SCHEDULED_DATE_REQUIRED"),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD HH:mm"
                  disabledDate={(current) => disabledDate(current)}
                  disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                    dayjs()
                  )}
                  disabledTime={disabledTime}
                  placeholder={t("BENEFIT_NOTIFICATION.SCHEDULED_DATE")}
                  showTime={{ format: "HH:mm" }}
                />
              </Item>

              {(!currentRecord || currentRecord?.expiryDate !== null) && (
                <Item name="expiryDate" label={t("EXPIRY_DATE")}>
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm"
                    disabledDate={(current) =>
                      disabledDate(current, watchScheduleDate)
                    }
                    disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                      dayjs()
                    )}
                    disabledTime={(current) =>
                      disabledTime(current, watchScheduleDate)
                    }
                    showTime={{ format: "HH:mm" }}
                    placeholder={t("EXPIRY_DATE_PLACEHOLDER")}
                  />
                </Item>
              )}
              <Item
                name="pressReleaseId"
                label={t("ANNOUNCEMENT.LINK_PRESS_RELEASE")}
              >
                <Select
                  optionFilterProp="label"
                  showSearch
                  disabled={
                    currentRecord?.scheduledDate
                      ? dayjs(currentRecord?.scheduledDate).isBefore(dayjs())
                      : loadingPressReleases
                  }
                  placeholder={t("ANNOUNCEMENT.LINK_PRESS_RELEASE_PLACEHOLDER")}
                  options={pressReleases?.map((el) => ({
                    value: el.id,
                    label: el.heading,
                  }))}
                  popupMatchSelectWidth={false}
                  dropdownStyle={{
                    maxWidth: `min(1145px, calc(100vw - 90px))`,
                  }}
                />
              </Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default NewNewsModal;
