// import Third party and React
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// import custom components
import {
  Button,
  Modal,
} from "../../../../../components/customAntd";
import { getSweepstakesDownloadUrl } from "../../apiUtils";


const DownloadRegistrations = ({ open, onOk, record, setData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { t } = useTranslation();

  useLayoutEffect(() => {
    const updateSize = () => {
      setTimeout(() => {
        if (targetRef.current) {
          setDimensions({
            width: targetRef.current.offsetWidth,
            height: targetRef.current.offsetHeight,
          });
        }
      }, 200);
    };

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [open]);

  useEffect(() => {
    fetchUrl();
  }, [record, isLoading]);

  const fetchUrl = async () => {
    if (record?.sweepStake?.id) {
      const res = await getSweepstakesDownloadUrl(record?.sweepStake?.id);
      setUrl(res.data);
    }
  };

  const renderRegistrations = () => {
    console.log("url", url)
    return (
      <div ref={targetRef} style={{ width: "100%", height: "20vh", paddingTop: "40px"}}>
       <a href={url.csvUrl} download><Button>Download as CSV</Button></a> 
       <br />
       <a href={url.jsonURL} download><Button>Download as JSON</Button></a>
      </div>
    );
  };

  return (
    <>
      <Modal
        onCancel={onOk}
        width={400}
        height={150}
        destroyOnClose
        cancelButtonProps={{ style: { display: "none" } }}
        open={open}
        onOk={onOk}
        okText={t("OK")}
      >
        {renderRegistrations()}
      </Modal>
    </>
  );
};

export default DownloadRegistrations;
