
export const getRequiredBenefitTypeFromAction = (action) => {
    if (action === 'view') {
        return 'video'
    } else if (action === 'listen') {
        return 'audio'
    } else if (action === 'read') {
        return 'digitalbook'
    } else {
        return null
    }
}

export const capitalize = (word) => {
    return word ? word.charAt(0).toUpperCase() + word.slice(1) : ""
}

export const getRewardId = (reward) => {
    if (reward.rewardType === "benefit") {
        return reward.rewardType + reward.name + reward.benefitType + reward.benefitId + reward.pointsNeeded + reward.tierIds.join(",")
    } else { // reward is nft
        return reward.rewardType + reward.name + reward.collectionId + reward.nftId + reward.pointsNeeded + reward.tierIds.join(",")
    }
}

export const getNftRewardsFromNfts = (nfts, collections) => {
    let nftRewards = nfts.filter(nft => nft.pointPrice && nft.pointPrice > 0).map(nft => ({
        name: nft.name,
        rewardType: 'nft',
        collectionId: collections.find(collection => collection.id === nft.collectionId).id,
        nftId: nft.id,
        pointsNeeded: nft.pointPrice,
        tierIds: [nft.tierId],
    }))
    nftRewards = nftRewards.map(reward => ({
        ...reward,
        id: getRewardId(reward)
    }))
    return nftRewards
}

export const getBenefitRewardsFromTiers = (tiers) => {
    let benefitRewards = tiers.reduce((rewards, tier) => {
        let tierRewards = tier.benefitsTier.map(tierBenefit => ({
            name: tierBenefit.benefit.title,
            rewardType: 'benefit',
            benefitType: tierBenefit.benefit.type,
            benefitId: tierBenefit.benefitId,
            pointsNeeded: tierBenefit.pointPrice,
            tierIds: [tierBenefit.tierId],
        }))
        for (let reward of tierRewards) {
            const existing = rewards.find(item => item.benefitId === reward.benefitId && item.pointsNeeded === reward.pointsNeeded)
            if (existing) {
                existing.tierIds = [...new Set([...existing.tierIds, ...reward.tierIds])]
            } else {
                rewards.push(reward)
            }
        }
        return rewards
    }, [])
    benefitRewards = benefitRewards.filter(reward => reward.pointsNeeded && reward.pointsNeeded > 0).map(reward => ({
        ...reward,
        id: getRewardId(reward)
    }))
    return benefitRewards
}