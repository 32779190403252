import { Input, Select } from "antd";
import {
  SearchOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/customAntd";

import Styles from "./styles/filters.module.scss";

const MerchantFilters = ({
  onViewChange,
  type,
  onSearch,
  onFilterByChange,
}) => {
  const { t } = useTranslation();
  return (
    <div className={Styles["filters-wrapper"]}>
      <div className={Styles.filters}>
        <div className={Styles.filter}>
          <Select
            placeholder={t("FILTER_BY_OPTIONS")}
            style={{ minWidth: 160 }}
            onChange={onFilterByChange}
            allowClear
            options={[]}
          />
        </div>
      </div>
      <div className={Styles.extra}>
        <Button
          type="link"
          icon={
            type === "list" ? <AppstoreOutlined /> : <UnorderedListOutlined />
          }
          onClick={onViewChange}
        >
          {`${type === "list" ? t("GRID") : t("LIST")} ${t("VIEW")}`}
        </Button>
        <Input
          style={{ minWidth: 300 }}
          placeholder={t("SEARCH_SOMETHING")}
          onChange={onSearch}
          prefix={<SearchOutlined />}
        />
      </div>
    </div>
  );
};

export default MerchantFilters;
