import { Input, Select } from "antd";
import {
  SearchOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { t } from "i18next";

import { Button } from "../../../../../components/customAntd";
import CategoryTreeSelect from "../../../../../components/categoryTreeSelect";

import Styles from "./styles/filters.module.scss";

const DiscountCodesFilters = ({
  onViewChange,
  type,
  onSearch,
  onFilterByChange,
  onCategoryFilterChange,
  categories,
}) => {
  return (
    <div className={Styles["filters-wrapper"]}>
      <div className={Styles.filters}>
        <div className={Styles.filter}>
          <Select
            placeholder={t("FILTER_BY_OPTIONS")}
            style={{ minWidth: 160 }}
            onChange={onFilterByChange}
            allowClear
            options={[
              {
                value: "draft",
                label: t("DRAFT"),
              },
              {
                value: "inActive",
                label: t("INACTIVE"),
              },
              {
                value: "active",
                label: t("ACTIVE"),
              },
            ]}
          />
        </div>
        <div className={Styles.filter}>
          <CategoryTreeSelect
            style={{ width: 200 }}
            placeholder={t("CATEGORIES_UI.FILTER_BY")}
            onChange={onCategoryFilterChange}
            allowClear
          />
        </div>
      </div>
      <div className={Styles.extra}>
        <Button
          type="link"
          icon={
            type === "list" ? <AppstoreOutlined /> : <UnorderedListOutlined />
          }
          onClick={onViewChange}
        >
          {`${type === "list" ? t("GRID") : t("LIST")} ${t("VIEW")}`}
        </Button>
        <Input
          style={{ minWidth: 300 }}
          placeholder={t("SEARCH_SOMETHING")}
          onChange={onSearch}
          prefix={<SearchOutlined />}
        />
      </div>
    </div>
  );
};

export default DiscountCodesFilters;
