import { useNavigate } from "react-router-dom";

import defaultImage from "../../../assets/images/default-img.jpg";
import { useAppContext } from "../../context/app.context";

import { Card } from "../../customAntd";
import ReadMore from "../../readmore";

const TierCardNew = ({ item }) => {
  const navigate = useNavigate();
  const { currentLocale } = useAppContext();

  const handleOnClick = () => {
    //Navigate to new tier page
    navigate(
      `/programs/${item?.programId}/tiers/${item?.id}/details`
    );
  };

  const currentLocaleData = item?.locales?.filter(
    (item) => item.localeId === currentLocale
  )[0];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 15px 25px rgba(9, 9, 9, 0.09)",
          cursor: "pointer",
        }}
        onClick={handleOnClick}
        cover={
          <img
            src={item?.stripImageUrl || defaultImage}
            alt={item?.name}
            height={200}
          />
        }
      >
        <Card.Meta
          title={currentLocaleData ? currentLocaleData.name : item?.name}
          description={
            <ReadMore
              text={
                currentLocaleData
                  ? currentLocaleData.description
                  : item?.description
              }
              characterLimit={86}
            >
              {(currentLocaleData
                ? currentLocaleData.description.length > 86
                : item?.description?.length > 86) && (
                <ReadMore.Trigger trigger="...more" text={item?.description} />
              )}
            </ReadMore>
          }
        />
      </Card>
    </>
  );
};

export default TierCardNew;
