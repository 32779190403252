import { useState, useEffect } from "react";
import Breadcrumb from "../breadCrumb";

import Styles from "./styles/pageHeader.module.scss";

const PageHeader = ({
  breadcrumbs,
  title,
  wrapperStyle,
  headStyle,
  headingStyle,
  extrasStyle,
  wrapperClassName,
  headClassName,
  headingClassName,
  extrasClassName,
  extra,
}) => {
  const [isTitleString, setIsTitleString] = useState(false);

  useEffect(() => {
    if (typeof title === "string") {
      setIsTitleString(true);
    } else {
      setIsTitleString(false);
    }
  }, [title]);

  return (
    <div
      className={`${Styles.header} ${wrapperClassName ? wrapperClassName : ""}`}
      style={wrapperStyle || {}}
    >
      {breadcrumbs && <Breadcrumb items={breadcrumbs} />}
      <div
        className={`${Styles.head} ${headClassName ? headClassName : ""}`}
        style={headStyle || {}}
      >
        {isTitleString ? (
          <h1
            style={headingStyle || {}}
            className={`${headingClassName ? headingClassName : ""}`}
          >
            {title}
          </h1>
        ) : (
          title
        )}

        {extra && (
          <div
            className={`${Styles.extras} ${
              extrasClassName ? extrasClassName : ""
            }`}
            style={extrasStyle || {}}
          >
            {extra}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
