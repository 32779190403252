import { useEffect, useState, useMemo } from "react";
import { Flex, Input, message, Popconfirm, Row, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import {
  Button,
  Micons,
  NoData,
  PageHeader,
  Table,
  Tag,
} from "../../../components/customAntd";
import {
  deleteCategoryFromDb,
  addCategoryToDB,
  updateCategoryToDb,
} from "./apiUtils";
import DefaultImage from "../../../assets/images/default-img.jpg";
import { useAppContext } from "../../../components/context/app.context";

import NewCategory from "./newCategory";
import {
  flatMapCategories,
  debounce,
  buildCategoryHierarchy,
  getCategoryName,
  getDeleteCategoryErrorMessage,
} from "../../../utils/helper";

dayjs.extend(isBetween);

const Categories = () => {
  const [list, setList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [heroImage, setHeroImage] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [isUploadingLogoImage, setIsUploadingLogoImage] = useState(false);
  const [isUploadingHeroImage, setIsUploadingHeroImage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoriesMap, setCategoriesMap] = useState({});

  const { currentLocale, isLoadingCategories, categories, setCategories } =
    useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (categories.length > 0) {
      const arr = flatMapCategories(categories);
      const obj = {};
      arr.forEach((el) => {
        obj[el.id] = el;
      });
      setCategoriesMap(obj);
      setList(arr);
    }
  }, [categories]);

  const columns = useMemo(
    () => [
      {
        dataIndex: "previewResourceUrl",
        width: 100,
        render: (_, record) => (
          <img
            src={
              record?.locales?.find?.(
                (locale) => locale.localeId === currentLocale
              )?.previewResourceUrl ??
              record?.previewResourceUrl ??
              DefaultImage
            }
            alt="Preview"
            height={50}
            width={50}
            className="table-data-image"
            // onClick={() => handleEditClick(record)}
          />
        ),
      },
      {
        title: t("NAME"),
        dataIndex: "name",
        key: "name",
        render: (_, record) => {
          return <p>{getCategoryName(record, currentLocale)}</p>;
        },
      },
      {
        title: t("CATEGORIES_UI.PARENT_CATEGORY"),
        key: "parentCategory",
        render: (_, { parentCategoryId }) => (
          <p>
            {parentCategoryId
              ? getCategoryName(categoriesMap[parentCategoryId], currentLocale)
              : "--"}
          </p>
        ),
      },
      {
        key: "status",
        render: (_, { isActive }) =>
          !isActive ? <Tag isDisabled>{t("INACTIVE")}</Tag> : <></>,
      },
      {
        title: t("ACTION"),
        dataIndex: "action",
        key: "action",
        width: 100,
        render: (_, record) => {
          // const {
          //   _count: { benefits },
          //   subCategories,
          // } = record;
          // const title = getDeleteCategoryErrorMessage(record, t);
          const { isActive } = record;
          return (
            <Flex
              gap={5}
              style={{ width: "max-content" }}
              key={`${record.id}-${Math.random()}`}
            >
              {record.parentCategoryId && (
                <Popconfirm
                  placement="topRight"
                  title={t(
                    isActive
                      ? "CATEGORIES_UI.HIDE_CONFIRMATION"
                      : "CATEGORIES_UI.RESTORE_CONFIRMATION"
                  )}
                  // onConfirm={
                  //   record?.subCategories?.length > 0 ||
                  //   record?._count?.benefits > 0
                  //     ? () => {}
                  //     : () => onDelete(record)
                  // }
                  onConfirm={() => onDelete(record)}
                  onCancel={() => {}}
                  okText={t("YES")}
                  cancelText={t("NO")}
                  // showCancel={!(subCategories?.length > 0 || benefits > 0)}
                  arrow={{ pointAtCenter: true }}
                  overlayInnerStyle={{ maxWidth: 360 }}
                >
                  <Button type="link">
                    <Tooltip title={t(isActive ? "DEACTIVATE" : "RESTORE")}>
                      {/* <Micons icon={isActive ? } className="table-action-icon" /> */}
                      {isActive ? (
                        <EyeInvisibleOutlined className={`table-action-icon`} />
                      ) : (
                        <EyeOutlined className={`table-action-icon`} />
                      )}
                    </Tooltip>
                  </Button>
                </Popconfirm>
              )}
              <Button
                onClick={() => onEditClick(record)}
                type="link"
                disabled={!record.isActive}
              >
                <Tooltip title={t("EDIT")}>
                  <Micons icon="edit" className="table-action-icon" />
                </Tooltip>
              </Button>
            </Flex>
          );
        },
      },
    ],
    [categoriesMap, t, currentLocale]
  );

  const onEditClick = (record) => {
    setIsModalOpen(true);
    setEditData(record);
  };

  const onDelete = async (record) => {
    const finalData = {
      defaultLocaleId: record.defaultLocaleId,
      name: record.name,
      logoUrl: record.logoImage,
      imageUrl: record.imageUrl,
      parentCategoryId: record.parentCategoryId,
      locales: record?.locales?.map(({ id, categoryId, ...rest }) => ({
        ...rest,
      })),
      order: record.order,
      isActive: !record.isActive,
    };
    const response = await updateCategoryToDb(finalData, record.id);
    if (response?.status === 200) {
      const arr = flatMapCategories(categories);
      const idx = arr.findIndex((el) => el.id === response.data.id);
      arr[idx] = response.data;
      setCategories(buildCategoryHierarchy(arr));
    } else {
      message.error({
        content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
        key: "error",
        duration: 2,
      });
    }
  };

  const onSubmit = async (data, edit) => {
    const finalData = {
      defaultLocaleId: data.defaultLocaleId,
      name: data.name,
      logoUrl: logoImage,
      imageUrl: heroImage,
      parentCategoryId: data.parentCategoryId,
      locales: data?.locales?.map(({ id, categoryId, ...rest }) => ({
        ...rest,
      })),
      order: data.order,
      isActive: data.isActive,
    };
    setIsSubmitting(true);
    const response =
      editData || edit
        ? await updateCategoryToDb(finalData, edit ? data.id : editData.id)
        : await addCategoryToDB(finalData);
    if (response?.status === 200) {
      const arr = flatMapCategories(categories);
      if (editData || edit) {
        const idx = arr.findIndex((el) => el.id === response.data.id);
        arr[idx] = response.data;
        setCategories(buildCategoryHierarchy(arr));
      } else {
        setCategories(buildCategoryHierarchy([response.data, ...arr]));
      }
      onModalClose();
    } else {
      message.error({
        content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
        key: "error",
        duration: 2,
      });
    }
    setIsSubmitting(false);
  };

  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    const arr = flatMapCategories(categories);
    if (searchTerm) {
      setList(
        arr.filter((el) =>
          el.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setList(arr);
    }
  }, 150);

  const onModalClose = () => {
    setIsModalOpen(false);
    setHeroImage("");
    setLogoImage("");
    setIsUploadingHeroImage(false);
    setIsUploadingLogoImage(false);
    setEditData(null);
    setIsSubmitting(false);
  };

  return (
    <>
      <PageHeader
        title={t("BENEFIT_CATEGORIES.LABEL")}
        extra={
          <div style={{ display: "flex" }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              {t("CATEGORIES_UI.NEW")}
            </Button>
          </div>
        }
      />
      <Flex justify="flex-start">
        <Input
          style={{ maxWidth: 300, marginBottom: 30 }}
          placeholder={t("SEARCH_SOMETHING")}
          onChange={handleSearch}
          prefix={<SearchOutlined />}
        />
      </Flex>
      {isLoadingCategories ? (
        <Spin size="small" style={{ marginTop: 30, width: "100%" }} />
      ) : list.length > 0 ? (
        <Table dataSource={list} columns={columns} />
      ) : (
        <NoData description={t("CATEGORIES_UI.NO_CATEGORIES_FOUND")} />
      )}

      <NewCategory
        isOpen={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        editData={editData}
        logoImage={logoImage}
        setLogoImage={setLogoImage}
        setHeroImage={setHeroImage}
        heroImage={heroImage}
        isUploadingLogoImage={isUploadingLogoImage}
        isUploadingHeroImage={isUploadingHeroImage}
        setIsUploadingHeroImage={setIsUploadingHeroImage}
        setIsUploadingLogoImage={setIsUploadingLogoImage}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default Categories;
