import { Button as ButtonAntd } from "antd";

import Styles from "./styles/button.module.scss";

const Button = ({ children, type, buttonType, className, isFullWidth, ...props }) => {
  return (
    <ButtonAntd
      className={`${Styles.button} ${type === "link" ? Styles.link : ""} ${
        type === "default" ? Styles.default : ""
      } ${buttonType === "cancel" ? Styles.cancel : ""} ${
        className ? className : ""
      }${isFullWidth ? ` ${Styles.fullwidth}` : ""}`}
      {...(type && {
        type: type,
      })}
      {...props}
    >
      {children}
    </ButtonAntd>
  );
};

export default Button;
