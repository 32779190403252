import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../context/app.context";

const RequireAuth = ({ children }) => {
  const { currentUserData } = useAppContext();
  const [isUserLoggednIn, setIsUserLoggedIn] = useState(null);

  useEffect(() => {
    if (currentUserData.id) {
      setIsUserLoggedIn(true);
    }
  }, [currentUserData]);

  return (
    <>
      {isUserLoggednIn !== null &&
        (isUserLoggednIn ? children : <Navigate to="/" replace />)}
    </>
  );
};
export default RequireAuth;
