import { Radio as RadioAntd } from "antd";

import Styles from "./styles/radio.module.scss";

const { Group } = RadioAntd;

const Radio = ({ children, className, ...props }) => {
  return (
    <RadioAntd
      className={`${Styles.radio}${className ? ` ${className}` : ""}`}
      {...props}
    >
      {children}
    </RadioAntd>
  );
};

Radio.Group = Group;

export default Radio;
