import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import constant from './../config/constant';

const baseURL = constant.config.REACT_API_HOST;

const initialState = {
    logger: {},
    userAuditHistoryLogs: [],
    success: '',
    error: '',
    loading: false,
};

export const createLogger = createAsyncThunk(
    "logger/createLogger",
    async (data, { rejectWithValue }) => {
        try {
            const logValue = {
                logger: "react-app",
                logType: "debug",
                message: "test message",
                userId: "113213633482525961921"
            }
            const response = await axios.post(`${baseURL}logger/create-log`, logValue);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const fetchUserAuditHistoryLogs = createAsyncThunk(
    "logger/fetchUserLogs",
    async (data, { rejectWithValue }) => {
        try {

            const payload = {
                filterCondition: data.filterCondition
            }
            const response = await axios.post(`${baseURL}profiles/audit/logs/me`, payload);

            return response.data;
        } catch (error) {

            return rejectWithValue(error.response.data);
        }
    }
);



const loggerSlice = createSlice({
    name: "logger", initialState, reducer: {}, extraReducers: {
        [createLogger.pending]: (state, action) => {
            return { ...state, success: 'pending', error: '', loading: true, }
        },
        [createLogger.fulfilled]: (state, action) => {
            return { ...state, logger: action.payload, success: 'success', error: '', loading: false, }
        },
        [createLogger.rejected]: (state, action) => {
            return { ...state, success: 'reject', error: action.payload, loading: false, }
        },

        [fetchUserAuditHistoryLogs.pending]: (state, action) => {
            return { ...state, success: 'pending', error: '', loading: true, }
        },
        [fetchUserAuditHistoryLogs.fulfilled]: (state, action) => {
            return { ...state, userAuditHistoryLogs: action.payload, success: 'success', error: '', loading: false, }
        },
        [fetchUserAuditHistoryLogs.rejected]: (state, action) => {
            return { ...state, success: 'reject', error: action.payload, loading: false, }
        },

    }
});

export default loggerSlice.reducer;