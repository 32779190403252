
import { Form } from "antd";
import ColorPicker from "../colorPicker";

const ColorPickerGroup = ({ label, description, defaultColor, onChange, fieldName, isCompactMode = false }) => {
    const { Item } = Form;
    return (

        <Item
            label={label}
            name={fieldName}
            extra={description}

        >
            <ColorPicker
                defaultColor={defaultColor}
                onChange={(color) => onChange(color)}
                isCompactMode={isCompactMode}
            />
        </Item>
    );
};

export default ColorPickerGroup;
