import axios from "axios";

import constant from "../../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const getAllBenefitsFromDb = async (filters) => {
  return axios
    .post(`${baseURL}benefit/all`, filters)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Get all benefits from DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const addBenefitToDb = async (data) => {
  return axios
    .post(`${baseURL}benefit`, data)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Add benefit to DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const updateBenefitToDb = async (data, id) => {
  return axios
    .put(`${baseURL}benefit/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Update benefit to DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const deleteBenefitFromDb = async (id) => {
  return axios
    .delete(`${baseURL}benefit/${id}`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Delete benefit from DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const cloneCurrentBenefitinDB = async (id) => {
  return axios
    .post(`${baseURL}benefit/${id}/clone`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Cloning benefit in DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const getBenefitPreviewToken = async (id) => {
  return axios
    .get(`${baseURL}benefit/${id}/previewurl`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Getting PreviewURLfor benefit in DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const generateSweepStakeResults = async (id, data) => {
  return axios
    .post(`${baseURL}sweepstake/${id}/result`, data)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Generating results for sweepstake in DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const getBenefitsByIdFromDb = async (id) => {
  return axios
    .get(`${baseURL}benefit/${id}`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Get benefit by id from DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const getMetabaseUrl = async (id) => {
  return axios
    .get(`${baseURL}sweepstake/${id}/report-url`)
    .then((response) => response)
    .catch((err) => {
      return err.response?.data?.message ?? err.response;
    });
};

export const getSweepstakesDownloadUrl = async (id) => {
  return axios
    .get(`${baseURL}sweepstake/${id}/download-url`)
    .then((response) => response)
    .catch((err) => {
      return err.response?.data?.message ?? err.response;
    });
};

export const createNotification = async (id, data) => {
  return axios
    .post(`${baseURL}notifications`, {
      ...data,
      benefitId: id,
    })
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Creating notifications for benefits in DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const updateNotification = async (benefitId, notificationId, data) => {
  return axios
    .put(`${baseURL}notifications/${notificationId}`, data)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Updating notifications for benefits in DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const getNewsListForBenefit = async (id) => {
  return axios
    .post(`${baseURL}notifications/all`, {
      filterCondition: id ? { benefitId: id, level: ['News'] } : { level: ['News'] },
    })
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Getting news for benefits in DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const getNotificationsListForBenefit = async (id) => {
  return axios
    .post(`${baseURL}notifications/all`, {
      filterCondition: id ? { benefitId: id, level: ['User', 'Benefit'] } : { level: ['User', 'Benefit'] },
    })
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Getting notifications for benefits in DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const deleteNotification = async (notificationId) => {
  return axios
    .delete(`${baseURL}notifications/${notificationId}`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Deleting notification for benefits in DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};
