import axios from "axios";

import constant from "../../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const addCategoryToDB = async (data) => {
  return axios
    .post(`${baseURL}categories`, data)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Add category to DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const updateCategoryToDb = async (data, id) => {
  return axios
    .put(`${baseURL}categories/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Update category to DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const deleteCategoryFromDb = async (id) => {
  return axios
    .delete(`${baseURL}categories/${id}`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Delete category from DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};
