import axios from "axios";

import constant from "../../config/constant";
const baseURL = constant.config.REACT_API_HOST;

export const updateCompany = async (data, id) => {
  return axios
    .put(`${baseURL}admin/company/${id}`, {...data})
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return error;
    });
};
