import Styles from "./styles/Micons.module.scss";

const Micons = ({
  icon,
  type = "outlined",
  className,
  style,
  wrapperStyle,
  isHover = true,
  wrapperClassName,
  ...rest
}) => {
  return (
    <div {...rest} style={wrapperStyle || {}} className={wrapperClassName}>
      <span
        className={`m-icons material-icons${
          type && type !== "" && type !== "filled" ? `-${type}` : ""
        } ${Styles.icon}${isHover && ` ${Styles["hover"]}`} ${
          className ? className : ""
        }`}
        style={style || {}}
      >
        {icon}
      </span>
    </div>
  );
};

export default Micons;
