// import Third party and React
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  Space,
  Row,
  Col,
  Form,
  Skeleton,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

// import utils, API Utils and helper functions
import { useAppContext } from "../../../components/context/app.context";
import { BREADCRUMB_PROGRAMS } from "../../../utils/programs/constant";
import { getAllProgramList, addProgram, deleteProgram } from "./apiUtils";
import { debounce } from "../../../utils/helper";

// import custom components
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Card,
  NoData,
  Micons,
} from "../../../components/customAntd";
import ProgramFilters from "./filters";
import NewProgram from "./newProgram";
import ReadMore from "../../../components/readmore";

// import custom Style
import Styles from "./styles/program.module.scss";
import Benefits from "./benefits";

const { Meta } = Card;

const ProgramPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { currentLocale } = useAppContext();

  const [displayType, setDisplayType] = useState("grid");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState([]);
  const [programIdEdit, setProgramIdEdit] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isbenefitsModalOpen, setIsBenefitsModalOpen] = useState(false);
  const [editPorgram, setEditProgram] = useState(null);
  const [filters, setFilters] = useState({
    filterCondition: {},
  });

  const [tiers, setTiers] = useState([]);

  // useMemo is used to prevent the re-creation of the 'columns' variable on every render.
  const columns = useMemo(
    () => [
      {
        title: t("PROGRAM_UI.PROGRAM_NAME"),
        dataIndex: "name",
        key: "name",
        width: "30%",
        sorter: (a, b) => a.title.length - b.title.length,
        render: (_, record) => (
          <Link to={`/programs/${record.id}`} className="table-link">
            {record.name}
          </Link>
        ),
      },
      {
        title: t("STATUS"),
        dataIndex: "status",
        key: "status",
        render: (_, record) => <Tag color="#9B43FD">{record.status}</Tag>,
      },
      {
        title: t("DESCRIPTION"),
        dataIndex: "description",
        key: "description",
      },
      {
        title: t("DATE"),
        key: "startDate",
        dataIndex: "startDate",
        width: "30%",
        render: (_, { createdAt }) => (
          <>
            <Tag isDisabled>{dayjs(createdAt).format("MM/DD/YYYY")}</Tag>
          </>
        ),
      },
      {
        title: t("ACTION"),
        key: "action",
        width: "15%",
        render: (_, record) => (
          <Space size="middle">
            <Button type="link">
              <Tooltip title={t("PROGRAM_UI.ASSIGN_BENEFITS")}>
                <Micons
                  icon="card_giftcard"
                  className="table-action-icon"
                  onClick={() =>
                    navigate(`/programs/${record.id}/tiers/benefits`)
                  }
                />
              </Tooltip>
            </Button>

            <Button type="link">
              <Tooltip title={t("EDIT")}>
                <Micons
                  icon="edit"
                  className="table-action-icon"
                  onClick={() => handleEditClick(record)}
                />
              </Tooltip>
            </Button>

            <Popconfirm
              title="Are you sure you want to delete this program?"
              onConfirm={() => handleDelete(record.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
              placement="topRight"
              arrowPointAtCenter
            >
              <Button type="link">
                <Tooltip title={t("DELETE")}>
                  <Micons icon="delete" className="table-action-icon" />
                </Tooltip>
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Get program categories initially

  // Get all Programs initially
  useEffect(() => {
    const fetchPrograms = async () => {
      setIsLoading(true);
      const getAllPrograms = await getAllProgramList(filters);
      if (getAllPrograms?.status === 200) {
        setData(getAllPrograms.data.programs);
      }
      setIsLoading(false);
    };
    fetchPrograms();
  }, [filters]);

  useEffect(() => {
    setData((prev) =>
      prev.map((program) =>
        program.id === programIdEdit
          ? {
              ...program,
              tiers: tiers.map((tier) => {
                return {
                  id: tier.id,
                  name: tier.tierName,
                  description: tier.tierDescription,
                  expiryDate: dayjs(tier.expiryDate, "DD/MM/YYYY"),
                  pointThreshold: tier.tierPointThreshold,
                  isSavedToDb: tier.isSavedToDb,
                  tierBenefits: tier.passData?.tierBenefits,
                  information: tier.passData?.tierInformation,
                  iconImageUrl: tier.passData?.iconImage[0]?.resourceUrl,
                  logoImageUrl: tier.passData?.logoImage[0]?.resourceUrl,
                  stripImageUrl: tier.passData?.stripImage[0]?.resourceUrl,
                  appleLogoImageUrl:
                    tier.passData?.applelogoimage[0]?.resourceUrl,
                  heroImageUrl: tier.passData?.heroimage[0]?.resourceUrl,
                  locations: tier.passData?.locations,
                  locales: tier.passData?.locales,
                  defaultLocaleId: tier.passData?.defaultLocaleId,
                  appleLogoSize: tier.passData?.appleLogoSize,
                  passColors: {
                    labelColor: tier.passData?.labelColor,
                    stripColor: tier.passData?.stripColor,
                    textColor: tier.passData?.textColor,
                  },
                };
              }),
            }
          : program
      )
    );
  }, [tiers]);

  const setProgramDetails = (details) => {
    setData((prev) => {
      const pos = prev.findIndex((item) => item.id === details.id);
      prev[pos] = details;
      return prev;
    });
  };

  // Switching between a list view and a grid view.
  const onViewChange = () => {
    setDisplayType(displayType === "list" ? "grid" : "list");
  };

  // Handler for the cancel button
  const handleCancelClick = () => {
    form.resetFields();
    setIsSubmitting(false);
    setIsDataLoading(false);
    setIsOpen(false);
    setIsEdit(false);
  };

  // Handler for the cancel button
  const handleCancelClickBenefits = () => {
    setIsBenefitsModalOpen(false);
  };
  // Submitting the final form after it has passed all of the validation checks
  const handleFormSubmit = async (data) => {
    try {
      if (!isEdit) {
        setIsSubmitting(true);
        const finalData = {
          ...data,
        };
        const createNewProgram = await addProgram(finalData);
        if (createNewProgram?.status === 200) {
          message.success("Program created successfully");
          setData((prev) => [createNewProgram.data, ...prev]);
          handleCancelClick();
        } else {
          throw new Error(
            createNewProgram.message ??
              "Something went wrong while creating a Program"
          );
        }
        setIsDataLoading(false);
        setIsSubmitting(false);
      } else {
        data.isEdit = false;
        setData((prev) => {
          return prev.map((item) => (item.id === programIdEdit ? data : item));
        });
        handleCancelClick();
        setIsDataLoading(false);
        setIsSubmitting(false);
      }
    } catch (err) {
      setIsDataLoading(false);
      setIsSubmitting(false);
      message.error(err.message);
    }
  };

  // Handler for the edit 'program' button
  const handleEditClick = (record) => {
    const finalData = {
      id: record.id,
      program_name: record.name,
      program_description: record.description,
      program_status: record.status,
      points_enabled: record.pointsEnabled,
      points_expire: record.pointsExpire ? "yes" : "no",
      points_expiration_period: record.expirationPeriod,
      defaultLocaleId: record.defaultLocaleId,
      program_locales: record.locales?.map((locale) => ({
        program_name: locale.name,
        program_description: locale.description,
        localeId: locale.localeId,
      })),
    };
    const tierData = record.tiers.map((item) => {
      return {
        id: item.id,
        tierName: item.name,
        tierDescription: item.description,
        expiryDate: dayjs(item.expiryDate).format("DD/MM/YYYY"),
        tierPointThreshold: item.pointThreshold,
        isSavedToDb: true,
        passData: {
          tierBenefits: item.tierBenefits,
          tierInformation: item.information,
          logoImage: [{ resourceUrl: item.logoImageUrl, type: "logoImage" }],
          iconImage: [{ resourceUrl: item.iconImageUrl, type: "iconImage" }],
          stripImage: [{ resourceUrl: item.stripImageUrl }],
          applelogoimage: [
            { resourceUrl: item.appleLogoImageUrl, type: "appleLogoImage" },
          ],
          heroimage: [{ resourceUrl: item.heroImageUrl }],
          locations: item?.locations?.map((el) => ({
            name: el.name,
            latitude: el.latitude || el.lat,
            longitude: el.longitude || el.lon,
            lockScreenMessage: el.lockScreenMessage,
          })),
          locales: item.locales,
          defaultLocaleId: item.defaultLocaleId,
          appleLogoSize: item.appleLogoSize,
          labelColor: item.passColors?.labelColor,
          passColor: item.passColors?.stripColor,
          textColor: item.passColors?.textColor,
        },
      };
    });
    form.resetFields();
    setTiers(tierData);
    setProgramIdEdit(record.id);
    setEditProgram(record);
    setIsEdit(true);
    form.setFieldsValue(finalData);
    setIsOpen(true);
  };

  //Handle assign benefits click
  const handleAssignBenefitsClick = (record) => {
    const tierData = record.tiers.map((item) => {
      return {
        id: item.id,
        tierName: item.name,
        tierDescription: item.description,
        expiryDate: dayjs(item.expiryDate).format("DD/MM/YYYY"),
        isSavedToDb: true,
        passData: {
          tierBenefits: item.tierBenefits,
          tierInformation: item.information,
        },
      };
    });
    setTiers(tierData);
    setProgramIdEdit(record.id);
    setIsBenefitsModalOpen(true);
  };

  // Handler for the delete 'program' button
  const handleDelete = async (recordId) => {
    const deletedProgram = await deleteProgram(recordId);
    if (deletedProgram?.status === 200) {
      message.success("Program deleted successfully");
      setData((prev) =>
        prev.filter((item) => item.id !== deletedProgram.data.id)
      );
    }
  };

  // Handler for the search input
  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    if (searchTerm.length !== "") {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            name: searchTerm,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.search;
      setFilters(filtersCopy);
    }
  });

  const handleViewClick = (record) => {
    navigate(`/programs/${record.id}`);
  };

  // Handler for the filter select
  const handleFilterByChange = (value) => {
    if (value) {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            status: value,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.status;
      setFilters(filtersCopy);
    }
  };

  return (
    <>
      <PageHeader
        title={t("PROGRAMS")}
        breadcrumbs={BREADCRUMB_PROGRAMS(t)}
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              form.resetFields();
              setTiers([]);
              setIsOpen(true);
            }}
          >
            {t("PROGRAM_UI.NEW_PROGRAM")}
          </Button>
        }
      />

      <ProgramFilters
        onViewChange={onViewChange}
        type={displayType}
        onSearch={handleSearch}
        onFilterByChange={handleFilterByChange}
      />
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {data.length === 0 ? (
            <NoData />
          ) : (
            <>
              {displayType === "list" ? (
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                />
              ) : (
                <Row gutter={[25, 25]}>
                  {data.map((item) => {
                    const currentLocaleData = item?.locales?.filter(
                      (item) => item.localeId === currentLocale
                    )[0];
                    return (
                      <Col
                        xs={24}
                        md={12}
                        lg={6}
                        key={`${item.name}-${item.id}}`}
                      >
                        <Card
                          actions={[
                            <Tooltip title={t("PROGRAM_UI.ASSIGN_BENEFITS")}>
                              <Micons
                                icon="card_giftcard"
                                className="table-action-icon"
                                onClick={() =>
                                  navigate(
                                    `/programs/${item.id}/tiers/benefits`
                                  )
                                }
                              />
                            </Tooltip>,
                            <Popconfirm
                              title="Are you sure you want to delete this program?"
                              onConfirm={() => handleDelete(item.id)}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip title={t("DELETE")}>
                                <Micons
                                  icon="delete"
                                  className="table-action-icon"
                                />
                              </Tooltip>
                            </Popconfirm>,
                            <Tooltip title={t("EDIT")}>
                              <Button
                                type="link"
                                onClick={() => handleEditClick(item)}
                              >
                                <Micons
                                  icon="edit"
                                  className="table-action-icon"
                                />
                              </Button>
                            </Tooltip>,
                            <Tooltip title={t("VIEW")}>
                              <Button
                                type="link"
                                onClick={() => handleViewClick(item)}
                              >
                                <Micons
                                  icon="remove_red_eye"
                                  className="table-action-icon"
                                />
                              </Button>
                            </Tooltip>,
                          ]}
                        >
                          <Tag className={Styles.tag} isDisabled>
                            {dayjs(item.createdAt).format("MM/DD/YYYY")}
                          </Tag>
                          <Meta
                            title={
                              currentLocaleData
                                ? currentLocaleData.name
                                : item.name
                            }
                            description={
                              <ReadMore
                                text={
                                  currentLocaleData
                                    ? currentLocaleData.description
                                    : item?.description
                                }
                                characterLimit={75}
                              >
                                {(currentLocaleData
                                  ? currentLocaleData.description.length > 75
                                  : item?.description.length > 75) && (
                                  <ReadMore.Trigger
                                    trigger={`...${t("MORE")}`}
                                    buttonClassName={Styles["more-button"]}
                                    text={
                                      currentLocaleData
                                        ? currentLocaleData.description
                                        : item?.description
                                    }
                                  />
                                )}
                              </ReadMore>
                            }
                          />
                          <Tag color="#9B43FD">{item.status}</Tag>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </>
          )}
        </>
      )}
      <NewProgram
        form={form}
        isOpen={isOpen}
        onCancel={handleCancelClick}
        onSubmitClick={() => form.submit()}
        onSubmit={handleFormSubmit}
        loading={isSubmitting}
        isDataLoading={isDataLoading}
        isEdit={isEdit}
        tierList={tiers}
        setTierList={setTiers}
        setProgramDetails={setProgramDetails}
        programIdEdit={programIdEdit}
        editProgram={editPorgram}
      />
      <Benefits
        isOpen={isbenefitsModalOpen}
        onCancel={handleCancelClickBenefits}
        tierList={tiers}
        setTierList={setTiers}
        programId={programIdEdit}
      />
    </>
  );
};

export default ProgramPage;
