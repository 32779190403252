import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Japanese from "./translations/ja.json";
import English from "./translations/en.json";
import Spanish from "./translations/es.json";
import Hindi from "./translations/hi.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en_US: { translation: English },
      ja_JP: { translation: Japanese },
      es_US: { translation: Spanish },
      hi_IN: { translation: Hindi },
    },
    fallbackLng: "en_US",
  });
