import { Form, Input } from "antd";

import { FormWrapper, UploadFile } from "../../../../components/customAntd";
import { CommercialTemplateName } from "../../../../utils/constants";

const { Item } = Form;

const Logo = ({
  t,
  themeId,
  isEdit,
  logoImage,
  setLogoImage,
  setIsLogoImageLoading,
}) => {
  return (
    <FormWrapper heading={t("STOREFRONT_UI.LOGO")}>
      <Item
        label={`${t("LOGO_IMAGE")} :`}
        name="logoUrl"
        extra={
          <>
            {themeId === CommercialTemplateName && (
              <p>{`${t("FILE_UPLOAD.RECOMMENDED_SIZE")} - 556x256`}</p>
            )}
            <p>
              {`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG. ${t(
                "MAX_SIZE"
              )}: 5${t("MB")}`}
            </p>
          </>
        }
        valuePropName=""
        rules={[
          {
            required: true,
            message: t("UPLOAD_LOGO_IMAGE"),
          },
        ]}
      >
        <UploadFile
          showUploadList={false}
          placeholder={
            themeId === CommercialTemplateName ? "556x256" : "1024x1024 (1:1)"
          }
          fileFor="collection"
          fileSize={5}
          accept=".jpg, .jpeg, .png"
          style={{ width: "144px" }}
          height={144}
          image={logoImage}
          imageFit="contain"
          onLoading={(value) => setIsLogoImageLoading(value)}
          onChange={(image) => {
            setLogoImage(image.s3Url);
          }}
        />
      </Item>
      <Item
        label={`${t("STOREFRONT_UI.TITLE")} :`}
        name="title"
        rules={[
          {
            required: true,
            message: t("STOREFRONT_UI.TITLE_VALIDATION"),
          },
          {
            validateTrigger: "onSubmit",
          },
          {
            validator: async (_, title) => {
              if (title && title.split(" ").length > 1) {
                return Promise.reject(
                  new Error(t("STOREFRONT_UI.SPACE_VALIDATION"))
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          placeholder={t("STOREFRONT_UI.TITLE_PLACEHOLDER")}
          disabled={isEdit}
        />
      </Item>
    </FormWrapper>
  );
};

export default Logo;
