import { useState, useEffect } from "react";
import { Form, Radio, InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";

import { BREADCRUMB_PROGRAM_WAY_TO_EARN } from "../../../../utils/programs/constant";
import { Modal, Button, ModalHeader } from "../../../../components/customAntd";
import Styles from "../styles/wayToEarn.module.scss";
import { Required } from "../newProgram/utils";
import { t } from "i18next";

const WayToSpendForm = ({
  form,
  isOpen,
  onSubmit,
  onClose,
  tierData,
  benefitData,
  loading,
  isEdit,
  programDetails,
  currentLocaleData,
}) => {
  const { t } = useTranslation();
  const rewardType = Form.useWatch("way_to_spend_type", form);
  const benefitType = Form.useWatch("benefit_type", form);
  const [nft, setNft] = useState(null);

  const { Option } = Select;

  // function renderOption(option) {
  //   return (
  //     <Option key={option.value} value={option.value} label={option.label}>
  //       <img
  //         src={option.imageURL}
  //         alt={option.label}
  //         height={40}
  //         width={40}
  //         className={Styles["label-img"]}
  //       />{" "}
  //       {option.label}
  //     </Option>
  //   );
  // }

  // useEffect(() => {
  //   if (nftId) {
  //     let selectedNft = nftData.find((nft) => nft.value == nftId);
  //     setNft(selectedNft);
  //     if (selectedNft.tierId) {
  //       form.setFieldValue("tier_select", selectedNft.tierId);
  //     }
  //   } else {
  //     setNft(null);
  //   }
  // }, [nftId]);

  return (
    <Modal
      title={
        <ModalHeader
          title={
            isEdit
              ? t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.EDIT_WAY_TO_SEND")
              : t("PROGRAM_UI.SPEND_POINTS_PAGE.ADD_WAY_TO_SPEND")
          }
          breadcrumbs={BREADCRUMB_PROGRAM_WAY_TO_EARN(
            currentLocaleData
              ? currentLocaleData.name
              : programDetails?.name || "",
            t
          )}
        />
      }
      style={{
        overflowX: "hidden",
        overflowY: "scroll",
        maxHeight: "calc(100vh - 250px)",
      }}
      open={isOpen}
      centered
      width={1500}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button buttonType="cancel" onClick={onClose} key="cancel-button">
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={() => form.submit()}
          loading={loading}
          key="create-button"
        >
          {isEdit
            ? t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.EDIT_REWARD")
            : t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.ADD_REWARD")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        disabled={loading}
        requiredMark={false}
      >
        <TypeSelect form={form} isEdit={isEdit} />
        <Form.Item
          label={
            <span className={Styles["title"]}>
              {t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.SELECT_TYPE_OF_BENEFIT")}
            </span>
          }
          name="benefit_type"
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder={t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.SELECT_BENEFIT")}
            onChange={() => form.resetFields(["benefit_select"])}
            options={[
              {
                label: t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.ONLINE_DISCOUNT"),
                value: "coupon",
              },
              {
                label: t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.VIDEO_BENEFIT"),
                value: "video",
              },
              {
                label: t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.AUDIO_BENEFIT"),
                value: "audio",
              },
              {
                label: t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.DIGITAL_BOOK"),
                value: "digitalbook",
              },
            ]}
            notFoundContent={t(
              "PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.NOT_ADDED_MERCHANTS"
            )}
          />
        </Form.Item>
        <Form.Item
          label={
            <span className={Styles["title"]}>
              {t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.LINK_BENEFIT")}:
            </span>
          }
          name="benefit_select"
        >
          <Select
            options={benefitData.filter((benefit) =>
              benefitType ? benefit.type === benefitType : true
            )}
            style={{ width: "100%" }}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              );
            }}
            placeholder={t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.SELECT_BENEFIT")}
            notFoundContent={`You do not yet have any '${benefitType}' benefits with your current search filters`}
          />
        </Form.Item>
        <Form.Item
          label={Required(
            <span className={Styles["title"]}>
              {t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.ENTER_POINTS")}
            </span>
          )}
          name="point_price"
          extra={t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.AMOUNT_OF_POINT")}
          rules={[
            {
              required: true,
              message: t(
                "PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.POINT_PRICE_MESSAGE"
              ),
            },
          ]}
        >
          <InputNumber
            placeholder={0}
            addonAfter={t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.POINTS")}
          />
        </Form.Item>
        <Form.Item
          label={
            <span className={Styles["title"]}>
              {rewardType === "benefit"
                ? `${t("PROGRAM_UI.WAY_TO_EARN_MODAL.LINK_TIERS")}:`
                : t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.LINK_TIER")}
            </span>
          }
          name="tier_select"
          // rules={
          //   rewardType === "nft"
          //     ? [
          //         {
          //           required: true,
          //           message: t(
          //             "PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.LINK_TIER_MESSAGE"
          //           ),
          //         },
          //       ]
          //     : []
          // }
        >
          <Select
            mode={rewardType === "benefit" ? "multiple" : undefined}
            allowClear
            style={{ width: "100%" }}
            //Need to check the disabled condition
            // disabled={nft && nft.tierId}
            title={
              nft && nft.tierId
                ? t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.NFT_ALREADY_CONNECTED")
                : ""
            }
            placeholder={t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.SELECT_TIER")}
            options={tierData}
            notFoundContent={t(
              "PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.SELECT_TIER_VALIDATION"
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const TypeSelect = ({ form, isEdit }) => {
  const rewardType = Form.useWatch("way_to_spend_type", form);
  const options = [
    // {
    //   action: t("NFT"),
    //   value: "nft",
    //   icon: <img src="https://img.icons8.com/stickers/100/null/nft.png" />,
    // },
    {
      action: t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.BENEFIT"),
      value: "benefit",
      icon: <img src="https://img.icons8.com/stickers/100/null/gift.png" />,
    },
  ];

  useEffect(() => {
    if (!isEdit) {
      form.resetFields([
        "benefit_type",
        "benefit_select",
        "tier_select",
        "point_price",
      ]);
      form.setFieldValue("way_to_spend_type", "benefit");
    }
  }, [rewardType]);

  return (
    <Form.Item
      label={Required(
        <span className={Styles["title"]}>
          {t("PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.SELECT_ITEM_TYPE")}
        </span>
      )}
      name="way_to_spend_type"
      rules={[
        {
          required: true,
          message: t(
            "PROGRAM_UI.ADD_WAY_TO_SPEND_MODAL.SELECT_ITEM_TYPE_MESSAGE"
          ),
        },
      ]}
    >
      <Radio.Group
        buttonStyle="outline"
        className={Styles["action"]}
        disabled={isEdit}
      >
        {options.map((option) => (
          <Radio.Button
            key={option.value}
            value={option.value}
            className={`${Styles["card"]} ${
              option.value === rewardType ? Styles["selected"] : ""
            }`}
          >
            <div>
              {option.icon}
              <p>{option.action}</p>
            </div>
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default WayToSpendForm;
