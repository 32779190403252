import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useProfileContext } from '../context/profile.context'
import { getPermissionsMetadata } from '../../redux/role.slice'
import './index.css'

const PermissionRowItem = ({
    permission,
    addPermissionId,
    removePermissionId
}) => {

    const { selectedRole } = useProfileContext()
    const hasPermission = (permissionId) => {
        return selectedRole.rolePermissions.find(rolePermission => rolePermission.permissionId === permissionId)
    }

    const togglePermission = (permission) => {
        if (hasPermission(permission.id)) {
            removePermissionId(permission.id)
        } else {
            addPermissionId(permission.id)
        }
    }

    return (
        <div className="row">
            <div className="d-flex justify-content-between align-items-center">
                <div className="w-75">
                    {permission.description}
                </div>
                <button
                    className="bg-transparent text-black"
                    onClick={() => togglePermission(permission)}
                >
                    {
                        selectedRole.rolePermissions.find(rolePermission => rolePermission.permissionId === permission.id)
                            ?
                            <i className="bi bi-toggle-on" style={{ fontSize: "35px" }}></i>
                            :
                            <i className="bi bi-toggle-off" style={{ fontSize: "35px" }}></i>

                    }
                </button>
            </div>
        </div>

    )
}

export const RolePermissions = () => {

    const { selectedRole, setSelectedRole } = useProfileContext()
    const [permissionsMetadata, setPermissionsMetadata] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        let mounted = true
        if (!mounted) return

        // Load all possible permissions if not yet loaded
        if (permissionsMetadata.length == 0) {
            dispatch(getPermissionsMetadata()).then(res => {
                setPermissionsMetadata(res.payload)
            })
        }

        return () => mounted = false
    }, [])

    const addPermissionId = (id) => {
        if (selectedRole.rolePermissions) {
            setSelectedRole(prev => ({
                ...prev,
                rolePermissions: [...prev.rolePermissions, { permissionId: id }]
            }))
        } else {
            setSelectedRole(prev => ({
                ...prev,
                rolePermissions: [{ permissionId: id }]
            }))
        }

    }

    const removePermissionId = (id) => {
        setSelectedRole(prev => ({
            ...prev,
            rolePermissions: prev.rolePermissions.filter(rolePermission => rolePermission.permissionId !== id)
        }))
    }

    return (
        <div className="table-sec mb-3 p-0 d-flex flex-column">
            <div className="container" style={{ height: "65vh", overflowY: "auto" }}>
                {selectedRole.rolePermissions &&
                    permissionsMetadata.map((permission, i) =>
                        <PermissionRowItem
                            permission={permission}
                            key={i}
                            addPermissionId={addPermissionId}
                            removePermissionId={removePermissionId}
                        />
                    )
                }
            </div>
        </div>
    )
}