import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { Modal } from "antd";

import { useProfileContext } from "../../components/context/profile.context";
import { getRoleTree } from "../../redux/role.slice";
import { useAppContext } from "../../components/context/app.context";
import ModalPopup from "../../components/modal.popup/modal.popup";
import CreateUserModal from "../../components/user.profile/user.create.modal";
import { getUsers, updateSpecificUser } from "../../redux/user.slice";
import { Table, Button } from "../../components/customAntd";

import "./user.css";

const User = (props) => {
  const { usersCount } = props;
  const { currentUserData, proxyCompany, currentUserRole } = useAppContext();
  const { roleTree, setRoleTree } = useProfileContext();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterByUserStatus, setFilterByUserStatus] = useState("all");
  const [selectedUserData, setSelectedUserData] = useState(null);

  const [modal, contextHolder] = Modal.useModal();

  const ActionButtons = (row) => (
    <div>
      <Button
        type="default"
        variant={row.status === "pendingReview" ? "primary" : "secondary"}
        onClick={() => {
          if (row.status === "approved") {
            showRevokePopup(row);
          } else if (
            row.status === "inActive" ||
            row.status === "pendingReview"
          ) {
            approveUser(row);
          } else {
            console.log(`Unhandled action for status: ${row.status}`);
          }
        }}
      >
        {row.status === "approved" ? "Revoke" : "Approve"}
      </Button>
    </div>
  );

  const RenderStatus = (record) => {
    let currentStatus = "-";
    switch (record.status) {
      case "pendingReview":
        currentStatus = "Pending Review";
        break;

      case "inviteSent":
        currentStatus = "Invite Sent";
        break;

      case "declined":
        currentStatus = "Declined";
        break;

      case "approved":
        currentStatus = "Approved";
        break;

      case "inActive":
        currentStatus = "Inactive";
        break;

      default:
        break;
    }
    return <div>{currentStatus}</div>;
  };

  const WalletDetailButton = ({ user }) => (
    <div>
      <Button onClick={() => showWalletPopup(user)}>{"Wallets"}</Button>
    </div>
  );

  const AuditHistoryButton = ({ user }) => (
    <div>
      <NavLink to={`/user-audit-history/${user.id}`} className="profileLink">
        <Button>History</Button>
      </NavLink>
    </div>
  );

  const tableColumn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
    },
    {
      title: "Merchants",
      dataIndex: "merchants",
      key: "merchants",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => <RenderStatus {...record} />,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => <ActionButtons {...record} />,
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => (
        <Button onClick={() => handleEditClick(record.id)}>Edit</Button>
      ),
    },
  ];

  // Get new role tree when currentRole is changed
  useEffect(() => {
    let mounted = true;
    if (mounted && currentUserRole && !roleTree) {
      dispatch(getRoleTree({ currentRoleId: currentUserRole.id })).then(
        (res) => {
          setRoleTree(res.payload);
        }
      );
    }
    return () => (mounted = false);
  }, [currentUserRole]);

  const handleClose = () => {
    setShow(false);
    setSelectedUser(null);
  };

  const showRevokePopup = (user) => {
    setShow(true);
    setSelectedUser(user);
  };

  const showWalletPopup = (user) => {
    modal.info({
      title: "User Wallet Details",
      content: (
        <>
          {user.userWallets.map((wallet) => (
            <div>
              <div>
                {wallet.walletProvider || "Wallet Address"}:{" "}
                {wallet.walletAddress}
              </div>
            </div>
          ))}
        </>
      ),
    });
  };

  const showSuccessToast = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateUserData = (data) => {
    const users = [...tableData];
    const pos = users.findIndex((user) => user.id === data.id);
    users[pos] = {
      ...users[pos],
      ...formatUserForDataTable(data),
    };
    setTableData(users);
  };

  const updateUserValues = async (data) => {
    const response = await dispatch(updateSpecificUser(data));
    if (response.type === "user/updateUser/fulfilled") {
      showSuccessToast(
        data.status === "approved" ? "Approve Successful" : "Revoke Successful"
      );
      updateUserData(response.payload);
    }
  };

  const revokeUser = async (user) => {
    if (user.id === currentUserData.id) {
      alert("Oops, you cannot revoke yourself!");
      handleClose();
      return;
    }
    const data = {
      id: user.id,
      status: "inActive",
    };

    await updateUserValues(data);
    handleClose();
  };

  const approveUser = async (user) => {
    const data = {
      id: user.id,
      status: "approved",
    };

    await updateUserValues(data);
  };

  const handleEditClick = (userId) => {
    const findUser = tableData.filter((item) => item.id === userId)[0];
    const userData = {
      id: findUser.id,
      name: findUser.name.props.children,
      email: findUser.email.props.children,
      roles: findUser.roles.props.children.map((item) => item.props["data-id"]),
      merchant: findUser.merchants.props.children.map(
        (item) => item.props["data-id"]
      ),
    };
    setSelectedUserData(userData);
    setShowCreateUserModal(true);
  };

  const formatUserForDataTable = (user) => {
    console.log(user);
    console.log(moment.utc(user.createdAt).local().format("DD-MMM-YYYY"), "CHECK");
    return {
      id: user.id,
      name: (
        <p className="black-text" style={{ color: "black" }}>
          {user.name}
        </p>
      ),
      email: <p className="black-text">{user.email}</p>,
      phone: <p className="black-text ml-2">{user.phone}</p>,
      company: <p className="black-text">{user.company?.name}</p>,
      roles: (
        <div>
          {user?.roles?.map((role) => (
            <p className="black-text" data-id={role.roleId}>
              {role?.role?.name}
            </p>
          ))}
        </div>
      ),
      merchants: (
        <div>
          {user?.merchants?.map(
            (merchant) =>
              merchant &&
              merchant?.merchantId && (
                <p
                  className="black-text"
                  data-id={merchant?.merchantId.toString()}
                >
                  {merchant.merchant?.name}
                </p>
              )
          )}
        </div>
      ),
      country: <p className="black-text">{user?.country ?? ""}</p>,
      createdAt: (
        <p className="black-text">
          {" "}
          {moment.utc(user?.createdAt).local().format("DD-MMM-YYYY")}
        </p>
      ),
      status: user?.status,
      wallets: <WalletDetailButton user={user} />,
      history: <AuditHistoryButton user={user} />,
    };
  };

  const addUser = (user, edit) => {
    if(edit){
      setTableData((prev) => {
        return [formatUserForDataTable(user), ...prev];
      });
    } else {
      setTableData((prev) => {
        return [...prev, formatUserForDataTable(user)];
      });
    }
  };

  const updateUser = () => {
    allUser();
  };

  const allUser = async () => {
    const payload = {
      filterCondition: {
        userStatus: filterByUserStatus,
      },
    };
    let response = await dispatch(getUsers(payload));
    const userData = response.payload.map((user) =>
      formatUserForDataTable(user)
    );
    setTableData(userData);
    usersCount(response.payload.length);
  };

  const handleFilterByUserStatus = (e) => {
    setFilterByUserStatus(e.target.value);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      allUser();
    }
    return () => (mounted = false);
  }, [dispatch, filterByUserStatus, currentUserRole]);

  return (
    <div className="table-sec mb-3 p-0">
      <div className="row mt-2 mb-3">
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Form.Group className="mr-3">
                <Form.Label className="titleStyle">
                  Filter by User Status:
                </Form.Label>
                <Form.Select
                  // ref={chainDropdown}
                  className="mt-2"
                  name="logLevelSelect"
                  onChange={(e) => handleFilterByUserStatus(e)}
                >
                  <option value="all">All</option>
                  <option value="pendingReview">Pending</option>
                  <option value="approved">Approved</option>
                </Form.Select>
              </Form.Group>
            </div>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setShowCreateUserModal(true);
              }}
            >
              Create New User
            </Button>
          </div>
        </div>

        <div className="col-md-3">
          <div className="d-flex align-items-center" style={{ float: "right" }}>
            <div
              className="mt-4 dropdown space-left"
              style={{ margin: "0px" }}
            ></div>
          </div>
        </div>
      </div>
      <Table
        columns={tableColumn}
        dataSource={tableData}
        scroll={{ x: "max-content" }}
      />
      <ModalPopup
        show={show}
        name={"Are you want sure you want to revoke this user?"}
        hide={handleClose}
        okClick={() => revokeUser(selectedUser)}
        hideFooter
        okText="Yes"
        cancelText="No"
      ></ModalPopup>
      {contextHolder}

      <CreateUserModal
        show={showCreateUserModal}
        hide={() => {
          setSelectedUserData(null);
          setShowCreateUserModal(false);
        }}
        addUser={addUser}
        updateUser={updateUser}
        selectedUserData={selectedUserData}
        setSelectedUserData={setSelectedUserData}
      />
    </div>
  );
};

export default User;
