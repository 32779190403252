import { Link } from "react-router-dom";
import { Image } from "antd";

import Card from "../card";
import Styles from "./styles/benefitsCard.module.scss";
import Micons from "../micons";

const BenefitsCard = ({ image, title, description, link, icon }) => {
  return (
    <Card className={Styles["benefits-card"]}>
      <div className={Styles.wrapper}>
        <Link to={link || ""}>
          {icon ? (
            <Micons icon={icon} isHover={false}/>
          ) : (
            <Image
              width={50}
              src={image}
              preview={false}
              className={Styles.image}
            />
          )}
          <h3>{title}</h3>
          <p>{description}</p>
        </Link>
      </div>
    </Card>
  );
};

export default BenefitsCard;
