export const verifyEmailEnglish = `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Password Reset</title>
  <style>
    /* Reset default margin and padding */
    body,
    div,
    p,
    a {
      margin: 0;
      padding: 0;
    }

    /* Container styles */
    .container {
      width: 100%;
      height: 100%;
      position: relative;
      background: #EEF0F2;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      padding-top: 40px;
      padding-bottom: 30px;
    }

    /* Logo styles */
    .logo {
      max-width: 139px;
      max-height: 32px;
    }

    /* Card styles */
    .card {
      width: 80%;
      max-width: 450px;
      background: white;
      border-radius: 8px;
      padding: 40px;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: auto;
    }

    /* Image styles */
    .image-container {
      width: 160px;
      height: 200px;
      position: relative;
      margin: 0 auto 20px;
    }

    .image {
      width: 100%;
      height: 100%;
      background: #DADBDC;
      border-radius: 8px;
    }

    /* Title styles */
    .title {
      font-size: 22px;
      font-weight: 700;
      color: #252323;
      margin-bottom: 16px;
    }

    /* Description styles */
    .description {
      font-size: 14px;
      font-weight: 400;
      color: #252323;
      line-height: 21px;
      font-family: Arial;
    }

    .disclaimer {
      font-size: 12px;
      font-weight: 400;
      color: #252323;
      line-height: 16px;
      font-family: Arial;
    }

    /* Button styles */
    .button {
      display: inline-block;
      background: linear-gradient(93deg, #BB52F5 0%, #802BE1 100%);
      color: white !important;
      font-size: 20px;
      padding: 10px 20px;
      border-radius: 34px;
      text-decoration: none;
      margin-bottom: 24px;
      margin-top: 24px;
      font-family: Arial;
    }

    /* Link styles */
    .link {
      font-weight: 400;
      line-height: 21px;
      font-family: Arial;
      font-size: 14px;
      color: #8A32E5 !important;
      text-decoration: underline;
    }

    /* Footer styles */
    .footer {
      margin-top: 40px;
      font-size: 12px;
      color: black;
      text-align: center;
    }

    .footer-links {
      margin-top: 8px;
    }

    .footer-link {
      text-decoration: none !important;
      color: #252323 !important;
    }

    .socials {
      margin-bottom: 40px;
    }

    .social-link {
      width: 24px;
      height: 24px;
      margin-right: 48px;
    }

    .social-link-last {
      margin: 0 !important;
    }
  </style>
</head>

<body>
  <div class="container">
    <img class="logo" src="{{ storefront.logoUrl }}" />
    <div class="card">
      <div class="image-container">
        <img src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/verify-email.png" />
      </div>
      <div class="title">Verify your email address</div>
      <div class="description">
        Thanks for signing up with us. Please click on the button below to verify your email address
      </div>
      <a href="{{ verifyEmailLink }}" class="button">Verify email address</a>
      <div class="description">
        If you're having trouble clicking the "Verify email address" button, copy and paste the URL below into your web browser:
      </div>
      <a href="{{ verifyEmailLink }}" class="link">{{ verifyEmailLink }}</a>
    </div>
    {{#if storefront.enableFooter}}
    <div class="footer">
      <div class="socials">
        {{#if storefront.twitterUrl}}
        <a href="{{ storefront.twitterUrl }}"> <img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/twitter.png" /></a>
        {{/if}}
        {{#if storefront.discordUrl}}
        <a href="{{ storefront.discordUrl }}"><img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/discord.png" /></a>
        {{/if}}
        {{#if storefront.mediumUrl}}
        <a href="{{ storefront.mediumUrl }}"><img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/medium.png" /></a>
        {{/if}}
        {{#if storefront.websiteUrl}}
        <a href="{{ storefront.websiteUrl }}"><img class="social-link social-link-last" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/website.png" /></a>
        {{/if}}
      </div>
      <div class="disclaimer">© {{ year }} {{ storefront.companyName }}, All right reserved.</div>
      <div class="footer-links">
        Powered by <a class="footer-link" href="https://faro.xyz">Faro</a>.
      </div>
    </div>
    {{/if}}
  </div>
</body>

</html>
`;

export const passwordResetEnglish = `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Password Reset</title>
  <style>
    /* Reset default margin and padding */
    body,
    div,
    p,
    a {
      margin: 0;
      padding: 0;
    }

    /* Container styles */
    .container {
      width: 100%;
      height: 100%;
      position: relative;
      background: #EEF0F2;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      padding-top: 40px;
      padding-bottom: 30px;
    }

    /* Logo styles */
    .logo {
      max-width: 139px;
      max-height: 32px;
    }

    /* Card styles */
    .card {
      width: 80%;
      max-width: 450px;
      background: white;
      border-radius: 8px;
      padding: 40px;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: auto;
    }

    /* Image styles */
    .image-container {
      width: 160px;
      height: 200px;
      position: relative;
      margin: 0 auto 20px;
    }

    .image {
      width: 100%;
      height: 100%;
      background: #DADBDC;
      border-radius: 8px;
    }

    /* Title styles */
    .title {
      font-size: 22px;
      font-weight: 700;
      color: #252323;
      margin-bottom: 16px;
    }

    /* Description styles */
    .description {
      font-size: 14px;
      font-weight: 400;
      color: #252323;
      line-height: 21px;
      font-family: Arial;
    }

    .disclaimer {
      font-size: 12px;
      font-weight: 400;
      color: #252323;
      line-height: 16px;
      font-family: Arial;
    }

    /* Button styles */
    .button {
      display: inline-block;
      background: linear-gradient(93deg, #BB52F5 0%, #802BE1 100%);
      color: white !important;
      font-size: 20px;
      padding: 10px 20px;
      border-radius: 34px;
      text-decoration: none;
      margin-bottom: 24px;
      margin-top: 24px;
      font-family: Arial;
    }

    /* Link styles */
    .link {
      font-weight: 400;
      line-height: 21px;
      font-family: Arial;
      font-size: 14px;
      color: #8A32E5 !important;
      text-decoration: underline;
    }

    /* Footer styles */
    .footer {
      margin-top: 40px;
      font-size: 12px;
      color: black;
      text-align: center;
    }

    .footer-links {
      margin-top: 8px;
    }

    .footer-link {
      text-decoration: none !important;
      color: #252323 !important;
    }

    .socials {
      margin-bottom: 40px;
    }

    .social-link {
      width: 24px;
      height: 24px;
      margin-right: 48px;
    }

    .social-link-last {
      margin: 0 !important;
    }
  </style>
</head>

<body>
  <div class="container">
    <img class="logo" src="{{ storefront.logoUrl }}" />
    <div class="card">
      <div class="image-container">
        <img src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/forgot-password.png" />
      </div>
      <div class="title">Password reset</div>
      <div class="description">
        Need to reset your password? No problem! Just click the button below. If you did not make this request, please ignore this email.
      </div>
      <a href="{{ resetPasswordLink }}" class="button">Reset your password</a>
      <div class="description">
        If you're having trouble clicking the "Reset your password" button, copy and paste the URL below into your web browser:
      </div>
      <a href="{{ resetPasswordLink }}" class="link">{{ resetPasswordLink }}</a>
    </div>
    {{#if storefront.enableFooter}}
    <div class="footer">
      <div class="socials">
        {{#if storefront.twitterUrl}}
        <a href="{{ storefront.twitterUrl }}"> <img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/twitter.png" /></a>
        {{/if}}
        {{#if storefront.discordUrl}}
        <a href="{{ storefront.discordUrl }}"><img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/discord.png" /></a>
        {{/if}}
        {{#if storefront.mediumUrl}}
        <a href="{{ storefront.mediumUrl }}"><img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/medium.png" /></a>
        {{/if}}
        {{#if storefront.websiteUrl}}
        <a href="{{ storefront.websiteUrl }}"><img class="social-link social-link-last" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/website.png" /></a>
        {{/if}}
      </div>
      <div class="disclaimer">© {{ year }} {{ storefront.companyName }}, All right reserved.</div>
      <div class="footer-links">
        Powered by <a class="footer-link" href="https://faro.xyz">Faro</a>.
      </div>
    </div>
    {{/if}}
  </div>
</body>

</html>
`;

export const verifyEmailJapanese = `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Password Reset</title>
  <style>
    /* Reset default margin and padding */
    body,
    div,
    p,
    a {
      margin: 0;
      padding: 0;
    }

    /* Container styles */
    .container {
      width: 100%;
      height: 100%;
      position: relative;
      background: #EEF0F2;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      padding-top: 40px;
      padding-bottom: 30px;
    }

    /* Logo styles */
    .logo {
      max-width: 139px;
      max-height: 32px;
    }

    /* Card styles */
    .card {
      width: 80%;
      max-width: 450px;
      background: white;
      border-radius: 8px;
      padding: 40px;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: auto;
    }

    /* Image styles */
    .image-container {
      width: 160px;
      height: 200px;
      position: relative;
      margin: 0 auto 20px;
    }

    .image {
      width: 100%;
      height: 100%;
      background: #DADBDC;
      border-radius: 8px;
    }

    /* Title styles */
    .title {
      font-size: 22px;
      font-weight: 700;
      color: #252323;
      margin-bottom: 16px;
    }

    /* Description styles */
    .description {
      font-size: 14px;
      font-weight: 400;
      color: #252323;
      line-height: 21px;
      font-family: Arial;
    }

    .disclaimer {
      font-size: 12px;
      font-weight: 400;
      color: #252323;
      line-height: 16px;
      font-family: Arial;
    }

    /* Button styles */
    .button {
      display: inline-block;
      background: linear-gradient(93deg, #BB52F5 0%, #802BE1 100%);
      color: white !important;
      font-size: 20px;
      padding: 10px 20px;
      border-radius: 34px;
      text-decoration: none;
      margin-bottom: 24px;
      margin-top: 24px;
      font-family: Arial;
    }

    /* Link styles */
    .link {
      font-weight: 400;
      line-height: 21px;
      font-family: Arial;
      font-size: 14px;
      color: #8A32E5 !important;
      text-decoration: underline;
    }

    /* Footer styles */
    .footer {
      margin-top: 40px;
      font-size: 12px;
      color: black;
      text-align: center;
    }

    .footer-links {
      margin-top: 8px;
    }

    .footer-link {
      text-decoration: none !important;
      color: #252323 !important;
    }

    .socials {
      margin-bottom: 40px;
    }

    .social-link {
      width: 24px;
      height: 24px;
      margin-right: 48px;
    }

    .social-link-last {
      margin: 0 !important;
    }
  </style>
</head>

<body>
  <div class="container">
    <img class="logo" src="{{ storefront.logoUrl }}" />
    <div class="card">
      <div class="image-container">
        <img src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/verify-email.png" />
      </div>
      <div class="title">メールアドレスを確認してください</div>
      <div class="description">
      会員登録いただきありがとうございます。メールアドレスに問題がなければ下記のボタンをクリックしてください。心あたりがない場合は放置してください
      </div>
      <a href="{{ verifyEmailLink }}" class="button">確認しました</a>
      <div class="description">
        "確認しました" ボタンをクリックできない場合は、以下のURLをコピーしてウェブブラウザに貼り付けてください。
      </div>
      <a href="{{ verifyEmailLink }}" class="link">{{ verifyEmailLink }}</a>
    </div>
    {{#if storefront.enableFooter}}
    <div class="footer">
      <div class="socials">
        {{#if storefront.twitterUrl}}
        <a href="{{ storefront.twitterUrl }}"> <img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/twitter.png" /></a>
        {{/if}}
        {{#if storefront.discordUrl}}
        <a href="{{ storefront.discordUrl }}"><img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/discord.png" /></a>
        {{/if}}
        {{#if storefront.mediumUrl}}
        <a href="{{ storefront.mediumUrl }}"><img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/medium.png" /></a>
        {{/if}}
        {{#if storefront.websiteUrl}}
        <a href="{{ storefront.websiteUrl }}"><img class="social-link social-link-last" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/website.png" /></a>
        {{/if}}
      </div>
      <div class="disclaimer">© {{ year }} {{ storefront.companyName }}, すべての権利を保有します。</div>
      <div class="footer-links">
        <a class="footer-link" href="https://faro.xyz">Faro</a>によって提供されます。
      </div>
    </div>
    {{/if}}
  </div>
</body>
</html>
`;
export const passwordResetJapanese = `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Password Reset</title>
  <style>
    /* Reset default margin and padding */
    body,
    div,
    p,
    a {
      margin: 0;
      padding: 0;
    }

    /* Container styles */
    .container {
      width: 100%;
      height: 100%;
      position: relative;
      background: #EEF0F2;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      padding-top: 40px;
      padding-bottom: 30px;
    }

    /* Logo styles */
    .logo {
      max-width: 139px;
      max-height: 32px;
    }

    /* Card styles */
    .card {
      width: 80%;
      max-width: 450px;
      background: white;
      border-radius: 8px;
      padding: 40px;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: auto;
    }

    /* Image styles */
    .image-container {
      width: 160px;
      height: 200px;
      position: relative;
      margin: 0 auto 20px;
    }

    .image {
      width: 100%;
      height: 100%;
      background: #DADBDC;
      border-radius: 8px;
    }

    /* Title styles */
    .title {
      font-size: 22px;
      font-weight: 700;
      color: #252323;
      margin-bottom: 16px;
    }

    /* Description styles */
    .description {
      font-size: 14px;
      font-weight: 400;
      color: #252323;
      line-height: 21px;
      font-family: Arial;
    }

    .disclaimer {
      font-size: 12px;
      font-weight: 400;
      color: #252323;
      line-height: 16px;
      font-family: Arial;
    }

    /* Button styles */
    .button {
      display: inline-block;
      background: linear-gradient(93deg, #BB52F5 0%, #802BE1 100%);
      color: white !important;
      font-size: 20px;
      padding: 10px 20px;
      border-radius: 34px;
      text-decoration: none;
      margin-bottom: 24px;
      margin-top: 24px;
      font-family: Arial;
    }

    /* Link styles */
    .link {
      font-weight: 400;
      line-height: 21px;
      font-family: Arial;
      font-size: 14px;
      color: #8A32E5 !important;
      text-decoration: underline;
    }

    /* Footer styles */
    .footer {
      margin-top: 40px;
      font-size: 12px;
      color: black;
      text-align: center;
    }

    .footer-links {
      margin-top: 8px;
    }

    .footer-link {
      text-decoration: none !important;
      color: #252323 !important;
    }

    .socials {
      margin-bottom: 40px;
    }

    .social-link {
      width: 24px;
      height: 24px;
      margin-right: 48px;
    }

    .social-link-last {
      margin: 0 !important;
    }
  </style>
</head>

<body>
  <div class="container">
    <img class="logo" src="{{ storefront.logoUrl }}" />
    <div class="card">
      <div class="image-container">
        <img src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/forgot-password.png" />
      </div>
      <div class="title">パスワードのリセット</div>
      <div class="description">
        パスワードをリセットする必要がありますか？問題ありません！下のボタンをクリックしてください。このリクエストを行っていない場合は、このメールを無視してください。
      </div>
      <a href="{{ resetPasswordLink }}" class="button">パスワードをリセット</a>
      <div class="description">
        "パスワードをリセット" ボタンをクリックできない場合は、以下のURLをコピーしてウェブブラウザに貼り付けてください。
      </div>
      <a href="{{ resetPasswordLink }}" class="link">{{ resetPasswordLink }}</a>
    </div>
    {{#if storefront.enableFooter}}
    <div class="footer">
      <div class="socials">
        {{#if storefront.twitterUrl}}
        <a href="{{ storefront.twitterUrl }}"> <img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/twitter.png" /></a>
        {{/if}}
        {{#if storefront.discordUrl}}
        <a href="{{ storefront.discordUrl }}"><img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/discord.png" /></a>
        {{/if}}
        {{#if storefront.mediumUrl}}
        <a href="{{ storefront.mediumUrl }}"><img class="social-link" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/medium.png" /></a>
        {{/if}}
        {{#if storefront.websiteUrl}}
        <a href="{{ storefront.websiteUrl }}"><img class="social-link social-link-last" src="https://faro-assets.s3.us-east-1.amazonaws.com/email-assets/website.png" /></a>
        {{/if}}
      </div>
      <div class="disclaimer">© {{ year }} {{ storefront.companyName }}, すべての権利を保有します。</div>
      <div class="footer-links">
        <a class="footer-link" href="https://faro.xyz">Faro</a>によって提供されます。
      </div>
    </div>
    {{/if}}
  </div>
</body>

</html>
`;
