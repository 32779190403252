import {Form, Select, Checkbox, Input, InputNumber} from "antd";

import { FormWrapper } from "../../../../components/customAntd";
import { AnimeTemplateName } from "../../../../utils/constants";

const {Item} = Form;

const LoginOptions = ({
    t,
    themeId,
    defaultLoginMethod,
    availableLoginMethods,
})=>{
    return (
        <FormWrapper heading={t("STOREFRONT_UI.LOGIN_OPTIONS")}>
        {themeId === AnimeTemplateName && (
          <Item
            label={`${t("SELECT_LOGIN_OPTIONS")}:`}
            name="loginMethod"
            initialValue={defaultLoginMethod}
            rules={[
              {
                required: true,
                message: t("LOGIN_METHOD_ERROR"),
                whitespace: true,
              },
            ]}
          >
            <Select options={availableLoginMethods} />
          </Item>
        )}
        <Item
          label={`${t("STOREFRONT_UI.REMEMBER_ME")} :`}
          name="rememberMeInDays"
          rules={[
            {
              required: true,
              message: t("STOREFRONT_UI.REMEMBER_ME_VALIDATION"),
            },
          ]}
        >
          <InputNumber max={30} min={1} />
        </Item>
        <Item
          label={`${t("STOREFRONT_UI.DEFAULT_SMS")} :`}
          name="defaultSmsCountryCode"
          rules={[
            {
              required: true,
              message: t("STOREFRONT_UI.DEFAULT_SMS_VALIDATION"),
            },
          ]}
        >
          <Input
            placeholder={t(
              "STOREFRONT_UI.DEFAULT_SMS_PLACEHOLDER"
            )}
          />
        </Item>
        <Item
          label={`${t("STOREFRONT_UI.SMS_COUNTRIES")} :`}
          name="smsSupportedCountries"
          rules={[
            {
              required: true,
              message: t(
                "STOREFRONT_UI.SMS_COUNTRIES_VALIDATION"
              ),
            },
          ]}
        >
          <Input
            placeholder={t(
              "STOREFRONT_UI.SMS_COUNTRIES_PLACEHOLDER"
            )}
          />
        </Item>
        {themeId !== AnimeTemplateName && (
          <Item name="preRegisterUser" valuePropName="checked">
            <Checkbox>
              {t("STOREFRONT_UI.PRE_REGISTER_USER")}
            </Checkbox>
          </Item>
        )}
      </FormWrapper>
    )
};

export default LoginOptions;