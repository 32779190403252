import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useAppContext } from '../context/app.context';
import { useProfileContext } from '../context/profile.context';
import './index.css'
import { Form, Accordion, Tabs, Tab } from "react-bootstrap";
import { RoleAccordionItem } from './role.row';
import { RolePermissions } from './role.permissions';
import { RoleMembers } from './role.members';
import { toast } from "react-toastify";
import { Button, Toast, ToastContainer } from 'react-bootstrap';
import { updateRole, createRole } from '../../redux/role.slice';
import { addRoleBFS } from '../../utils/rolesHelper';

const SaveChanges = ({
    showSaveChanges,
    setShowSaveChanges,
    onSaveChanges,
    saving
}) => {
    return (
        <ToastContainer
            position="bottom-center"
            containerPosition=''
        >
            <Toast
                onClose={() => setShowSaveChanges(false)}
                autohide={false}
                show={showSaveChanges}
                className="mb-4"
            >
                <Toast.Header>
                    <strong className="mr-auto">Careful - you have unsaved changes!</strong>
                </Toast.Header>
                <Toast.Body>
                    <Button
                        className="bg-primaryrounded-2"
                        type="submit"
                        onClick={() => {
                            onSaveChanges()
                        }}
                    >
                        {saving ? "..." : "Save Changes"}
                    </Button>
                </Toast.Body>
            </Toast>
        </ToastContainer>

    )
}

export const EditRoles = ({
    onClickEditRole,
    onClickCreateRole,
    onClickDeleteRole,
    onClickBack,
}) => {
    const dispatch = useDispatch()
    const { roleTree, setRoleTree, selectedRole, setSelectedRole } = useProfileContext()
    const { userRoles, setUserRoles } = useAppContext()
    const [key, setKey] = useState("permissions")
    const [showSaveChanges, setShowSaveChanges] = useState(false)
    const [prevSelectedRoleId, setPrevSelectedRoleId] = useState()
    const [saving, setSaving] = useState(false)

    const onSaveChanges = () => {
        setSaving(true)
        if (selectedRole.rolePermissions.length === 0) {
            alert("At least one permission must be selected.")
            setSaving(false)
            return
        }

        if (selectedRole.id === "new") {
            dispatch(createRole(selectedRole)).then(res => {
                setShowSaveChanges(false)
                const updatedRole = {...selectedRole, ...res.payload}
                setSelectedRole(updatedRole)
                setRoleTree(prev => {
                    const updatedRoleTree = addRoleBFS(updatedRole, JSON.parse(JSON.stringify(prev)))
                    return updatedRoleTree ? updatedRoleTree : prev
                })
                setSaving(false)
            })
        } else {
            dispatch(updateRole(selectedRole)).then(res => {
                // If one of the user's roles was edited, update state with new name
                const updateUserRoleIndex = userRoles.findIndex(role => role.id === selectedRole.id)
                if (updateUserRoleIndex >= 0) {
                    let updatedRoles = [...userRoles]
                    updatedRoles[updateUserRoleIndex] = { ...userRoles[updateUserRoleIndex], name: selectedRole.name }
                    setUserRoles(updatedRoles)

                }
                setShowSaveChanges(false)
                setSelectedRole(prev => ({ ...prev, ...res.payload }))
                setSaving(false)
            })
        }
        setPrevSelectedRoleId(null)
    }

    // Show "save changes" prompt when the selectedRole changes
    useEffect(() => {
        if (selectedRole) {
            if (prevSelectedRoleId === selectedRole.id) {
                setShowSaveChanges(true)
            } else {
                setPrevSelectedRoleId(selectedRole.id)
            }
        }
    }, [selectedRole])

    return (
        <div className='container'>
            <div className="row">
                <div className="col-4 border-right">
                    <div className="row row-header mb-2">
                        <button
                            title="Back"
                            type="button"
                            className="font-weight-bold text-black bg-transparent"
                            style={{ width: "100px" }}
                            onClick={onClickBack}
                        >
                            <i class="bi bi-arrow-left pr-1" />
                            Back
                        </button>
                    </div>
                    <div className="row">
                        {roleTree ?
                            <Accordion
                                activeKey={roleTree.id}
                                alwaysOpen={true}
                                className="col"
                            >
                                <div className="row row-header border-bottom">
                                    <div className="pl-3 font-weight-bold">
                                        Role
                                    </div>
                                </div>
                                <RoleAccordionItem
                                    topRoleId={roleTree.id}
                                    role={roleTree}
                                    level={0}
                                    onClickEditRole={onClickEditRole}
                                    onClickCreateRole={onClickCreateRole}
                                    onClickDeleteRole={onClickDeleteRole}
                                    editing={true}
                                />
                            </Accordion>
                            :
                            <div>No Roles Found</div>
                        }
                    </div>
                </div>
                <div className="col-8">
                    <div className="row row-header mb-2">
                        <div className="font-weight-bold d-flex align-items-center">
                            <div>Edit Role - </div>
                            {
                                selectedRole
                                    ?
                                    <div className="pl-1">
                                        <Form.Control
                                            value={selectedRole.name}
                                            onChange={(e) => setSelectedRole(prev => ({ ...prev, name: e.target.value }))}
                                        />
                                    </div>
                                    :
                                    <div className="pl-1">No role selected</div>
                            }
                        </div>
                    </div>
                    {
                        selectedRole
                            ?
                            <div className="row">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                >
                                    <Tab eventKey="permissions" title="Permissions">
                                        <RolePermissions />
                                    </Tab>
                                    <Tab eventKey="members" title="Manage Members">
                                        <RoleMembers
                                            role={selectedRole}
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                            :
                            <div>No role selected</div>

                    }

                </div>
            </div>
            <SaveChanges
                showSaveChanges={showSaveChanges}
                setShowSaveChanges={setShowSaveChanges}
                onSaveChanges={onSaveChanges}
                saving={saving}
            />
        </div>
    )
}