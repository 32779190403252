import {
  getAppSettings,
  updateAppSettings,
} from "./../../redux/appSettings.slice";
import { updateUser, loadLoggedInUser } from "../../redux/user.slice";
import { fetchUserAuditHistoryLogs } from "../../redux/logger.slice";
import axios from "axios";
import constant from "../../config/constant";
import { toast } from "react-toastify";

const showErrorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const baseURL = constant.config.REACT_API_HOST;

export const fetchAppSettingsData = async (dispatch, data) => {
  try {
    const response = await dispatch(getAppSettings());
    const isSuccess = response?.meta?.requestStatus === "fulfilled";
    if (isSuccess) {
      return response.payload;
    } else {
      return {
        ...response.payload,
        hasError: true,
      };
    }
  } catch (err) {
    return err;
  }
};

export const updateAppSettingsData = async (dispatch, data) => {
  try {
    const response = await dispatch(updateAppSettings(data));

    const isSuccess = response?.meta?.requestStatus === "fulfilled";
    if (isSuccess) {
      return response.payload;
    } else {
      return {
        ...response.payload,
        hasError: true,
      };
    }
  } catch (err) {
    return err;
  }
};

export const loadLoggedInUserData = async (dispatch, data) => {
  try {
    const response = await dispatch(loadLoggedInUser(data));
    const isSuccess = response?.meta?.requestStatus === "fulfilled";
    if (isSuccess) {
      return response.payload;
    } else {
      return {
        ...response.payload,
        hasError: true,
      };
    }
  } catch (err) {
    return err;
  }
};

export const updateUserData = async (dispatch, data) => {
  try {
    const response = await dispatch(updateUser(data));
    const isSuccess = response?.meta?.requestStatus === "fulfilled";
    if (isSuccess) {
      return response.payload;
    } else {
      return {
        ...response.payload,
        hasError: true,
      };
    }
  } catch (err) {
    return err;
  }
};

export const fetchUserAuditLogs = async (dispatch, data) => {
  try {
    const response = await dispatch(fetchUserAuditHistoryLogs(data));
    const isSuccess = response?.meta?.requestStatus === "fulfilled";
    if (isSuccess) {
      return response.payload;
    } else {
      return {
        ...response.payload,
        hasError: true,
      };
    }
  } catch (err) {
    return err;
  }
};

export const createUser = async (name, email, phone, roleIds, merchantIds) => {
  const body = {
    name: name,
    email: email,
    phone: phone,
    userId: email,
    roleIds: roleIds,
    merchantIds: merchantIds,
    status: "approved",
  };
  try {
    const response = await axios.post(`${baseURL}admin/users/new`, body);
    return response;
  } catch (error) {
    showErrorToast(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
  }
};

export const updtaeUser = async (id, name, email, phone, roleIds, merchantIds) => {
  const body = {
    name: name,
    email: email,
    phone: phone,
    userId: email,
    roleIds: roleIds,
    merchantIds: merchantIds,
    status: "approved",
  };
  try {
    const response = await axios.put(`${baseURL}admin/users/${id}`, body);
    return response;
  } catch (error) {
    showErrorToast(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
  }
};

export const getCompanies = async () => {
  try {
    const response = await axios.get(`${baseURL}admin/company`);
    return response;
  } catch (error) {
    showErrorToast(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
  }
};

export const createCompany = async (company) => {
  const body = {
    ...company,
    primaryContact: company.primaryContact
      ? { ...company.primaryContact, contactType: "primary" }
      : undefined,
    billingContact: company.billingContact
      ? { ...company.billingContact, contactType: "billing" }
      : undefined,
    technicalContact: company.technicalContact
      ? { ...company.technicalContact, contactType: "technical" }
      : undefined,
    companyStatus: "approved",
  };
  try {
    const response = await axios.post(`${baseURL}admin/company`, body);
    return response;
  } catch (error) {
    let errorMessage = "";
    if (error.response?.data?.message) {
      Object.entries(error.response?.data?.message).forEach((element) => {
        errorMessage += `
                    ${element.join(" -")}.  
                `;
      });
    }
    showErrorToast(`Error:  ${errorMessage ?? error.message}`);
    throw error;
  }
};

export const updateCompany = async (company) => {
  const body = {
    ...company,
    primaryContact: company.primaryContact
      ? { ...company.primaryContact, contactType: "primary" }
      : undefined,
    billingContact: company.billingContact
      ? { ...company.billingContact, contactType: "billing" }
      : undefined,
    technicalContact: company.technicalContact
      ? { ...company.technicalContact, contactType: "technical" }
      : undefined,
  };
  try {
    const response = await axios.put(
      `${baseURL}admin/company/${company.id}`,
      body
    );
    return response;
  } catch (error) {
    let errorMessage = "";
    if (error.response?.data?.message) {
      Object.entries(error.response?.data?.message).forEach((element) => {
        errorMessage += `
                    ${element.join(" -")}.  
                `;
      });
    }
    showErrorToast(`Error:  ${errorMessage ?? error.message}`);
    throw error;
  }
};

export const deleteCompany = async (id) => {
  try {
    const response = await axios.delete(`${baseURL}admin/company/${id}`);
    return response;
  } catch (error) {
    showErrorToast(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
    throw error;
  }
};

export const addRoleMembers = async (roleId, members) => {
  const body = {
    userIds: members,
  };
  try {
    const response = await axios.post(
      `${baseURL}admin/role/users/${roleId}`,
      body
    );
    return response;
  } catch (error) {
    showErrorToast(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
    throw error;
  }
};

export const deleteRoleMembers = async (roleId, members) => {
  const body = {
    userIds: members,
  };
  try {
    const response = await axios.delete(
      `${baseURL}admin/role/users/${roleId}`,
      { data: body }
    );
    return response;
  } catch (error) {
    showErrorToast(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
    throw error;
  }
};

export const getUsersByCompany = async (data, companyId) => {
  try {
    const response = await axios.post(`${baseURL}admin/users`, data, {
      headers: {
        proxyCompanyId: companyId,
      },
    });
    return response;
  } catch (error) {
    showErrorToast(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
    throw error;
  }
};

export const geCompany = async (roleId) => {
  try {
    const response = await axios.get(`${baseURL}admin/company`);
    return response;
  } catch (error) {
    showErrorToast(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
    throw error;
  }
};

export const getAllFunds = async () => {
  try {
    const response = await axios.post(`${baseURL}admin/transfers/all`);
    return response;
  } catch (error) {
    showErrorToast(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
    throw error;
  }
};

export const addFundTransfer = async (data, roleId) => {
  try {
    const response = await axios.post(`${baseURL}admin/transfers`, data, {
      headers: {
        role: roleId,
      },
    });
    return response;
  } catch (error) {
    console.log(
      "Error: Something went wrong. Please contact admin!. " + error.message
    );
    throw error;
  }
};
