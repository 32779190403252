import { Col, Form, Row } from "antd";
import { t } from "i18next";

import { Radio } from "../../../../../components/customAntd";

const { Item } = Form;

const Step1 = ({ isEdit }) => {
  return (
    <>
      <h3>{`${t("SELECT_BENEFIT_TYPE")}:`}</h3>
      <Item
        name="benefit_type"
        rules={[{ required: true, message: t("SELECT_BENEFIT_TYPE_ERROR") }]}
      >
        <Radio.Group style={{ width: "100%" }} disabled={isEdit}>
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Radio
                style={{ width: "100%" }}
                value="single_audio"
                key="single_audio"
              >
                {t("AUDIO.SINGLE")}
              </Radio>
            </Col>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value="playlist" key="playlist">
                {`${t("AUDIO.ALBUM")}/${t("PLAYLIST")}`}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Item>
      <Item
        label={`${t("SHOWIMAGES_ONLY")}:`}
        name="show_images_only"
        rules={[
          {
            required: true,
            message: t("SHOWIMAGES_ONLY_ERROR"),
          },
        ]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value={false}>
                {t("NO")}
              </Radio>
            </Col>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value={true}>
                {t("YES")}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Item>
    </>
  );
};

export default Step1;
