import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Card, Button, message, DatePicker, InputNumber } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { StarFilled, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { updateTier, createTier } from "../apiUtils";
import { Required } from "./utils";
import { useAppContext } from "../../../../components/context/app.context";

// import "../styles/programsteps.scss"
import Styles from "../styles/tier.module.scss";

export const TierForm = ({
  form,
  setTierData,
  tierData,
  isEdit,
  setTiersToDelete,
  tiersToDelete,
  programIdEdit,
  tierFormError,
  setAllTiers,
}) => {
  const { t } = useTranslation();
  const { Item } = Form;
  const [tiers, setTiers] = useState([]);
  const dateFormat = "DD/MM/YYYY";
  const formattedExpiry = moment(new Date()).add(5, "year").format(dateFormat);
  const tierName = Form.useWatch("tier_name", form);
  const tierDescription = Form.useWatch("tier_description", form);
  const tierExpiry = Form.useWatch("tier_expiry", form);
  const tierPointThreshold = Form.useWatch("tier_point_threshold", form);
  const [activeTier, setActiveTier] = useState(0);
  const [expiryDate, setExpiryDate] = useState(formattedExpiry);
  const {currentLocale} = useAppContext();
  const [loading, setLoading] = useState(false);

  const isTierFormTouched = form.isFieldsTouched([
    "tier_name",
    "tier_description",
    "tier_expiry",
    "tier_point_threshold"
  ]);

  useEffect(() => {
    setTiers([...tierData]);
  }, [tierData]);

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    const tier = {
      tierName,
      tierDescription,
      expiryDate,
      tierPointThreshold
    };
    form
      .validateFields(["tier_name", "tier_description"])
      .then(async (values) => {
        if (tierName && tierDescription) {
          const tierDataNew = [...tiers];
          tierDataNew[activeTier] = {
            ...tiers[activeTier],
            ...tier,
            id: tiers[activeTier].id,
          };
          let response;
          if (isEdit) {
            try {
              if (tierDataNew[activeTier].id) {
                response = await updateTier({
                  programId: programIdEdit,
                  tierData: { ...tierDataNew[activeTier] },
                });
              } else {
                response = await createTier({
                  ...tierDataNew[activeTier],
                  programId: programIdEdit,
                });
                tierDataNew[activeTier].id = response.id;
              }
              form.resetFields([
                "tier_name",
                "tier_description",
                "tier_expiry",
                "tier_point_threshold"
              ]);
              if (response.status !== (500 || 422)) {
                tierDataNew[activeTier].isSavedToDb = true;
                form.resetFields([
                  "tier_name",
                  "tier_description",
                  "tier_expiry",
                  "tier_point_threshold"
                ]);
                setTiers(tierDataNew);
                setTierData(tierDataNew);
                setLoading(false);
                message.success("Tier data updated successfully");
              } else {
                form.resetFields([
                  "tier_name",
                  "tier_description",
                  "tier_expiry",
                  "tier_point_threshold"
                ]);
                throw Error("Error while updating tier");
              }
            } catch (err) {
              setLoading(false);
              message.error(
                "Something went wrong while updating the tier,please try again"
              );
            }
          } else {
            tierDataNew[activeTier].isSavedToDb = true;
            form.resetFields(["tier_name", "tier_description", "tier_expiry", "tier_point_threshold"]);
            setTiers(tierDataNew);
            setTierData(tierDataNew);
          }
        }
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error("Please fill the form correctly");
      });

    setLoading(false);
  };

  const onTierSelect = (e, index) => {
    if (!index === activeTier)
      form.resetFields(["tier_name", "tier_description"]);
    setActiveTier(index);
  };
  const addTier = () => {
    form
      .validateFields(["tier_name", "tier_description"])
      .then((values) => {
        const tierDataNew = [...tiers];
        tierDataNew[tiers.length] = {
          tierName: "NewTier",
          tierDescription: "New tier description",
          tierExpiry: tierExpiry,
          savedInDb: false,
        };
        setActiveTier(tiers.length);
        setTiers(tierDataNew);
        setTierData(tierDataNew);
      })
      .catch((errorInfo) => {
        if (tiers.length === 0) {
          const tierDataNew = [...tiers];
          tierDataNew[tiers.length] = {
            tierName: "NewTier",
            tierDescription: "New tier description",
            savedInDb: false,
          };
          setActiveTier(tiers.length);
          setTiers(tierDataNew);
          setTierData(tierDataNew);
        }
      });
  };
  const onDelete = () => {
    let tierDataNew = [...tiers];
    if (isEdit && tiers[activeTier]?.id) {
      setTiersToDelete([
        ...tiersToDelete,
        { id: tiers[activeTier].id, delete: true },
      ]);
    }
    if (activeTier !== 0) {
      tierDataNew.splice(activeTier, 1);
      setActiveTier(activeTier - 1);
      setTiers(tierDataNew);
      setTierData(tierDataNew);
    }
  };

  const setTierExpiryDate = (date) => {
    date
      ? setExpiryDate(dayjs(date).format(dateFormat))
      : setExpiryDate(dayjs(formattedExpiry, dateFormat).format(dateFormat));
  };

  useEffect(() => {
    form.setFieldValue("tier_name", tiers[activeTier]?.tierName);
    form.setFieldValue("tier_description", tiers[activeTier]?.tierDescription);
    form.setFieldValue("tier_point_threshold", tiers[activeTier]?.tierPointThreshold);
    setExpiryDate(tiers[activeTier]?.expiryDate ?? expiryDate);
  }, [activeTier, tiers]);

  return (
    <>
      <Row className={Styles["tier-stepper-container"]}>
        <Col lg={6} className={Styles["tierFormContainer"]}>
          <p className={Styles["tierCardsHeading"]}>{t("PROGRAM_UI.TIERS")}</p>
          <div className={Styles["tierCardsList"]}>
            {tiers.map((item, index) => {
              const currentLocaleData = item?.passData?.locales?.find?.(locale => locale.localeId === currentLocale);
              return (
                <Card
                  onClick={(e) => onTierSelect(e, index)}
                  className={
                    activeTier === index
                      ? `${Styles["tierCardActive"]} ${Styles["tierSingleCard"]
                      } ${!item.isSavedToDb ? Styles["tierCardError"] : ""} `
                      : `${Styles["tierSingleCard"]} ${!item.isSavedToDb ? Styles["tierCardError"] : ""
                      }`
                  }
                >
                  <Row
                    align={"middle"}
                    className={Styles["tier-single-card-body"]}
                  >
                    <StarFilled
                      key={index}
                      className={Styles["tierCardIcon"]}
                    ></StarFilled>
                    <p> {currentLocaleData?.name ?? item.tierName}</p>
                  </Row>
                </Card>
              );
            })}
          </div>
          <Card onClick={addTier} className={Styles["tierCard"]}>
            <Row align={"middle"} className={Styles["add-tier-card-row"]}>
              <PlusOutlined className={Styles["tierCardIcon"]}></PlusOutlined>
              <p>{t("PROGRAM_UI.TIER_FORM.ADD_NEW_TIER")}</p>
            </Row>
          </Card>
        </Col>

        <Col lg={15} className={Styles["addTierCard"]}>
          <h3>{t("PROGRAM_UI.DEFINE_TIERS")}</h3>

          <Item
            label={Required(t("PROGRAM_UI.TIER_NAME"))}
            name="tier_name"
            rules={[
              { required: true, message: t("PROGRAM_UI.TIER_FORM.NAME_REQUIRED") },
              {
                max: 250,
                message: "Description cannot be greater than 250 char",
              },
              ({ getFieldValue }) => ({
                validator() {
                  const nameExisits = tiers.filter(
                    (item) => item.tierName === getFieldValue("tier_name")
                  );

                  if (
                    nameExisits.length > 0 &&
                    tiers[activeTier] !== nameExisits[0]
                  ) {
                    return Promise.reject(
                      new Error(
                        t("PROGRAM_UI.TIER_FORM.NAME_IDENTICAL_VALIDATION")
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              disabled={isEdit && tiers[activeTier]?.isSavedToDb}
              placeholder={t("PROGRAM_UI.TIER_FORM.NAME_PLACEHOLDER")}
            />
          </Item>
          <Item
            label={Required(t("PROGRAM_UI.TIER_DESCRIPTION"))}
            name="tier_description"
            rules={[
              { required: true, message: t("PROGRAM_UI.TIER_FORM.DESC_REQUIRED") },
              {
                max: 250,
                message: t("PROGRAM_UI.TIER_FORM.DESC_VALIDATION"),
              },
            ]}
            extra={t("PROGRAM_UI.TIER_FORM.DESC_EXTRA")}
          >
            <Input placeholder={t("PROGRAM_UI.TIER_FORM.DESC_PLACEHOLDER")}/>
          </Item>
          <Item
            label={Required(t("PROGRAM_UI.EXPIRATION_DATE"))}
            name="tier_expiry"
            extra={t("PROGRAM_UI.TIER_FORM.EXPIRATION_DATE_EXTRA")}
          >
            <DatePicker
              key={Date.now()}
              onChange={(date) => setTierExpiryDate(date)}
              defaultValue={dayjs(expiryDate, dateFormat)}
              format={dateFormat}
            ></DatePicker>
          </Item>
          <Item
            label={Required(t("PROGRAM_UI.POINTS_THRESHOLD"))}
            name="tier_point_threshold"
            extra={t("PROGRAM_UI.TIER_FORM.POINT_THRESHOLD_EXTRA")}
          >
            <InputNumber defaultValue={0} addonAfter={t("PROGRAM_UI.POINTS")} />
          </Item>
          <div className={Styles["actionButtonContainer"]}>
            <Button
              type="primary"
              disabled={!isTierFormTouched || !tiers.length > 0}
              loading={loading}
              style={{ width: "10rem" }}
              onClick={onSubmit}
            >
              {t("SAVE")}
            </Button>
            {isEdit && !tiers[activeTier]?.isSavedToDb && (
              <DeleteOutlined
                className={Styles["deleteButton"]}
                onClick={onDelete}
              />
            )}
            {!isEdit && (
              <DeleteOutlined
                className={Styles["deleteButton"]}
                onClick={onDelete}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
