import React, { useState } from 'react'
import './index.css'
import { useProfileContext } from '../context/profile.context';
import { EditRoles } from './roles.edit';
import { ViewRoles } from './roles.view';
import { DeleteRoleModal } from './role.delete.modal';

export const Roles = (props) => {
    const [editing, setEditing] = useState(false)
    const [roleToDelete, setRoleToDelete] = useState()
    const { setSelectedRole } = useProfileContext()

    const onClickEditRole = async (role) => {
        setSelectedRole(role)
        setEditing(true)
    }

    const onClickCreateRole = (parentRole) => {
        const newRole = {
            parentRoleId: parentRole.id,
            name: "New Role",
            id: "new",
            rolePermissions: [],
            numChildRoles: 0,
            numMembers: 0,
            children: []
        }
        setEditing(true)
        setSelectedRole(newRole)
    }
    const onClickDeleteRole = (role) => {
        setRoleToDelete(role)
    }

    const onClickBack = () => {
        setEditing(false)
        setSelectedRole(null)
    }

    console.log("YoLOoY");
    return (

        <div className={props.adminView && ("audit-admin-view")}>
            {editing ?
                <EditRoles
                    onClickEditRole={onClickEditRole}
                    onClickCreateRole={onClickCreateRole}
                    onClickDeleteRole={onClickDeleteRole}
                    onClickBack={onClickBack}
                />
                :
                <ViewRoles
                    onClickEditRole={onClickEditRole}
                    onClickCreateRole={onClickCreateRole}
                    onClickDeleteRole={onClickDeleteRole}
                />
            }
            <DeleteRoleModal
                show={roleToDelete}
                hide={() => setRoleToDelete(null)}
                roleToDelete={roleToDelete}
            />
        </div>
    )
}