import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PageHeader } from "../../../../components/customAntd";
import { BREADCRUMB_ANNOUNCEMENT } from "../../../../utils/announcements/constant";
import { getMetabaseUrl } from "./apiUtils";

const MerchantCommunications = () => {
  const { t } = useTranslation();
  const [metabaseBaseUrl, setMetabaseBaseUrl] = useState();

  const getIframeUrl = useCallback(async () => {
    const res = await getMetabaseUrl();
    setMetabaseBaseUrl(res.data.url);
  }, []);

  useEffect(() => {
    getIframeUrl();
  }, [getIframeUrl]);

  return (
    <>
      <PageHeader
        title={t("ANNOUNCEMENT.MERCHANT_COMMUNICATIONS")}
        breadcrumbs={BREADCRUMB_ANNOUNCEMENT(t, t("ANNOUNCEMENT.MERCHANT_COMMUNICATIONS"))}
      />
      <div style={{ width: "100%", height: "65vh" }}>
        <iframe
          title="email communications metabase dashboard"
          src={metabaseBaseUrl}
          width={"100%"}
          height={"100%"}
        />
      </div>
    </>
  );
};

export default MerchantCommunications;
