import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { Form, Switch } from "antd";

import { FormWrapper } from "../../../../components/customAntd";
import { AnimeTemplateName } from "../../../../utils/constants";

const { Item } = Form;

const EmailTemplates = ({ themeId, t, watchEnableCustomTemplates }) => {
  const form = Form.useFormInstance();
  return (
    themeId !== AnimeTemplateName && (
      <FormWrapper heading={t("STOREFRONT_UI.EMAIL_TEMPLATES")}>
        <Item
          label={`${t("STOREFRONT_UI.USE_CUSTOM_TEMPLATE")} :`}
          name="useCustomMailTemplate"
        >
          <Switch checked={watchEnableCustomTemplates} />
        </Item>
        {watchEnableCustomTemplates && (
          <>
            <Item
              label={`${t("STOREFRONT_UI.VERIFY_EMAIL_TEMPLATE")} :`}
              name="verifyEmailMailTemplate"
              rules={[
                {
                  required: true,
                  message: t("STOREFRONT_UI.TEMPLATE_REQUIRED"),
                },
              ]}
            >
              <MDEditor
                overflow={false}
                placeholder={t(
                  "STOREFRONT_UI.VERIFY_EMAIL_TEMPLATE_PLACEHOLDER"
                )}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
            </Item>
            <Item
              label={`${t("STOREFRONT_UI.RESET_PASSWORD_TEMPLATE")} :`}
              name="resetPasswordMailTemplate"
              rules={[
                {
                  required: true,
                  message: t("STOREFRONT_UI.TEMPLATE_REQUIRED"),
                },
              ]}
            >
              <MDEditor
                overflow={false}
                placeholder={t(
                  "STOREFRONT_UI.RESET_PASSWORD_TEMPLATE_PLACEHOLDER"
                )}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
            </Item>
          </>
        )}
      </FormWrapper>
    )
  );
};

export default EmailTemplates;
