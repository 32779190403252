import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ConfirmationModal from "../../components/modal.popup/modal.confirmation";
import SelectProxyCompany from "../../components/user.profile/company.proxy";
import CompanyModal from "../../components/user.profile/company.modal";
import { Table } from "../../components/customAntd";
import { useAppContext } from "../../components/context/app.context";

import { checkUserRolePermissions } from "../../utils/helper";
import { ROLE_PERMISSIONS } from "../../utils/constants";
import { getCompanies, deleteCompany, updateCompany } from "./apiUtils";

export const Companies = ({ setCompanyCount }) => {
  const [companyTableData, setCompanyTableData] = useState([]);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingCompany, setDeletingCompany] = useState();
  const [editingCompany, setEditingCompany] = useState();
  const { originalRole, currentUserData } = useAppContext();
  const [t] = useTranslation();

  const getContacts = useCallback((company) => {
    const Contact = ({ type, contact }) => (
      <div className="mb-1">
        <p className="font-weight-bold text-black">{t(`COMPANIES.${type.toUpperCase()}`)}</p>
        <p className="text-black">
          {contact.firstName} {contact.lastName}
        </p>
        <p className="text-black">{contact.email}</p>
        {contact.phoneNumber && (
          <p className="text-black">{contact.phoneNumber}</p>
        )}
        {contact.addressLine1 && (
          <p className="text-black text-truncate">{contact.addressLine1}</p>
        )}
        {contact.addressLine2 && (
          <p className="text-black text-truncate">{contact.addressLine2}</p>
        )}
        <p>
          {contact.city && <span className="text-black">{contact.city}, </span>}
          {contact.stateCode && (
            <span className="text-black">{contact.stateCode}</span>
          )}
          {contact.CountryCode && (
            <span className="text-black">{contact.CountryCode}</span>
          )}
        </p>
        {contact.postalCode && (
          <p className="text-black">{contact.postalCode}</p>
        )}
      </div>
    );

    return (
        <div>
          {company.primaryContact && (
            <Contact type="Primary" contact={company.primaryContact} />
          )}
          {company.billingContact && (
            <Contact type="Billing" contact={company.billingContact} />
          )}
          {company.technicalContact && (
            <Contact type="Technical" contact={company.technicalContact} />
          )}
        </div>
  );
  }, []);

  const STATUS_MAP = useMemo(
    () => ({
      approved: t("COMPANIES.APPROVED"),
      onHold: t("COMPANIES.ON_HOLD"),
      inActive: t("COMPANIES.INACTIVE"),
      pendingReview: t("COMPANIES.PENDING_REVIEW"),
      pendingSignup: t("COMPANIES.PENDING_SIGNUP"),
      declined: t("COMPANIES.DECLINED"),
    }),
    [t]
  );

  const PENDING_STATUS_OPTIONS = useMemo(
    () => [
      {
        status: "pendingReview",
        text: t("COMPANIES.PENDING_REVIEW"),
      },
      {
        status: "pendingSignup",
        text: t("COMPANIES.PENDING_SIGNUP"),
      },
      {
        status: "approved",
        text: t("COMPANIES.APPROVED"),
      },
    ],
    [t]
  );

  const APPROVED_STATUS_OPTIONS = useMemo(
    () => [
      {
        status: "approved",
        text: t("COMPANIES.APPROVED"),
      },
      {
        status: "onHold",
        text: t("COMPANIES.ON_HOLD"),
      },
      {
        status: "inActive",
        text: t("COMPANIES.INACTIVE"),
      },
    ],
    [t]
  );

  const onClickAddCompany = () => {
    setShowCompanyModal(true);
  };

  const addCompanyToTable = (companyToAdd) => {
    setCompanyTableData((prev) => {
      return [...prev, companyToAdd];
      // return [...prev, formatCompanyForTableData(companyToAdd, t)];
    });
    setCompanyCount((prev) => prev + 1);
  };

  const onClickDelete = (companyToDelete) => {
    setDeletingCompany(companyToDelete);
    setShowDeleteModal(true);
  };

  const onDelete = async () => {
    await deleteCompany(deletingCompany.id);
    let companies = [...companyTableData].filter(
      (company) => company.id !== deletingCompany.id
    );
    setCompanyTableData(companies);
    setCompanyCount((prev) => prev - 1);
  };

  const onClickEdit = (companyToEdit) => {
    setEditingCompany(companyToEdit);
    setShowCompanyModal(true);
  };

  const updateCompanyInTable = (companyToUpdate) => {
    const companies = [...companyTableData];
    const pos = companies.findIndex(
      (company) => company.id === companyToUpdate.id
    );
    companies[pos] = {
      ...companies[pos],
      companyToUpdate
    };
    setCompanyTableData(companies);
  };

  const handleStatusChange = (company, status) => {
    updateCompany({ ...company, companyStatus: status }).then((response) => {
      updateCompanyInTable(response.data);
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getCompanies().then((response) => {
        setCompanyTableData(
          response.data
          // response.data.map((company) => formatCompanyForTableData(company, t))
        );
        setCompanyCount(response.data.length);
      });
    }
    return () => (mounted = false);
  }, []);

  const StatusAction = (company) => {
    return (
      <div>
        {currentUserData.company.companyType !== "internal" ? (
          <p className="font-weight-bold text-black">
            {STATUS_MAP[company.companyStatus]}
          </p>
        ) : company.companyStatus === "declined" ? (
          <p className="font-weight-bold text-black">
            {t("COMPANIES.DECLINED")}
          </p>
        ) : company.companyStatus === "pendingSignup" ? (
          <p className="font-weight-bold text-black">
            {t("COMPANIES.PENDING_SIGNUP")}
          </p>
        ) : (
          <Form.Select
            value={company.companyStatus}
            onChange={(e) => handleStatusChange(company, e.target.value)}
            className="font-16"
          >
            {company.companyStatus === "pendingReview"
              ? PENDING_STATUS_OPTIONS.map((option) => (
                  <option key={option.status} value={option.status}>
                    {option.text}
                  </option>
                ))
              : APPROVED_STATUS_OPTIONS.map((option) => (
                  <option key={option.status} value={option.status}>
                    {option.text}
                  </option>
                ))}
          </Form.Select>
        )}
      </div>
    );
  };

  const EditDeleteButtons = (company) => {
    const approved =
      company.companyStatus !== "pendingReview" &&
      company.companyStatus !== "pendingSignup";
    return (
      <div className="d-flex">
        <span>
          <button
            title={t("EDIT")}
            type="button"
            className="text-black bg-transparent ml-4 pr-3"
            onClick={() => onClickEdit(company)}
          >
            <i className="bi bi-pencil" />
          </button>
        </span>
        <span>
          <button
            title={
              approved ? t("COMPANIES.DELETE_ERROR") : t("COMPANIES.DELETE")
            }
            type="button"
            className={`bg-transparent ${
              approved ? "text-muted" : "text-black"
            }`}
            onClick={() => onClickDelete(company)}
            disabled={approved}
          >
            <i className="bi bi-trash" />
          </button>
        </span>
      </div>
    );
  };

  const companyTableColumns = useMemo(()=>[
    {
      title: t("NAME"),
      dataIndex: "tableName",
      key: "tableName",
      render: (_,company)=>{
        return (<p className="black-text" style={{ color: "black" }}>
        {company.name}
      </p>)
      }
    },
    {
      title: t("COMPANIES.URL"),
      dataIndex: "tableUrl",
      key: "tableUrl",
      render: (_,company)=>{
        return(
        <p className="black-text text-truncate" style={{ color: "black" }}>
          {company.url}
        </p>)
      }
    },
    {
      title: t("COMPANIES.CONTACTS"),
      dataIndex: "tableContacts",
      key: "tableContacts",
      render: (_,company)=>{
        return getContacts(company)
      }
    },
    {
      title: t("COMPANIES.COMPANY_TYPE"),
      dataIndex: "tableCompanyType",
      key: "tableCompanyType",
      render: (_, company) => (
        <p className="black-text" style={{ color: "black" }}>
          {" "}
          {t(`COMPANIES.${company.companyType.toUpperCase()}`)}
        </p>
      ),
    },
    {
      title: t("STATUS"),
      dataIndex: "status",
      key: "status",
      render: (_, record) => <StatusAction {...record} />,
    },
    {
      title: `${t("EDIT")}/${t("DELETE")}`,
      dataIndex: "edit-delete",
      key: "edit-delete",
      render: (_, record) => <EditDeleteButtons {...record} />,
    },
  ],[t]);

  return (
    <div className={`d-flex flex-column`}>
      <Row>
        <Col>
          {checkUserRolePermissions(
            originalRole,
            ROLE_PERMISSIONS.CREATECOMPANY
          ) && <SelectProxyCompany companies={companyTableData} />}
        </Col>
        <Col>
          {checkUserRolePermissions(
            originalRole,
            ROLE_PERMISSIONS.CREATECOMPANY
          ) && (
            <div className="d-flex justify-content-end">
              <Button
                className={"btnPrimary mb-2 rounded-2"}
                onClick={onClickAddCompany}
              >
                {`+ ${t("COMPANIES.NEW")}`}
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Table
        fixedHeaderScrollHeight="550px"
        columns={companyTableColumns}
        dataSource={companyTableData}
      />
      <CompanyModal
        show={showCompanyModal}
        hide={() => {
          setShowCompanyModal(false);
          setEditingCompany(null);
        }}
        addCompanyToTable={addCompanyToTable}
        updateCompanyInTable={updateCompanyInTable}
        editingCompany={editingCompany}
      />
      <ConfirmationModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        title={`${t("COMPANIES.DELETE")} "${deletingCompany ? deletingCompany.name : ""}"`}
        bodyText={`${t("COMPANIES.DELETE_BODYTEXT")}: ${
          deletingCompany ? deletingCompany.name : ""
        }?`}
        confirmText={t("COMPANIES.DELETE_CONFIRM_TEXT")}
        action={onDelete}
      />
    </div>
  );
};
