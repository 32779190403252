import { useState, useEffect, useMemo } from "react";
import { Row, Col, Form, Input, Select, message, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  createNotification,
  updateNotification,
} from "../../../benefits/apiUtils";
import { getAllMerchantsFromDb } from "../../../merchant/apiUtils";

import { Modal, Button, ModalHeader } from "../../../../../components/customAntd";

import { BENEFITS_LIST } from "../../../../../utils/benefits/constant";
import { getAllBenefitsFromDb } from "../../../benefits/apiUtils";
import { checkUserRolePermissions } from "../../../../../utils/helper";
import { useAppContext } from "../../../../../components/context/app.context";
import { ROLE_PERMISSIONS } from "../../../../../utils/constants";
import { getAllStorefrontList } from "../../../storefrontBuilder/apiUtils";

const { Item } = Form;

const NewNotificationModal = ({
  isOpen,
  onCancel,
  currentRecord,
  getNotifications,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [benefitList, setBenefitList] = useState([]);
  const watchNotificationType = Form.useWatch("level", form);
  const watchBenefitType = Form.useWatch("benefitType", form);
  const watchUserType = Form.useWatch("userType", form);
  const watchScheduleDate = Form.useWatch("scheduledDate", form);
  const [storeList, setStoreList] = useState([]);
  const { currentUserRole } = useAppContext();
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);

  const notificationType = useMemo(
    () => [
      {
        label: t("USER"),
        value: "User",
      },
      {
        label: t("BENEFIT"),
        value: "Benefit",
      }
    ],
    [t]
  );

  const userCriteriaOptions = useMemo(
    () => [
      {
        label: t("BENEFIT_NOTIFICATION.ALL_USERS"),
        value: "AllUsers",
      },
      {
        label: t("BENEFIT_NOTIFICATION.REGISTERED_USERS"),
        value: "RegisteredUsers",
      },
      {
        label: t("BENEFIT_NOTIFICATION.WINNERS"),
        value: "Winners",
      },
    ],
    [t]
  );

  const userType = useMemo(
    () => [
      {
        label: t("ALL_USERS"),
        value: "AllUser",
      },
      {
        label: t("PARTNER_MEMBERS"),
        value: "PartnerMember",
      },
    ],
    [t, currentRecord]
  );

  const userBenefits = useMemo(() => {
    return BENEFITS_LIST(t).map((benefit) => ({
      label: t(benefit.name),
      value: benefit.type,
    }));
  }, [t]);

  const disabledDate = (current, date = null) => {
    const day = date ? dayjs(date) : dayjs();
    return current && current < day.startOf("day");
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    data.expiryDate = dayjs(data.expiryDate).set("second", 0).format();
    data.scheduledDate = (
      dayjs() >= data.scheduledDate
        ? dayjs().add(3, "minutes").set("second", 0)
        : dayjs(data.scheduledDate).add(3, "minutes").set("second", 0)
    ).format();
    const finalData = {
      ...data,
      userCriteria: data.userCriteria ? data.userCriteria : "AllUsers",
      storefrontId: data.storefrontId?.toString(),
    };

    delete finalData.benefitType; //because we only want benefitId

    const response = currentRecord
      ? await updateNotification(null, currentRecord.id, finalData)
      : await createNotification(finalData.benefitId, finalData);
    if (response?.status === 200) {
      message.success({
        content: currentRecord
          ? t("UPDATED_SUCCESSFULLY")
          : t("CREATED_SUCCESSFULLY"),
        key: "success",
        duration: 2,
      });
      getNotifications();
      onCancel();
    } else {
      message.error({
        content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
        key: "error",
        duration: 2,
      });
    }
    setIsLoading(false);
    form.resetFields();
  };

  const getStoreFrontList = async () => {
    const getAllStores = await getAllStorefrontList({
      limit: 50,
      offset: 0,
      filterCondition: {
        isArchived: false,
      },
    });

    if (getAllStores?.status === 200) {
      setStoreList(() =>
        getAllStores.data.map((item) => {
          return {
            label: item.title,
            value: item.id,
          };
        })
      );
    }
  };

  const getBenefitsList = async () => {
    const response = await getAllBenefitsFromDb({
      filterCondition: {
        benefitType: watchBenefitType,
      },
    });

    const filteredData = response?.data?.map((el) => ({
      label: el.title,
      value: el.id,
    }));

    setBenefitList(filteredData);
  };

  useEffect(() => {
    // Reset the benefitId field when benefitType changes
    if (form.isFieldsTouched(["benefitType"])) {
      form.resetFields(["benefitId"]);
    }
  }, [watchBenefitType, form]);

  useEffect(() => {
    if (currentRecord) {
      // Extract values from currentRecord
      const { id, scheduledDate, expiryDate, benefit, ...otherValues } =
        currentRecord;

      // Initialize form fields with values
      form.setFieldsValue({
        ...otherValues,
        id,
        benefitType: benefit?.type,
        scheduledDate: dayjs(scheduledDate),
        expiryDate: expiryDate && dayjs(expiryDate),
      });
    }
  }, [currentRecord, form]);

  useEffect(() => {
    if (currentRecord) {
      const { merchantNotifications } = currentRecord;

      // Check if there are merchant notifications
      if (merchantNotifications && merchantNotifications.length > 0) {
        const partnerIds = merchantNotifications.map(
          (notification) => notification.partnerId
        );

        // Set the selected partnerIds
        form.setFieldsValue({
          partnerIds: partnerIds, // Set the value for the partnerIds field
        });
      }
    }
  }, [currentRecord, form]);

  useEffect(() => {
    if (
      !checkUserRolePermissions(
        currentUserRole,
        ROLE_PERMISSIONS.VIEWBENEFITS
      ) ||
      !currentRecord
    ) {
      form.setFieldsValue({
        level: "User",
      });
    }
  }, [checkUserRolePermissions, currentUserRole, form, isOpen]);

  useEffect(() => {
    if (watchBenefitType) {
      getBenefitsList();
    }
  }, [watchBenefitType]);

  // get All merchants initially
  useEffect(() => {
    const fetchMerchants = async () => {
      const getAllMerchants = await getAllMerchantsFromDb();
      if (getAllMerchants?.status === 200) {
        setMerchants(() =>
          getAllMerchants.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
        );
      }
    };

    let mounted = true;
    if (mounted) {
      fetchMerchants();
      getStoreFrontList();
    }
    return () => (mounted = false);
  }, []);

  const disabledTime = (current, date = null) => {
    const day = date ? dayjs(date) : dayjs();
    return {
      disabledHours: () =>
        current && current?.format("DD-MM-YYYY") === day.format("DD-MM-YYYY")
          ? Array.from({ length: day.get("hour") }, (_, index) => index)
          : [],
      disabledMinutes: (selectedHour) =>
        current && current?.format("DD-MM-YYYY") === day.format("DD-MM-YYYY") &&
          day.get("hour") === selectedHour
          ? Array.from({ length: day.get("minute") }, (_, index) => index)
          : []
    }
  };

  return (
    <>
      <Modal
        destroyOnClose
        onCancel={() => {
          // setList([]);
          onCancel();
        }}
        open={isOpen}
        centered
        title={
          <ModalHeader
            title={t("NOTIFICATIONS")}
          // breadcrumbs={BREADCRUMB_ADD_NEW_NOTIFICATION(
          //   t,
          //   ...getBenefitTitleAndLink()
          // )}
          />
        }
        width={1200}
        footer={[
          <Button
            buttonType="cancel"
            onClick={() => {
              onCancel();
            }}
            key="cancel-button"
          // disabled={isLoading || loadingNotifications}
          >
            {t("CANCEL")}
          </Button>,
          <Button
            onClick={() => {
              form.submit();
            }}
            key="create-button"
            disabled={dayjs(currentRecord?.scheduledDate).isBefore(dayjs())}
            loading={isLoading}
          >
            {currentRecord ? t("UPDATE") : t("CREATE")}
          </Button>,
        ]}
      >
        <Row gutter={52}>
          <Col sm={20} md={12}>
            <Form
              form={form}
              onFinish={onSubmit}
              initialValues={{ type: "Toast" }}
              layout="vertical"
              requiredMark={false}
              preserve={false}
            // disabled={isLoading || loadingNotifications}
            >
              <Item name="id" hidden></Item>

              <Item
                label={t("TYPE_OF_NOTIFICATION")}
                name="level"
                rules={[
                  {
                    required: true,
                    message: t("TYPE_OF_NOTIFICATION_REQUIRED"),
                  },
                ]}
              >
                <Select
                  options={notificationType}
                  disabled={
                    !checkUserRolePermissions(
                      currentUserRole,
                      ROLE_PERMISSIONS.VIEWBENEFITS
                    ) || dayjs(currentRecord?.scheduledDate).isBefore(dayjs())
                  }
                  placeholder={t("TYPE_OF_NOTIFICATION_REQUIRED")}
                />
              </Item>

              {watchNotificationType === "User" && (
                <Item
                  label={t("TYPE_OF_USER")}
                  name="userType"
                  rules={[
                    {
                      required: true,
                      message: t("USER_REQUIRED"),
                    },
                  ]}
                >
                  <Select
                    options={userType}
                    disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                      dayjs()
                    )}
                    placeholder={t(
                      "BENEFIT_NOTIFICATION.USER_CRITERIA_PLACEHOLDER"
                    )}
                  />
                </Item>
              )}
              {watchNotificationType === "Benefit" && (
                <Item
                  label={t("SELECT_BENEFIT")}
                  name="benefitType"
                  rules={[
                    {
                      required: true,
                      message: t("BENEFIT_NOTIFICATION.USER_CRITERIA_REQUIRED"),
                    },
                  ]}
                >
                  <Select
                    options={userBenefits}
                    disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                      dayjs()
                    )}
                    placeholder={t(
                      "BENEFIT_NOTIFICATION.USER_CRITERIA_PLACEHOLDER"
                    )}
                  />
                </Item>
              )}

              {watchNotificationType === "Benefit" && (
                <Item
                  label={t("TYPE_OF_BENEFIT")}
                  name="benefitId"
                  rules={[
                    {
                      required: true,
                      message: t("BENEFIT_REQUIRED"),
                    },
                  ]}
                >
                  <Select
                    options={benefitList}
                    disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                      dayjs()
                    )}
                    placeholder={t("BENEFIT_REQUIRED")}
                  />
                </Item>
              )}

              {watchUserType === "AllUser" && (
                <Item
                  label={t("SELECT_STOREFRONT")}
                  name="storefrontId"
                  rules={[
                    {
                      required: true,
                      message: t("STOREFRONT_REQUIRED"),
                    },
                  ]}
                >
                  <Select
                    options={storeList}
                    disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                      dayjs()
                    )}
                    placeholder={t("STOREFRONT_REQUIRED")}
                  />
                </Item>
              )}

              {watchUserType === "PartnerMember" && (
                <Item
                  label={t("SELECT_MERCHANT")}
                  name="partnerIds"
                  rules={[
                    {
                      required: true,
                      message: t("MERCHANT_REQUIRED"),
                    },
                  ]}
                >
                  <Select
                    options={merchants}
                    disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                      dayjs()
                    )}
                    mode="multiple"
                    placeholder={t("MERCHANT_REQUIRED")}
                    value={selectedPartnerIds}
                    onChange={(selectedValues) =>
                      setSelectedPartnerIds(selectedValues)
                    }
                  />
                </Item>
              )}

              <Item
                name="title"
                label={`${t("PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE")} :`}
                rules={[
                  {
                    required: true,
                    message: t(
                      "PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE_VALIDATION"
                    ),
                  },
                ]}
              >
                <Input
                  disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                    dayjs()
                  )}
                  placeholder={t(
                    "PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE_PLACEHOLDER"
                  )}
                />
              </Item>
              <Item
                name="message"
                label={`${t("PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE")} :`}
                rules={[
                  {
                    required: true,
                    message: t(
                      "PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE_VALIDATION"
                    ),
                  },
                ]}
              >
                <Input
                  disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                    dayjs()
                  )}
                  placeholder={t(
                    "PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE_PLACEHOLDER"
                  )}
                />
              </Item>
              <Item
                name="scheduledDate"
                label={`${t("BENEFIT_NOTIFICATION.SCHEDULED_DATE")}:`}
                rules={[
                  {
                    required: true,
                    message: t("BENEFIT_NOTIFICATION.SCHEDULED_DATE_REQUIRED"),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD HH:mm"
                  disabledDate={(current) => disabledDate(current)}
                  disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                    dayjs()
                  )}
                  disabledTime={disabledTime}
                  placeholder={t("BENEFIT_NOTIFICATION.SCHEDULED_DATE")}
                  showTime={{ format: "HH:mm" }}
                />
              </Item>

              {(!currentRecord || currentRecord?.expiryDate !== null) && (
                <Item name="expiryDate" label={t("EXPIRY_DATE")}>
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm"
                    disabledDate={(current) => disabledDate(current, watchScheduleDate)}
                    disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                      dayjs()
                    )}
                    disabledTime={(current) => disabledTime(current, watchScheduleDate)}
                    showTime={{ format: "HH:mm" }}
                    placeholder={t("EXPIRY_DATE_PLACEHOLDER")}
                  />
                </Item>
              )}

              <Item
                name="type"
                label={`${t("BENEFIT_NOTIFICATION.TYPE")} :`}
                rules={[
                  {
                    required: true,
                    message: t("BENEFIT_NOTIFICATION.TYPE_REQUIRED"),
                  },
                ]}
              >
                <Select
                  disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                    dayjs()
                  )}
                  options={[
                    {
                      label: t("BENEFIT_NOTIFICATION.WEB_NOTIFICATION"),
                      value: "Toast",
                    },
                    {
                      label: t("BENEFIT_NOTIFICATION.PUSH_NOTIFICATION"),
                      value: "PushNotification",
                    },
                    { label: t("BOTH"), value: "Both" },
                  ]}
                />
              </Item>
              {watchUserType !== "AllUser" &&
                watchNotificationType !== "User" && (
                  <Item
                    label={`${t("BENEFIT_NOTIFICATION.USER_CRITERIA")} :`}
                    name="userCriteria"
                    rules={[
                      {
                        required: true,
                        message: t(
                          "BENEFIT_NOTIFICATION.USER_CRITERIA_REQUIRED"
                        ),
                      },
                    ]}
                  >
                    <Select
                      disabled={dayjs(currentRecord?.scheduledDate).isBefore(
                        dayjs()
                      )}
                      options={
                        watchBenefitType === "sweepstakes"
                          ? userCriteriaOptions.filter(
                            (option) =>
                              option.value === "RegisteredUsers" ||
                              option.value === "Winners"
                          )
                          : userCriteriaOptions.filter(
                            (option) => option.value === "AllUsers"
                          )
                      }
                      placeholder={t(
                        "BENEFIT_NOTIFICATION.USER_CRITERIA_PLACEHOLDER"
                      )}
                    />
                  </Item>
                )}
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default NewNotificationModal;
