export const BREADCRUMB_STOREBUILDER = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("STOREFRONT_UI.BUILDER"),
  },
];

export const BREADCRUMB_STOREBUILDER_SELECT_PROGRAM = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("STOREFRONT_UI.BUILDER"),
    link: "/launchpad/storefront-builder",
  },
  {
    title: t("STOREFRONT_UI.SELECT_PROGRAM_TITLE"),
  },
];

export const BREADCRUMB_CREATE_STOREFRONT = (isEdit, t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("STOREFRONT_UI.BUILDER"),
    link: "/launchpad/storefront-builder",
  },
  {
    title: isEdit
      ? t("STOREFRONT_UI.SELECT_PROGRAM_EDIT")
      : t("STOREFRONT_UI.CREATE"),
  },
];

export const BREADCRUMB_STOREFRONT_FAQ = (t) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("STOREFRONT_UI.BUILDER"),
    link: "/launchpad/storefront-builder",
  },
  {
    title: t("STOREFRONT_UI.FAQ"),
  },
];

export const BREADCRUMB_STOREFRONT_MEMBER_ID_UPLOAD = (t) =>[
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("STOREFRONT_UI.BUILDER"),
    link: "/launchpad/storefront-builder",
  },
  {
    title: t("UPLOAD"),
  }
]

export const BREADCRUMB_STOREFRONT_USER_DELETE = (t) =>[
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("LAUNCHPAD"),
    link: "/launchpad",
  },
  {
    title: t("STOREFRONT_UI.BUILDER"),
    link: "/launchpad/storefront-builder",
  },
  {
    title: t("DELETE_USER"),
  }
]

export const MENU_OPTIONS = (t) => [
  {
    label: t("VIEW_PASS"),
    value: "viewPass"
  },
  {
    label: t("STOREFRONT_UI.FAQ"),
    value: "faq"
  },
  {
    label: t("CHECK_IN"),
    value: "checkIn"
  },

]