import { useMemo } from "react";
import { Table, Input, Tooltip, Popconfirm, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { Micons } from "../../../../components/customAntd";
import { debounce } from "../../../../utils/helper";

import styles from "./style.module.scss";

const SelectUsers = ({
  tabKey,
  handleDeleteAndRestore,
  pagination,
  setSearchValue,
  setPagination,
  userList,
  showResetPassword,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: t("EMAIL").toUpperCase(),
        key: "email",
        dataIndex: "email",
      },
      {
        title: t("LAST_NAME").toUpperCase(),
        key: "lastName",
        dataIndex: "lastName",
        render: (_, data) => {
          return <p>{data.lastName ? data.lastName : "--"}</p>;
        },
      },
      {
        title: t("FIRST_NAME").toUpperCase(),
        key: "firstName",
        dataIndex: "firstName",
        render: (_, data) => {
          return <p>{data.firstName ? data.firstName : "--"}</p>;
        },
      },
      {
        title: t("MERCHANT.PHONE_PLACEHOLDER").toUpperCase(),
        key: "phoneNumber",
        dataIndex: "phoneNumber",
        render: (_, data) => {
          return <p>{data.phoneNumber ? data.phoneNumber : "--"}</p>;
        },
      },
      {
        title: t("ACTION"),
        key: "action",
        render: (_, record) => (
          <Row>
            <Popconfirm
              title={
                tabKey === "list"
                  ? t("USER_RESTORE_CONFIRMATION")
                  : t("USER_DELETE_CONFIRMATION")
              }
              onConfirm={() => handleDeleteAndRestore(record?.id)}
              onCancel={() => {}}
              okText={t("YES")}
              cancelText={t("NO")}
              placement="topRight"
            >
              <Tooltip title={tabKey === "list" ? t("RESTORE") : t("DELETE")}>
                <Micons
                  icon={
                    tabKey === "list" ? "settings_backup_restore" : "delete"
                  }
                  className="table-action-icon"
                  wrapperClassName={styles["icon"]}
                />
              </Tooltip>
            </Popconfirm>

            <Tooltip title={t("RESET_PASSWORD")}>
              <Micons
                icon={"lock_reset"}
                className="table-action-icon"
                wrapperClassName={styles["icon"]}
                onClick={() => showResetPassword(record)}
              />
            </Tooltip>
          </Row>
        ),
      },
    ],
    [t, tabKey]
  );

  const handleSearch = debounce(async (e) => {
    setSearchValue(e.target.value);
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  return (
    <>
      <Input
        onChange={(e) => handleSearch(e)}
        placeholder={t("SEARCH_USER")}
        prefix={<SearchOutlined />}
      />
      <Table
        columns={columns}
        dataSource={userList}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default SelectUsers;
