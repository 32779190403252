import React, { useEffect, useState } from "react";
import { Input } from "antd";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";



const GooglePlacesAutoComplete = ({
  label,
  form,
  updateStateValue,
  index,
  defaultValue,
  register,
  ...props
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect =
    ({ description }) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description }).then((results) => {
          const { lat, lng } = getLatLng(results[0]);
          console.log("📍 Coordinates: ", { lat, lng });
          let currentLocations = form.getFieldValue("discount_location")
          const newLocation = { name: description, latitude: lat, longitude: lng }
          currentLocations[index] = newLocation

          form.setFieldValue("discount_location", [...currentLocations])

        });
      };

  const [defaultLocation, setDefaultLocation] = useState(null)
  const handleInput = (e) => {
    if (e.target.value.length <= 1) {
      setDefaultLocation(null)
    }
    setValue(e.target.value);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  useEffect(() => {
    if (defaultValue) {
      setDefaultLocation(defaultValue)
    }
  }, [defaultValue])

  return (
    <>
      <Input
        value={value || defaultLocation}

        onChange={handleInput}
        disabled={!ready}
        autoComplete="off"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <ul className="places-suggestions">{renderSuggestions()}</ul>
      )}
    </>
  );
};

export default GooglePlacesAutoComplete;
