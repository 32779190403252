import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";

const ModalPopup = (props) => {

    const modalItem = () => {
        props.okClick();
    };

    return (
        <Modal centered backdrop="static" show={props.show} onHide={props.hide} className="modalStyle">
            <Modal.Header closeButton>
                <Modal.Title>{props.name}</Modal.Title>
            </Modal.Header>
            {props.hideFooter ?
                <Modal.Footer>
                    <Button variant="secondary" className="btnSecondary"  onClick={props.hide}>{props.cancelText}</Button>
                    <Button className="btnPrimary" onClick={() => modalItem()}>{props.okText}</Button>
                </Modal.Footer>
                : null}
        </Modal>
    )
};

export default ModalPopup;