import { useEffect, useMemo, useState } from "react";
import { Input, Select, Skeleton, Image, Tag, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { getAllTypesBenefitsFromDb } from "./apiUtils";
import { debounce } from "../../utils/helper";
import { getTranslatedBenefitType } from "../../utils/utils";

import { Button, Modal, ModalHeader, NoData, Table } from "../customAntd";
import Filters from "../filters";
import { useAppContext } from "../context/app.context";

import Styles from "./addbenefits.module.scss";

const { Item } = Filters;
const AddBenefitsModal = ({
  open,
  endPoint,
  isLoading: loading,
  onCancel,
  onSubmit,
  isTier,
  isSweepStake,
  hideBenefitsList,
}) => {
  const { t } = useTranslation();
  const [benefitsData, setBenefitsData] = useState([]);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [benefitTypeFilter, setBenefitTypeFilter] = useState("");
  const [benefitStatusFilter, setBenefitStatusFilter] = useState("");
  const [searchString, setSearchString] = useState("");
  const [attachAll, setAttachAll] = useState(true);
  const { currentLocale } = useAppContext();
  const filterOptions = useMemo(() => {
    return [
      {
        value: "audio",
        label: t("SINGLE_AUDIO"),
        field: "SINGLE_AUDIO",
      },
      {
        value: "coupon",
        label: t("ADD_BENEFITS_MODAL.COUPON"),
        field: "ADD_BENEFITS_MODAL.COUPON",
      },
      {
        value: "digitalbook",
        label: t("DIGITAL_BOOK"),
        field: "DIGITAL_BOOK",
      },
      {
        value: "streams",
        label: t("ADD_BENEFITS_MODAL.STREAMS"),
        field: "ADD_BENEFITS_MODAL.STREAMS",
      },
      {
        value: "video",
        label: t("SINGLE_VIDEO"),
        field: "SINGLE_VIDEO",
      },
      {
        value: "survey",
        label: t("SURVEY.SURVEY"),
        field: "SURVEY.SURVEY",
      },
      !isSweepStake && {
        value: "sweepstakes",
        label: t("ADD_BENEFITS_MODAL.SWEEPSTAKE"),
        field: "ADD_BENEFITS_MODAL.SWEEPSTAKE",
      },
    ].filter(Boolean);
  }, [t]);

  // useMemo is used to prevent the re-creation of the 'columns' variable on every render.
  const columns = useMemo(
    () => [
      {
        title: t("ADD_BENEFITS_MODAL.BENEFIT_NAME"),
        dataIndex: "title",
        key: "title",
        render: (_, { title, locales }) => {
          return (
            locales?.find?.((locale) => locale.localeId === currentLocale)
              ?.title ?? title
          );
        },
      },
      {
        title: t("TYPE"),
        dataIndex: "type",
        key: "type",
        render: (_, { type }) => {
          const { label, icon, color } = getTranslatedBenefitType(type, t);
          return (
            <Tag className={Styles["benefits-tag"]} color={color}>
              {" "}
              <Image
                preview={false}
                className={Styles["benefits-icon"]}
                src={icon}
              >
                {" "}
              </Image>
              {label}
            </Tag>
          );
        },
      },
      {
        title: t("ADD_BENEFITS_MODAL.STATUS"),
        dataIndex: "status",
        key: "status",
        render: (_, { status }) => t(status.toUpperCase()),
      },
      {
        title: t("ADD_BENEFITS_MODAL.DATE_CREATED"),
        key: "createdAt",
        dataIndex: "createdAt",
        render: (_, { createdAt }) => (
          <>
            <Tag style={{ borderRadius: "10px !important" }} isDisabled>
              {dayjs(createdAt).format("MM/DD/YYYY")}
            </Tag>
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedBenefits([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      // name: record.name,
    }),
  };

  useEffect(() => {
    if (open) {
      const fetchBenefits = async () => {
        const filters = {
          filterCondition: {
            status: benefitStatusFilter,
            benefitType:
              (isTier || isSweepStake) && benefitTypeFilter === ""
                ? filterOptions.map((filter) => filter.value).join()
                : benefitTypeFilter,
            search: searchString,
          },
        };
        setIsLoading(true);
        const getBenefits = await getAllTypesBenefitsFromDb(
          endPoint,
          filters,
          isSweepStake && "post"
        );

        if (getBenefits?.status === 200) {
          if (hideBenefitsList?.length > 0) {
            console.log(hideBenefitsList, "Hidebenefits liset");
            setBenefitsData(
              getBenefits.data.filter((el) => !hideBenefitsList.includes(el.id))
            );
          } else {
            setBenefitsData(getBenefits.data);
          }
        }
        setIsLoading(false);
      };
      fetchBenefits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, benefitStatusFilter, benefitTypeFilter, searchString]);

  useEffect(() => {
    if (!loading) {
      setSelectedBenefits([]);
    }
  }, [loading]);

  const handleSearchString = debounce((e) => {
    setSearchString(e.target.value);
  });

  const reset = () => {
    setBenefitStatusFilter("");
    setBenefitTypeFilter("");
    setSearchString("");
    setSelectedBenefits([]);
  };

  return (
    <Modal
      title={<ModalHeader title={t("ADD_BENEFITS_MODAL.ADD")} />}
      open={open}
      centered
      width={1200}
      onCancel={() => {
        reset();
        onCancel();
      }}
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
      footer={[
        <Button
          buttonType="cancel"
          onClick={() => {
            reset();
            onCancel();
          }}
          key="cancel-button"
        >
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={() => onSubmit(selectedBenefits, attachAll)}
          loading={loading}
          disabled={selectedBenefits.length === 0}
          key="create-button"
        >
          {t("ATTACH_BENEFITS")}
        </Button>,
        isTier && (
          <Checkbox
            defaultChecked={true}
            className={Styles["attach-all-checkbox"]}
            onChange={(e) => {
              setAttachAll(e.target.checked);
            }}
          >
            All Tiers
          </Checkbox>
        ),
      ]}
    >
      <Filters
        extra={
          <Input
            style={{ minWidth: 300 }}
            placeholder={t("ADD_BENEFITS_MODAL.SEARCH")}
            onChange={handleSearchString}
            prefix={<SearchOutlined />}
          />
        }
        wrapperStyle={{ marginBottom: 16, marginTop: 30 }}
      >
        <Item>
          <Select
            placeholder={t("FILTER_BY_OPTIONS")}
            style={{ minWidth: 160 }}
            allowClear
            options={[
              {
                value: "draft",
                label: t("DRAFT"),
              },
              {
                value: "inActive",
                label: t("INACTIVE"),
              },
              {
                value: "active",
                label: t("ACTIVE"),
              },
            ]}
            onSelect={(key) => {
              setBenefitStatusFilter(key);
            }}
            onClear={() => {
              setBenefitStatusFilter("");
            }}
          />
        </Item>
        <Item>
          <Select
            placeholder={t("ADD_BENEFITS_MODAL.FILTER_BY_TYPE")}
            style={{ minWidth: 160 }}
            allowClear
            options={filterOptions}
            onSelect={(key) => {
              setBenefitTypeFilter(key);
            }}
            onClear={() => {
              setBenefitTypeFilter("");
            }}
          />
        </Item>
      </Filters>

      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {benefitsData.length > 0 ? (
            <Table
              rowSelection={{
                columnWidth: 32,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={benefitsData}
              rowKey={(record) => record.id}
              pagination={{
                pageSize: 5,
              }}
            />
          ) : (
            <NoData />
          )}
        </>
      )}
    </Modal>
  );
};

export default AddBenefitsModal;
