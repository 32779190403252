import { useState, useEffect } from "react";
import { Row, Form, Radio, InputNumber, Input, DatePicker, Select } from "antd";
import { useTranslation } from "react-i18next";

import { BREADCRUMB_PROGRAM_WAY_TO_EARN } from "../../../../utils/programs/constant";
import { Modal, Button, ModalHeader } from "../../../../components/customAntd";
import Styles from "../styles/wayToEarn.module.scss";
import { Required } from "../newProgram/utils";
import { getRequiredBenefitTypeFromAction, capitalize } from "./utils";

const WayToEarnForm = ({
  form,
  isOpen,
  onSubmit,
  onClose,
  merchantData,
  tierData,
  benefitData,
  loading,
  isEdit,
  programDetails,
  currentLocaleData,
}) => {
  const { t } = useTranslation();
  const dateFormat = "DD/MM/YYYY";
  const [requiredBenefitType, setRequiredBenefitType] = useState(null);
  const action = Form.useWatch("action", form);
  const frequencyUsage = Form.useWatch("frequency", form);
  const isStreakReward = Form.useWatch("is_streak", form);
  const unlimitedEarn = Form.useWatch("unlimited_earn", form);

  useEffect(() => {
    setRequiredBenefitType(getRequiredBenefitTypeFromAction(action));
  }, [action]);

  const { Option } = Select;

  function renderOption(option) {
    return (
      <Option key={option.value} value={option.value} label={option.label}>
        <img
          src={option.imageURL}
          alt={option.label}
          height={25}
          width={25}
          className={Styles["label-img"]}
        />{" "}
        {option.label}
      </Option>
    );
  }

  return (
    <Modal
      title={
        <ModalHeader
          title={t("PROGRAM_UI.WAY_TO_EARN")}
          breadcrumbs={BREADCRUMB_PROGRAM_WAY_TO_EARN(
            currentLocaleData
              ? currentLocaleData.name
              : programDetails?.name || "",
            t
          )}
        />
      }
      style={{
        overflowX: "hidden",
        overflowY: "scroll",
        maxHeight: "calc(100vh - 250px)",
      }}
      open={isOpen}
      centered
      width={1500}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button buttonType="cancel" onClick={onClose} key="cancel-button">
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={() => form.submit()}
          loading={loading}
          key="create-button"
        >
          {isEdit
            ? t("PROGRAM_UI.EDIT_WAY_TO_EARN")
            : t("PROGRAM_UI.ADD_WAY_TO_EARN")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        disabled={loading}
        requiredMark={false}
      >
        <ActionSelect form={form} isEdit={isEdit} />
        {["view", "listen", "read"].includes(action) && (
          <Form.Item
            label={
              <span className={Styles["title"]}>
                {`Link ${capitalize(requiredBenefitType)} Benefit`}{" "}
                <span className={Styles["optional"]}>(Optional)</span>{" "}
              </span>
            }
            name="benefit_select"
            extra={`This is the ${requiredBenefitType} that a customer would need to ${action.toLowerCase()} to earn points. This is applicable to all by default.`}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder={t(
                "PROGRAM_UI.WAY_TO_EARN_MODAL.BEGIN_TYPING_PLACEHOLDER"
              )}
              notFoundContent={`You have not yet added any ${requiredBenefitType} benefis to choose from.`}
            >
              {benefitData
                .filter((benefit) => benefit.type === requiredBenefitType)
                .map(renderOption)}
            </Select>
          </Form.Item>
        )}
        {action === "visit" && (
          <Form.Item
            label={
              <span className={Styles["title"]}>
                {t("PROGRAM_UI.WAY_TO_EARN_MODAL.LINK_MERCHANT")}{" "}
                <span className={Styles["optional"]}>(Optional)</span>{" "}
              </span>
            }
            name="merchant_select"
            extra={t("PROGRAM_UI.WAY_TO_EARN_MODAL.MERCHANT_HELPER_TEXT")}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              showSearch={true}
              filterOption={(inputValue, option) => {
                return (
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                );
              }}
              placeholder={t(
                "PROGRAM_UI.WAY_TO_EARN_MODAL.BEGIN_TYPING_FOR_MERCHANT_PLACEHOLDER"
              )}
              notFoundContent={t(
                "PROGRAM_UI.WAY_TO_EARN_MODAL.NOT_ADDED_MERCHANTS"
              )}
            >
              {merchantData.map(renderOption)}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={
            <span className={Styles["title"]}>
              {t("PROGRAM_UI.WAY_TO_EARN_MODAL.LINK_TIERS")}{" "}
              <span className={Styles["optional"]}>(Optional)</span>{" "}
            </span>
          }
          name="tier_select"
          extra={t("PROGRAM_UI.WAY_TO_EARN_MODAL.TIER_HELPER_TEXT")}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              );
            }}
            placeholder={t(
              "PROGRAM_UI.WAY_TO_EARN_MODAL.BEGIN_TYPING_TIER_PLACEHOLDER"
            )}
            options={tierData}
            notFoundContent={t("PROGRAM_UI.WAY_TO_EARN_MODAL.NOT_ADDED_TIERS")}
          />
        </Form.Item>
        <Form.Item
          label={Required(
            <span className={Styles["title"]}>
              {t("PROGRAM_UI.WAY_TO_EARN_MODAL.POINT_VALUE")}
            </span>
          )}
          name="points"
          rules={[
            {
              required: true,
              message: t("PROGRAM_UI.WAY_TO_EARN_MODAL.ADD_POINT_VALUE"),
            },
          ]}
        >
          <InputNumber
            placeholder={0}
            addonAfter={t("PROGRAM_UI.WAY_TO_EARN_MODAL.POINTS")}
          />
        </Form.Item>
        <Form.Item
          label={Required(
            <span className={Styles["title"]}>
              {t("PROGRAM_UI.WAY_TO_EARN_MODAL.GIVE_NAME_TO_EARN")}
            </span>
          )}
          name="name"
          rules={[
            {
              required: true,
              message: t("PROGRAM_UI.WAY_TO_EARN_MODAL.ENTER_NAME"),
            },
            {
              max: 250,
              message: t("PROGRAM_UI.WAY_TO_EARN_MODAL.NAME_VALIDATION"),
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label={
            <span className={Styles["title"]}>
              {t("PROGRAM_UI.WAY_TO_EARN_MODAL.ADD_DESCRIPTION")}
            </span>
          }
          name="description"
          rules={[
            {
              max: 250,
              message: t("PROGRAM_UI.WAY_TO_EARN_MODAL.NAME_VALIDATION"),
            },
          ]}
        >
          <Input placeholder="Description" />
        </Form.Item>
        <Row className={Styles["split-row"]}>
          <Form.Item
            label={
              <span className={Styles["title"]}>
                {t("PROGRAM_UI.WAY_TO_EARN_MODAL.START_DATE")}
              </span>
            }
            name="start_date"
            className={Styles["date"]}
          >
            <DatePicker
              key={Date.now()}
              format={dateFormat}
              style={{ width: "100%" }}
            ></DatePicker>
          </Form.Item>
          <Form.Item
            label={
              <span className={Styles["title"]}>
                {t("PROGRAM_UI.WAY_TO_EARN_MODAL.END_DATE")}
              </span>
            }
            name="end_date"
            className={Styles["date"]}
          >
            <DatePicker
              key={Date.now()}
              format={dateFormat}
              style={{ width: "100%" }}
            ></DatePicker>
          </Form.Item>
        </Row>
        <Form.Item
          label={Required(
            <span className={Styles["title"]}>
              {t("PROGRAM_UI.WAY_TO_EARN_MODAL.UNLIMITED_EARNS")}:
            </span>
          )}
          name="unlimited_earn"
          rules={[
            {
              required: true,
              message: t("PROGRAM_UI.WAY_TO_EARN_MODAL.YES_OR_NO"),
            },
          ]}
        >
          <Radio.Group style={{ width: "100%" }} defaultValue={"no"}>
            <Radio value="yes">{t("YES")}</Radio>
            <Radio value="no">{t("NO")}</Radio>
          </Radio.Group>
        </Form.Item>

        {unlimitedEarn !== "yes" && (
          <>
            <Form.Item
              label={Required(
                <span className={Styles["title"]}>
                  {t("PROGRAM_UI.WAY_TO_EARN_MODAL.EARN_LIMIT")}:
                </span>
              )}
              name="pass_earn_limit"
              rules={[
                {
                  required: true,
                  message: t("PROGRAM_UI.WAY_TO_EARN_MODAL.ADD_EARN_LIMIT"),
                },
              ]}
            >
              <InputNumber defaultValue={1} placeholder={1} />
            </Form.Item>
          </>
        )}
        <Form.Item
          label={
            <span className={Styles["title"]}>
              {t("DISCOUNT_CODES.FREQUENCY_CAP")}:
            </span>
          }
          name="frequency"
          extra={t("PROGRAM_UI.WAY_TO_EARN_MODAL.SET_FREQUENCY_CAP")}
        >
          <Select
            defaultValue="none"
            style={{ width: 200 }}
            className="mr-2"
            placeholder={t("DISCOUNT_CODES.SELECT_FREQUENCY_CAP")}
            options={[
              {
                value: "none",
                label: t("NONE"),
              },
              {
                value: "daily",
                label: t("DISCOUNT_CODES.DAILY"),
              },
              {
                value: "weekly",
                label: t("DISCOUNT_CODES.WEEKLY"),
              },
              {
                value: "monthly",
                label: t("DISCOUNT_CODES.MONTHLY"),
              },
            ]}
          />
        </Form.Item>

        {frequencyUsage && frequencyUsage !== "none" && (
          <Form.Item
            label={
              <span className={Styles["title"]}>
                {t("DISCOUNT_CODES.FREQUENCY_LIMIT")}:
              </span>
            }
            name="frequency_limit"
            rules={[
              {
                required: true,
                message: t("DISCOUNT_CODES.ENTER_USAGE_LIMIT"),
              },
            ]}
          >
            <InputNumber min={1} placeholder="1" />
          </Form.Item>
        )}

        <Form.Item
          label={Required(
            <span className={Styles["title"]}>{t("STATUS")}:</span>
          )}
          name="status"
          rules={[
            {
              required: true,
              message: t("SELECT_STATUS"),
            },
          ]}
        >
          <Radio.Group style={{ width: "100%" }}>
            <Radio value="draft">{t("DRAFT")}</Radio>
            <Radio value="active">{t("ACTIVE")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={
            <span className={Styles["title"]}>
              {t("PROGRAM_UI.WAY_TO_EARN_MODAL.ADD_STREAK_REQUIREMENT")}
            </span>
          }
          name="is_streak"
          extra={t("PROGRAM_UI.WAY_TO_EARN_MODAL.STREAK_REQUIREMENT")}
        >
          <Radio.Group value={isStreakReward} buttonStyle="outline">
            <Radio value={false}>{t("NO")}</Radio>
            <Radio value={true}>{t("YES")}</Radio>
          </Radio.Group>
        </Form.Item>
        {isStreakReward && <StreakInput form={form} />}
      </Form>
    </Modal>
  );
};

const ActionSelect = ({ form, isEdit }) => {
  const selectedAction = Form.useWatch("action", form);
  const { t } = useTranslation();
  const options = [
    {
      action: t("PROGRAM_UI.WAY_TO_EARN_MODAL.VISIT"),
      value: "visit",
      icon: <img src="https://img.icons8.com/office/80/null/shop.png" />,
    },
    {
      action: t("PROGRAM_UI.WAY_TO_EARN_MODAL.VIEW"),
      value: "view",
      icon: <img src="https://img.icons8.com/stickers/100/null/visible.png" />,
    },
    {
      action: t("PROGRAM_UI.WAY_TO_EARN_MODAL.READ"),
      value: "read",
      icon: (
        <img src="https://img.icons8.com/stickers/100/null/e-learning-2.png" />
      ),
    },
    {
      action: t("PROGRAM_UI.WAY_TO_EARN_MODAL.LISTEN"),
      value: "listen",
      icon: (
        <img src="https://img.icons8.com/stickers/100/null/high-volume.png" />
      ),
    },
    // {
    //   action: t("PROGRAM_UI.WAY_TO_EARN_MODAL.CLAIM"),
    //   value: "claim",
    //   icon: <img src="https://img.icons8.com/stickers/100/null/nft.png" />,
    // }, // TODO: handle 'claim' actions
  ];

  return (
    <Form.Item
      label={Required(
        <span className={Styles["title"]}>
          {t("PROGRAM_UI.WAY_TO_EARN_MODAL.CHOOSE_AN_ACTION")}
        </span>
      )}
      name="action"
      rules={[
        {
          required: true,
          message: t("PROGRAM_UI.WAY_TO_EARN_MODAL.SELECT_AN_ACTION"),
        },
      ]}
    >
      <Radio.Group
        buttonStyle="outline"
        className={Styles["action"]}
        disabled={isEdit}
      >
        {options.map((option) => (
          <Radio.Button
            key={option.value}
            value={option.value}
            className={`${Styles["card"]} ${
              option.value === selectedAction ? Styles["selected"] : ""
            }`}
          >
            {option.icon}
            <p>{option.action}</p>
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

const StreakInput = ({ form }) => {
  const streakFrequency = Form.useWatch("streak_frequency", form);
  const { t } = useTranslation();
  return (
    <Row>
      <Form.Item
        label={Required(
          <span className={Styles["title"]}>
            {t("PROGRAM_UI.WAY_TO_EARN_MODAL.ONCE_EVERY")}:
          </span>
        )}
        name="streak_frequency"
      >
        <Select
          placeholder={t("PROGRAM_UI.WAY_TO_EARN_MODAL.SELECT_TIME_PERIOD")}
          options={[
            { value: "day", label: t("DAY") },
            { value: "week", label: t("WEEK") },
            { value: "month", label: t("MONTH") },
            { value: "year", label: t("YEAR") },
          ]}
          style={{ marginRight: "20px", width: "200px" }}
        />
      </Form.Item>

      <div className={Styles["streak-count"]}>
        <Form.Item
          label={Required(<span className={Styles["title"]}>{t("FOR")}:</span>)}
          name="streak_count"
        >
          <InputNumber placeholder={0} style={{ width: "75px" }} />
        </Form.Item>
        <p className={Styles["streak-frequency"]}>
          &nbsp;{streakFrequency ? streakFrequency : "..."}(s)
        </p>
      </div>
    </Row>
  );
};

export default WayToEarnForm;
