// import Third party and React
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, DatePicker, Form, message } from "antd";
import dayjs from "dayjs";

// import custom components
import {
  Button,
  Modal,
  PageHeader,
} from "../../../../../components/customAntd";
import { BREADCRUMB_SWEEPSTAKES_REGISTRATIONS } from "../../../../../utils/benefits/constant";
import { PlusOutlined } from "@ant-design/icons";
import { generateSweepStakeResults, getMetabaseUrl } from "../../apiUtils";

const { Item } = Form;

const SweepstakeRegistrations = ({ open, onOk, record, setData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState();
  const [url, setUrl] = useState("");
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const watchEliminate = Form.useWatch("eliminatePreviousWinners", form);

  useEffect(() => {
    setIsDisabled(
      record?.sweepStake?.resultAnnounced ||
        dayjs(record?.endDate).isAfter(dayjs())
    );
  }, [record]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setTimeout(() => {
        if (targetRef.current) {
          setDimensions({
            width: targetRef.current.offsetWidth,
            height: targetRef.current.offsetHeight,
          });
        }
      }, 200);
    };

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [open]);

  useEffect(() => {
    fetchUrl();
  }, [record, isLoading]);

  const fetchUrl = async () => {
    if (record?.sweepStake?.id) {
      const res = await getMetabaseUrl(record?.sweepStake?.id);
      setUrl(res.data.url);
    }
  };

  const renderRegistrations = () => {
    return (
      <div ref={targetRef} style={{ width: "100%", height: "43vh" }}>
        <iframe
          src={url}
          frameBorder={0}
          width={dimensions.width}
          height={dimensions.height}
          allowTransparency
        />
      </div>
    );
  };

  const generateResults = async (data) => {
    setIsLoading(true);
    setIsDisabled(true);
    const generateResults = await generateSweepStakeResults(
      record?.sweepStake?.id,
      {
        eliminatePreviousWinner: data?.eliminatePreviousWinners,
        eliminationTime: data?.month?.startOf("month").format(),
      }
    );
    if (generateResults?.status === 200) {
      record.sweepStake.resultAnnounced = true;
      setData((prev) =>
        prev.map((item) => (item.id === record.id ? record : item))
      );
    } else if (generateResults?.status === 422) {
      if (generateResults.data.code === "ER-1002") {
        message.error({
          content: t("SWEEPSTAKES.REWARD_COUNT_ERROR"),
          duration: 3,
          key: "reward-error",
        });
      } else {
        message.error({
          content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        onCancel={onOk}
        title={
          <PageHeader
            title={t("REGISTRATIONS")}
            breadcrumbs={BREADCRUMB_SWEEPSTAKES_REGISTRATIONS(t)}
            wrapperStyle={{ marginBottom: 0 }}
            headStyle={{ alignItems: "flex-start", marginTop: 15 }}
            extra={
              <>
                <Button
                  icon={<PlusOutlined />}
                  onClick={form.submit}
                  loading={isLoading}
                  disabled={isDisabled}
                  style={{ marginBottom: 10, marginLeft: "auto" }}
                >
                  {t("SWEEPSTAKES.ALLOT_SWEEPSTAKES")}
                </Button>
                {!isDisabled && (
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={generateResults}
                  >
                    <div style={{ display: "flex" }}>
                      <Item
                        name="eliminatePreviousWinners"
                        valuePropName="checked"
                      >
                        <Checkbox>
                          {t("SWEEPSTAKES.ELIMINATE_PREV_WINNERS")}
                        </Checkbox>
                      </Item>
                      {watchEliminate && (
                        <Item
                          name="month"
                          label={t("SWEEPSTAKES.ELIMINATE_FROM")}
                          style={{ marginTop: 6, marginLeft: 20 }}
                          rules={[
                            {
                              required: watchEliminate,
                              message: t("SWEEPSTAKES.ELIMINATE_FROM_ERROR"),
                            },
                          ]}
                        >
                          <DatePicker
                            picker="month"
                            disabledDate={(date) => {
                              return (
                                date.isBefore(dayjs().subtract(12, "month")) ||
                                date.isAfter(dayjs())
                              );
                            }}
                            placeholder={t("SELECT_MONTH")}
                          />
                        </Item>
                      )}
                    </div>
                  </Form>
                )}
              </>
            }
          />
        }
        width={1000}
        height={400}
        destroyOnClose
        cancelButtonProps={{ style: { display: "none" } }}
        open={open}
        onOk={onOk}
        okText={t("OK")}
      >
        {renderRegistrations()}
      </Modal>
    </>
  );
};

export default SweepstakeRegistrations;
