import axios from "axios";

import constant from "../../../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const getMetabaseUrl = async () => {
  return axios
    .get(`${baseURL}dashboard/metabase/url-email-communications`)
    .then((response) => response)
    .catch((err) => {
      return err.response?.data?.message ?? err.response;
    });
};
