import React, { useEffect, useState } from 'react'
import { Form, InputGroup } from "react-bootstrap";

export default function SearchableDropdown({
    options,
    searchPrompt,
    emptyPrompt,
    selected,
    setSelected
}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSearch = event => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        const filteredOptions = options.filter(option =>
            option.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOptions(filteredOptions);
    }

    const handleSelection = (event) => {
        if (selected.includes(event.target.value)) {
            const filterSelected = selected.filter(item => item !== event.target.value);
            setSelected(filterSelected)
        } else {
            setSelected(prev => [...prev, event.target.value])
        }
    }

    return (
        <Form>
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder={searchPrompt}
                    aria-label={searchPrompt}
                    aria-describedby="basic-addon2"
                    onChange={handleSearch}
                />
            </InputGroup>
            {filteredOptions.length > 0 ?
                <Form.Control
                    as="select"
                    multiple
                    value={selected}
                    onClick={handleSelection}
                    onChange={() => { }}
                >
                    {filteredOptions.map(option => (
                        <option
                            key={option.id}
                            value={option.id}
                        >
                            {option.name}
                        </option>
                    ))}
                </Form.Control>
                :
                <Form.Text>{emptyPrompt}</Form.Text>
            }
        </Form>
    );
};