import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Card, Tag, Image, message, Popconfirm } from "antd";
import { StarFilled, DeleteFilled } from "@ant-design/icons";

import { useTranslation } from "react-i18next";

// import utils, API Utils and helper functions
import {
  attachBenefitToTier,
  getProgram,
  detachBenefitFromTier,
} from "../apiUtils";
import { getTranslatedBenefitType } from "../../../../utils/utils";

// import custom components
import { PageHeader } from "../../../../components/customAntd";
import AddBenefitsModal from "../../../../components/addBenefitsModal";
import { useAppContext } from "../../../../components/context/app.context";

import { BREADCRUMB_PROGRAMS_ATTACH_BENEFITS } from "../../../../utils/programs/constant";

//Import styles
import Styles from "./styles/tierBenefits.module.scss";

const TierBenefits = () => {
  const { t } = useTranslation();
  const { programId } = useParams();
  const [activeTier, setActiveTier] = useState(0);
  const [isBenefitOpen, setIsBenefitOpen] = useState(false);
  const [isAttachBenefitLoading, setIsAttachBenefitLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const { currentLocale } = useAppContext();
  const onTierSelect = (e, index) => {
    setActiveTier(index);
  };

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await getProgram(programId);
        if (response.status === 200) {
          setTiers(response.data.tiers);
        } else {
          throw new Error(
            `${t("PROGRAM_UI.FETCH_PROGRAM_ERROR")} ${t("TRY_AGAIN_LATER")}`
          );
        }
      } catch (err) {
        message.error(err);
      }
    };
    fetchProgram();
  }, [programId, isAttachBenefitLoading]);

  const handleAttachBenefitSubmit = async (data, all) => {
    try {
      setIsAttachBenefitLoading(true);
      const response = await attachBenefitToTier(
        { benefits: data },
        programId,
        all ? "all" : tiers[activeTier]?.id
      );
      if (response.status === 200) {
        setIsBenefitOpen(false);
        message.success(t("PROGRAM_UI.ASSIGN_BENEFITS_PAGE.ATTACH_SUCCESSFUL"));
      } else {
        throw new Error(
          `${t("PROGRAM_UI.ASSIGN_BENEFITS_PAGE.ATTACH_SUCCESSFUL")}, ${t(
            "TRY_AGAIN_LATER"
          )}`
        );
      }
      setIsAttachBenefitLoading(false);
    } catch (err) {
      message.error(err);
    }
  };
  const disconnectBenefit = async (data) => {
    setIsAttachBenefitLoading(true);
    const response = await detachBenefitFromTier(
      { benefits: [data.benefitId] },
      programId,
      data.tierId
    );
    if (response.status === 200) {
      setIsBenefitOpen(false);
      message.success(t("PROGRAM_UI.ASSIGN_BENEFITS_PAGE.DELETE_SUCCESSFUL"));
    } else {
      message.error(
        `${t("PROGRAM_UI.ASSIGN_BENEFITS_PAGE.DELETE_ERROR")} ${t(
          "TRY_AGAIN_LATER"
        )}`
      );
    }
    setIsAttachBenefitLoading(false);
  };

  const handleDeleteAll = async (index) => {
    setIsAttachBenefitLoading(true);
    const response = await detachBenefitFromTier(
      {
        benefits: tiers[index].benefitsTier.map((item) => item.benefitId),
      },
      programId,
      tiers[index].id
    );
    if (response.status === 200) {
      message.success(t("PROGRAM_UI.ASSIGN_BENEFITS_PAGE.DELETE_SUCCESSFUL"));
    } else {
      message.error(
        `${t("PROGRAM_UI.ASSIGN_BENEFITS_PAGE.DELETE_ALL_ERROR")} ${t(
          "TRY_AGAIN_LATER"
        )}`
      );
    }
    setIsAttachBenefitLoading(false);
  };

  return (
    <>
      <PageHeader
        title={t("PROGRAM_UI.COMPARE_TIER_BENEFITS")}
        breadcrumbs={BREADCRUMB_PROGRAMS_ATTACH_BENEFITS(t)}
      />
      <div className={Styles["tier-list"]}>
        {tiers?.map((tier, index) => {
          // Check if the current tier has attached benefits
          const hasAttachedBenefits =
            tier.benefitsTier && tier.benefitsTier.length > 0;

          const localisedName = tier?.locales?.filter(
            (locale) => locale.localeId === currentLocale
          )[0]?.name;
          return (
            <Col lg={6}>
              <Card
                onClick={(e) => onTierSelect(e, index)}
                className={`${Styles["tierSingleCard"]} ${
                  activeTier === index ? Styles["tierCardActive"] : ""
                }`}
              >
                <Row
                  align={"middle"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexFlow: "column",
                  }}
                >
                  <StarFilled
                    key={index}
                    className={Styles["tierCardIcon"]}
                  ></StarFilled>
                  <p> {localisedName ?? tier.name}</p>
                </Row>
              </Card>
              <div
                onClick={(e) => {
                  setActiveTier(index);
                  setIsBenefitOpen(true);
                }}
                className={Styles["assign-benefits-button"]}
              >
                <span className={Styles["add-benefits-text"]}>
                  {" "}
                  {t("PROGRAM_UI.ASSIGN_BENEFITS")}
                </span>
              </div>
              <div
                onClick={(e) => {
                  setActiveTier(index);
                  handleDeleteAll(index);
                }}
                className={`${Styles["assign-benefits-button"]} ${
                  !hasAttachedBenefits ? Styles["disabled-button"] : ""
                }`}
              >
                <span className={Styles["add-benefits-text"]}>
                  {" "}
                  {t("PROGRAM_UI.ASSIGN_BENEFITS_PAGE.REMOVE_ALL")}
                </span>
              </div>
              {tier.benefitsTier
                ?.filter((item) => !item.pointPrice || item.pointPrice === 0)
                .map((item) => {
                  const currentLocaleName = item?.benefit?.locales?.find(
                    (locale) => locale.localeId === currentLocale
                  )?.title;
                  const { label, icon, color } = getTranslatedBenefitType(
                    item.benefit.type,
                    t
                  );
                  return (
                    <div className={Styles["benefits-tile"]}>
                      <div>
                        <p>
                          {currentLocaleName ??
                            item.benefit.title ??
                            t("BENEFITS")}
                        </p>
                        <Tag className={Styles["benefits-tag"]} color={color}>
                          <Image
                            preview={false}
                            className={Styles["benefits-icon"]}
                            src={icon}
                          ></Image>
                          {label}
                        </Tag>
                      </div>
                      <Popconfirm
                        title={t(
                          "PROGRAM_UI.ASSIGN_BENEFITS_PAGE.DELETE_CONFIRMATION"
                        )}
                        onConfirm={() => disconnectBenefit(item)}
                        onCancel={() => {}}
                        okText={t("YES")}
                        cancelText={t("NO")}
                        placement="topRight"
                        arrowPointAtCenter
                      >
                        <DeleteFilled className={Styles["hide"]} />
                      </Popconfirm>
                    </div>
                  );
                })}
            </Col>
          );
        })}
      </div>
      <AddBenefitsModal
        open={isBenefitOpen}
        isLoading={isAttachBenefitLoading}
        endPoint={`programs/${programId}/tiers/${
          tiers && tiers[activeTier]?.id
        }/benefit`}
        onCancel={() => setIsBenefitOpen(false)}
        onSubmit={handleAttachBenefitSubmit}
        isTier
      />
    </>
  );
};

export default TierBenefits;
