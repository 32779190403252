import React, { useEffect, useState } from "react";
import { getAppSettings, updateAppSettings } from "./../../redux/appSettings.slice";
import { useDispatch } from "react-redux";
import DataTable from "../../components/dataTable";
import { Button, Form } from "react-bootstrap";

const AppSettings = () => {
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState([]);
    const [updateKey, setUpdateKey] = useState({});

    const ActionButtons = (row) => (
        row.isEdit ?
            <div>
                <Button
                    variant="secondary"
                    className="btnSecondary"
                    disabled={row.isLoading}
                    onClick={() => updateSettingKeyData(row.name, { isEdit: false })}
                >
                    CANCEL
                </Button>
                <Button
                    className="btnPrimary"
                    type="submit"
                    disabled={row.isLoading}
                    onClick={() => saveKey(row.name)}
                >
                    SAVE
                </Button>
            </div>
            :
            <div>
                <Button
                    className="btnPrimary"
                    type="submit"
                    onClick={() => updateSettingKeyData(row.name, { isEdit: true })}
                >
                    EDIT
                </Button>
            </div>
    )

    const KeyValue = (row) => (
        row.isEdit ?
            <Form.Control
                style={{ width: '100%' }}
                type="text"
                name={row.name}
                defaultValue={row.value}
                disabled={row.isLoading}
                onChange={(e) => handleKeyChange(e, row.name)}
            />

            :
            row.value
    )

    const tableColumn = [{
        name: "keyName",
        selector: (row) => (row.name),
        maxWidth: "30%",
    },
    {
        name: "KeyValue",
        cell: (row) => <KeyValue {...row} />,
        allowOverflow: true
    },
    {
        name: "action",
        selector: (row) => <ActionButtons {...row} />
    }];

    const updateSettingKeyData = (key, data) => {
        const settings = [...tableData];
        const pos = settings.findIndex((setting) => setting.name === key)
        settings[pos] = {
            ...settings[pos],
            ...data,
        }
        setTableData(settings);
    }

    const saveKey = async (key) => {
        updateSettingKeyData(key, { isLoading: true })
        let data = {};
        data[key] = updateKey[key];
        const response = await dispatch(updateAppSettings(data));
        let settingData = {
            isLoading: false,
            isEdit: false
        }
        if (response.type === "appSettings/updateAppSettings/fulfilled") {
            settingData.value = response.payload[key]
        }

        updateSettingKeyData(key, settingData)

    }

    const handleKeyChange = (e, key) => {
        updateKey[key] = e.target.value;
        setUpdateKey(updateKey)
    }

    useEffect(() => {
        const fetchApiSettings = async () => {
            let response = await dispatch(getAppSettings());
            const data = response.payload
            let keys = Object.keys(data)
            const excludedKeys = ['id', 'createdAt', 'updatedAt']
            const visibleKeys = keys.filter((key) => !excludedKeys.includes(key))
            const dataValues = visibleKeys.map((key) => {
                return {
                    name: key,
                    value: data[key],
                    isEdit: false,
                    isLoading: false
                };
            })
            setTableData(dataValues)
        }

        fetchApiSettings()
    }, [dispatch])

    return (
        <div className="table-sec mb-3 p-0">
            <DataTable
                columns={tableColumn}
                data={tableData}
            />
        </div>
    );
};

export default AppSettings