import { useCallback } from "react";
import { Form } from "react-bootstrap";
function Searchbar({ setValue, setIsSearchString, placeholder, width }) {
  const setDebouncedValue = (value) => {
    setValue(value);
    setIsSearchString(true);
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const optimizedDebounce = useCallback(debounce(setDebouncedValue), []);
  return (
    <Form.Control
      type="text"
      placeholder={placeholder ? placeholder : "Search here"}
      name="name"
      className="program-search"
      style={{
        width: width || 300,
        background:
          "url('/assets/dashboard/images/icons/search-ico.svg') no-repeat center left 7px",
        backgroundSize: "15px",
        paddingLeft: 30,
      }}
      onChange={(e) => {
        optimizedDebounce(e.target.value);
      }}
    />
  );
}

export default Searchbar;
