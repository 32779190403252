import { Form, Input as InputBox } from "antd";

import Styles from "./styles/input.module.scss";

const Input = ({ placeholder, className, ...props }) => {
  return (
    <Form.Item className={Styles["input-item-wrapper"]} {...props}>
      <InputBox
        placeholder={placeholder}
        className={`${Styles.input} ${className ? className : ""}`}
      />
    </Form.Item>
  );
};

export default Input;
