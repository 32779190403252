const config = {
  REACT_API_HOST: process.env.REACT_APP_API_HOST,
  MAGIC_AUTH_KEY: process.env.REACT_APP_MAGIC_AUTH_KEY,
  STOREFRONT_SITE_URL: process.env.REACT_APP_STOREFRONT_SITE_URL,
  STOREFRONT_SITE_TITLES: JSON.parse(
    process.env.REACT_APP_STOREFRONT_SITE_TITLES
  ),
};

export default { config };
