import { Tag as TagAntd } from "antd";

import Styles from "./styles/tag.module.scss";

const Tag = ({ children, className, color, isDisabled, ...props }) => {
  return (
    <TagAntd
      className={`${Styles.tag} ${!color ? Styles.color : ""} ${className ? className : ""} ${
        isDisabled ? Styles.disabled : ""
      }`}
      color={color}
      {...props}
    >
      {children}
    </TagAntd>
  );
};

export default Tag;
