import React, { useState } from 'react'
import {
    BsFillCheckCircleFill,
    BsCheckCircle,
} from 'react-icons/bs'
import { Button } from "react-bootstrap";
import { redeemBenefit } from './apiUtils';
import { useTranslation } from "react-i18next";

const ValidResult = ({
    validResponse,
    passId
}) => {
    const { tier, benefits, merchants, numScans } = validResponse
    const { t } = useTranslation();

    const ScanHeader = ({ tier, numScans }) => {
        return (
            <div className='mt-2 mb-2'>
                <div className='d-flex mb-2'>
                    <div className='position-relative'>
                        <BsCheckCircle className="checkmark text-white" />
                        <BsFillCheckCircleFill className="checkmark faro-pink" />
                        <img
                            src={tier.logoImageUrl}
                            className="tier-logo mr-4"
                            style={{
                                backgroundColor: tier.passColors.backgroundColor,
                                borderColor: tier.passColors.labelColor
                            }}
                            alt="Tier Logo"
                        />
                    </div>
                    <div
                        className='d-flex flex-column justify-content-center align-items-center mr-2'
                    >
                        <div className='font-md'>{numScans}</div>
                        <div className='text-muted font-sm'>{t("SCAN.SCANS")}</div>
                    </div>
                    {/* TODO: Track points <div
                        className='d-flex flex-column justify-content-center align-items-center mr-2'
                    >
                        <div className='font-md'>135</div>
                        <div className='text-muted font-sm'>Points</div>
                    </div> */}
                </div>
                <div className="font-md">
                    <p>{t("PROGRAM")}: {tier.program.name}</p>
                    <p>{t("TIER")}: {tier.name}</p>
                </div>
            </div>
        )
    }
    const MerchantBenefits = ({ merchant, benefits, passId }) => {
        const localeId = merchant.defaultLocaleId.replace("_", "-")

        const Benefit = ({ benefit, passId }) => {
            const [numRedeemedTotal, setNumRedeemedTotal] = useState(benefit.numRedeemedTotal)
            const [numRedeemedThisPass, setNumRedeemedThisPass] = useState(benefit.numRedeemedThisPass)
            const [numRedeemedThisFrequency, setNumRedeemedThisFrequency] = useState(benefit.numRedeemedThisFrequency)
            const [redeemed, setRedeemed] = useState(false)
            const [loading, setLoading] = useState(false)
            const hasNotStarted = new Date(benefit.startDate) > new Date()
            const onRedeem = async () => {
                setLoading(true)
                try {
                    await redeemBenefit(benefit.id, passId)
                    setNumRedeemedTotal(numRedeemedTotal+1)
                    setNumRedeemedThisPass(numRedeemedThisPass+1)
                    setNumRedeemedThisFrequency(numRedeemedThisFrequency+1)
                    setRedeemed(true)
                    setLoading(false)
                } catch (e) {
                    setLoading(false)
                }
            }

            const redeemable = (
                (benefit.frequency === "none" || numRedeemedThisFrequency < benefit.frequencyLimit)
                && (benefit.unlimitedRedemption || numRedeemedTotal < benefit.redemptionTotalLimit) 
                && (benefit.unlimitedRedemption || numRedeemedThisPass < benefit.redemptionPerPass) 
                && !hasNotStarted
            )

            return (
                <div className={`benefit-card`}>
                    {!redeemable && <div className="grey-out-overlay"></div>}
                    <p className='font-md text-dark font-weight-bold'>{benefit.title}</p>
                    {benefit.description && <p className='font-sm text-muted'>{benefit.description}</p>}
                    {benefit.discountPrice &&
                        <p className='font-sm text-muted'>
                            {
                                benefit.discountType === "flat"
                                    ?
                                    <span>
                                        <span>{benefit.currencySymbol ? benefit.currencySymbol : "$"}</span>
                                        <span>{benefit.discountPrice} {t("SCAN.BENEFIT.DISCOUNT")}</span>
                                    </span>
                                    :
                                    <span>
                                        {benefit.discountPrice}% {t("SCAN.BENEFIT.DISCOUNT")}
                                    </span>
                            }
                        </p>}
                    {benefit.couponCode && <p className='font-sm text-muted'>{t("SCAN.BENEFIT.CODE")}: {benefit.couponCode}</p>}
                    {benefit.endDate && <p className='font-sm text-muted'>{t("SCAN.BENEFIT.ENDS")}: {new Date(benefit.endDate).toLocaleDateString(localeId, { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</p>}
                    {benefit.startDate && hasNotStarted && <p className='font-sm faro-pink font-weight-bold'>{t("SCAN.BENEFIT.STARTS")}: {new Date(benefit.startDate).toLocaleDateString(localeId, { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</p>}
                    <p className={`font-sm ${(!benefit.unlimitedRedemption && numRedeemedTotal >= benefit.redemptionTotalLimit) ? "text-danger font-weight-bold" : "text-muted"}`}>{t(`SCAN.BENEFIT.TOTAL_LIMIT`)}: {benefit.unlimitedRedemption ? "UNLIMITED" : `${numRedeemedTotal} / ${benefit.redemptionTotalLimit}`}</p>
                    <p className={`font-sm ${(!benefit.unlimitedRedemption && numRedeemedThisPass >= benefit.redemptionPerPass) ? "text-danger font-weight-bold" : "text-muted"}`}>{t(`SCAN.BENEFIT.PASS_LIMIT`)}: {benefit.unlimitedRedemption ? "UNLIMITED" : `${numRedeemedThisPass} / ${benefit.redemptionPerPass}`}</p>
                    {benefit.frequency !== "none" && <p className={`font-sm ${numRedeemedThisFrequency >= benefit.frequencyLimit ? "text-danger font-weight-bold" : "text-muted"}`}>{t(`SCAN.BENEFIT.FREQUENCY_LIMIT.${benefit.frequency}`)}: {numRedeemedThisFrequency} / {benefit.frequencyLimit}</p>}
                    <Button
                        className={`redeem-button ${redeemable ? "" : "bg-secondary border-0"} rounded-3 font-sm p-1 mt-1`}
                        onClick={onRedeem}
                        disabled={loading || !redeemable}
                    >{
                            loading
                                ?
                                <div className="spinner-border spinner-border-sm" role="status" />
                                :
                                redeemed
                                ?
                                "Redeem Again"
                                :
                                "Redeem"
                        }
                    </Button>
                </div>
            )
        }


        return (
            <div>
                <div className='d-flex border-top pt-2 mt-3'>
                    <img
                        src={merchant.logoUrl}
                        className="merchant-logo mr-2"
                        alt="Tier Image"
                    />
                    <p className="font-lg font-weight-bold">{merchant.name} {t("BENEFITS")}</p>
                </div>
                <div>
                    {
                        benefits.filter(benefit => benefit.merchantId === merchant.id)
                            .map(benefit =>
                                <Benefit key={benefit.id} benefit={benefit} passId={passId} />
                            )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="scan-result">
            <ScanHeader tier={tier} numScans={numScans} />
            <div>
                {
                    merchants.length == 0
                    ?
                    <p>{t("NO_BENEFITS")}</p>
                    :
                    merchants.map(merchant =>
                        <MerchantBenefits key={merchant.id} merchant={merchant} benefits={benefits} passId={passId} />
                    )
                }
            </div>
        </div>
    )
}

export default ValidResult