import Styles from "./styles/note.module.scss";
import { t } from "i18next";
const Note = () => {
  return (
    <div className={Styles.note}>
      <p>
      {t("CONTENT_NOTICE")}{" "}
        <a href="https://www.faro.xyz/terms" target="_blank" rel="noreferrer">
        {t("TERMS_OF_SERVICE")}
        </a>
        .
      </p>
    </div>
  );
};

export default Note;
