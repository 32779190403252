import axios from "axios";

import moment from "moment";
import constant from "../../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const getAllProgramList = async (params) => {
  const { limit = 10, offset = 0 } = params;
  let filterConditionString = "";
  for (const [key, value] of Object.entries(params.filterCondition)) {
    if (key === "storefront" || value) {
      filterConditionString += `&filterCondition[${key}]=${value}`;
    }
  }
  return axios
    .get(
      `${baseURL}programs?limit=${limit}&offset=${offset}${filterConditionString}`
    )
    .then((response) => response)
    .catch((err) => {
      return err.response?.data?.message ?? err.response;
    });
};

export const addProgram = async (data) => {
  return axios
    .post(`${baseURL}programs`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const updateProgram = async (data, id) => {
  return axios
    .put(`${baseURL}programs/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgram = async (id) => {
  return axios
    .delete(`${baseURL}programs/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const updateTier = async (data) => {
  try {
    let { programId, tierData } = data;
    tierData = {
      id: tierData.id,
      name: tierData.tierName,
      description: tierData.tierDescription,
      tierBenefits: tierData.passData?.tierBenefits,
      information: tierData.passData?.tierInformation,
      logoImageUrl: tierData.passData?.logoImage[0].resourceUrl,
      iconImageUrl: tierData.passData?.iconImage[0].resourceUrl,
      appleLogoImageUrl: tierData.passData?.applelogoimage[0].resourceUrl,
      stripImageUrl: tierData.passData?.stripImage[0].resourceUrl,
      heroImageUrl: tierData.passData?.heroimage[0]?.resourceUrl,
      companyName: tierData.passData?.companyName,
      programName: tierData.passData?.programName,
      passColors: {
        labelColor: tierData.passData?.labelsColor,
        textColor: tierData.passData?.textColor,
        stripColor: tierData.passData?.passColor,
      },
      defaultLocaleId: tierData.passData?.defaultLocaleId,
      locales: tierData.passData?.locales?.map((item) => {
        return {
          name: item.name,
          description: item.description,
          tierBenefits: item.tierBenefits,
          information: item.information,
          localeId: item.localeId,
        };
      }),
      appleLogoSize: tierData.passData?.appleLogoSize,
      locations: tierData.passData?.locations?.map((item) => {
        return {
          lat: item.latitude,
          lon: item.longitude,
          lockScreenMessage: item.lockScreenMessage,
          name: item.name,
        };
      }),
      ...tierData,
      expiryDate: moment(tierData.expiryDate, "DD/MM/YYYY").toDate(),
    };

    const config = { headers: { "content-type": "application/json" } };
    const createResponse = await axios.put(
      `${baseURL}programs/${programId}/tiers/${tierData.id}`,
      tierData,
      config
    );
    if (createResponse.status !== 200) {
      throw new Error(createResponse.data.message);
    }

    return createResponse.data;
  } catch (error) {
    return error.response;
  }
};

export const createTier = async (tierData) => {
  try {
    const payload = {
      name: tierData.tierName,
      description: tierData.tierDescription,
      tierPointThreshold: tierData.tierPointThreshold
    };

    const config = { headers: { "content-type": "application/json" } };
    const createResponse = await axios.post(
      `${baseURL}programs/${tierData.programId}/tiers`,
      payload,
      config
    );
    if (createResponse.status !== 200) {
      throw new Error(createResponse.data.message);
    }

    return createResponse.data;
  } catch (error) {
    return error.response;
  }
};

export const getPublicAppSettings = async () => {
  try {
    const response = await axios.get(`${baseURL}profiles/app-settings`);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

export const attachBenefitToTier = async (data, programId, tierId) => {
  return axios
    .put(`${baseURL}programs/${programId}/tiers/${tierId}/benefit`, data)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Error while fetching connected items for tier: \n\n${err?.response?.data?.message}`
      );
      return err.response;
    });
};

export const detachBenefitFromTier = async (data, programId, tierId) => {
  return axios
    .post(`${baseURL}programs/${programId}/tiers/${tierId}/benefit`, {
      ...data,
    })
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Error while fetching connected items for tier: \n\n${err?.response?.data?.message}`
      );
      return err.response;
    });
};

export const getProgram = async (id) => {
  return axios
    .get(`${baseURL}programs/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getProgramMetabaseUrls = async (id) => {
  return axios
    .get(`${baseURL}programs/${id}/metabase-urls`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getAllTiers = async (params) => {
  try {
    const { limit, offset } = params.filters;
    let filterConditionString = "";
    for (const [key, value] of Object.entries(
      params.filters.filterCondition || []
    )) {
      if (value) {
        filterConditionString += `&filterCondition[${key}]=${value}`;
      }
    }
    const response = await axios.get(
      `${baseURL}programs/${params.programId}/tiers?limit=${limit}&offset=${offset}${filterConditionString}`
    );
    return response;
  } catch (err) {
    console.log("Something went wrong", err);
    return err.response.data;
  }
};

export const connectTierToCollectionOrNFt = async (data) => {
  try {
    const response = await axios.put(
      `${baseURL}programs/${data.programId}/tiers/${data.tierId}/connect`,
      { ...data.body }
    );
    return response;
  } catch (err) {
    console.log("err", err);
    return err.response.data;
  }
};

export const getItemsConnectedToTier = async (programId, tierId) => {
  return axios
    .get(`${baseURL}programs/${programId}/tiers/${tierId}/connected-resources/`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Error while fetching connected items for tier: \n\n${err?.response?.data?.message}`
      );
      return err.response;
    });
};

export const getStatisticsForTier = async (programId, tierId) => {
  return axios
    .get(`${baseURL}programs/${programId}/tiers/${tierId}/stats`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Error while fetching statistics for tier: \n\n${err?.response?.data?.message}`
      );
      return err.response;
    });
};

export const createPushNotification = async (programId, tierId, data) => {
  return axios
    .post(
      `${baseURL}programs/${programId}/tiers/${tierId}/push-notifications`,
      { ...data }
    )
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Error while creating push notification: \n\n${err?.response?.data?.message}`
      );
      return err.response;
    });
};
export const getAllNotifications = async (programId, tierId, data) => {
  return axios
    .post(
      `${baseURL}programs/${programId}/tiers/${tierId}/push-notifications/all`,
      { ...data }
    )
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Error while fetching push notification: \n\n${err?.response?.data?.message}`
      );
      return err.response;
    });
};

export const deletePushNotification = async (
  programId,
  tierId,
  notificationId
) => {
  return axios
    .delete(
      `${baseURL}programs/${programId}/tiers/${tierId}/push-notifications/${notificationId}`
    )
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Error while fetching push notification: \n\n${err?.response?.data?.message}`
      );
      return err.response;
    });
};

export const addWayToEarn = async (data) => {
  return axios
    .post(`${baseURL}pointreward`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const editWayToEarn = async (id, data) => {
  return axios
    .put(`${baseURL}pointreward/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const deleteWayToEarn = async (id) => {
  return axios
    .delete(`${baseURL}pointreward/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getAllPointRewardsFromDb = async (filters) => {
  return axios
    .post(`${baseURL}pointreward/all`, filters)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
