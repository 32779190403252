import { Fragment } from "react";
import {
  Form,
  Row,
  Col,
  Radio,
  Input,
  Select,
  Popconfirm,
  Divider,
  InputNumber
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {
  FormWrapper,
  Micons,
  Button,
  Modal,
} from "../../../../components/customAntd";

const { Item } = Form;

const WaysToEarnPoints = ({
  t,
  watchEnablePointsTable,
  setIsPointsTableModalOpen,
}) => {

  return (
    <FormWrapper heading={t("STOREFRONT_UI.WAYS_TO_EARN_POINTS")}>
      <Item
        label={`${t("STOREFRONT_UI.SHOW_POINTS_TABLE")}:`}
        name="enablePointsTable"
        rules={[
          {
            required: true,
            message: t("STOREFRONT_UI.SHOW_POINTS_TABLE_ERROR"),
          },
        ]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value={false}>
                {t("NO")}
              </Radio>
            </Col>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value={true}>
                {t("YES")}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Item>
      {watchEnablePointsTable && (
        <>
          <Item
            label={`${t("STOREFRONT_UI.HEADLINE")} :`}
            name="pointsTableHeaderText"
          >
            <Input
              placeholder={t("STOREFRONT_UI.POINTS_HEADLINE_PLACEHOLDER")}
            />
          </Item>
          <Item
            label={`${t("STOREFRONT_UI.CAPTION")} :`}
            name="pointsTableBodyText"
          >
            <MDEditor
              overflow={false}
              placeholder={t("STOREFRONT_UI.CAPTION_PLACEHOLDER")}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
            />
            {/* <TextArea
        placeholder={t("STOREFRONT_UI.CAPTION_PLACEHOLDER")}
        rows={3}
      /> */}
          </Item>
          <Item>
            <Button isFullWidth onClick={() => setIsPointsTableModalOpen(true)}>
              <Micons icon="edit" style={{ fontSize: 16 }} isHover={false} />
              {t("STOREFRONT_UI.EDIT_POINTS_TABLE")}
            </Button>
          </Item>
        </>
      )}
    </FormWrapper>
  );
};

export const WaysToEarnPointsModal = ({
  t,
  isPointsTableModalOpen,
  onCancel,
  onSubmit,
  pointsForm,
  handlePointsDragEnd,
  pointsMarketingData,
  watchLocalesPoints,
  watchPointsMarketingData,
  availableLocalesPoints,
  setAvailableLocalesPoints,
  updateLocales,

}) => {
  return (
    <Modal
      centered
      open={isPointsTableModalOpen}
      title={t("STOREFRONT_UI.POINTS_TABLE")}
      width={976}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      onCancel={onCancel}
      footer={[
        <Button
          buttonType="cancel"
          key="cancel-button"
          onClick={onCancel}
        >
          {t("CANCEL")}
        </Button>,
        <Button
          key="create-button"
          onClick={() => {
            pointsForm.submit();
          }}
        >
          {t("STOREFRONT_UI.SAVE_AND_ADD")}
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={pointsForm}
        onFinish={onSubmit}
        initialValues={{ pointsData: [{}] }}
      >
        <DragDropContext onDragEnd={handlePointsDragEnd}>
          <Row gutter={[20, 20]} style={{ marginBottom: 10 }}>
            <Col lg={1}></Col>
            <Col lg={12}>{`${t("STOREFRONT_UI.HOW_TO_EARN")}:`}</Col>
            <Col lg={11}>{`${t("STOREFRONT_UI.NUMBER_OF_POINTS")}:`}</Col>
          </Row>
          <Droppable droppableId="droppable-1">
            {(provided) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Form.List name="pointsData">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name }, index) => (
                          <Draggable
                            draggableId={`${key}`}
                            index={index}
                            key={`${key}`}
                          >
                            {(provided) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <Row gutter={[20]}>
                                    <Col lg={1}>
                                      <Micons
                                        icon="drag_handle"
                                        isHover={false}
                                        style={{
                                          fontSize: 24,
                                          color: "var(--color-drag-icon)",
                                        }}
                                        wrapperStyle={{
                                          marginRight: 10,
                                          marginTop: 5,
                                        }}
                                        {...provided.dragHandleProps}
                                      />
                                    </Col>
                                    <Col lg={12}>
                                      <Item
                                        name={[name, "wayToEarn"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: t(
                                              "STOREFRONT_UI.WAY_TO_EARN_VALIDATION"
                                            ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder={t(
                                            "STOREFRONT_UI.WAY_TO_EARN_PLACEHOLDER"
                                          )}
                                        />
                                      </Item>
                                    </Col>
                                    <Col lg={11}>
                                      <Row gutter={[20, 10]} align="start">
                                        <Col lg={20}>
                                          <Item
                                            name={[name, "pointValue"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: t(
                                                  "STOREFRONT_UI.ENTER_EARNABLE_POINTS"
                                                ),
                                              },
                                            ]}
                                          >
                                            <InputNumber
                                              placeholder={0}
                                              addonAfter={t(
                                                "STOREFRONT_UI.TEMPLATE.POINTS"
                                              )}
                                            />
                                          </Item>
                                        </Col>
                                        {fields.length > 1 && (
                                          <Col lg={3}>
                                            <Popconfirm
                                              title={t(
                                                "STOREFRONT_UI.WAY_TO_EARN_DELETE_CONFIRMATION"
                                              )}
                                              onConfirm={() => {
                                                /*Deleting the specific point description in 
                                              language options for points*/
                                                pointsForm.setFieldValue(
                                                  "pointLocales",
                                                  watchLocalesPoints?.map(
                                                    (localeEl) => {
                                                      const pointsArray = [
                                                        ...localeEl?.waysToEarn,
                                                      ];
                                                      pointsArray.splice(
                                                        index,
                                                        1
                                                      );
                                                      return {
                                                        ...localeEl,
                                                        waysToEarn: pointsArray,
                                                      };
                                                    }
                                                  )
                                                );
                                                remove(name);
                                              }}
                                              onCancel={() => {}}
                                              okText={t("YES")}
                                              cancelText={t("NO")}
                                            >
                                              <DeleteOutlined
                                                className="deleteIcon"
                                                style={{
                                                  fontSize: 16,
                                                  color: "red",
                                                  height: 35,
                                                }}
                                              />
                                            </Popconfirm>
                                          </Col>
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <Button
                          type="link"
                          icon={<PlusOutlined />}
                          style={{
                            color: "var(--color-brand-primary)",
                            marginBottom: 20,
                          }}
                          onClick={() => {
                            pointsForm.setFieldValue(
                              // Adding an empty field to the points language options
                              "pointLocales",
                              watchLocalesPoints?.map((localeEl) => {
                                return {
                                  ...localeEl,
                                  waysToEarn: [...localeEl?.waysToEarn, {}],
                                };
                              })
                            );
                            add();
                          }}
                        >
                          {t("STOREFRONT_UI.ADD_WAY_TO_EARN")}
                        </Button>
                      </>
                    )}
                  </Form.List>
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        <FormWrapper
          heading={t("LANGUAGE_OPTIONS.HEADING")}
          description={t("STOREFRONT_UI.LANGUAGE_OPTIONS.DESC")}
        >
          <Form.List name="pointLocales">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }, index) => (
                  <Fragment key={key}>
                    <Row gutter={[13]}>
                      <Col lg={8}>
                        <Item
                          label={`${t("LANGUAGE")} :`}
                          name={[name, "localeId"]}
                          rules={[
                            {
                              required: true,
                              message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                            },
                          ]}
                        >
                          <Select
                            options={availableLocalesPoints.map((localEl) => ({
                              ...localEl,
                              disabled: localEl.isSelected,
                            }))}
                            onChange={(value) => {
                              updateLocales(
                                value,
                                watchLocalesPoints[index].localeId,
                                "points"
                              );
                            }}
                            placeholder={t("SELECT")}
                          />
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Form.List name={[name, "waysToEarn"]}>
                          {(fields) =>
                            fields.map(({ key, name }, index) => (
                              <Fragment key={key}>
                                <Item
                                  name={[name, "wayToEarn"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        "STOREFRONT_UI.WAY_TO_EARN_VALIDATION"
                                      ),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={`${
                                      pointsMarketingData[index]?.wayToEarn ||
                                      t("STOREFRONT_UI.TRANSLATED_WAY_TO_EARN")
                                    }`}
                                    /* The placeholder for points in language options shows the corresponding
                                point description in default language or the default string. */
                                  />
                                </Item>
                              </Fragment>
                            ))
                          }
                        </Form.List>
                      </Col>
                    </Row>

                    <Popconfirm
                      title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                      onConfirm={() => {
                        setAvailableLocalesPoints((prev) => {
                          return prev.map((item) =>
                            item.value === watchLocalesPoints[index].localeId
                              ? {
                                  ...item,
                                  isSelected: false,
                                }
                              : item
                          );
                        });
                        remove(name);
                      }}
                      onCancel={() => {}}
                      okText={t("YES")}
                      cancelText={t("NO")}
                    >
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        style={{
                          color: "var(--color-form-error)",
                          placeContent: "flex-end",
                          marginTop: -20,
                          float: "right",
                        }}
                      >
                        {t("LANGUAGE_OPTIONS.DELETE_LOCALE")}
                      </Button>
                    </Popconfirm>
                    {fields.length !== 0 && (
                      <Divider
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          borderColor: "#D9D9D9",
                        }}
                      />
                    )}
                  </Fragment>
                ))}

                <Item style={{ marginBottom: 0 }}>
                  <Button
                    type="link"
                    onClick={
                      fields.length >= 3
                        ? () => {}
                        : () =>
                            add({
                              // Adding as many input fields as there are point desriptions to each language option
                              waysToEarn: watchPointsMarketingData?.map(
                                () => ({})
                              ),
                            })
                    }
                    icon={<PlusOutlined />}
                    style={{ color: "var(--color-brand-primary)" }}
                    disabled={fields.length >= 3}
                  >
                    {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
                  </Button>
                </Item>
              </>
            )}
          </Form.List>
        </FormWrapper>
      </Form>
    </Modal>
  );
};

export default WaysToEarnPoints;
