import React from 'react'
import { useDispatch } from 'react-redux';
import { useAppContext } from '../context/app.context';
import './index.css'
import { Form } from "react-bootstrap";

export const CurrentRole = ({
}) => {

    const { currentUserRole, setCurrentUserRole, userRoles } = useAppContext()
    const dispatch = useDispatch()

    const updateCurrentRole = (roleId) => {
        setCurrentUserRole(userRoles.find(role => role.id === roleId))
    }

    return (
        <div className="d-flex flex-row align-items-center">
            <p className="font-weight-bold mr-2">My Current Role -</p>
            <div>
                <Form.Group className="mr-3">
                    <Form.Select
                        className="mt-2"
                        onChange={(e) => {
                            updateCurrentRole(e.target.value)
                        }}
                        value={currentUserRole?.id}
                    >
                        {
                            userRoles.map(role => <option value={role.id} key={role.id}>{role.name}</option>)
                        }
                    </Form.Select>
                </Form.Group>
            </div>
        </div>
    )
}