import { useState, useEffect } from "react";
import { Checkbox, Form } from "antd";

import { Input, Button } from "../../components/form";
import LandingHeader from "../../components/headers/landingheadernew";
import LandingFooter from "../../components/footer/landingfooter";
import { runGradient } from "../../gradient";
import { useAppContext } from "../../components/context/app.context";
import { userLogin } from "./apiUtil";
import { getBrowserInfo, getOSInfo } from "../../utils/utils";

import OtpModal from "./otpModal";
import "./styles/landingpagenew.css";

const LandingPage = () => {
  const [form] = Form.useForm();
  const { loadCurrentUser } = useAppContext();
  const [canvasHeight, setCanvasHeight] = useState("100vh");
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [openModal, setOpenModal] = useState();
  const [otpError, setOtpError] = useState();
  const [checkingOtp, setCheckingOtp] = useState();
  const [isValidOtp, setIsValidOtp] = useState(false);
  const watchRemember = Form.useWatch("remember", form);
  const watchEmail = Form.useWatch("email", form);

  useEffect(() => {
    runGradient();
  }, []);

  useEffect(() => {
    if (loginError) {
      form.validateFields();
    }
  }, [loginError, form]);

  const handleSubmit = async (data) => {
    setIsLoading(true);
    try {
      const finalData = {
        email: data.email,
        remember: watchRemember ?? false,
        browser: getBrowserInfo(),
        os: getOSInfo(),
      };
      const logiinUser = await userLogin(finalData);
      if (logiinUser?.data?.message) {
        setLoginError({
          message: logiinUser.data.message,
          status: logiinUser.status,
        });
      } else {
        setOpenModal(true);
        if (otpError) {
          setOtpError(null);
        }
      }
    } catch (err) {
      setLoginError({
        message: err?.data?.message,
        status: err?.status,
      });
    }
    setIsLoading(false);
  };

  const handleOtpSubmit = async (data) => {
    setCheckingOtp(true);
    try {
      const finalData = {
        otp: data,
        email: watchEmail,
      };
      const resp = await userLogin(finalData);
      if (resp?.data?.token) {
        localStorage.setItem("authorization", "Bearer " + resp.data.token);
        setIsValidOtp(true);
        loadCurrentUser();
      }
    } catch (err) {
      if (err?.data?.error_code) {
        setOtpError(err.data);
      }
    } finally {
      setCheckingOtp(false);
    }
  };

  const resetError = () => {
    if (loginError) {
      setLoginError(null);
    }
  };

  const onCancel = () => {
    setOpenModal(false);
    setIsValidOtp(null);
    setOtpError(null);
  };

  return (
    <>
      <OtpModal
        isOpen={openModal}
        email={watchEmail}
        onCancel={onCancel}
        handleOtpSubmit={handleOtpSubmit}
        otpError={otpError}
        checkingOtp={checkingOtp}
        setOtpError={setOtpError}
        isValidOtp={isValidOtp}
      />
      <canvas id="gradient-canvas" style={{ height: canvasHeight }}></canvas>

      <div className="landing-wrapper d-flex flex-column ">
        <LandingHeader />
        <div className="user-control-wrapper d-flex justify-content-center align-items-center">
          <div className="user-control p-5">
            <>
              <h1>Welcome to EngageAI!</h1>
              <p className="signup-text mb-3">Sign in to continue</p>
              <Form form={form} className="form-login" onFinish={handleSubmit}>
                <Input
                  rootClassName="form-login-email-field"
                  placeholder="Enter your email address"
                  name="email"
                  className="login-input"
                  onChange={resetError}
                  {...(loginError && {
                    validateStatus:
                      loginError.status === 200 ? "success" : "error",
                  })}
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid email!",
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      validator: () => {
                        if (loginError?.message) {
                          return Promise.reject(loginError.message);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                />
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{ offset: 0, span: 24 }}
                  style={{ flex: "inherit" }}
                >
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
                <Button
                  htmlType="submit"
                  style={{ width: "100%" }}
                  onClick={resetError}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Continue"}
                </Button>
              </Form>
              <p className="mt-3">
                By signing up, you agree to <br />
                <a
                  href="https://www.threecore.ai/en/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="https://www.threecore.ai/en/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </>
          </div>
        </div>

        <LandingFooter setCanvasHeight={setCanvasHeight} />
      </div>
    </>
  );
};

export default LandingPage;
