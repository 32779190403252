import ImgCrop from "antd-img-crop";
import { message, Upload } from "antd";
import { useState } from "react";
import { updateUserData } from "../../pages/profile/apiUtils";
import { imageFileSizes } from "../../utils/utils";
import { useAppContext } from "../context/app.context";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";

const LoadingSpinner = () => {
  return (
    <div className="spinner-border faro-purple" role="status">
      <span className="sr-only">{`${t("LOADING")}...`}</span>
    </div>
  );
};

const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const showErrorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const ProfileImage = ({ setErrorList, errorList }) => {
  const dispatch = useDispatch();
  const { currentUserData, setCurrentUserData } = useAppContext();
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(
        `${t("FILE_UPLOAD.ONLY")} JPG, JPEG, PNG, GIF ${t(
          "FILE_UPLOAD.EXTENSIONS_ALLOWED"
        )}`
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error(
        `${t("FILE_UPLOAD.SIZE_ERROR")} ${imageFileSizes["profileImage"]}${t(
          "MB"
        )}.`
      );
    }
    return isJpgOrPng && isLt2M;
  };

  const handleImageUpdate = async ({ file, onSuccess }) => {
    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
      showErrorToast(
        `${t("FILE_UPLOAD.ONLY")} JPG, JPEG, PNG, GIF ${t(
          "FILE_UPLOAD.EXTENSIONS_ALLOWED"
        )}`
      );
    } else if (file.size / 1024 / 1024 > imageFileSizes["profileImage"]) {
      showErrorToast(
        `${t("FILE_UPLOAD.SIZE_ERROR")} ${imageFileSizes["profileImage"]}${t(
          "MB"
        )}.`
      );
    } else {
      setLoading(true);
      const data = {
        id: currentUserData.id,
        email: currentUserData.email,
        fileFor: "profile",
        imageToUpdate: "profileImage",
        isUploadIpfs: false,
        image: file,
      };

      const response = await updateUserData(dispatch, data);
      if (!response?.hasError) {
        showSuccessToast(t("PROFILE.IMAGE_UPDATE_SUCCESS"));
        setCurrentUserData((prev) => ({
          ...prev,
          profileImage: response.profileImage,
        }));
        onSuccess("ok");
      } else {
        showErrorToast(t("PROFILE.IMAGE_UPDATE_ERROR"));
        setErrorList({
          errorList,
          appsettingError: {
            message: response.message ?? t("PROFILE.IMAGE_UPDATE_ERROR"),
          },
        });
      }
      setLoading(false);
    }
  };

  return (
    <div className="profile-image-container">
      {loading ? (
        <div className="avatar d-flex justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      ) : currentUserData.profileImage ? (
        <img
          src={currentUserData.profileImage}
          alt="avatar"
          className="avatar"
        />
      ) : (
        <div className="avatar default-background" />
      )}
      <ImgCrop
        modalCancel={t("CANCEL")}
        modalOk={t("OK")}
        modalTitle={t("PROFILE.EDIT_IMAGE")}
      >
        <Upload
          name="avatar"
          showUploadList={false}
          customRequest={handleImageUpdate}
          beforeUpload={beforeUpload}
          className="edit mt-2"
        >
          <p>{t("EDIT")}</p>
        </Upload>
      </ImgCrop>
    </div>
  );
};
export default ProfileImage;
