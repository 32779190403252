
import axios from "axios";

import constant from "../../../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const getTierDetails = async (data) => {

    return axios
        .get(`${baseURL}programs/${data.programId}/tiers/${data.tierId}`, data)
        .then((response) => response)
        .catch((err) => {
            console.log(
                `Error while fetching connected items for tier: \n\n${err?.response?.data?.message}`
            );
            return err.response;
        });
} 