import { useEffect, useState } from "react";
import { Tooltip } from "antd";
import Icon from "@ant-design/icons";
import ReactPlayer from "react-player";

import { ReactComponent as MusicNoteIcon } from "../../assets/images/music-note-icon.svg";
import { Modal } from "../customAntd";

import Styles from "./styles/playlist.module.scss";

const MediaPlayer = ({
  isOpen,
  width,
  mediaFiles,
  isAudio,
  onCancel,
  isDownloadable,
  ...props
}) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [videoTitle, setVideoTitle] = useState("");

  useEffect(() => {
    if (mediaFiles && mediaFiles.length !== 0) {
      setVideoUrl(mediaFiles[0]?.resourceUrl);
      setVideoTitle(mediaFiles[0]?.name);
    }
  }, [mediaFiles]);

  const handleOnCancel = () => {
    onCancel();
  };

  const handlePlayListClick = (link, name) => {
    setVideoTitle(name);
    setVideoUrl(link);
  };

  return (
    <Modal
      title={videoTitle.substring(0, videoTitle.lastIndexOf("."))}
      open={isOpen}
      centered
      width={width || 1200}
      onCancel={handleOnCancel}
      destroyOnClose
      footer={false}
      headerStyle={{ marginBottom: 100 }}
      className={Styles.modal}
      {...props}
    >
      <ReactPlayer
        url={videoUrl}
        controls
        width="calc(100% - -40px)"
        height={isAudio ? 150 : 550}
        config={{
          file: {
            attributes: {
              controlsList: `nooptions, ${isDownloadable ? "" : "nodownload"}`,
            },
          },
        }}
        style={{
          marginLeft: -20,
          marginRight: -20,
          paddingBottom: 5,
          paddingTop: 5,
        }}
      />

      {mediaFiles?.length > 1 && (
        <div
          className={`${Styles.playlist}${isAudio ? ` ${Styles.audio}` : ""}`}
        >
          {mediaFiles.map((item) => (
            <Tooltip title={item.name}>
              <button
                type="button"
                onClick={() => handlePlayListClick(item.resourceUrl, item.name)}
              >
                {item.type === "audio" ? (
                  <Icon component={MusicNoteIcon} style={{ fontSize: 16 }} />
                ) : (
                  <video
                    src={item.resourceUrl}
                    controls={false}
                    width="100%"
                    height="100%"
                  />
                )}
              </button>
            </Tooltip>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default MediaPlayer;
