import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useProfileContext } from '../context/profile.context';
import './index.css'
import { Modal, Button } from 'react-bootstrap';
import { deleteRole } from '../../redux/role.slice';
import { removeRoleByIdBFS } from '../../utils/rolesHelper';

export const DeleteRoleModal = ({
    show,
    hide,
    roleToDelete
}) => {

    const { selectedRole, setSelectedRole, setRoleTree } = useProfileContext()
    const dispatch = useDispatch()
    const [deleting, setDeleting] = useState(false)

    const onDelete = () => {
        if (roleToDelete.id !== "new") {
            dispatch(deleteRole(roleToDelete)).then(_ => {
                setDeleting(false)
                setRoleTree(prev => {
                    const updated = removeRoleByIdBFS(roleToDelete.id, JSON.parse(JSON.stringify(prev)))
                    return updated ? updated : prev
                })
            })
        }
        if (selectedRole && selectedRole.id === roleToDelete.id) {
            setSelectedRole(null)
        }
        hide()
    }

    return (
        <Modal centered backdrop={true} show={show} onHide={hide} className="modalStyle">
            <Modal.Header closeButton>
                <Modal.Title className="text-black">
                    Delete "{roleToDelete ? roleToDelete.name : ""}"
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-black">
                Are you sure you want to delete this role?
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-start">
                <Button variant="secondary" className="btnSecondary" onClick={hide}>Cancel</Button>
                <Button
                    className="btnPrimary"
                    type="submit"
                    onClick={onDelete}
                    disabled={deleting}
                >
                    {deleting ? "..." : "Yes, Delete"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
