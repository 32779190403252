import { Row, Col } from "antd";
import { t } from "i18next";

import discountIcon from "../../../assets/images/discount-icon.png";
import gatedVideoIcon from "../../../assets/images/gated-video-icon.png";
import gatedAudioIcon from "../../../assets/images/gated-audio-icon.png";
import digitalBook from "../../../assets/images/digital-book.png";
import survey from "../../../assets/images/survey-benefit.png";
import sweepstakesIcon from "../../../assets/images/sweepstakes-icon.png";

import { BREADCRUMB_BENEFITS } from "../../../utils/benefits/constant";
import { BenefitsCard, PageHeader } from "../../../components/customAntd";

const Benefits = () => {
  return (
    <>
      <PageHeader title={t("BENEFITS")} breadcrumbs={BREADCRUMB_BENEFITS(t)} />
      <Row gutter={[40, 40]}>
        <Col xs={24} md={8}>
          <BenefitsCard
            image={discountIcon}
            title={t("BENEFIT_TYPES.DISCOUNT_CODES.TITLE")}
            description={t("BENEFIT_TYPES.DISCOUNT_CODES.DESC")}
            link="/benefits/discount-codes"
          />
        </Col>
        <Col xs={24} md={8}>
          <BenefitsCard
            image={sweepstakesIcon}
            title={t("BENEFIT_TYPES.SWEEPSTAKES.TITLE")}
            description={t("BENEFIT_TYPES.SWEEPSTAKES.DESC")}
            link="/benefits/sweepstakes"
          />
        </Col>
        <Col xs={24} md={8}>
          <BenefitsCard
            image={gatedVideoIcon}
            title={t("BENEFIT_TYPES.VIDEO.TITLE")}
            description={t("BENEFIT_TYPES.VIDEO.DESC")}
            link="/benefits/gated-video"
          />
        </Col>
        {/* <Col xs={24} md={8}>
          <BenefitsCard
            image={gatedVideoIcon}
            title={t("BENEFIT_TYPES.STREAMS.TITLE")}
            description={t("BENEFIT_TYPES.STREAMS.DESC")}
            link="/benefits/gated-streams"
          />
        </Col> */}
        <Col xs={24} md={8}>
          <BenefitsCard
            image={gatedAudioIcon}
            title={t("BENEFIT_TYPES.AUDIO.TITLE")}
            description={t("BENEFIT_TYPES.AUDIO.DESC")}
            link="/benefits/gated-audio"
          />
        </Col>
        <Col xs={24} md={8}>
          <BenefitsCard
            image={digitalBook}
            title={t("BENEFIT_TYPES.DIGITAL_BOOK.TITLE")}
            description={t("BENEFIT_TYPES.DIGITAL_BOOK.DESC")}
            link="/benefits/gated-ebook"
          />
        </Col>
        <Col xs={24} md={8}>
          <BenefitsCard
            image={survey}
            title={t("BENEFIT_TYPES.SURVEY.TITLE")}
            description={t("BENEFIT_TYPES.SURVEY.DESC")}
            link="/benefits/survey"
          />
        </Col>
      </Row>
    </>
  );
};

export default Benefits;
