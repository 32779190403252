import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Image, message, Row, Col, Space, Tabs, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";

import { BREADCRUMB_TIER } from "../../../../utils/programs/constant";
import { getTranslatedBenefitType } from "../../../../utils/utils";
import { getTierDetails } from "./apiUtils";
import {
  attachBenefitToTier,
  detachBenefitFromTier,
  updateProgram,
} from "../apiUtils";
import { useAppContext } from "../../../../components/context/app.context";

// import custom components
import {
  Button,
  Card,
  Micons,
  PageHeader,
  Tag,
} from "../../../../components/customAntd";
import AddBenefitsModal from "../../../../components/addBenefitsModal";
import ReadMore from "../../../../components/readmore";
import MediaPlayer from "../../../../components/mediaPlayer";
import EpubReader from "../../../../components/epubReader";
import { NoData } from "../../../../components/customAntd";
import PushNotifications from "./pushNotifications";
import Statistics from "./statistics";
//Import styles
import Styles from "./styles/tierBenefits.module.scss";

const { Meta } = Card;

const TierDetails = () => {
  const { t } = useTranslation();
  const { currentLocale } = useAppContext();
  const { programId, id } = useParams();
  const [tier, setTier] = useState();
  const [benefits, setBenefits] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAttachBenefitLoading, setIsAttachBenefitLoading] = useState(false);
  const [openMediaPlayer, setOpenMediaPlayer] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [media, setMedia] = useState([]);
  const [isDownloadable, setIsDownloadable] = useState(false);
  const [epubFile, setEpubFile] = useState("");
  const [isEpubOpen, setIsEpubOpen] = useState(false);
  const [epubTitle, setEpubTitle] = useState("");
  const [currentLocaleDetail, setCurrentLocaleDetail] = useState(null);
  const isTierLive = tier?.program?.status === "Live";

  useEffect(() => {
    const fetchTier = async () => {
      try {
        const response = await getTierDetails({
          tierId: id,
          programId: programId,
        });
        if (response.status === 200) {
          setTier(response.data);
          setBenefits(response.data.benefitsTier);
        } else {
          throw new Error(
            "Error while fetching tier details , please try again"
          );
        }
      } catch (err) {
        message.error(err);
      }
    };
    fetchTier();
  }, [id, programId]);

  useEffect(() => {
    if (tier) {
      setCurrentLocaleDetail(
        tier.locales.find((item) => item.localeId === currentLocale)
      );
    }
  }, [tier, currentLocale]);

  const handleAttachBenefitSubmit = async (data) => {
    setIsAttachBenefitLoading(true);
    const response = await attachBenefitToTier(
      { benefits: data },
      programId,
      id
    );
    if (response?.status === 200) {
      setIsModalOpen(false);
      setBenefits(response.data);
    }
    setIsAttachBenefitLoading(false);
  };

  const handleBenefitDelete = async (benefitId) => {
    const response = await detachBenefitFromTier(
      { benefits: [benefitId] },
      programId,
      id
    );
    if (response?.status === 200) {
      setBenefits((prev) => {
        return prev.filter((item) => item.benefit.id !== benefitId);
      });
    }
  };

  const renderDate = (createdDate) => {
    const now = dayjs(dayjs().format("MM/DD/YYYY")); //To get today's start of day i.e. MM/DD/YYYY 00:00:00
    const compareDate = dayjs(
      dayjs(createdDate).add(2, "day").format("MM/DD/YYYY")
    ); // To get createdDate's start of day i.e. MM/DD/YYYY 00:00:00
    const diff = -now.diff(compareDate, "d"); // To get difference in days

    if (compareDate.isAfter(now)) {
      return `Expires in ${diff} days`;
    } else if (compareDate.isBefore(now)) {
      return "Expired";
    } else if (compareDate.isSame(now)) {
      return "Expiring today";
    }
  };

  const handleActivePass = async () => {
    const response = await updateProgram(
      {
        status: "Live",
      },
      programId
    );
    if (response.status === 200) {
      setTier((prev) => {
        return {
          ...prev,
          program: {
            status: response.data.status,
          },
        };
      });
    }
  };

  return (
    <>
      <PageHeader
        breadcrumbs={BREADCRUMB_TIER(t, [
          {
            title: tier?.program?.name,
            link: `/programs/${tier?.programId}`,
          },
          {
            title: currentLocaleDetail?.name ?? tier?.name,
          },
        ])}
        title={
          <>
            <Space align="center" size={30} className={Styles.heading}>
              <Image
                src={tier?.stripImageUrl}
                preview={false}
                width={123}
                height={97}
              />
              <div>
                <h1>{currentLocaleDetail?.name ?? tier?.name}</h1>
                <p>{currentLocaleDetail?.description ?? tier?.description}</p>
              </div>
            </Space>
          </>
        }
        headStyle={{ marginTop: 30, marginBottom: 20, alignItems: "start" }}
        extra={
          <Space size={25}>
            <Button
              type="link"
              className={Styles["action-button"]}
              onClick={() => setIsModalOpen(true)}
            >
              <Micons icon="loyalty" style={{ fontSize: 15 }} />
              {t("PROGRAM_UI.ASSIGN_BENEFIT")}
            </Button>
            <Button type="link" className={Styles["action-button"]}>
              <Micons icon="edit" style={{ fontSize: 15 }} />
              {t("PROGRAM_UI.EDIT_TIERS")}
            </Button>
          </Space>
        }
      />

      <Tabs
        defaultActiveKey="1"
        tabBarGutter={47}
        animated={{ inkBar: true, tabPane: true }}
        className="tabs"
        items={[
          {
            label: (
              <div className="tab-label">
                <Micons icon="loyalty" style={{ fontSize: 18 }} />
                {t("BENEFITS")}
              </div>
            ),
            key: 1,
            children: (
              <>
                <Row gutter={[21, 21]}>
                  {benefits.length === 0 && <NoData />}
                  {benefits?.map((item) => {
                    const currentLocaleData = item?.benefit?.locales?.filter(
                      (item) => item.localeId === currentLocale
                    )[0];
                    const { label, color } = getTranslatedBenefitType(
                      item.benefit.type,
                      t
                    );
                    return (
                      <Col lg={12} xl={6}>
                        <Card
                          cover={
                            <>
                              <Image
                                src={
                                  (currentLocaleData?.previewResourceUrl ??
                                    item?.benefit?.previewResourceUrl) ||
                                  item?.benefit?.merchant?.heroImageUrl
                                }
                                width="100%"
                                height={220}
                                preview={false}
                                style={{ objectFit: "cover" }}
                              />
                              {item.benefit.merchant && (
                                <Image
                                  src={item.benefit.merchant.logoUrl}
                                  width={50}
                                  height={50}
                                  preview={false}
                                  rootClassName={Styles["merchant-logo"]}
                                />
                              )}
                            </>
                          }
                          actions={[
                            <Popconfirm
                              title={t(
                                "PROGRAM_UI.ASSIGN_BENEFITS_PAGE.DELETE_CONFIRMATION"
                              )}
                              onConfirm={(e) => {
                                e.stopPropagation();
                                handleBenefitDelete(item.benefit.id);
                              }}
                              okText={t("YES")}
                              cancelText={t("NO")}
                            >
                              <Button
                                type="link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {t("DELETE")}
                              </Button>
                            </Popconfirm>,
                          ]}
                          onClick={
                            !["coupon", "sweepstakes", "survey"].includes(
                              item?.benefit?.type
                            )
                              ? () => {
                                  setIsDownloadable(item.benefit.allowDownload);
                                  if (item.benefit.type === "digitalbook") {
                                    setEpubFile(
                                      item.benefit.BenefitResource[0]
                                        .resourceUrl
                                    );
                                    setEpubTitle(item.benefit.title);
                                    setIsEpubOpen(true);
                                    return;
                                  }
                                  setOpenMediaPlayer(!openMediaPlayer);
                                  setMedia(item.benefit.BenefitResource);
                                  setIsAudio(item.benefit.type === "audio");
                                }
                              : () => {}
                          }
                          style={
                            !["sweepstakes", "survey", "coupon"].includes(
                              item.benefit.type
                            )
                              ? { cursor: "pointer" }
                              : {}
                          }
                        >
                          <div className={Styles["tag-wrapper"]}>
                            <Tag color={color}>{label}</Tag>
                            <Tag isDisabled style={{ marginLeft: 5 }}>
                              {dayjs(item.benefit.createdAt).format(
                                "DD MMM YY"
                              )}
                            </Tag>
                          </div>
                          <Meta
                            title={
                              <Space align="center" className={Styles.title}>
                                {(item.benefit.type === "audio" ||
                                  item.benefit.type === "video") && (
                                  <Micons
                                    icon="play_circle_filled"
                                    style={{
                                      color: "rgba(140, 140, 140, 0.5)",
                                    }}
                                  />
                                )}
                                {currentLocaleData?.title ?? item.benefit.title}
                              </Space>
                            }
                            description={
                              <ReadMore
                                text={
                                  currentLocaleData?.description ??
                                  item.benefit.description
                                }
                                characterLimit={78}
                              >
                                {item.benefit.description.length > 78 && (
                                  <ReadMore.Trigger
                                    trigger={`...${t("MORE")}`}
                                    text={item.benefit.description}
                                  />
                                )}
                              </ReadMore>
                            }
                          />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </>
            ),
          },
          {
            label: (
              <div className="tab-label">
                <Micons icon="confirmation_number" style={{ fontSize: 18 }} />
                {t("PASS")}
              </div>
            ),
            key: 2,
            children: (
              <div
                className={`${Styles["pass-wrapper"]}${
                  isTierLive ? ` ${Styles.active}` : ""
                }`}
              >
                <div className={Styles["status-band"]}>
                  {!isTierLive ? (
                    <>
                      <div>
                        <ExclamationCircleFilled
                          style={{ color: "red", marginRight: 8 }}
                        />
                        <strong>{t("PROGRAM_UI.PASS_STATUS")}</strong>:{" "}
                        {tier?.program?.status}
                        <span>{tier && renderDate(tier.createdAt)}</span>
                      </div>
                      <Button onClick={handleActivePass}>
                        {t("PROGRAM_UI.ACTIVATE_PASS")}
                      </Button>
                    </>
                  ) : (
                    <>
                      <div>
                        <CheckCircleFilled
                          style={{ color: "green", marginRight: 8 }}
                        />
                        <strong>{t("PROGRAM_UI.PASS_STATUS")}</strong>:{" "}
                        {tier?.program?.status === "Live"
                          ? t("ACTIVE")
                          : tier?.program?.status}
                      </div>
                    </>
                  )}
                </div>
                <Row justify="center">
                  <Col sm={10}>
                    <Card>
                      <iframe
                        src={tier?.samplePassUrl}
                        title="Pass"
                        allowFullScreen={false}
                        width="100%"
                        height={800}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            ),
          },
          {
            label: (
              <div className="tab-label">
                <Micons icon="notifications" style={{ fontSize: 18 }} />
                {t("PUSH_NOTIFICATIONS")}
              </div>
            ),
            key: 3,
            children: <PushNotifications tier={tier} />,
          },
          {
            label: (
              <div className="tab-label">
                <Micons icon="notifications" style={{ fontSize: 18 }} />
                {t("STATISTICS")}
              </div>
            ),
            key: 4,
            children: (
              <Statistics programId={tier?.programId} tierId={tier?.id} />
            ),
          },
        ]}
      />

      <MediaPlayer
        isOpen={openMediaPlayer}
        onCancel={() => {
          setOpenMediaPlayer(false);
          setMedia([]);
        }}
        mediaFiles={media}
        isDownloadable={isDownloadable}
        bodyStyle={{}}
        isAudio={isAudio}
        width={isAudio ? 500 : 1000}
      />
      <EpubReader
        isOpen={isEpubOpen}
        setIsOpen={setIsEpubOpen}
        file={epubFile}
        title={epubTitle}
      />
      <AddBenefitsModal
        open={isModalOpen}
        isLoading={isAttachBenefitLoading}
        endPoint={`programs/${programId}/tiers/${id}/benefit`}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={handleAttachBenefitSubmit}
        isTier
      />
    </>
  );
};

export default TierDetails;
