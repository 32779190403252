import axios from 'axios'
import constant from "../../config/constant";
import { toast } from "react-toastify";

const baseURL = constant.config.REACT_API_HOST;

const showErrorToast = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

const showSuccessToast = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
export const scanPass = async (passId, position) => {
    const body = {
        passId: passId,
        position: position,
    }
    try {
        const response = await axios.post(
            baseURL + `scan`,
            body
        );
        return response.data
    } catch (error) {
        if (error.response.status === 500) {
            showErrorToast(
                "Error: Something went wrong when scanning. Please contact admin!. " + error.message
            );
        }
        throw error
    }
};

export const redeemBenefit = async (benefitId, passId) => {
    const body = {
        benefitId: Number(benefitId),
        passId: passId
    }
    try {
        const response = await axios.post(
            baseURL + `redeem`,
            body
        );
        showSuccessToast("Benefit successfully redeemed!")
        return response.data
    } catch (error) {
        if (error.response.status === 500) {
            showErrorToast(
                "Error: Something went wrong when redeeming. Please contact admin!. " + error.response.data.message
            );
        } else {
            showErrorToast(error.response.data.message)
        }
        throw error
    }
};