import { PlusOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom"
import { Button } from "../../customAntd";
import GreenTick from "../../../assets/images/green-tick.svg"
import "./styles/homepageCard.css";

const HomepageCard = ({ icon, title, description, buttonText, buttonLink, disable, ...props }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();
    navigate(buttonLink);
  }

  return (
    <Col {...props}>
      <Row className="homepageCard d-flex flex-row align-items-center">
        <Col className="icon" >
          <img className="card-icon" src={icon} alt={title} />
        </Col>
        <Col className={`content ${disable ? 'disable' : ''}`}>
          <p className="title mb-1">{title}</p>
          <p className="description">{description}</p>
        </Col>
        <Col className="action d-flex flex-row-reverse">
          {disable ?
            <img src={GreenTick} />
            :
            <Button icon={<PlusOutlined />} onClick={(e) => { handleClick(e) }}>
              {buttonText}
            </Button>
          }
        </Col>
      </Row >
    </Col >
  )
}

export default HomepageCard
