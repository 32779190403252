import {Checkbox, Form} from "antd";

import { MENU_OPTIONS } from "../../../../utils/storeFront/constant";
import { FormWrapper } from "../../../../components/customAntd";

import Styles from "./styles/buildStorefront.module.scss";

const {Item} = Form;

const MenuOptions = ({t}) => {
  return (
    <FormWrapper heading={t("MENU_OPTIONS")}>
      <Item name="hiddenMenuItems">
        <Checkbox.Group
          options={MENU_OPTIONS(t)}
          className={Styles["menu-options"]}
        />
      </Item>
    </FormWrapper>
  );
};

export default MenuOptions;
