import React from 'react'
import {
    BsXCircle,
    BsXCircleFill
} from 'react-icons/bs'

const InvalidCard = ({
    invalidMessage
}) => {

    const InvalidIcon = () => {
        return (
            <div
                className="result-icon-container"
            >
                <BsXCircle size={"35px"} className="result-icon" />
                <BsXCircleFill size={"35px"} color="#EB4335" className="result-icon" />
            </div>
        )
    }

    return (
        <div className="result-card card invalid-result">
            <div className="result-card-header">
                <div className="d-flex flex-row">
                    <InvalidIcon/>
                    <p className="ml-2">INVALID</p>
                </div>
                <p className='mt-1 font-weight-normal font-md invalid-message'>
                    {invalidMessage}
                </p>
            </div>
        </div>
    )
}

export default InvalidCard