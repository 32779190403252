import { useState, useCallback } from "react";
import { ReactReader } from "react-reader";

import { Modal } from "../customAntd";

import Styles from "./styles/epubReader.module.scss";

const EpubReader = ({ isOpen, setIsOpen, file, title }) => {
  const [location, setLocation] = useState(null);
  const locationChanged = useCallback(
    (epubcifi) => {
      setLocation(epubcifi);
    },
    [setLocation]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={"100%"}
      style={{
        height: "calc(100vh - 20px)",
        overflowX: "hidden",
        overflowY: "scroll",
        marginTop: -90,
        marginBottom: -20,
      }}
      onCancel={() => setIsOpen(false)}
      className={Styles["epub-reader-modal"]}
      destroyOnClose
    >
      <ReactReader
        location={location}
        locationChanged={locationChanged}
        url={file}
        title={title}
      />
    </Modal>
  );
};

export default EpubReader;
