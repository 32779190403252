import { useEffect, useState } from "react";
import { Row, Col, Form, Steps, Divider, message } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { BREADCRUMB_PROGRAMS_CREATE } from "../../../../utils/programs/constant";
import { Modal, Button, ModalHeader } from "../../../../components/customAntd";

import { ProgramForm } from "./programForm.jsx";
import { TierForm } from "./tierForm.jsx";
import { PassForm } from "./passForm.jsx";
import { RequireStep } from "./utils";

import Styles from "../styles/program.module.scss";

const NewProgram = ({
  form,
  isOpen,
  onCancel,
  onSubmit,
  loading,
  isDataLoading,
  isEdit,
  program,
  tierList,
  setTierList,
  programIdEdit,
  currentStep,
  disableNext,
  setAllTiers,
  setProgramDetails,
  editProgram,
}) => {
  const { t } = useTranslation();
  const [tiers, setTiers] = useState([{ tierName: "", tierDescription: "" }]);
  const [stepFormIsEdit, setstepFormIsEdit] = useState(false);
  const [tiersToDelete, setTiersToDelete] = useState([]);
  const [current, setCurrent] = useState(0);
  const [tierFormError, setTierFormError] = useState([]);

  useEffect(() => {
    if (tierList.length >= 1) {
      setTiers([...tierList]);
    } else {
      setTiers([{ tierName: "", tierDescription: "" }]);
    }
    setTiersToDelete([]);
  }, [tierList]);

  const onChange = (value) => {
    if (value === 1) {
      form
        .validateFields(["program_name", "program_description"])
        .then((values) => {
          setCurrent(value);
        })
        .catch((errorInfo) => {
          setCurrent(current);
        });
    } else if (value === 2) {
      form.validateFields(["program_name", "program_description"]).then(() => {
        if (tiers.length > 0) {
          form
            .validateFields(["program_name", "program_description"])
            .then((values) => {
              setCurrent(2);
            })
            .catch((errorInfo) => {
              setCurrent(current);
            });
        } else {
          message.error("Please add atleast one tier to proceed");
          setCurrent(1);
        }
      });
    } else {
      setCurrent(value);
    }
  };
  const onProgramFormSubmits = () => {
    form
      .validateFields(["program_name", "program_description"])
      .then((values) => {
        setCurrent(1);
      })
      .catch((errorInfo) => {});
  };
  const onTierFormSubmit = async () => {
    if (tiers.length > 0) {
      const unsavedTiers = tiers.filter((item) => !item.isSavedToDb);

      if (unsavedTiers.length > 0) {
        setTierFormError(unsavedTiers);
        message.error(
          "Please fill all the added tier details completely to proceed to next step"
        );
      } else {
        setCurrent(2);
      }
    } else {
      message.error("Please add atleast one tier to proceed");
    }
  };

  const onPassFormSubmit = async () => {
    console.log('test')
    const unsavedPassData = tiers.filter((item) => !item.passData);
    if (unsavedPassData.length > 0) {
      message.error(
        "Please fill all the pass detials details of the added tier, completley to proceed to next step"
      );
    } else {
      let tiersPayload = tiers.map((item) => {
        return {
          id: item.id ?? null,
          name: item.tierName,
          description: item.tierDescription,
          expiryDate: moment(item.expiryDate, "DD/MM/YYYY").toDate(),
          pointThreshold: item.tierPointThreshold,
          tierBenefits: item.passData?.tierBenefits,
          information: item.passData?.tierInformation,
          logoImageUrl: item.passData?.logoImage[0].resourceUrl,
          iconImageUrl: item.passData?.iconImage[0].resourceUrl,
          appleLogoImageUrl: item.passData?.applelogoimage[0].resourceUrl,
          stripImageUrl: item.passData?.stripImage[0].resourceUrl,
          heroImageUrl: item.passData?.heroimage[0]?.resourceUrl,
          passColors: {
            labelColor: item.passData?.labelsColor,
            textColor: item.passData?.textColor,
            stripColor: item.passData?.passColor,
          },
          appleLogoSize: item.passData?.appleLogoSize,
          companyName: item.passData?.companyName,
          programName: item.passData?.programName,
          defaultLocaleId: item.passData?.defaultLocaleId,
          locales: item.passData?.locales,
          // benefits: [],
          locations: item.passData?.locations?.map((el) => ({
            name: el.name,
            lat: el.latitude,
            lon: el.longitude,
            lockScreenMessage: el.lockScreenMessage,
          })),
        };
      });
      let payload;
      if (!isEdit) {
        payload = {
          name: form.getFieldValue("program_name"),
          description: form.getFieldValue("program_description"),
          status: form.getFieldValue("program_status"),
          pointsEnabled: form.getFieldValue("points_enabled"),
          pointsExpire: form.getFieldValue("points_expire") === "yes",
          expirationPeriod: form.getFieldValue("points_expiration_period"),
          defaultLocaleId: form.getFieldValue("defaultLocaleId"),
          locales: form.getFieldValue("program_locales")?.map((locale) => ({
            localeId: locale.localeId,
            name: locale.program_name,
            description: locale.program_description,
          })),
          tiers: [...tiersPayload],
        };
      } else {
        payload = {
          id: form.getFieldValue("id"),
          name: form.getFieldValue("program_name"),
          status: form.getFieldValue("program_status"),
          description: form.getFieldValue("program_description"),
          pointsEnabled: form.getFieldValue("points_enabled"),
          pointsExpire: form.getFieldValue("points_expire") === "yes",
          expirationPeriod: form.getFieldValue("points_expiration_period"),
          defaultLocaleId: form.getFieldValue("defaultLocaleId"),
          locales: form.getFieldValue("program_locales")?.map((locale) => ({
            localeId: locale.localeId,
            name: locale.program_name,
            description: locale.program_description,
          })),
          tiers: [...tiersPayload],
        };
      }
      console.log('before onSubmit')
      await onSubmit(payload);
    }
  };
  const [steps, setSteps] = useState([
    {
      title: RequireStep(
        isEdit ? t("PROGRAM_UI.EDIT_PROGRAM") : t("PROGRAM_UI.DEFINE_PROGRAM")
      ),
      content: (
        <ProgramForm
          form={form}
          program={program}
          setProgram={tiers}
          programIdEdit={programIdEdit}
          editProgram={editProgram}
          isEdit={isEdit}
        />
      ),
      button: t("CREATE_PROGRAM"),
      onSubmit: onProgramFormSubmits,
    },
    {
      title: RequireStep(
        isEdit ? t("PROGRAM_UI.EDIT_TIERS") : t("PROGRAM_UI.DEFINE_TIERS")
      ),
      content: (
        <TierForm
          form={form}
          setTierData={setTierList}
          tierData={tiers}
          isEdit={isEdit}
          setstepFormIsEdit={setstepFormIsEdit}
          programIdEdit={programIdEdit}
          tierFormError={tierFormError}
        />
      ),
      button: t("CREATE_TIERS"),
      onSubmit: onTierFormSubmit,
      onSubmitState: tierFormError.length > 0,
    },
    {
      title: isEdit
        ? t("PROGRAM_UI.EDIT_PASS_TO_TIERS")
        : t("PROGRAM_UI.ADD_PASS_TO_TIERS"),
      content: (
        <PassForm
          form={form}
          setTierData={setTierList}
          isEdit={isEdit}
          tierData={tiers}
          setstepFormIsEdit={setstepFormIsEdit}
          setTiersToDelete={setTiersToDelete}
          tiersToDelete={tiersToDelete}
          programIdEdit={programIdEdit}
        />
      ),
      button: t("CREATE_TIERS"),
      onSubmit: () => onPassFormSubmit(),
      onSubmitState: stepFormIsEdit,
    },
  ]);

  const items = steps?.map((item) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled,
  }));

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    setCurrent(currentStep === 1 ? 1 : 0);
  }, [isOpen, currentStep]);

  useEffect(() => {
    setSteps([
      {
        title: RequireStep(
          isEdit
            ? t("PROGRAM_UI.EDIT_PROGRAM")
            : t("PROGRAM_UI.DEFINE_PROGRAM"),
          t
        ),
        content: (
          <ProgramForm
            form={form}
            program={program}
            setProgram={tiers}
            programIdEdit={programIdEdit}
            isEdit={isEdit}
            editProgram={editProgram}
            setProgramDetails={setProgramDetails} //From Program detail page
          />
        ),
        button: t("PROGRAM_UI.NEXT"),
        onSubmit: onProgramFormSubmits,
      },
      {
        title: RequireStep(
          isEdit ? t("PROGRAM_UI.EDIT_TIERS") : t("PROGRAM_UI.DEFINE_TIERS"),
          t
        ),
        content: (
          <TierForm
            form={form}
            setTierData={setTierList}
            tierData={tiers}
            isEdit={isEdit}
            setstepFormIsEdit={setstepFormIsEdit}
            programIdEdit={programIdEdit}
            tierFormError={tierFormError}
            setAllTiers={setAllTiers} //From Program Detail page
          />
        ),
        button: t("PROGRAM_UI.NEXT"),
        onSubmit: onTierFormSubmit,
      },
      {
        title: isEdit
          ? t("PROGRAM_UI.EDIT_PASS_TO_TIERS")
          : t("PROGRAM_UI.ADD_PASS_TO_TIERS"),
        content: (
          <PassForm
            form={form}
            setTierData={setTierList}
            tierData={tiers}
            isEdit={isEdit}
            programIdEdit={programIdEdit}
          />
        ),
        button: t("PROGRAM_UI.CREATE_PROGRAM"),
        onSubmit: () => {
          onPassFormSubmit();
        },
      },
    ]);
  }, [tiers, stepFormIsEdit, form, isOpen]);

  return (
    <Modal
      title={
        <ModalHeader
          title={
            isEdit ? t("PROGRAM_UI.EDIT_PROGRAM") : t("PROGRAM_UI.NEW_PROGRAM")
          }
          breadcrumbs={BREADCRUMB_PROGRAMS_CREATE(t)}
        />
      }
      style={{
        overflowX: "hidden",
        overflowY: "scroll",
        maxHeight: "calc(100vh - 250px)",
      }}
      open={isOpen}
      centered
      width={1500}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button buttonType="cancel" onClick={onClose} key="cancel-button">
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={() => {
            steps[current].onSubmit();
          }}
          disabled={steps[current].onSubmitState || disableNext}
          loading={loading || isDataLoading}
          key="create-button"
        >
          {isDataLoading
            ? `${t("LOADING")}...`
            : isEdit
            ? current !== 2
              ? t("PROGRAM_UI.NEXT")
              : t("PROGRAM_UI.UPDATE_PROGRAM")
            : steps[current].button}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        disabled={loading || isDataLoading}
        requiredMark={false}
      >
        <Row gutter={52}>
          <Col xs={24} md={24}>
            <Steps current={current} onChange={onChange} items={items} />
            <Divider className={Styles["steps-divider"]} />
            <div>{steps[current].content}</div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewProgram;
