import { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { t } from "i18next";
import { BREADCRUMB_TOKEN_GATED_VIDEO_ADD_NEW } from "../../../../../utils/benefits/constant";

import {
  Modal,
  Button,
  ModalHeader,
} from "../../../../../components/customAntd";
import Step1 from "./step1";
import Step2 from "./step2";
import Note from "./note";
import Step3 from "./step3";

const NewVideo = ({
  isOpen,
  onCancel,
  form,
  onSubmitClick,
  isEdit,
  editData,
  onSubmit,
}) => {
  const [step, setStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [heroImage, setHeroImage] = useState([]);
  const [heroImages, setHeroImages] = useState([]);
  const [formData, setFormData] = useState({});
  const [isImageLoading, setIsImageLoading] = useState(false);
  const isSingleFile = Object.keys(formData).length
    ? formData["benefit_type"] === "video_file"
    : false;

  // Initially set the hero image and video array in case of editing
  useEffect(() => {
    if (isEdit && editData) {
      setHeroImage([
        {
          name: editData.resourceUrl.split("/").reverse()[0],
          resourceUrl: editData.resourceUrl,
        },
      ]);
      const heroImagesArray = [];
      editData?.locales?.forEach((locale, index) => {
        if (locale?.previewResourceUrl) {
          heroImagesArray.push([{ resourceUrl: locale.previewResourceUrl }]);
        } else {
          heroImagesArray.push([]);
        }
      });
      setHeroImages(heroImagesArray);
      setFiles(() => {
        return editData.BenefitResource.map((item) => {
          return {
            name: item.name,
            resourceUrl: item.resourceUrl,
            type: item.type,
          };
        });
      });
    }
  }, [isEdit, editData]);

  // Resetting the form step to 0 and clearing all the necessary data for completing the form
  const handleCancel = () => {
    setStep(0);
    setFormData({});
    setFiles([]);
    setHeroImage([]);
    setHeroImages([]);
    onCancel();
  };

  // This function will advance the stepper form to the next step,
  // and once the final step has been reached, it will submit the form.
  const onFinish = (data) => {
    let tempObj = {};
    setFormData((prev) => {
      tempObj = { ...prev, ...data };
      return tempObj;
    });

    if (step === forms.length - 1) {
      if (heroImage.length !== 0) {
        console.log("temp", tempObj);
        onSubmit(tempObj, files, heroImage, heroImages, handleCancel);
      }
    } else {
      setStep((prev) => prev + 1);
    }
  };

  // Maintaining an array of videos that holds the videos that have been uploaded,
  // which will later be sent to the API as resources.
  // In this array, we perform a file name change.
  const handleFileChange = (data, link) => {
    if (link) {
      setFiles((prev) => [...prev, data]);
    } else {
      if (isSingleFile) {
        setFiles([...data]);
      } else {
        setFiles((prev) => [...prev, ...data]);
      }
    }
  };

  // Maintaining an array of images
  const handleHeroImageChange = (data) => {
    setHeroImage([...data]);
  };

  const forms = [
    <Step1 isEdit={isEdit} />,
    <Step2
      form={form}
      onChange={handleFileChange}
      files={files}
      isSingleFile={isSingleFile}
      setFiles={setFiles}
      setIsImageLoading={setIsImageLoading}
      isEdit={isEdit}
    />,
    <Step3
      form={form}
      onChange={handleHeroImageChange}
      isSingleFile={isSingleFile}
      files={heroImage}
      setHeroImage={setHeroImage}
      setIsImageLoading={setIsImageLoading}
      heroImage={heroImage}
      heroImages={heroImages}
      setHeroImages={setHeroImages}
      isEdit={isEdit}
      editData={editData}
    />,
  ];

  return (
    <Modal
      title={
        <ModalHeader
          title={
            Object.keys(formData).length
              ? formData["benefit_type"] === "video_file"
                ? t("VIDEO.FILE")
                : `${t("VIDEO.SERIES")}/${t("PLAYLIST")}`
              : t("VIDEO.BENEFIT")
          }
          breadcrumbs={BREADCRUMB_TOKEN_GATED_VIDEO_ADD_NEW(isEdit, t)}
        />
      }
      open={isOpen}
      centered
      width={944}
      onCancel={handleCancel}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button buttonType="cancel" onClick={handleCancel} key="cancel-button">
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={onSubmitClick}
          disabled={
            (files.length === 0 && step === 1 ? true : false) || isImageLoading
          }
          key="next-button"
        >
          {step === 1
            ? isEdit
              ? t("UPDATE_DETAILS")
              : t("ADD_DETAILS")
            : step === 2
            ? isEdit
              ? t("VIDEO.UPDATE_BENEFIT")
              : t("VIDEO.CREATE")
            : isEdit
            ? t("VIDEO.UPDATE")
            : t("VIDEO.UPLOAD")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        preserve={false}
        initialValues={{ primary_category: 33 }}
      >
        {isEdit && (
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
        )}
        {forms[step]}
        {step !== 0 && <Note />}
      </Form>
    </Modal>
  );
};

export default NewVideo;
