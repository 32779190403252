import { useState } from "react";
import { Col, Form, Input, Row } from "antd";

import { BREADCRUMB_ADD_NEW_SURVEY } from "../../../../../utils/benefits/constant";
import {
  Modal,
  Button,
  ModalHeader,
} from "../../../../../components/customAntd";

import { t } from "i18next";
import Step1 from "./step1";
import Step2 from "./step2";

const { Item } = Form;

const NewSurvey = ({
  form,
  isEdit,
  isOpen,
  onCancel,
  onSubmitClick,
  onSubmit,
  loading,
  isDataLoading,
  setCoverImage,
  coverImage,
  localisedCoverImages,
  setLocalisedCoverImages,
  editData,
}) => {
  const [step, setStep] = useState(0);
  const [isCoverImageLoading, setIsCoverImageLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const defaultLocaleId = Object.keys(formData).length
    ? formData["defaultLocaleId"]
    : "";

  const handleCancel = () => {
    setStep(0);
    setCoverImage([]);
    onCancel();
  };

  const onFinish = (data) => {
    let tempObj = {};
    setFormData((prev) => {
      tempObj = { ...prev, ...data };
      return tempObj;
    });

    if (step === forms.length - 1) {
      onSubmit(tempObj);
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const forms = [
    <Step1
      form={form}
      isEdit={isEdit}
      editData={editData}
      coverImage={coverImage}
      setIsCoverImageLoading={setIsCoverImageLoading}
      setCoverImage={setCoverImage}
      localisedCoverImages={localisedCoverImages}
      setLocalisedCoverImages={setLocalisedCoverImages}
    />,
    <Step2
      form={form}
      formData={formData}
      isEdit={isEdit}
      editData={editData}
      coverImage={coverImage}
      setIsCoverImageLoading={setIsCoverImageLoading}
      setCoverImage={setCoverImage}
      localisedCoverImages={localisedCoverImages}
      setLocalisedCoverImages={setLocalisedCoverImages}
      defaultLocaleId={defaultLocaleId}
    />,
  ];

  return (
    <>
      <Modal
        title={
          <ModalHeader
            title={isEdit ? t("SURVEY.EDIT") : t("SURVEY.NEW")}
            breadcrumbs={BREADCRUMB_ADD_NEW_SURVEY(isEdit, t)}
          />
        }
        open={isOpen}
        centered
        width={1200}
        onCancel={handleCancel}
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button
            buttonType="cancel"
            onClick={handleCancel}
            key="cancel-button"
          >
            {t("CANCEL")}
          </Button>,
          <Button
            onClick={onSubmitClick}
            loading={loading || isDataLoading}
            disabled={isCoverImageLoading}
            key="create-button"
          >
            {isDataLoading
              ? `${t("LOADING")}...`
              : isEdit
              ? t("SURVEY.UPDATE")
              : t("SURVEY.CREATE")}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          preserve={false}
          scrollToFirstError
          requiredMark={false}
          disabled={loading}
          preseve={false}
          initialValues={{ primary_category: 34 }}
        >
          <Row gutter={25}>
            <Col lg={24}>
              {isEdit && (
                <Item name="id" hidden>
                  <Input />
                </Item>
              )}
              {forms[step]}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default NewSurvey;
