import { Row, Col, Switch, Divider, Input, Form } from "antd";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

import { FormWrapper } from "../../../../components/customAntd";

const { Item } = Form;

const Footer = ({ t, watchEnableFooter }) => {
  return (
    <FormWrapper heading={t("STOREFRONT_UI.FOOTER")}>
      <Item label={`${t("STOREFRONT_UI.ENABLE_FOOTER")} :`} name="enableFooter">
        <Switch checked={watchEnableFooter} />
      </Item>
      <Item
        label={`${t("STOREFRONT_UI.COMPANY_NAME")} :`}
        name="companyName"
        rules={[
          {
            required: watchEnableFooter ? true : false,
            message: t("STOREFRONT_UI.COMPANY_NAME_VALIDATION"),
            whitespace: true,
            validateTrigger: "onSubmit",
          },
        ]}
      >
        <Input
          placeholder={t("STOREFRONT_UI.COMPANY_NAME_PLACHOLDER")}
          disabled={!watchEnableFooter}
        />
      </Item>

      <Item label={`${t("STOREFRONT_UI.FOOTER_TEXT")} :`} name="footerText">
        <MDEditor
          overflow={false}
          placeholder={t("STOREFRONT_UI.FOOTER_TEXT_PLACHOLDER")}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
      </Item>

      <Divider />

      <Item>
        <h4>{`${t("STOREFRONT_UI.SOCIAL_LINKS")} :`}</h4>
        <span>{`${t("STOREFRONT_UI.ADD_SOCIAL_LINKS")}`}</span>
      </Item>
      <Row gutter={[10]}>
        <Col lg={12}>
          <Item
            label={`${t("STOREFRONT_UI.WEBSITE")}:`}
            name="websiteUrl"
            rules={[
              {
                type: "url",
                message: t("STOREFRONT_UI.WEBSITE_VALIDATION"),
              },
            ]}
          >
            <Input
              placeholder={t("STOREFRONT_UI.WEBSITE_PLACEHOLDER")}
              disabled={!watchEnableFooter}
            />
          </Item>
        </Col>
        <Col lg={12}>
          <Item
            label={`${t("STOREFRONT_UI.TWITTER")}:`}
            name="twitterUrl"
            rules={[
              {
                type: "url",
                message: t("STOREFRONT_UI.WEBSITE_VALIDATION"),
              },
            ]}
          >
            <Input
              placeholder={t("STOREFRONT_UI.WEBSITE_PLACEHOLDER")}
              disabled={!watchEnableFooter}
            />
          </Item>
        </Col>
        <Col lg={12}>
          <Item
            label={`${t("STOREFRONT_UI.DISCORD")}:`}
            name="discordUrl"
            rules={[
              {
                type: "url",
                message: t("STOREFRONT_UI.WEBSITE_VALIDATION"),
              },
            ]}
          >
            <Input
              placeholder={t("STOREFRONT_UI.WEBSITE_PLACEHOLDER")}
              disabled={!watchEnableFooter}
            />
          </Item>
        </Col>
        <Col lg={12}>
          <Item
            label={`${t("STOREFRONT_UI.MEDIUM")}:`}
            name="mediumUrl"
            rules={[
              {
                type: "url",
                message: t("STOREFRONT_UI.WEBSITE_VALIDATION"),
              },
            ]}
          >
            <Input
              placeholder={t("STOREFRONT_UI.WEBSITE_PLACEHOLDER")}
              disabled={!watchEnableFooter}
            />
          </Item>
        </Col>
      </Row>
    </FormWrapper>
  );
};

export default Footer;
