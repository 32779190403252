export const BREADCRUMB_ANNOUNCEMENT = (t, extra) => [
  {
    title: t("HOME"),
    link: "/home",
  },
  {
    title: t("ANNOUNCEMENT.ANNOUNCEMENT"),
    ...(extra ? { link: "/announcement" } : {}),
  },
  ...(extra ? [{ title: extra }] : []),
];
