import Trigger from "./trigger";

import Styles from "./styles/readmore.module.scss";

const ReadMore = ({
  children,
  className,
  style,
  text,
  characterLimit,
  textClassName,
}) => {
  return (
    <div
      className={`${Styles["read-more-wrapper"]} ${className}`}
      style={style || {}}
    >
      {" "}
      {text && (
        <p className={`${Styles.text} ${textClassName}`}>
          {text.length > characterLimit
            ? text.substring(0, characterLimit)
            : text}
        </p>
      )}
      {children}
    </div>
  );
};

ReadMore.Trigger = Trigger;
export default ReadMore;
