import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import {t} from "i18next"

import constant from "../../../config/constant";
import VideoIcon from "../../../assets/images/video-icon.svg";
import AudioIcon from "../../../assets/images/audio-icon.svg";
import EyeIcon from "../../../assets/images/eye-icon.svg";

import "./styles/uploadFile.css";

const baseURL = constant.config.REACT_API_HOST;

const UploadFile = ({
  isRequired,
  isLogoImage,
  label,
  description,
  name,
  accept,
  onSubmit,
  fileFor,
  fileSize,
  allowedFileFormats,
  isUploadIpfs,
  isError,
  errorMessage,
  register,
  field,
  onError,
  defaultFile,
  isErrorBorder,
  onLoading,
  isEdit,
  isHidden = false,
}) => {
  const [imageObject, setImageObject] = useState(null);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileSizeComp, setfileSizeComp] = useState(0);

  useEffect(() => {
    if (defaultFile) {
      setImage(defaultFile);
    } else {
      setImage(null);
    }
  }, [defaultFile]);

  useEffect(() => {
    const size = Number(fileSize);
    if (!isNaN(size)) {
      setfileSizeComp(fileSize);
    }
  }, []);

  useEffect(() => {
    if (imageObject) {
      setIsLoading(true);
      if (onLoading) {
        onLoading(true);
      }
      const formData = new FormData();
      if (fileFor === "pass") {
        if (isLogoImage) {
          formData.append("sizes[0][width]", "660");
          formData.append("sizes[0][height]", "660");
          formData.append("sizes[1][width]", "480");
          formData.append("sizes[1][height]", "150");
        } else {
          formData.append("sizes[0][width]", "1032");
          formData.append("sizes[0][height]", "336");
          formData.append("sizes[1][width]", "1125");
          formData.append("sizes[1][height]", "432");
        }
      }
      formData.append("fileFor", fileFor);
      formData.append("uploadIpfs", isUploadIpfs);
      formData.append("file", imageObject);

      axios
        .post(`${baseURL}uploads`, formData)
        .then((resp) => {
          let imageUrl;
          if (fileFor === "pass") {
            if (isLogoImage) {
              imageUrl = resp.data["s3Url-660x660"];
            } else {
              imageUrl = resp.data["s3Url-1125x432"];
            }
          } else {
            imageUrl = resp.data.s3Url;
          }
          setImage(imageUrl);
          onSubmit(resp.data);
          if (onLoading) {
            onLoading(false);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
          if (onLoading) {
            onLoading(false);
          }
          setImage(null);
        });
    }
  }, [imageObject, onSubmit, fileFor, isUploadIpfs]);

  const handleOnChange = (file) => {
    if (fileSize && file.size / 1024 / 1024 > fileSizeComp && !isHidden) {
      onError({
        isError: true,
        message: `${t("FILE_UPLOAD.SIZE_ERROR")} ${fileSizeComp}${t("MB")}.`,
      });
      setImage(null);
    } else if (
      allowedFileFormats &&
      Array.isArray(allowedFileFormats) &&
      allowedFileFormats.length > 0 &&
      !isHidden
    ) {
      const ext = getFileExtension(file.name).toUpperCase();
      if (!allowedFileFormats.includes(ext)) {
        onError({
          isError: true,
          message: `${t("FILE_UPLOAD.ONLY")} ${allowedFileFormats.toString()} ${t("FILE_UPLOAD.EXTENSIONS_ALLOWED")}`,
        });
        setImage(null);
      } else {
        setImageObject(file);
      }
    }
  };

  const getFileExtension = (imageUrl) => {
    const fileExtension = imageUrl.split(".").pop().toLowerCase();
    return fileExtension;
  };

  const renderImage = (imageUrl) => {
    const getExtension = getFileExtension(imageUrl);
    let imageIcon = "";
    let imageLabel = "";
    let mediaClassName = "media-file";
    if (
      getExtension === "mp4" ||
      getExtension === "m4a" ||
      getExtension === "webm"
    ) {
      imageIcon = VideoIcon;
      imageLabel = t("FILE_UPLOAD.VIDEO_UPLOADED");
    } else if (getExtension === "mp3" || getExtension === "wav") {
      imageIcon = AudioIcon;
      imageLabel = t("FILE_UPLOAD.AUDIO_UPLOADED");
    } else if (getExtension === "glb") {
      imageIcon = VideoIcon;
      imageLabel = t("FILE_UPLOAD.THREED_UPLOADED");
    } else {
      imageIcon = imageUrl;
      imageLabel = "";
    }

    return (
      <div className={imageLabel ? mediaClassName : ""}>
        <img src={imageIcon} alt="" />
        {imageLabel}
      </div>
    );
  };

  const renderViewButton = (imageUrl) => {
    const getExtension = getFileExtension(imageUrl);
    if (
      getExtension === "mp4" ||
      getExtension === "m4a" ||
      getExtension === "webm" ||
      getExtension === "mp3" ||
      getExtension === "wav" ||
      getExtension === "glb"
    ) {
      return (
        <button
          className="view-file"
          type="button"
          onClick={(e) => window.open(imageUrl, "_blank")}
        >
          <img src={EyeIcon} alt="" />
          {t("FILE_UPLOAD.VIEW_FILE")}
        </button>
      );
    }
  };

  return (
    <div className={`upload-file-wrapper ${isHidden ? "d-none" : ""}`}>
      <div className="titleStyle" data-testid="label-text">
        {label} {isRequired && <span className="text-danger">*</span>}
      </div>
      <div
        className="fileText"
        data-testid="desctiption-text"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Form.Control
        type="file"
        id={name}
        name={name}
        accept={accept || ""}
        role="uploadfile"
        hidden={true}
        disabled={isLoading}
        onChange={
          !register
            ? (event) => {
                handleOnChange(event.target.files[0]);
                event.target.value = "";
              }
            : () => {}
        }
        {...(register && {
          ...register(field, {
            required: isEdit ? false : isRequired ? true : false,
            validate: {
              lessThan10MB: (files) => {
                if (isRequired) {
                  if (
                    isRequired &&
                    fileSize &&
                    files[0]?.size / 1024 / 1024 > fileSizeComp
                  ) {
                    setImage(null);
                    return `${t("FILE_UPLOAD.SIZE_ERROR")} ${fileSizeComp}${t("MB")}.`;
                  } else {
                    return true;
                  }
                } else {
                  if (
                    fileSize &&
                    files[0]?.size / 1024 / 1024 > fileSizeComp &&
                    !isEdit
                  ) {
                    setImage(null);
                    return `${t("FILE_UPLOAD.SIZE_ERROR")} ${fileSizeComp}${t("MB")}.`;
                  } else {
                    return true;
                  }
                }
              },
              acceptedFormats: (files) => {
                if (
                  allowedFileFormats &&
                  Array.isArray(allowedFileFormats) &&
                  allowedFileFormats.length > 0 &&
                  files.length > 0
                ) {
                  const ext = getFileExtension(files[0].name).toUpperCase();
                  const fileFormat = allowedFileFormats.includes(ext);
                  if (isRequired) {
                    if (fileFormat) {
                      return true;
                    } else if (!fileFormat && !isError && !isEdit) {
                      setImage(null);
                      return `${t("FILE_UPLOAD.ONLY")} ${allowedFileFormats.toString()} ${t("FILE_UPLOAD.EXTENSIONS_ALLOWED")}`
                    } else if (!isError) {
                      return true;
                    } else {
                      setImage(null);
                      return `${t("FILE_UPLOAD.ONLY")} ${allowedFileFormats.toString()} ${t("FILE_UPLOAD.EXTENSIONS_ALLOWED")}`
                    }
                  } else {
                    if (!fileFormat && files.length !== 0 && !isEdit) {
                      setImage(null);
                      return `${t("FILE_UPLOAD.ONLY")} ${allowedFileFormats.toString()} ${t("FILE_UPLOAD.EXTENSIONS_ALLOWED")}`
                    } else {
                      return true;
                    }
                  }
                } else {
                  return true;
                }
              },
              onChange: (files) => {
                if (files.length !== 0) {
                  setImageObject(files[0]);
                  return true;
                }
              },
            },
          }),
        })}
      />
      <Form.Label
        className={`${!image ? "initial-label" : ""} ${
          isLoading ? "loading" : ""
        } ${isError && isErrorBorder ? "error" : ""}`}
        htmlFor={name}
        role="label"
      >
        {image ? renderImage(image) : ""}
      </Form.Label>
      {image && !isLoading && renderViewButton(image)}
      {isError && register && (
        <p className="text-warning">
          {errorMessage.message
            ? errorMessage.message
            : t("FILE_UPLOAD.REQUIRED_FIELD")}
        </p>
      )}
      {errorMessage && !register && (
        <p className="text-warning">{errorMessage}</p>
      )}
      <div className="clear"></div>
    </div>
  );
};

export default UploadFile;
