import React, { useEffect, useState } from "react";
import { getUsers, updateUser } from "../../redux/user.slice";
import { useDispatch } from "react-redux";
import DataTable from "../../components/dataTable";
import ModalPopup from "../../components/modal.popup/modal.popup";
import { toast } from 'react-toastify';
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const User = () => {
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [showWalletDetails, setShowWalletDetails] = useState(false);
    const [currentUser, setCurrentUser] = useState(null)

    const ActionButtons = (row) => (
        <div>

            <Button
                variant={row.isApproved && "secondary"}
                className={row.isApproved ? "btnSecondary" : "btnPrimary"}
                onClick={() => { row.isApproved ? showRevokePopup(row) : approveUser(row) }}
            >
                {row.isApproved ? 'REVOKE' : 'APPROVE'}
            </Button>
        </div>
    )

    const WalletDetailButton = (user) => (
        <div>

            <Button
                className={"btnPrimary"}
                onClick={() => showWalletPopup(user)}
            >
                {'Wallets'}
            </Button>
        </div>
    )

    const AuditHistoryButton = ({ user }) => (
        <div>
            <NavLink to={`/user-audit-history/${user.id}`} className="profileLink" >
                <Button
                    // variant={row.isApproved && "secondary"}
                    className={"btnPrimary"}

                >
                    View
                </Button>

            </NavLink>

        </div>
    )



    const tableColumn = [{
        name: "name",
        selector: (row) => (row.name),
        maxWidth: "30%",
    },
    {
        name: "email",
        selector: (row) => (row.email),
        maxWidth: "30%",
    },
    {
        name: "phone",
        selector: (row) => (row.phone),
        maxWidth: "30%",
    },
    {
        name: "company",
        selector: (row) => (row.company),
        maxWidth: "30%",
    },
    {
        name: "country",
        selector: (row) => (row.country),
        maxWidth: "30%",
    },
    {
        name: "action",
        selector: (row) => <ActionButtons {...row} />
    },
    {
        name: "wallets",
        selector: (row) => (row.wallets)
    },
    {
        name: "history",
        selector: (row) => (row.history)
    }
    ];

    const handleClose = () => {
        setShow(false);
        setCurrentUser(null);
    }

    const showRevokePopup = (user) => {
        setShow(true);
        setCurrentUser(user)
    }

    const showWalletPopup = (user) => {
        setShowWalletDetails(true);
        setCurrentUser(user)
    }

    const showSuccessToast = (message) => {
        toast.success(message, {
            position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    const updateUserData = (data) => {
        const users = [...tableData];
        const pos = users.findIndex((user) => user.id === data.id)
        users[pos] = {
            ...users[pos],
            ...data,
        }
        setTableData(users);
    }

    const updateUserValues = async (data) => {
        const response = await dispatch(updateUser(data));
        if (response.type === 'user/updateUser/fulfilled') {
            showSuccessToast(data.isApproved ? "Approve Sucessfully" : "Revoke Sucessfully")
            updateUserData(data)
        }
    }

    const revokeUser = async (user) => {
        const data = {
            id: user.id,
            isApproved: false
        };

        await updateUserValues(data)
        handleClose()
    }

    const approveUser = async (user) => {
        const data = {
            id: user.id,
            isApproved: true
        };

        await updateUserValues(data)
    }

    useEffect(() => {
        const allUser = async () => {
            let response = await dispatch(getUsers());
            if (response.payload.length > 0) {

                const userData = response.payload.map((user) => {
                    return {
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        phone: user.phone,
                        company: user.company,
                        country: user.country,
                        isApproved: user.isApproved,
                        history: <AuditHistoryButton user={user} />,
                        wallets: <WalletDetailButton user={user} />
                    }
                });
                setTableData(userData);
            }

        };

        allUser();
    }, [dispatch]);


    return (
        <div className="table-sec mb-3 p-0">
            <DataTable
                columns={tableColumn}
                data={tableData}
            />

            <ModalPopup
                show={show}
                name={"Are you want to revoke?"}
                hide={handleClose}
                okClick={() => revokeUser(currentUser)}
                hideFooter
                okText='Yes'
                cancelText='No'
            ></ModalPopup>

            <ModalPopup
                show={showWalletDetails}
                name={currentUser.userWallets}
                hide={handleClose}
                okClick={() => setShowWalletDetails(false)}
                hideFooter
                okText='Close'
            ></ModalPopup>
        </div >
    );
};

export default User