import { Input, Select } from "antd";
import {
  SearchOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/customAntd";

import Styles from "./styles/filters.module.scss";

const ProgramFilters = ({ onViewChange, type, onSearch, onFilterByChange }) => {
  const { t } = useTranslation();
  return (
    <div className={Styles["filters-wrapper"]}>
      <div className={Styles.extra}>
        <Button
          type="link"
          icon={
            type === "list" ? <AppstoreOutlined /> : <UnorderedListOutlined />
          }
          onClick={onViewChange}
        >
          {type === "list" ? t('GRID') : t('LIST')} View
        </Button>
        <Input
          style={{ minWidth: 300 }}
          placeholder={t('SEARCH_SOMETHING')}
          onChange={onSearch}
          prefix={<SearchOutlined />}
        />
      </div>
    </div>
  );
};

export default ProgramFilters;
