import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Form } from "react-bootstrap";
import { t } from "i18next";

import Searchbar from "../../components/searchBar/Searchbar";
import { PageHeader, Table } from "../customAntd";

import { fetchUserAuditLogs } from "../../pages/profile/apiUtils";

import "./index.css";

export const AuditHistory = ({ adminView, setErrorList, errorList }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [isSearchString, setIsSearchString] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterByDay, setFilterByDay] = useState("7");
  const [filterByLogLevel, setFilterByLogLevel] = useState("all");

  const tableColumn = [
    {
      title: t("ACTIVITY.ACTION_TIME"),
      dataIndex: "actionTime",
      key: "actionTime",
      width: "25%",
      render: (_, record) => record.actionTime,
    },
    {
      title: t("ACTIVITY.LOG_LEVEL"),
      dataIndex: "level",
      key: "level",
    },
    {
      title: t("ACTIVITY.LOG_MESSAGE"),
      dataIndex: "message",
      key: "message",
    },
    {
      title: `${t("ACTIVITY.RESOURCE_TYPES")}/${t("ACTIVITY.RESOURCE_ID")}`,
      dataIndex: "resource",
      key: "resource",
    },
  ];

  const handleFilterByDay = (e) => {
    setFilterByDay(e.target.value);
  };

  const handleFilterByLogLevel = (e) => {
    setFilterByLogLevel(e.target.value);
  };

  const fetchLogsData = async () => {
    if (adminView) {
      const payload = {
        user: { id: id },
        filterCondition: {
          search: searchString,
          created: filterByDay,
          loglevel: filterByLogLevel,
        },
      };

      if (searchString !== "") {
        payload.filterCondition.search = searchString;
      } else {
        delete payload.filterCondition.search;
      }

      const response = await fetchUserAuditLogs(dispatch, payload);
      if (!response?.hasError) {
        const transformedData = response.map((item) => {
          return {
            actionTime: item.createdAt,
            resource: item.category,
            level: (
              <span
                className={`${
                  item.level === "DEBUG"
                    ? "log-level-color-debug"
                    : "log-level-color-error"
                }`}
              >
                {" "}
                {item.level}
              </span>
            ),
            message: item.message,
          };
        });
        setTableData(transformedData);
      } else {
        setErrorList({
          ...errorList,
          appsettingError: {
            message:
              response.message ?? t("APPSETTINGS_ERROR"),
          },
        });
      }
    } else {
      const payload = {
        filterCondition: {
          search: searchString,
          created: filterByDay,
          loglevel: filterByLogLevel,
        },
      };

      if (searchString !== "") {
        payload.filterCondition.search = searchString;
      } else {
        delete payload.filterCondition.search;
      }
      const response = await fetchUserAuditLogs(dispatch, payload);
      if (!response?.hasError) {
        const transformedData = response.map((item) => {
          return {
            actionTime: (
              <p className="table-para">
                {moment(item.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}
              </p>
            ),
            resource: <p className="table-para">{item.category}</p>,
            level: (
              <span
                className={`${
                  item.level === "DEBUG"
                    ? "log-level-color-debug"
                    : "log-level-color-error"
                }`}
              >
                {" "}
                {item.level}
              </span>
            ),
            message: <p className="table-para">{item.message}</p>,
          };
        });
        setTableData(transformedData);
      } else {
        setErrorList({
          ...errorList,
          appsettingError: {
            message:
              response.message ?? t("APPSETTINGS_ERROR"),
          },
        });
      }
    }
  };

  useEffect(() => {
    fetchLogsData();
  }, [searchString, isSearchString, filterByDay, filterByLogLevel]);

  return (
    <div>
      <div className="row mt-2 mb-3">
        {adminView && (
          <PageHeader
            breadcrumbs={[
              {
                title: t("PROFILE.TITLE"),
                link: "/user-profile",
              },
              {
                title: t("ACTIVITY.USER_HISTORY"),
              },
            ]}
          />
        )}

        <div className="col-md-9">
          <div>
            <div className="d-flex">
              <div>
                <Form.Group className="mr-3">
                  <Form.Label className="titleStyle">{`${t("FILTER_BY")} :`}</Form.Label>
                  <Form.Select
                    className="mt-2"
                    name="filterBy"
                    onChange={(e) => handleFilterByDay(e)}
                  >
                    <option value="7" selected>
                      {t("ACTIVITY.SEVEN_DAYS")}
                    </option>
                    <option value="30">{t("ACTIVITY.THIRTY_DAYS")}</option>
                    <option value="60">{t("ACTIVITY.SIXTY_DAYS")}</option>
                    <option value="all">{t("ALL")}</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div>
                <Form.Group className="mr-3">
                  <Form.Label className="titleStyle">{`${t("ACTIVITY.LOG_TYPE")}:`}</Form.Label>
                  <Form.Select
                    className="mt-2"
                    name="logLevelSelect"
                    onChange={(e) => handleFilterByLogLevel(e)}
                  >
                    <option value="all">{t("ALL")}</option>
                    <option value="DEBUG">{t("ACTIVITY.DEBUG")}</option>
                    <option value="ERROR">{t("ACTIVITY.ERROR")}</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center" style={{ float: "right" }}>
            <div className="mt-4 dropdown space-left" style={{ margin: "0px" }}>
              <Searchbar
                setValue={setSearchString}
                setIsSearchString={setIsSearchString}
                placeholder={t("ACTIVITY.SEARCH")}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        columns={tableColumn}
        dataSource={tableData}
        progressPending={isLoading}
      />
    </div>
  );
};
