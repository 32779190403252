import { Image } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import IphoneMock from "../../../../../assets/images/iPhone14Mock.png";
import FaroLogo from "../../../../../assets/images/logo.png";

import { FormWrapper } from "../../../../../components/customAntd";

import Styles from "../styles/pushNotifications.module.scss";

const NotificationPreview = ({
  title,
  message,
  companyName,
  type,
  date,
  time,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={Styles["iphone-mock-wrapper"]}>
        <Image src={IphoneMock} alt="Iphone Mock" preview={false} />
        <div className={Styles["notification"]}>
          <Image
            src={FaroLogo}
            alt="Faro Logo"
            preview={false}
            height={40}
            width={40}
          />
          <div className="text">
            <p style={{ fontWeight: 600, fontSize: 15 }}>{companyName}</p>
            <p style={{ fontWeight: 500, fontSize: 15 }}>{title}</p>
            <p>{message}</p>
          </div>
        </div>
      </div>
      <FormWrapper description={t("PROGRAM_UI.PUSH_NOTIFICATIONS.SENDING_NOTIFICATION")}>
        <p className={Styles["form-wrapper-text"]}>
          {`${type === "oneTime" ? `${t("PROGRAM_UI.PUSH_NOTIFICATIONS.NOW")}, ` : ""} 
            ${dayjs(type !== "oneTime" ? date : Date.now()).format(
              "dddd, MMMM DD, YYYY"
            )}
            `}
        </p>
        <p className={Styles["form-wrapper-text"]}>
          {`At ${dayjs(type !== "oneTime" ? time : Date.now()).format(
            "hh:mm a"
          )}`}
        </p>
      </FormWrapper>
    </>
  );
};
export default NotificationPreview;
