import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useAppContext } from "../../components/context/app.context";

import Users from "./users";
import AppSettings from "./appSettings";
import "./styles/admin.css";
import { checkUserRolePermissions } from "../../utils/helper";
import { ROLE_PERMISSIONS } from "../../utils/constants";

const Admin = () => {
    const [key, setKey] = useState("user");
    const { currentUserRole } = useAppContext()
    return (
        <div className="container admin-container">
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="user" title="User">
                    <Users />
                </Tab>
                {checkUserRolePermissions(currentUserRole, ROLE_PERMISSIONS.MANAGEAPPSETTINGS) &&
                    <Tab eventKey="setting" title="Setting">
                        <AppSettings />
                    </Tab>
                }
            </Tabs>
        </div>
    );
};

export default Admin