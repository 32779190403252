import { useState } from "react";
import {
  Table,
  Row,
  Col,
  Space,
  Form,
  message,
  Popconfirm,
  Tooltip,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { addWayToEarn, editWayToEarn, deleteWayToEarn } from "../apiUtils";
import { getRequiredBenefitTypeFromAction, capitalize } from "./utils";
import { useAppContext } from "../../../../components/context/app.context";

import { PageHeader, Button, Micons } from "../../../../components/customAntd";
import WayToEarnForm from "./wayToEarnForm";

import Styles from "../styles/pointsSettings.module.scss";
import ProgramDetailStyles from "../styles/programDetails.module.scss";

const EarnPointsSettings = ({
  allTiers,
  merchantData,
  benefitData,
  waysToEarn,
  setWaysToEarn,
  programDetails,
  currentLocaleData
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { currentLocale } = useAppContext();
  const [wayToEarnModalOpen, setWayToEarnModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [pointRewardIdEdit, setPointRewardIdEdit] = useState(null);

  const actionIcons = {
    visit: <img src="https://img.icons8.com/office/80/null/shop.png" />,
    view: <img src="https://img.icons8.com/stickers/100/null/visible.png" />,
    read: (
      <img src="https://img.icons8.com/stickers/100/null/e-learning-2.png" />
    ),
    listen: (
      <img src="https://img.icons8.com/stickers/100/null/high-volume.png" />
    ),
    // claim: <img src="https://img.icons8.com/stickers/100/null/nft.png" />,
  };

  const columns = [
    {
      title: t("PROGRAM_UI.WAYS_TO_EARN"),
      className: Styles["table-column"],
      render: (pointReward) => (
        <div>
          <Row>
            <Space size="middle">
              <Col>{actionIcons[pointReward.actionType]}</Col>
              <Col>
                <h4>{capitalize(pointReward.actionType)}</h4>
                <p className={Styles["italic"]}>{pointReward.name}</p>
                <PointRelationsDisplay pointReward={pointReward} />
              </Col>
            </Space>
          </Row>
        </div>
      ),
    },
    {
      title: t("PROGRAM_UI.TIERS"),
      className: Styles["table-column"],
      render: (pointReward) => {
        const tiers = [
          ...new Set(
            pointReward.pointRelations
              .filter((relation) => relation.tier)
              .map((relation) => relation.tier.name)
          ),
        ];
        return (
          <>
            {tiers.length > 0 ? (
              tiers.map((tierName) => <h2>{tierName}</h2>)
            ) : (
              <h2>All</h2>
            )}
          </>
        );
      },
    },
    {
      title: t("PROGRAM_UI.POINTS"),
      className: Styles["table-column"],
      render: (pointReward) => <h2>{pointReward.pointReward}</h2>,
    },
    {
      title: t("PROGRAM_UI.STREAK"),
      className: Styles["table-column"],
      render: (pointReward) => <StreakDisplay pointReward={pointReward} />,
    },
    {
      title: " ",
      render: (pointReward) => (
        <EditDelete
          pointReward={pointReward}
          handleEditClick={handleEditClick}
          handleDelete={handleDelete}
        />
      ),
    },
  ];

  // Submitting the final form after it has passed all of the validation checks
  const handleFormSubmit = async (formData) => {
    try {
      const dateFormat = "DD/MM/YYYY";
      let payload = {
        name: formData.name,
        description: formData.description,
        pointReward: Number(formData.points),
        actionType: formData.action,
        startDate: formData.start_date
          ? moment(
              dayjs(formData.start_date).format(dateFormat),
              dateFormat
            ).toDate()
          : null,
        endDate: formData.end_date
          ? moment(
              dayjs(formData.end_date).format(dateFormat),
              dateFormat
            ).toDate()
          : null,
        perPassLimit:
          formData.unlimited_earn === "no" ? formData.pass_earn_limit : null,
        frequency: formData.frequency,
        frequencyLimit: formData.frequency_limit,
        status: formData.status,
        isStreakReward: formData.is_streak,
        streakCount: Number(formData.streak_count),
        streakFrequency: formData.streak_frequency,
        programId: programDetails?.id,
        pointRelations: parsePointRelations(formData),
      };
      setIsSubmitting(true);
      if (!isEdit) {
        const wayToEarn = await addWayToEarn(payload);
        if (wayToEarn?.status === 200) {
          message.success("Way to earn created successfully");
          setWaysToEarn((prev) => [wayToEarn.data, ...prev]);
          handleClose();
        } else {
          throw new Error(
            wayToEarn.message ??
              JSON.stringify(wayToEarn.data.message) ??
              "Something went wrong while adding a new way to earn"
          );
        }
        setIsDataLoading(false);
        setIsSubmitting(false);
      } else {
        const wayToEarn = await editWayToEarn(pointRewardIdEdit, payload);
        if (wayToEarn?.status === 200) {
          message.success("Way to earn updated successfully");
          setWaysToEarn((prev) =>
            prev.map((item) =>
              item.id === pointRewardIdEdit ? wayToEarn.data : item
            )
          );
          handleClose();
        } else {
          throw new Error(
            wayToEarn.message ??
              JSON.stringify(wayToEarn.data.message) ??
              "Something went wrong while editing a way to earn"
          );
        }
        setIsDataLoading(false);
        setIsSubmitting(false);
      }
    } catch (err) {
      setIsDataLoading(false);
      setIsSubmitting(false);
      message.error(err.message);
    }
  };

  // Handler for the edit pointReward button
  const handleEditClick = (pointReward) => {
    const formData = {
      name: pointReward.name,
      description: pointReward.description,
      points: pointReward.pointReward,
      action: pointReward.actionType,
      end_date: pointReward.endDate ? dayjs(pointReward.endDate) : null,
      start_date: pointReward.startDate ? dayjs(pointReward.startDate) : null,
      unlimited_earn: !!pointReward.perPassLimit ? "no" : "yes",
      pass_earn_limit: pointReward.perPassLimit,
      frequency: pointReward.frequency,
      frequency_limit: pointReward.frequencyLimit,
      status: pointReward.status,
      is_streak: pointReward.isStreakReward,
      streak_count: pointReward.streakCount,
      streak_frequency: pointReward.streakFrequency,
      benefit_select: [
        ...new Set(
          pointReward.pointRelations
            .filter((relation) => relation.benefit)
            .map((relation) => relation.benefitId)
        ),
      ],
      merchant_select: [
        ...new Set(
          pointReward.pointRelations
            .filter((relation) => relation.merchant)
            .map((relation) => relation.merchantId)
        ),
      ],
      tier_select: [
        ...new Set(
          pointReward.pointRelations
            .filter((relation) => relation.tier)
            .map((relation) => relation.tierId)
        ),
      ],
      nft_select: [
        ...new Set(
          pointReward.pointRelations
            .filter((relation) => relation.nft)
            .map((relation) => relation.nftId)
        ),
      ],
    };
    form.resetFields();
    setPointRewardIdEdit(pointReward.id);
    setIsEdit(true);
    form.setFieldsValue(formData);
    setWayToEarnModalOpen(true);
  };

  const handleDelete = async (pointReward) => {
    const deleteRes = await deleteWayToEarn(pointReward.id);
    if (deleteRes?.status === 200) {
      message.success("Way to earn deleted successfully");
      setWaysToEarn((prev) =>
        prev.filter((item) => item.id !== pointReward.id)
      );
    } else {
      message.error("Something went wrong while deleting this way to earn");
    }
  };

  const handleClose = () => {
    form.resetFields();
    setWayToEarnModalOpen(false);
    setIsEdit(false);
  };

  return (
    <div>
      <div className={Styles["tier-thresholds"]}>
        {allTiers.map((tier) => {
          const currentLocaleData = tier.locales.filter(
            (item) => item.localeId === currentLocale
          )[0];
          return (
            <div className={Styles["tier-card"]} key={tier.id}>
              <p>{currentLocaleData?.name ?? tier.name} {currentLocaleData?.name ? t("PROGRAM_UI.TIER") : "Tier"}</p>
              <div className={Styles["point-threshold"]}>
                {tier.pointThreshold ? Number(tier.pointThreshold) : 0} points
                to enter
              </div>
            </div>
          );
        })}
      </div>
      <div className={Styles["earn-points"]}>
        <PageHeader
          title={
            <div className={Styles["header"]}>
              <h2>{t("PROGRAM_UI.EARN_POINTS")}</h2>
              <h3>{t("PROGRAM_UI.MANAGE_POINTS")}</h3>
            </div>
          }
          headStyle={{ marginTop: 40 }}
          extra={[
            <Row gutter={20} className={Styles["buttons"]}>
              {/* <Col> //TODO: handle this button
                                <Button
                                    type="default"
                                >
                                    View Points & Rewards
                                </Button>
                            </Col> */}
              <Col>
                <Button
                  type="primary"
                  onClick={() => setWayToEarnModalOpen(true)}
                >
                  <span
                    className={`material-icons-round ${Styles["add-icon"]}`}
                  >
                    add
                  </span>{" "}
                  {t("PROGRAM_UI.ADD_WAY_TO_EARN")}
                </Button>
              </Col>
            </Row>,
          ]}
          extrasClassName={ProgramDetailStyles["page-header-button-cont"]}
        />
        <Table columns={columns} dataSource={waysToEarn} className="" />
      </div>
      <WayToEarnForm
        form={form}
        isOpen={wayToEarnModalOpen}
        onSubmit={handleFormSubmit}
        onClose={handleClose}
        merchantData={merchantData}
        benefitData={benefitData}
        tierData={allTiers.map((item) => {
          return {
            label: item.name,
            value: item.id,
            id: item.id,
            isSelected: false,
          };
        })}
        loading={isSubmitting || isDataLoading}
        isEdit={isEdit}
        programDetails={programDetails}
        currentLocaleData={currentLocaleData}
      />
    </div>
  );
};

const PointRelationsDisplay = ({ pointReward }) => {
  if (pointReward.actionType === "visit") {
    let merchantNames = [
      ...new Set(
        pointReward.pointRelations
          .filter((relation) => relation.merchant)
          .map((relation) => relation.merchant.name)
      ),
    ].join(", ");
    if (merchantNames.length === 0) {
      merchantNames = "All";
    }
    return <p>Merchant: {merchantNames}</p>;
  } else if (["view", "read", "listen"].includes(pointReward.actionType)) {
    let benefitNames = [
      ...new Set(
        pointReward.pointRelations
          .filter((relation) => relation.benefit)
          .map((relation) => relation.benefit.title)
      ),
    ].join(", ");
    if (benefitNames.length === 0) {
      benefitNames = "All";
    }
    return (
      <p>
        {capitalize(getRequiredBenefitTypeFromAction(pointReward.actionType))}:{" "}
        {benefitNames}
      </p>
    );
  } else if (pointReward.actionType === "claim") {
    let nftNames = [
      ...new Set(
        pointReward.pointRelations
          .filter((relation) => relation.nft)
          .map((relation) => relation.nft.name)
      ),
    ].join(", ");
    if (nftNames.length === 0) {
      nftNames = "All";
    }
    return <p>NFT: {nftNames}</p>;
  } else {
    return <>{pointReward.actionType}</>;
  }
};

const EditDelete = ({ pointReward, handleEditClick, handleDelete }) => {
  return (
    <Row className={Styles["space-between"]}>
      <Button type="link">
        <Tooltip title="Edit">
          <Micons
            icon="edit"
            className="table-action-icon"
            onClick={() => handleEditClick(pointReward)}
          />
        </Tooltip>
      </Button>
      <Popconfirm
        title="Are you sure to delete this way to earn?"
        onConfirm={() => handleDelete(pointReward)}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
        placement="topRight"
        arrowPointAtCenter
      >
        <Button type="link">
          <Tooltip title="Delete">
            <Micons icon="delete" className="table-action-icon" />
          </Tooltip>
        </Button>
      </Popconfirm>
    </Row>
  );
};

const StreakDisplay = ({ pointReward }) => {
  if (pointReward.isStreakReward) {
    return (
      <div className={Styles["streak-box"]}>
        <p>
          <span className={Styles["title"]}>Once every: </span>
          <span className={Styles["value"]}>{pointReward.streakFrequency}</span>
        </p>
        <p>
          <span className={Styles["title"]}>For: </span>
          <span className={Styles["value"]}>
            {pointReward.streakCount} {pointReward.streakFrequency}(s)
          </span>
        </p>
      </div>
    );
  } else {
    return (
      <h2>
        <CloseOutlined />
      </h2>
    );
  }
};

const parsePointRelations = (formData) => {
  // First, get the relations that are specific to the current action
  let actionSpecificRelations = [];

  // For example, 'view', 'read', and 'listen' actions will all have benefitIds linked
  if (
    (formData.action === "view" ||
      formData.action === "read" ||
      formData.action === "listen") &&
    formData.benefit_select
  ) {
    actionSpecificRelations = formData.benefit_select.map((benefitId) => ({
      benefitId: benefitId,
    }));
  }
  // 'visit' actions will optionally have merchantIds linked
  else if (formData.action === "visit" && formData.merchant_select) {
    actionSpecificRelations = formData.merchant_select.map((merchantId) => ({
      merchantId: merchantId,
    }));
  }
  // 'claim' actions will optionally have nftIds linked
  else if (formData.action === "claim" && formData.nft_select) {
    actionSpecificRelations = formData.nft_select.map((nftId) => ({
      nftId: nftId,
    }));
  }

  // Then join the action specific relations with tier relations
  let pointRelations = [];
  if (
    formData.tier_select &&
    formData.tier_select.length > 0 &&
    actionSpecificRelations.length > 0
  ) {
    for (let tierId of formData.tier_select) {
      pointRelations = pointRelations.concat(
        actionSpecificRelations.map((relation) => ({
          ...relation,
          tierId,
          tierId,
        }))
      );
    }
  } else if (formData.tier_select && formData.tier_select.length > 0) {
    pointRelations = formData.tier_select.map((tierId) => ({ tierId: tierId }));
  } else {
    pointRelations = actionSpecificRelations;
  }
  return pointRelations;
};

export default EarnPointsSettings;
