const findRoleBFS = (searchString, roleRoot) => {
    let queue = [roleRoot]
    while (queue.length > 0) {
        let cur = queue.shift()
        if (cur.name.toLowerCase().startsWith(searchString.toLowerCase())) {
            return cur
        }

        for (let childRole of cur.children) {
            queue.push(childRole)
        }
    }
    return undefined
}

const findRoleByIdBFS = (id, roleRoot) => {
    let queue = [roleRoot]
    while (queue.length > 0) {
        let cur = queue.shift()
        if (cur.id === id) {
            return cur
        }

        for (let childRole of cur.children) {
            queue.push(childRole)
        }
    }
    return undefined
}

const removeRoleByIdBFS = (id, roleRoot) => {
    const removeRole = (role, index, arr) => {
        if (role.id === id) {
            arr.splice(index,1)
            return true
        }
        return false
    }

    let queue = [roleRoot]
    while (queue.length > 0) {
        let cur = queue.shift()
        if (cur.children.filter(removeRole).length > 0) {
            return roleRoot
        }

        for (let childRole of cur.children) {
            queue.push(childRole)
        }
    }
    return undefined
}


const addRoleBFS = (role, roleRoot) => {
    let queue = [roleRoot]
    while (queue.length > 0) {
        let cur = queue.shift()
        if (cur.id === role.parentRoleId){
            cur.children.unshift(role)
            return roleRoot
        }

        for (let childRole of cur.children) {
            queue.push(childRole)
        }
    }
    return undefined
}

// Returns all the roleIds of a roleTree in an array
const getRoleIds = (role) => {
    let roleIds = [role.id]
    role.children.forEach(child => {
        roleIds = [...roleIds, ...getRoleIds(child)]
    })
    return roleIds
}

export {
    findRoleBFS,
    findRoleByIdBFS,
    removeRoleByIdBFS,
    addRoleBFS,
    getRoleIds
}