import { useState, useEffect } from "react";
import { Form, Tabs, message, Flex, Input, Result } from "antd";
import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalHeader,
  Button,
  Micons,
} from "../../../../components/customAntd";
import { BREADCRUMB_STOREFRONT_MEMBER_ID_UPLOAD } from "../../../../utils/storeFront/constant";
import { getUsersList, changeUserStatus, resetUserPassword } from "../apiUtils";

import SelectUsers from "./users";
import styles from "./style.module.scss";

const DeleteUser = ({ isOpen, store, onClose }) => {
  const [form] = Form.useForm();
  const [tabKey, setTabKey] = useState();
  const [userList, setUserSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [resetPasswordUserDetails, setResetPasswordUserDetails] =
    useState(null);
  const [resetPasswordResponse, setResetPasswordResponse] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const { t } = useTranslation();

  const handleDeleteAndRestore = async (id) => {
    const response = await changeUserStatus(store?.id, id, tabKey === "list");
    if (response?.status === 200) {
      setUserSuggestions((prev) =>
        prev.filter((el) => el.id !== response.data.id)
      );
    } else {
      message.error({
        content: "Delete unsuccessful",
        duration: 2,
        key: "error",
      });
    }
  };

  useEffect(() => {
    if (isOpen) setTabKey("select");
  }, [isOpen]);

  const getList = async () => {
    const response = await getUsersList(store?.id, {
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      filterCondition: { search: searchValue, isActive: tabKey === "select" },
    });
    if (response.status === 200) {
      setUserSuggestions(response.data.users);
      setPagination((prev) => ({
        ...prev,
        total: response?.data?.totalCount || 0,
      }));
    } else {
      setUserSuggestions(null);
    }
  };

  useEffect(() => {
    if (store && tabKey) getList();
  }, [pagination.current, tabKey, store]);

  useEffect(() => {
    if (pagination.current === 1 && store) {
      getList();
    } else {
      setPagination((prev) => ({
        ...prev,
        current: 1,
      }));
    }
  }, [searchValue]);

  const validatePasswords = (_, value) => {
    if (!value || form.getFieldValue("newpassword") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("PASSWORD_MISMATCH")));
  };

  const handleResetUserPassword = async (data) => {
    setResetPasswordResponse("loading");
    const { newpassword } = data;
    const response = await resetUserPassword(
      store?.id,
      resetPasswordUserDetails?.id,
      newpassword
    );
    if (response?.status === 200) {
      form.resetFields();
      setResetPasswordResponse("success");
    } else {
      setResetPasswordResponse("error");
    }
  };

  const showResetPassword = (user) => {
    setResetPasswordUserDetails(user);
  };

  return (
    <Modal
      open={isOpen}
      title={
        <ModalHeader
          breadcrumbs={
            !resetPasswordUserDetails &&
            BREADCRUMB_STOREFRONT_MEMBER_ID_UPLOAD(t)
          }
          title={`${t("MANAGE_USER")} ${t("FOR").toLowerCase()} ${
            store?.title
          }`}
        />
      }
      onCancel={() => {
        setResetPasswordUserDetails(null);
        setResetPasswordResponse("");
        onClose();
      }}
      footer={null}
      width={1000}
      destroyOnClose
      className={styles["user-modal"]}
    >
      {" "}
      {resetPasswordUserDetails ? (
        resetPasswordResponse && resetPasswordResponse !== "loading" ? (
          <Result
            status={resetPasswordResponse}
            subTitle={
              resetPasswordResponse === "error" ? (
                `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`
              ) : (
                <p>
                  {t("PASSWORD_RESET_SUCCESSFUL")}{" "}
                  <strong>{resetPasswordUserDetails?.email}.</strong>
                </p>
              )
            }
            extra={
              <Button
                type="primary"
                icon={
                  <Micons
                    style={{
                      fontSize: 20,
                    }}
                    icon="arrow_back"
                    isHover={false}
                  />
                }
                onClick={() => {
                  form.resetFields();
                  setResetPasswordUserDetails(null);
                  setResetPasswordResponse("");
                }}
              >
                {t("BACK_TO_USERS")}
              </Button>
            }
          />
        ) : (
          <Form
            form={form}
            autoComplete="off"
            onFinish={handleResetUserPassword}
            layout="vertical"
            disabled={resetPasswordResponse === "loading"}
          >
            <h3>
              {t("RESET_PASSWORD")} {t("FOR").toLowerCase()}
            </h3>
            <h3>{resetPasswordUserDetails?.email}</h3>
            <Form.Item
              label={t("NEW_PASSWORD")}
              name="newpassword"
              rules={[
                {
                  required: true,
                  message: t("NEW_PASSWORD_REQUIRED"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t("CONFIRM_PASSWORD")}
              name="confirmpassword"
              rules={[
                {
                  required: true,
                  message: t("CONFIRM_PASSWORD_REQUIRED"),
                },
                {
                  validator: validatePasswords,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Flex gap={20} style={{ marginTop: 40 }}>
              <Button
                buttonType="cancel"
                onClick={() => {
                  form.resetFields();
                  setResetPasswordUserDetails(null);
                  setResetPasswordResponse("");
                }}
                isFullWidth
                disabled={resetPasswordResponse === "loading"}
              >
                {t("CANCEL")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                isFullWidth
                loading={resetPasswordResponse === "loading"}
              >
                {t("SUBMIT")}
              </Button>
            </Flex>
          </Form>
        )
      ) : (
        <Tabs
          defaultActiveKey="select"
          onChange={(key) => {
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
            setTabKey(key);
          }}
          items={[
            {
              label: t("SELECT_USERS"),
              key: "select",
              children: (
                <SelectUsers
                  store={store}
                  tabKey={tabKey}
                  userList={userList}
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  handleDeleteAndRestore={handleDeleteAndRestore}
                  pagination={pagination}
                  setPagination={setPagination}
                  showResetPassword={showResetPassword}
                />
              ),
            },
            {
              label: t("DELETED_USERS"),
              key: "list",
              children: (
                <SelectUsers
                  store={store}
                  tabKey={tabKey}
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  userList={userList}
                  handleDeleteAndRestore={handleDeleteAndRestore}
                  setPagination={setPagination}
                />
              ),
            },
          ]}
        />
      )}
    </Modal>
  );
};

export default DeleteUser;
