import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { t } from 'i18next';

export default function ConfirmationModal({
    show,
    hide,
    title,
    bodyText,
    confirmText,
    action,
}){

    const [performingAction, setPerformingAction] = useState(false)

    const onConfirm = () => {
        setPerformingAction(true)
        action().finally(_ => {
            setPerformingAction(false)
            hide()
        })
    }

    return (
        <Modal centered backdrop={true} show={show} onHide={hide} className="modalStyle">
            <Modal.Header closeButton>
                <Modal.Title className="text-black">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-black">
                {bodyText}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-start">
                <Button variant="secondary" className="btnSecondary" onClick={hide}>{t("CANCEL")}</Button>
                <Button
                    className="btnPrimary"
                    type="submit"
                    onClick={onConfirm}
                    disabled={performingAction}
                >
                    {performingAction ? "..." : confirmText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
