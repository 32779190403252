import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";

import Copy from "../../assets/images/copy.svg";

import { copyToClipboard } from "../../utils/helper";
import { scanURLs } from "../../utils/utils";

import "./styles/copyToClipboard.css";

const CopyToClipboard = ({ network, address, copyText, onlyImage, tooltipText }) => {
  const [isCopy, setIsCopy] = useState(false);
  const [show, setShow] = useState(false);
  const {t} = useTranslation();

  const handleCopy = (e) => {
    e.stopPropagation();
    copyToClipboard(copyText);
    setIsCopy(!isCopy);
  };

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setIsCopy(false);
    setShow(false);
  };

  const handleAddressClick = async (e) => {
    e.stopPropagation();
    const contractLink =  `${scanURLs[network.toLowerCase()]}address/${copyText}`;
    window.open(contractLink, "_blank");
  };

  return (
    <div
      className={`copy-to-clip-board ${onlyImage ? "only-image" : "full-card"}`}
    >
      <OverlayTrigger
        placement="top"
        show={show}
        overlay={
          <Tooltip id="contract-id">
            {isCopy ? `${t("COPIED_TO_CLIPBOARD")}!` : `${tooltipText ? tooltipText : `${t("COPY_TO_CLIPBOARD")}!` }`}
          </Tooltip>
        }
      >
        <button
          onClick={(e) => handleCopy(e)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img src={Copy} alt="Copy to clipboard" />
        </button>
      </OverlayTrigger>
      {!onlyImage && (
        <button className="address-link" onClick={(e) => handleAddressClick(e)}>
          {address}
        </button>
      )}
    </div>
  );
};

export default CopyToClipboard;
