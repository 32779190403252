import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useProfileContext } from "../context/profile.context";
import { useAppContext } from "../context/app.context";
import "./index.css";
import { Accordion } from "react-bootstrap";
import Searchbar from "../../components/searchBar/Searchbar";
import { RoleAccordionItem } from "./role.row";
import { CurrentRole } from "./role.current";
import { findRoleBFS } from "../../utils/rolesHelper";
import { getRoleTree } from "../../redux/role.slice";
import { checkUserRolePermissions } from "../../utils/helper";
import { ROLE_PERMISSIONS } from "../../utils/constants";

export const ViewRoles = ({
  onClickEditRole,
  onClickCreateRole,
  onClickDeleteRole,
}) => {
  const dispatch = useDispatch();
  const { currentUserRole, proxyCompany } = useAppContext();
  const { roleTree, setRoleTree } = useProfileContext();

  const [displayedRoleTree, setDisplayedRoleTree] = useState(roleTree);
  const [searchString, setSearchString] = useState("");
  const [isSearchString, setIsSearchString] = useState(false);

  // Update the displayed role tree whenever the search bar is used
  useEffect(() => {
    let mounted = true;
    if (mounted && roleTree) {
      setDisplayedRoleTree(findRoleBFS(searchString, roleTree));
    }
    return () => (mounted = false);
  }, [searchString]);

  // Update the displayed role tree whenever roleState is changed
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setDisplayedRoleTree(roleTree);
    }
    return () => (mounted = false);
  }, [roleTree]);

  // Get new role tree when currentRole is changed
  useEffect(() => {
    let mounted = true;
    if (mounted && currentUserRole) {
      dispatch(getRoleTree({ currentRoleId: currentUserRole.id })).then(
        (res) => {
          setRoleTree(res.payload);
        }
      );
    }
    return () => (mounted = false);
  }, [currentUserRole]);

  return (
    <div>
      <CurrentRole />
      <br />
      <Searchbar
        setValue={setSearchString}
        setIsSearchString={setIsSearchString}
        placeholder="Search Roles"
        width={"100%"}
      />
      <br />

      {displayedRoleTree ? (
        <Accordion activeKey={displayedRoleTree.id} className="container">
          <div className="row">
            <div className="col-6">
              <div className="pl-2 font-weight-bold">Role</div>
            </div>
            <div className="col-2 font-weight-bold d-flex justify-content-center">
              Members
            </div>
            <div className="col-2 font-weight-bold d-flex justify-content-center">
              Sub Roles
            </div>
            {checkUserRolePermissions(
              currentUserRole,
              ROLE_PERMISSIONS.MANAGEROLES
            ) && (
              <div className="col-2 font-weight-bold d-flex justify-content-center">
                Action
              </div>
            )}
          </div>
          <RoleAccordionItem
            topRoleId={displayedRoleTree.id}
            role={displayedRoleTree}
            level={0}
            onClickEditRole={onClickEditRole}
            onClickCreateRole={onClickCreateRole}
            onClickDeleteRole={onClickDeleteRole}
            searchString={searchString}
          />
        </Accordion>
      ) : (
        <div>No Roles Found</div>
      )}
    </div>
  );
};
