import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Form, Input, Select, Popconfirm, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import {
  Modal,
  Button,
  ModalHeader,
  UploadFile,
  FormWrapper,
} from "../../../../components/customAntd";
import CategoryTreeSelect from "../../../../components/categoryTreeSelect";
import { getLocalesFromDb } from "../../../../utils/common/apiUtils";

const { Item } = Form;

const NewCategory = ({
  isOpen,
  onClose,
  editData,
  logoImage,
  setLogoImage,
  heroImage,
  setHeroImage,
  isUploadingLogoImage,
  isUploadingHeroImage,
  setIsUploadingHeroImage,
  setIsUploadingLogoImage,
  isSubmitting,
  onSubmit,
}) => {
  const [availableLocales, setAvailableLocales] = useState([]);
  const [defaultLocale] = useState("en_US");

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);
  const watchLocales = Form.useWatch("locales", form);

  useEffect(() => {
    const getLocales = async () => {
      const data = await getLocalesFromDb();
      if (data) {
        const localesData = data.map((locale) => {
          return {
            value: locale.localeId,
            label: `${locale.language.language} (${locale.country.country})`,
            isSelected: locale.localeId === defaultLocale,
          };
        });
        setAvailableLocales(localesData);
      }
    };
    getLocales();
  }, [defaultLocale]);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue(editData);
      setHeroImage(editData.imageUrl);
      setLogoImage(editData.logoUrl);
    }
  }, [editData]);

  const onCancel = () => {
    onClose();
  };

  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
              ...item,
              isSelected: true,
            }
          : {
              ...item,
              isSelected: item.value === prevValue ? false : item.isSelected,
            }
      );
    });
  };

  return (
    <>
      <Modal
        destroyOnClose
        onCancel={() => onCancel()}
        open={isOpen}
        centered
        maskClosable={false}
        title={
          <ModalHeader
            title={`${t(
              editData ? "CATEGORIES_UI.EDIT" : "CATEGORIES_UI.NEW"
            )}`}
          />
        }
        width={1200}
        footer={[
          <Button
            buttonType="cancel"
            onClick={() => onCancel()}
            key="cancel-button"
          >
            {t("CANCEL")}
          </Button>,
          <Button
            onClick={() => form.submit()}
            key="create-button"
            loading={isSubmitting}
            disabled={isUploadingHeroImage || isUploadingLogoImage}
          >
            {editData ? t("UPDATE") : t("CREATE")}
          </Button>,
        ]}
      >
        <Row gutter={52}>
          <Col sm={20} md={12}>
            <Form
              form={form}
              onFinish={onSubmit}
              layout="vertical"
              preserve={false}
              disabled={isSubmitting}
            >
              <Item
                label={`${t("DEFAULT_LANGUAGE")}:`}
                name="defaultLocaleId"
                initialValue={defaultLocale}
                rules={[
                  {
                    required: true,
                    message: t("DEFAULT_LANGUAGE_ERROR"),
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  options={availableLocales.map((localEl) => {
                    return {
                      ...localEl,
                      disabled: localEl.isSelected,
                    };
                  })}
                  onChange={(value) =>
                    updateLocales(value, watchDefaultLanguage)
                  }
                />
              </Item>
              <Item
                name={"name"}
                label={t("NAME")}
                rules={[
                  {
                    required: true,
                    message: t("CATEGORIES_UI.NAME_REQUIRED"),
                  },
                ]}
              >
                <Input placeholder={t("CATEGORIES_UI.NAME_PLACEHOLDER")} />
              </Item>

              <Item
                name="logoUrl"
                label={t("LOGO_IMAGE")}
                valuePropName=""
                rules={[
                  {
                    required: true,
                    message: t("UPLOAD_LOGO_IMAGE"),
                  },
                ]}
              >
                <UploadFile
                  showUploadList={false}
                  placeholder={`${t("ASPECT_RATIO")}: 1`}
                  fileFor="benefit"
                  fileSize={5}
                  accept=".jpg, .jpeg, .png, .webp"
                  style={{ width: "144px" }}
                  height={144}
                  image={logoImage}
                  imageFit="contain"
                  onLoading={(value) => {
                    setIsUploadingLogoImage(value);
                  }}
                  onChange={(image) => {
                    setLogoImage(image.s3Url);
                  }}
                  aspectRatio={1}
                />
              </Item>

              <Item
                name="imageUrl"
                label={t("HERO_IMAGE")}
                valuePropName=""
                rules={[
                  {
                    required: true,
                    message: t("UPLOAD_HERO_IMAGE"),
                  },
                ]}
              >
                <UploadFile
                  showUploadList={false}
                  placeholder="390x82"
                  fileFor="benefit"
                  fileSize={5}
                  accept=".jpg, .jpeg, .png, .webp"
                  style={{ width: "144px" }}
                  height={144}
                  image={heroImage}
                  imageFit="contain"
                  onLoading={(value) => {
                    setIsUploadingHeroImage(value);
                  }}
                  onChange={(image) => {
                    setHeroImage(image.s3Url);
                  }}
                  dimensions={{
                    width: 390,
                    height: 82,
                  }}
                />
              </Item>

              {/* <Item
                name={"order"}
                label={t("POSITION")}
                rules={[
                  {
                    type: "number",
                    min: 1,
                    message: "SHOULD be an integer",
                  },
                ]}
              >
                <InputNumber
                  placeholder="placeholder"
                  precision={0}
                  min={1}
                  style={{ width: "100%" }}
                />
              </Item> */}
              {(editData?.parentCategoryId || !editData) && (
                <Item
                  label={t("CATEGORIES_UI.PARENT_CATEGORY")}
                  name="parentCategoryId"
                  rules={[
                    {
                      required: true,
                      message: t("CATEGORIES_UI.PARENT_CATEGORY_REQUIRED"),
                    },
                  ]}
                >
                  <CategoryTreeSelect
                    startLevel={1}
                    endLevel={2}
                    name="parentCategoryId"
                    placeholder={t("CATEGORIES_UI.PARENT_CATEGORY_PLACEHOLDER")}
                  />
                </Item>
              )}
              <FormWrapper
                heading={t("LANGUAGE_OPTIONS.HEADING")}
                description={t("LANGUAGE_OPTIONS.DESCRIPTION")}
                wrapperStyles={{ marginTop: 40 }}
              >
                <Form.List name="locales">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }, index) => (
                        <Fragment key={key}>
                          <Row>
                            <Col lg={12} md={24}>
                              <Item
                                label={t("LANGUAGE")}
                                name={[name, "localeId"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "LANGUAGE_OPTIONS.SELECT_LANGUAGE"
                                    ),
                                  },
                                ]}
                              >
                                <Select
                                  options={availableLocales.map((localEl) => ({
                                    ...localEl,
                                    disabled: localEl.isSelected,
                                  }))}
                                  onChange={(value) => {
                                    updateLocales(
                                      value,
                                      watchLocales[index]?.localeId
                                    );
                                  }}
                                  placeholder={t("SELECT")}
                                />
                              </Item>
                              <Item
                                label={t("LANGUAGE_OPTIONS.NAME")}
                                name={[name, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("LANGUAGE_OPTIONS.ENTER_NAME"),
                                  },
                                ]}
                              >
                                <Input placeholder={t("ADD_NAME")} />
                              </Item>
                            </Col>
                          </Row>
                          <Popconfirm
                            title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                            onConfirm={() => {
                              setAvailableLocales((prev) =>
                                prev.map((item) =>
                                  item.value === watchLocales[index]?.value
                                    ? { ...item, isSelected: false }
                                    : item
                                )
                              );
                              remove(name);
                            }}
                            okText={t("YES")}
                            cancelText={t("NO")}
                          >
                            <Button
                              type="link"
                              icon={<DeleteOutlined />}
                              style={{
                                color: "var(--color-form-error)",
                                marginTop: -20,
                                float: "right",
                              }}
                            >
                              {t("LANGUAGE_OPTIONS.DELETE_LOCALE")}
                            </Button>
                          </Popconfirm>

                          {fields.length !== 0 && (
                            <Divider
                              style={{
                                marginTop: 20,
                                marginBottom: 20,
                                borderColor: "#D9D9D9",
                              }}
                            />
                          )}
                        </Fragment>
                      ))}

                      <Item style={{ marginBottom: 0 }}>
                        <Button
                          type="link"
                          onClick={fields.length >= 3 ? () => {} : () => add()}
                          icon={<PlusOutlined />}
                          style={{ color: "var(--color-brand-primary)" }}
                        >
                          {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
                        </Button>
                      </Item>
                    </>
                  )}
                </Form.List>
              </FormWrapper>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default NewCategory;
