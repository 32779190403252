import { useEffect, useCallback } from "react";
import { useRef } from "react";
import "./styles/landingfooter.css";
const LandingFooter = ({ setCanvasHeight }) => {
  const footerRef = useRef();
  const handleResize = useCallback(() => {
    setCanvasHeight(
      1 + document.body.offsetHeight - footerRef.current.clientHeight
    );
  });
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <footer className="footer-new" ref={footerRef}>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center flex-direction-sm-column">
          <p>
            © Copyright 2024 | Three Core AI, LLC. All Rights Reserved&nbsp;|&nbsp;
            <span className="links">
              <a
                href="https://www.threecore.ai/en/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.threecore.ai/en/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};
export default LandingFooter;
