import DefaultProfileImage from "../../../assets/images/default-profile.svg";
import { useAppContext } from "../../context/app.context";
import Styles from "./styles/userProfile.module.scss";

const UserProfile = ({ collapsed }) => {
  const { currentUserData, currentUserRole} = useAppContext();
  return (
    <div>
      <div
        className={`${Styles["profile-image-wrapper"]} ${
          collapsed ? Styles.collapsed : ""
        }`}
      >
        <img src={DefaultProfileImage} alt="" />
        {!collapsed && (
          <div>
            <p>{currentUserData.name}</p>
            <p>
              Company:
              {!!localStorage.getItem("proxyCompanyName") ? (
                <>
                  <span> {localStorage.getItem("proxyCompanyName") }</span>
                  {(<span className={Styles["proxy-badge"]}>PROXY</span>)}
                </>
              ) : (
                <span> {currentUserData.company.name}</span>
              )}
            </p>
            {currentUserRole && <p>Role: {currentUserRole.name}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
