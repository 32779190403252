import "./styles/landingheadernew.css";
import logo from "../../assets/images/engaged-ai-logo.jpg";
const LandingHeader = () => {
  const imageStyles = { height: 60, width: 60 };
  return (
    <header className="header-new ">
      <div className="container d-flex justify-content-between align-items-center">
        <div>
          <img src={logo} alt="logo" style={imageStyles} />
        </div>
      </div>
    </header>
  );
};
export default LandingHeader;
