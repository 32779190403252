import { useState, useEffect, Fragment } from "react";
import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Popconfirm,
} from "antd";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { t } from "i18next";

import { BREADCRUMB_ADD_NEW_TOKEN_GATED_EBOOK } from "../../../../../utils/benefits/constant";
import {
  Modal,
  Button,
  ModalHeader,
  UploadFile,
  Switch,
  Radio,
  Micons,
} from "../../../../../components/customAntd";
import EpubReader from "../../../../../components/epubReader";
import { getLocalesFromDb } from "../../../../../utils/common/apiUtils";
import CategoryTreeSelect from "../../../../../components/categoryTreeSelect";

import Styles from "../styles/ebooks.module.scss";

const { Item } = Form;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const NewEbook = ({
  form,
  isEdit,
  isOpen,
  onCancel,
  onSubmitClick,
  onSubmit,
  loading,
  isDataLoading,
  setCoverImage,
  setEbookFile,
  ebookFile,
  coverImage,
  localisedCoverImages,
  setLocalisedCoverImages,
}) => {
  const [defaultLocale] = useState("en_US");
  const [availableLocales, setAvailableLocales] = useState([]);
  const [isEbookLoading, setIsEbookLoading] = useState(false);
  const [isCoverImageLoading, setIsCoverImageLoading] = useState(false);
  const [isReaderOpen, setIsReaderOpen] = useState(false);

  const watchLocales = Form.useWatch("locales", form);
  const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);
  const title = Form.useWatch("title", form);

  useEffect(() => {
    const getLocales = async () => {
      const data = await getLocalesFromDb();
      if (data) {
        const localesData = data.map((locale) => ({
          value: locale.localeId,
          label: `${locale.language.language} (${locale.country.country})`,
          isSelected: locale.localeId === defaultLocale,
        }));
        setAvailableLocales(localesData);
      }
    };
    getLocales();
  }, [defaultLocale]);

  //Set available locales on editing the current record
  useEffect(() => {
    console.log("UseEffect");
    if (isEdit) {
      setAvailableLocales((prev) => {
        return prev.map((locale) => {
          return {
            ...locale,
            isSelected:
              !!form
                .getFieldValue("locales")
                ?.find((localeEl) => localeEl?.localeId === locale?.value) ||
              form.getFieldValue("defaultLocaleId") === locale?.value,
          };
        });
      });
    }
  }, [isEdit]);

  // Updated locals state if someone changed language in the form
  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
              ...item,
              isSelected: true,
            }
          : {
              ...item,
              isSelected: item.value === prevValue ? false : item.isSelected,
            }
      );
    });
  };

  //To set available locales to default state when modal is closed
  const clearAvailableLocales = () => {
    setAvailableLocales((prev) => {
      return prev.map((locale) => {
        return {
          ...locale,
          isSelected: locale.value === defaultLocale,
        };
      });
    });
  };

  return (
    <>
      <Modal
        title={
          <ModalHeader
            title={isEdit ? t("EBOOK.EDIT") : t("EBOOK.NEW")}
            breadcrumbs={BREADCRUMB_ADD_NEW_TOKEN_GATED_EBOOK(isEdit, t)}
          />
        }
        open={isOpen}
        centered
        width={1200}
        onCancel={onCancel}
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button
            buttonType="cancel"
            onClick={() => {
              clearAvailableLocales();
              onCancel();
            }}
            key="cancel-button"
          >
            {t("CANCEL")}
          </Button>,
          <Button
            onClick={onSubmitClick}
            loading={loading || isDataLoading}
            disabled={isCoverImageLoading || isEbookLoading}
            key="create-button"
          >
            {isDataLoading
              ? `${t("LOADING")}...`
              : isEdit
              ? t("EBOOK.UPDATE")
              : t("EBOOK.CREATE")}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          preserve={false}
          scrollToFirstError
          requiredMark={false}
          disabled={loading}
          initialValues={{ primary_category: 33 }}
        >
          <Row gutter={25}>
            <Col lg={7}>
              {isEdit && (
                <Item name="id" hidden>
                  <Input />
                </Item>
              )}
              <Item
                label={`${t("EBOOK.UPLOAD_COVER")} :`}
                name="image"
                extra={
                  <>
                    <p>{`${t(
                      "FILE_UPLOAD.RECOMMENDED_SIZE"
                    )} - 1024×768 (4:3)`}</p>
                    <p>{`${t("FILE_TYPES_SUPPORTED")}  - JPG, JPEG, PNG. ${t(
                      "MAX_SIZE"
                    )}: 5${t("MB")}`}</p>
                  </>
                }
                valuePropName=""
              >
                <UploadFile
                  showUploadList={false}
                  placeholder=" 1024×768 (4:3)"
                  fileFor="benefit"
                  fileSize={5}
                  accept=".jpg, .jpeg, .png"
                  height={170}
                  image={coverImage}
                  onLoading={(value) => setIsCoverImageLoading(value)}
                  onChange={(image) => {
                    setCoverImage(image.s3Url);
                  }}
                />
              </Item>
            </Col>
            <Col lg={17}>
              <Item
                label={`${t("EBOOK.UPLOAD")}:`}
                name="ebook"
                extra={`${t("FILE_TYPES_SUPPORTED")}: .epub`}
                valuePropName=""
              >
                <UploadFile
                  showUploadList={false}
                  placeholder={t("UPLOAD")}
                  fileFor="benefit"
                  fileSize={100}
                  accept=".epub"
                  height={32}
                  showFileName
                  files={[ebookFile]}
                  className={Styles["ebook-upload"]}
                  onLoading={(value) => setIsEbookLoading(value)}
                  onChange={(ebook) => {
                    setEbookFile({
                      name: ebook.s3Url.split("/").reverse()[0],
                      resourceUrl: ebook.s3Url,
                      type: "digitalbook",
                    });
                  }}
                />
              </Item>
              <div
                className={`${Styles["preview-container"]} ${
                  ebookFile ? Styles["hover"] : ""
                }`}
              >
                <button
                  type="button"
                  onClick={() => setIsReaderOpen(true)}
                  disabled={!ebookFile}
                >
                  {coverImage ? (
                    <>
                      <img src={coverImage} alt={t("PREVIEW")} />
                      <Micons
                        icon="menu_book"
                        isHover={false}
                        wrapperClassName={Styles["preview-icon"]}
                      />
                    </>
                  ) : (
                    <>
                      <p>{t("PREVIEW")}</p>
                      <Micons
                        icon="menu_book"
                        isHover={false}
                        style={{ fontSize: 42 }}
                      />
                    </>
                  )}
                </button>
              </div>
              <Item
                label={`${t("DEFAULT_LANGUAGE")}:`}
                name="defaultLocaleId"
                initialValue={defaultLocale}
                rules={[
                  {
                    required: true,
                    message: t("DEFAULT_LANGUAGE_ERROR"),
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  options={availableLocales.map((localEl) => {
                    return {
                      ...localEl,
                      disabled: localEl.isSelected,
                    };
                  })}
                  onChange={(value) =>
                    updateLocales(value, watchDefaultLanguage)
                  }
                />
              </Item>
              <Row>
                <Item
                  label={`${t("BENEFIT_CATEGORIES.LABEL")} :`}
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: t("BENEFIT_CATEGORIES.SELECT_CATEGORY"),
                    },
                  ]}
                >
                  <CategoryTreeSelect
                    name="categoryId"
                    style={{ width: 250 }}
                  />
                </Item>
              </Row>
              <Item
                label={t("EBOOK.TITLE")}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t("EBOOK.TITLE_ERROR"),
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder={t("EBOOK.TITLE_PLACEHOLDER")} />
              </Item>
              <Item
                label={`${t("EBOOK.TITLE_PREFIX")}:`}
                name="code_short_description"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 10) {
                        return Promise.reject(
                          new Error(t("EBOOK.CODE_PREFIX_VALIDATION_ERROR"))
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <TextArea
                  placeholder={t("PREFIX_TITLE_VALIDATION_ERROR")}
                  style={{ resize: "none" }}
                />
              </Item>
              <Item
                label={t("EBOOK.AUTHOR_FIELD")}
                name="author"
                rules={[
                  {
                    required: true,
                    message: t("EBOOK.AUTHOR_ERROR"),
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder={t("EBOOK.AUTHOR_PLACEHOLDER")} />
              </Item>
              <Item
                label={t("EBOOK.DESC")}
                name="description"
                rules={[
                  {
                    required: true,
                    message: t("EBOOK.DESC_ERROR"),
                    whitespace: true,
                  },
                ]}
              >
                <MDEditor
                  overflow={false}
                  placeholder={t("EBOOK.DESC_PLACEHOLDER")}
                  previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                  }}
                />
                {/* <TextArea placeholder={t("EBOOK.DESC_PLACEHOLDER")} rows={5} /> */}
              </Item>
              <Item
                label={t("REDEEMABLE_PERIOD")}
                name="redeemable_period"
                rules={[
                  {
                    required: true,
                    message: t("REDEEMABLE_PERIOD_ERROR"),
                  },
                ]}
              >
                <RangePicker
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD HH:mm"
                  disabledDate={(current) => {
                    return (
                      current &&
                      current < dayjs().subtract(1, "day").endOf("day")
                    );
                  }}
                  placeholder={[t("START_DATE"), t("END_DATE")]}
                  showTime={{ format: "HH:mm" }}
                />
              </Item>
              <Item
                label={`${t("STATUS")}:`}
                name="status"
                rules={[
                  {
                    required: true,
                    message: t("SELECT_STATUS"),
                  },
                ]}
              >
                <Radio.Group style={{ width: "100%" }}>
                  <Row gutter={20}>
                    <Col xs={24} md={12}>
                      <Radio style={{ width: "100%" }} value="draft">
                        {t("DRAFT")}
                      </Radio>
                    </Col>
                    <Col xs={24} md={12}>
                      <Radio style={{ width: "100%" }} value="active">
                        {t("ACTIVE")}
                      </Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Item>
              <Item label={`${t("ACTIVE_DATE")}:`} name="active_date">
                <DatePicker
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD HH:mm"
                  disabledDate={(current) => {
                    return (
                      current &&
                      current < dayjs().subtract(1, "day").endOf("day")
                    );
                  }}
                  placeholder={[t("ACTIVE_DATE")]}
                  showTime={{ format: "HH:mm" }}
                />
              </Item>
              <Item
                label={`${t("SHOWIMAGES_ONLY")}:`}
                name="show_images_only"
                rules={[
                  {
                    required: true,
                    message: t("SHOWIMAGES_ONLY_ERROR"),
                  },
                ]}
              >
                <Radio.Group style={{ width: "100%" }}>
                  <Row gutter={20}>
                    <Col xs={24} md={12}>
                      <Radio style={{ width: "100%" }} value={false}>
                        {t("NO")}
                      </Radio>
                    </Col>
                    <Col xs={24} md={12}>
                      <Radio style={{ width: "100%" }} value={true}>
                        {t("YES")}
                      </Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Item>
              <Item label={`${t("ALLOW_DOWNLOAD")}:`} name="allow_download">
                <Switch />
              </Item>
              <Form.List name="locales">
                {(fields, { add, remove }) => (
                  <div className="languageOptions">
                    <h3 className="heading">{t("LANGUAGE_OPTIONS.HEADING")}</h3>
                    <p className="info">{t("LANGUAGE_OPTIONS.DESCRIPTION")}</p>
                    {fields.map(({ key, name }, index) => (
                      <Fragment key={key}>
                        <Row gutter={[25, 25]}>
                          <Col lg={8}>
                            <Item
                              label={`${t("EBOOK.LOCALISED_COVER_IMAGE")} :`}
                              extra={
                                <>
                                  <p>
                                    {`${t(
                                      "FILE_UPLOAD.RECOMMENDED_SIZE"
                                    )} - 1024×768 (4:3)`}
                                  </p>
                                  <p>
                                    {`${t(
                                      "FILE_TYPES_SUPPORTED"
                                    )}  - JPG, JPEG, PNG. ${t(
                                      "MAX_SIZE"
                                    )}: 5${t("MB")}`}
                                  </p>
                                </>
                              }
                              valuePropName=""
                            >
                              <UploadFile
                                showUploadList={false}
                                placeholder=" 1024×768 (4:3)"
                                fileFor="benefit"
                                fileSize={5}
                                accept=".jpg, .jpeg, .png"
                                height={170}
                                image={localisedCoverImages[index]}
                                onLoading={(value) =>
                                  setIsCoverImageLoading(value)
                                }
                                onChange={(image) => {
                                  setLocalisedCoverImages((prev) => {
                                    prev[index] = image?.s3Url;
                                    return [...prev];
                                  });
                                }}
                              />
                            </Item>
                          </Col>
                          <Col lg={16}>
                            <Item
                              label={`${t("LANGUAGE")} :`}
                              name={[name, "localeId"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "LANGUAGE_OPTIONS.SELECT_LANGUAGE"
                                  ),
                                },
                              ]}
                            >
                              <Select
                                options={availableLocales.map((localEl) => ({
                                  ...localEl,
                                  disabled: localEl.isSelected,
                                }))}
                                onChange={(value) =>
                                  updateLocales(
                                    value,
                                    watchLocales[index].localeId
                                  )
                                }
                                placeholder={t("SELECT")}
                              />
                            </Item>
                            <Item
                              label={`${t(
                                "LANGUAGE_OPTIONS.LOCALISED_TITLE"
                              )} :`}
                              name={[name, "title"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "LANGUAGE_OPTIONS.LOCALISED_TITLE_ERROR"
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t(
                                  "EBOOK.LOCALISED_TITLE_PLACEHOLDER"
                                )}
                              />
                            </Item>
                            <Item
                              label={t("LANGUAGE_OPTIONS.DESC")}
                              name={[name, "description"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("LANGUAGE_OPTIONS.ENTER_DESC"),
                                  whitespace: true,
                                },
                              ]}
                            >
                              <MDEditor
                                overflow={false}
                                placeholder={t(
                                  "EBOOK.LOCALISED_DESC_PLACEHOLDER"
                                )}
                                previewOptions={{
                                  rehypePlugins: [[rehypeSanitize]],
                                }}
                              />
                              {/* <Input.TextArea
                                placeholder={t(
                                  "EBOOK.LOCALISED_DESC_PLACEHOLDER"
                                )}
                                rows={4}
                              /> */}
                            </Item>
                          </Col>
                          {fields.length > 0 && (
                            <Divider orientation="center">
                              <Popconfirm
                                title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                                onConfirm={() => {
                                  setLocalisedCoverImages((prev) => {
                                    prev.splice(index, 1);
                                    return [...prev];
                                  });
                                  remove(index);
                                  setAvailableLocales((prev) => {
                                    return prev.map((item) =>
                                      item.value ===
                                      watchLocales[index].localeId
                                        ? {
                                            ...item,
                                            isSelected: false,
                                          }
                                        : item
                                    );
                                  });
                                  remove(name);
                                }}
                                onCancel={() => {}}
                                okText={t("YES")}
                                cancelText={t("NO")}
                              >
                                <DeleteOutlined
                                  className="deleteIcon"
                                  style={{ fontSize: 24 }}
                                />
                              </Popconfirm>
                            </Divider>
                          )}
                        </Row>
                      </Fragment>
                    ))}

                    <Button
                      type="primary"
                      className="add-locale-button"
                      onClick={fields.length >= 3 ? () => {} : () => add()}
                      icon={<PlusOutlined />}
                      disabled={fields.length >= 3}
                    >
                      {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
                    </Button>
                  </div>
                )}
              </Form.List>
            </Col>
          </Row>
        </Form>
        <EpubReader
          file={ebookFile?.resourceUrl}
          title={title}
          isOpen={isReaderOpen}
          setIsOpen={setIsReaderOpen}
        />
      </Modal>
    </>
  );
};

export default NewEbook;
