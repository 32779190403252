import { Fragment } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Radio,
  Col,
  Row,
  Input,
  Select,
  Popconfirm,
  Divider,
} from "antd";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {
  FormWrapper,
  Micons,
  Button,
  Modal,
} from "../../../../components/customAntd";

const { Item } = Form;

const LoyaltyProgram = ({
  t,
  watchEnableProgramTable,
  setIsLoyaltyProgramModalOpen,
}) => {
  return (
    <FormWrapper heading={t("STOREFRONT_UI.LOYALTY_PROGRAM")}>
      <Item
        label={`${t("STOREFRONT_UI.SHOW_LOYALTY_TABLE")}:`}
        name="enableProgramTable"
        rules={[
          {
            required: true,
            message: t("STOREFRONT_UI.SHOW_LOYALTY_TABLE_ERROR"),
          },
        ]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value={false}>
                {t("NO")}
              </Radio>
            </Col>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value={true}>
                {t("YES")}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Item>
      {watchEnableProgramTable && (
        <>
          <Item
            label={`${t("STOREFRONT_UI.HEADLINE")} :`}
            name="headerText"
            rules={[
              {
                required: true,
                message: t("STOREFRONT_UI.HEADLINE_VALIDATION"),
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder={t("STOREFRONT_UI.POINTS_HEADLINE_PLACEHOLDER")}
            />
          </Item>
          <Item label={`${t("STOREFRONT_UI.CAPTION")} :`} name="bodyText">
            <MDEditor
              overflow={false}
              placeholder={t("STOREFRONT_UI.CAPTION_PLACEHOLDER")}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
            />
            {/* <TextArea
        placeholder={t("STOREFRONT_UI.CAPTION_PLACEHOLDER")}
        rows={3}
      /> */}
          </Item>
          <Item>
            <Button
              isFullWidth
              onClick={() => setIsLoyaltyProgramModalOpen(true)}
            >
              <Micons icon="edit" style={{ fontSize: 16 }} isHover={false} />
              {t("STOREFRONT_UI.EDIT_LOYALTY_TABLE")}
            </Button>
          </Item>
        </>
      )}
    </FormWrapper>
  );
};

export const LoyaltyProgramModal = ({
  t,
  isLoyaltyProgramModalOpen,
  pointsForm,
  tiers,
  handleRewardDragEnd,
  watchMarketingData,
  watchLocalesRewards,
  availableLocalesRewards,
  setAvailableLocalesRewards,
  marketingData,
  updateLocales,
  onSubmit,
  onCancel
}) => {
  return (
    <Modal
      centered
      open={isLoyaltyProgramModalOpen}
      title={t("STOREFRONT_UI.LOYALTY_PROGRAM")}
      width={976}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      onCancel={onCancel}
    //   onCancel={() => setIsLoyaltyProgramModalOpen(false)}
      footer={[
        <Button
          buttonType="cancel"
          key="cancel-button"
          onClick={onCancel}
        //   onClick={() => setIsLoyaltyProgramModalOpen(false)}
        >
          {t("CANCEL")}
        </Button>,
        <Button
          key="create-button"
          onClick={() => {
            pointsForm.submit();
          }}
        >
          {t("STOREFRONT_UI.SAVE_AND_ADD")}
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={pointsForm}
        onFinish={onSubmit}
        initialValues={{ marketingData: [{}] }}
      >
        <label>{`${t("STOREFRONT_UI.TIER_POINTS")}:`}</label>
        <Row gutter={[20, 20]}>
          {tiers?.map((tier) => {
            return (
              <Col lg={6}>
                <Item label={tier.name}>
                  <Input disabled value={tier.pointThreshold || "0"} />
                </Item>
              </Col>
            );
          })}
        </Row>
        <DragDropContext onDragEnd={handleRewardDragEnd}>
          <Row gutter={[20, 20]} style={{ marginBottom: 10 }}>
            <Col lg={1}></Col>
            <Col lg={12}>{`${t("STOREFRONT_UI.REWARDS")}:`}</Col>
            <Col lg={11}>{`${
              tiers.length > 1
                ? t("STOREFRONT_UI.SELECT_TIERS")
                : t("STOREFRONT_UI.SELECT_TIER")
            }:`}</Col>
          </Row>
          <Droppable droppableId="droppable-1">
            {(provided) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Form.List name="marketingData">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name }, index) => (
                          <Draggable
                            draggableId={`${key}`}
                            index={index}
                            key={`${key}`}
                          >
                            {(provided) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <Row gutter={[20]}>
                                    <Col lg={1}>
                                      <Micons
                                        icon="drag_handle"
                                        isHover={false}
                                        style={{
                                          fontSize: 24,
                                          color: "var(--color-drag-icon)",
                                        }}
                                        wrapperStyle={{
                                          marginRight: 10,
                                          marginTop: 5,
                                        }}
                                        {...provided.dragHandleProps}
                                      />
                                    </Col>
                                    <Col lg={12}>
                                      <Item
                                        name={[name, "reward"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: t(
                                              "STOREFRONT_UI.REWARD_VALIDATION"
                                            ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder={t(
                                            "STOREFRONT_UI.REWARD_DESC_PLACEHOLDER"
                                          )}
                                        />
                                      </Item>
                                    </Col>
                                    <Col lg={11}>
                                      <Row gutter={[20, 10]} align="start">
                                        <Col lg={20}>
                                          <Item
                                            name={[name, "tiers"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: t(
                                                  "STOREFRONT_UI.TIER_VALIDATION"
                                                ),
                                              },
                                            ]}
                                          >
                                            <Select
                                              mode="multiple"
                                              placeholder={t(
                                                "STOREFRONT_UI.SELECT_TIER_PLACEHOLDER"
                                              )}
                                              options={tiers.map((tier) => {
                                                return {
                                                  label: tier.name,
                                                  value: tier.tierId,
                                                };
                                              })}
                                            />
                                          </Item>
                                        </Col>
                                        {fields.length > 1 && (
                                          <Col lg={3}>
                                            <Popconfirm
                                              title={t(
                                                "STOREFRONT_UI.REWARD_DELETE_CONFIRMATION"
                                              )}
                                              onConfirm={() => {
                                                remove(name);
                                                /*Deleting the specific point description in 
                                                  language options for points*/
                                                pointsForm.setFieldValue(
                                                  "rewardLocales",
                                                  watchLocalesRewards?.map(
                                                    (localeEl) => {
                                                      const rewardArray = [
                                                        ...localeEl.rewards,
                                                      ];
                                                      rewardArray.splice(
                                                        index,
                                                        1
                                                      );
                                                      return {
                                                        ...localeEl,
                                                        rewards: rewardArray,
                                                      };
                                                    }
                                                  )
                                                );
                                              }}
                                              onCancel={() => {}}
                                              okText={t("YES")}
                                              cancelText={t("NO")}
                                            >
                                              <DeleteOutlined
                                                className="deleteIcon"
                                                style={{
                                                  fontSize: 16,
                                                  color: "red",
                                                  height: 35,
                                                }}
                                              />
                                            </Popconfirm>
                                          </Col>
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <Button
                          type="link"
                          icon={<PlusOutlined />}
                          style={{
                            color: "var(--color-brand-primary)",
                            marginBottom: 20,
                          }}
                          onClick={() => {
                            pointsForm.setFieldValue(
                              // Adding an empty field to the reward language options
                              "rewardLocales",
                              watchLocalesRewards?.map((localeEl) => {
                                return {
                                  ...localeEl,
                                  rewards: [...localeEl?.rewards, {}],
                                };
                              })
                            );
                            add();
                          }}
                        >
                          {t("STOREFRONT_UI.ADD_REWARD")}
                        </Button>
                      </>
                    )}
                  </Form.List>
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        <FormWrapper
          heading={t("LANGUAGE_OPTIONS.HEADING")}
          description={t("STOREFRONT_UI.LANGUAGE_OPTIONS.DESC")}
        >
          <Form.List name="rewardLocales">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }, index) => (
                  <Fragment key={key}>
                    <Row gutter={[13]}>
                      <Col lg={8}>
                        <Item
                          label={`${t("LANGUAGE")} :`}
                          name={[name, "localeId"]}
                          rules={[
                            {
                              required: true,
                              message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                            },
                          ]}
                        >
                          <Select
                            options={availableLocalesRewards.map((localEl) => ({
                              ...localEl,
                              disabled: localEl.isSelected,
                            }))}
                            onChange={(value) => {
                              updateLocales(
                                value,
                                watchLocalesRewards[index].localeId,
                                "rewards"
                              );
                            }}
                            placeholder={t("SELECT")}
                          />
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Form.List name={[name, "rewards"]}>
                          {(fields) =>
                            fields.map(({ key, name }, index) => (
                              <Fragment key={key}>
                                <Item
                                  name={[name, "reward"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        "STOREFRONT_UI.REWARD_VALIDATION"
                                      ),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={`${
                                      marketingData[index]?.reward ||
                                      t(
                                        "STOREFRONT_UI.TRANSLATED_REWARD_DESCRIPTION"
                                      )
                                    }`}
                                    /* The placeholder for rewards in language options shows the corresponding
                                    reward description in default language or the default string. */
                                  />
                                </Item>
                              </Fragment>
                            ))
                          }
                        </Form.List>
                      </Col>
                    </Row>

                    <Popconfirm
                      title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                      onConfirm={() => {
                        setAvailableLocalesRewards((prev) => {
                          return prev.map((item) =>
                            item.value === watchLocalesRewards[index].localeId
                              ? {
                                  ...item,
                                  isSelected: false,
                                }
                              : item
                          );
                        });
                        remove(name);
                      }}
                      onCancel={() => {}}
                      okText={t("YES")}
                      cancelText={t("NO")}
                    >
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        style={{
                          color: "var(--color-form-error)",
                          placeContent: "flex-end",
                          marginTop: -20,
                          float: "right",
                        }}
                      >
                        {t("LANGUAGE_OPTIONS.DELETE_LOCALE")}
                      </Button>
                    </Popconfirm>
                    {fields.length !== 0 && (
                      <Divider
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          borderColor: "#D9D9D9",
                        }}
                      />
                    )}
                  </Fragment>
                ))}

                <Item style={{ marginBottom: 0 }}>
                  <Button
                    type="link"
                    onClick={
                      fields.length >= 3
                        ? () => {}
                        : () =>
                            add({
                              // Adding as many input fields as there are reward desriptions to each language option
                              rewards: watchMarketingData?.map(() => ({})),
                            })
                    }
                    icon={<PlusOutlined />}
                    style={{ color: "var(--color-brand-primary)" }}
                    disabled={fields.length >= 3}
                  >
                    {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
                  </Button>
                </Item>
              </>
            )}
          </Form.List>
        </FormWrapper>
      </Form>
    </Modal>
  );
};
export default LoyaltyProgram;
