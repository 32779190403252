import { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Button, Menu, Tooltip, Dropdown, Space } from "antd";
import Icon, { CaretDownOutlined } from "@ant-design/icons";

import { Micons } from "../../customAntd";
import UserProfile from "../../navigation/userProfile";
import { useAppContext } from "../../context/app.context";
import { ReactComponent as ArrowIcon } from "../../../assets/images/arrow.svg";
import Logo from "../../../assets/images/engaged-ai-logo.jpg";
import { hasRequiredPermissions } from "../../../utils/helper";
import { ROLE_PERMISSIONS, localeDetail } from "../../../utils/constants";
import { getLocalesFromDb } from "../../../utils/common/apiUtils";
import { routesConfig } from "../../../routes/config";

import Styles from "./styles/private.module.scss";

const { Sider } = Layout;
const MOBILE_LIMIT = 768;

const subMenus = {
  launchpad: [
    "collections",
    "programs",
    "benefits",
    "storefront-builder",
    "merchants",
  ],
};
const PrivateLayout = ({ onLogOut, children }) => {
  const [leftNavWidth, setLeftNavWidth] = useState(249);
  const [showLogOutTooltip, setShowLogOutTooltip] = useState(false);
  const [showHelpTooltip, setShowHelpTooltip] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [openKeys, setOpenKeys] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [defaultLocale] = useState("en_US");
  const {
    currentUserRole,
    proxyCompany,
    setCurrentLocale,
    currentLocale,
    setSidebarCollapsed,
  } = useAppContext();

  const navigate = useNavigate();
  let location = useLocation();
  function handleWindowSizeChange() {
    if (window.innerWidth <= MOBILE_LIMIT) {
      localStorage.setItem("isCollapsed", true);
      setCollapsed(true);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // Get all locales
  useEffect(() => {
    const getLocales = async () => {
      const localeArray = [];
      const data = await getLocalesFromDb();
      if (data) {
        data.forEach((locale) => {
          if (
            locale.isSupported &&
            (locale.localeId === "en_US" || locale.localeId === "ja_JP")
          ) {
            localeArray.push({
              key: locale.localeId,
              label: localeDetail[locale.localeId].label,
              icon: localeDetail[locale.localeId].icon,
            });
          }
        });
      }
      setLanguageOptions(localeArray);
      setCurrentLocale(defaultLocale);
    };
    getLocales();
  }, [defaultLocale]);

  const [collapsed, setCollapsed] = useState(
    window.innerWidth <= MOBILE_LIMIT
      ? true
      : localStorage.getItem("isCollapsed")
      ? true
      : false
  );
  const [current, setCurrent] = useState(location.pathname);

  const toggleCollapse = () => {
    if (!collapsed) {
      localStorage.setItem("isCollapsed", true);
    } else {
      localStorage.removeItem("isCollapsed");
    }

    setSidebarCollapsed(!collapsed);
    setCollapsed(!collapsed);
  };

  const scannerRoleOnly =
    currentUserRole.rolePermissions?.length === 1 &&
    currentUserRole.rolePermissions[0].permissionsMetadata.name ===
      ROLE_PERMISSIONS.SCANNER;

  // Function to generate menu items dynamically based on permissions
  const generateMenuItems = (routesConfig) => {
    const generateItem = (route) => {
      // If the route has no menu or the user doesn't have permission, skip it
      if (
        !route.menu ||
        !hasRequiredPermissions(
          currentUserRole.rolePermissions,
          route.permissions
        )
      ) {
        return null;
      }

      let children = null;

      // If the route has children, recursively generate menu items for them
      if (route.children && route.children.length > 0) {
        children = route.children
          .map((child) => generateItem(child)) // Recursively process children
          .filter((item) => item !== null); // Filter out null items (those without permission)

        // If children array is empty, set it to null
        if (children.length === 0) {
          children = null;
        }
      }

      // Return the menu item
      const menuItem = {
        key: route.menu.key,
        icon: route.menu.icon,
        label: route.menu.label,
        link: route.path,
      };

      // Only add the children key if it's not null
      if (children) {
        menuItem.children = children;
      } else {
        // Remove children from the menuItem if it's empty or null
        delete menuItem.children;
      }

      return menuItem;
    };

    // Generate menu items for the top-level routes
    return routesConfig
      .map((child) => generateItem(child))
      .filter((item) => item !== null); // Filter out null items (those without permission)
  };

  const items = useMemo(
    () => generateMenuItems(routesConfig),
    [currentUserRole]
  );

  useEffect(() => {
    changeActiveKey();
  }, [current, collapsed]);

  useEffect(() => {
    setCurrent(location.pathname.split("/"));
  }, [location]);

  const changeActiveKey = () => {
    setActiveKey(current[1]);
    return activeKey;
  };

  const onClick = ({ key }) => {
    setCurrentLocale(key);
  };

  return (
    <Layout hasSider>
      <Sider
        width={leftNavWidth}
        style={{
          position: "sticky",
        }}
        className={`${Styles.navigation}`}
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={59}
      >
        <div
          className={`${Styles["nav-wrapper"]} ${
            collapsed ? Styles.collapsed : ""
          }`}
        >
          <div
            className={`${Styles.logo} ${collapsed ? Styles.collapsed : ""}`}
          >
            <img
              className={`${Styles.logoimage} ${
                collapsed ? Styles.collapsed : ""
              }`}
              src={Logo}
              alt="EngageAILogo"
            />
          </div>
          <div className={Styles["language-options"]}>
            <Dropdown
              menu={{ items: languageOptions, onClick }}
              overlayClassName={Styles["locale-dropdown"]}
              placement="bottomLeft"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {localeDetail[currentLocale]?.icon}
                  <CaretDownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
          <UserProfile collapsed={collapsed} />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          inlineIndent={20}
          className={`${Styles.menu} ${collapsed ? Styles.collapsed : ""}`}
          items={items}
          activeKey={activeKey}
          openKeys={openKeys}
          onClick={(info) => {
            if (info.item.props.link !== "logout") {
              navigate(info.item.props.link);
            }
          }}
          // onOpenChange={(info) => {
          //   //Executed when sub menus are opening or closing
          //   if (
          //     info.length > openKeys.length //Additional sub menu is opened
          //   ) {
          //     const newSet = new Set([...info, openKeys]);
          //     const values = newSet.values();
          //     const currentSubMenu = values.next().value;
          //     if (!collapsed) {
          //       if (current.length === 2 && current[1] !== currentSubMenu) {
          //         navigate(`/${currentSubMenu}`);
          //       }
          //       setOpenKeys([...openKeys, currentSubMenu]);
          //     } else {
          //       setOpenKeys([currentSubMenu]);
          //       changeActiveKey(true);
          //     }
          //   } else {
          //     setOpenKeys(info);
          //     setActiveKey(
          //       current.length > 2 && Object.keys(subMenus).includes(current[1])
          //         ? current[1]
          //         : activeKey
          //     );
          //   }
          // }}
        />
        <Button
          className={`${Styles["collapse-btn"]} ${
            collapsed ? Styles.collapsed : ""
          }`}
          onClick={toggleCollapse}
        >
          <Icon
            component={ArrowIcon}
            style={{
              fontSize: 12,
              transform: `rotate(${collapsed ? "180deg" : "0deg"})`,
            }}
          />
        </Button>
        <div
          className={`${Styles["button-cont"]} ${
            collapsed ? Styles.collapsed : ""
          }`}
          style={{ width: leftNavWidth }}
        >
          <Tooltip title="Log Out" open={showLogOutTooltip} placement="right">
            <Button
              type="link"
              onClick={onLogOut}
              onMouseEnter={() => {
                setShowLogOutTooltip(collapsed ? true : false);
              }}
              onMouseLeave={() => setShowLogOutTooltip(false)}
              className={`${collapsed ? Styles.collapsed : ""}`}
            >
              <Micons icon="logout" isHover={false} />
              Log Out
            </Button>
          </Tooltip>
          {/* <Tooltip title="Help" open={showHelpTooltip} placement="right">
            <Button
              type="link"
              onMouseEnter={() => {
                setShowHelpTooltip(collapsed ? true : false);
              }}
              onMouseLeave={() => setShowHelpTooltip(false)}
              className={`${collapsed ? Styles.collapsed : ""}`}
            >
              <Micons icon="help_outline" isHover={false} />
              Help
            </Button>
          </Tooltip> */}
        </div>
      </Sider>
      <Layout
        className={`${Styles["content-wrapper"]} ${
          !!localStorage.getItem("proxyCompanyId") || proxyCompany ? "pt-0" : ""
        }`}
      >
        {children}
      </Layout>
    </Layout>
  );
};

export default PrivateLayout;
