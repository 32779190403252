import { useEffect, useState } from "react";
import { Form, Input, List, Upload, message, Divider, Row, Col } from "antd";
import { v4 as uuidv4 } from "uuid";
import { t } from "i18next";

import {
  uploadFiles,
  uploadMultipleFiles,
} from "../../../../../utils/common/apiUtils";

import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  MenuOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { Button } from "../../../../../components/customAntd";

import Styles from "./styles/step2.module.scss";

const { Item } = Form;
const { Dragger } = Upload;

const Step2 = ({
  form,
  onChange,
  files,
  setFiles,
  setIsImageLoading,
  isEdit,
  isSingleFile,
}) => {
  const [isEditIndex, setIsEditIndex] = useState(-1);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [toBeUploadFiles, setToBeUploadFiles] = useState([]);
  // Performing validation on an image before it is uploaded to the API
  const handleBeforeUpload = async (file, fileList) => {
    const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
    const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
    const allowedExtensions = ["mp4", "mov"];
    if (extension !== "mp4" && extension !== "mov") {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.ONLY")} ${allowedExtensions.join(".")} ${t(
          "FILE_UPLOAD.EXTENSIONS_ALLOWED"
        )}`,
      });
      return false;
    } else if (currentFileIndexOf !== -1) {
      message.open({
        type: "error",
        content: t("FILE_UPLOAD.EXISTS"),
      });
      return false;
    } else {
      if (isSingleFile) {
        setIsFileLoading(true);
        const uploadFile = await uploadFiles({
          fileFor: "benefit",
          isUploadIpfs: false,
          files: file,
        });
        if (uploadFile?.status === 200) {
          const uploadArray = [
            {
              name: uploadFile.data.s3Url.split("/").reverse()[0],
              resourceUrl: uploadFile.data.s3Url,
              type: "video",
            },
          ];
          onChange(uploadArray);
        }
        setIsFileLoading(false);
      } else {
        setToBeUploadFiles((prev) => [...prev, file]);
      }
      return false;
    }
  };

  // Uploading only multiple files
  useEffect(() => {
    const uploadImages = async () => {
      if (toBeUploadFiles.length > 0) {
        setIsImageLoading(true);
        setIsFileLoading(true);
        const uploadFile = await uploadMultipleFiles({
          fileFor: "benefit",
          isUploadIpfs: false,
          files: toBeUploadFiles,
        });
        if (uploadFile?.status === 200) {
          const uploadArray = uploadFile.data.map((item) => {
            return {
              name: item.s3Url.split("/").reverse()[0],
              resourceUrl: item.s3Url,
              type: "video",
            };
          });
          onChange(uploadArray);
        }
        setIsFileLoading(false);
        setIsImageLoading(false);
        setToBeUploadFiles([]);
      }
    };
    uploadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toBeUploadFiles]);

  // Using the edit button to set the current image index.
  const handleEditClick = (index) => {
    setIsEditIndex(index);
  };

  // Removing an image from the files array using the delete button
  const handleDeleteClick = (fileName) => {
    setFiles((prev) => prev.filter((item) => item.name !== fileName));
    setIsEditIndex(-1);
  };

  // Modifying the name by pressing the Enter key
  const handlePressEnter = (e, oldName, source) => {
    const newFileName = e.target.value;
    const newData = files.map((item) => {
      if (item.name === oldName) {
        if (!source) {
          const ext = oldName.substring(oldName.lastIndexOf("."));
          const newName = newFileName !== "" ? `${newFileName}${ext}` : oldName;
          return {
            ...item,
            name: newName,
          };
        } else {
          return {
            ...item,
            name: newFileName,
          };
        }
      } else return item;
    });
    setFiles(newData);
    setIsEditIndex(-1);
  };

  // Removing the input from the list view by pressing the Esc key
  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      setIsEditIndex(-1);
    }
  };

  return (
    <>
      <h3>{isSingleFile ? t("VIDEO.ADD") : t("VIDEO.ADD_MULTIPLE")}</h3>
      <Item
        name="tracks"
        extra={`${t("FILE_TYPES_SUPPORTED")}: mp4, mov`}
        valuePropName=""
        rules={[
          {
            required: files.length !== 0 ? false : true,
            message: t("VIDEO.SELECT_VIDEO"),
          },
        ]}
      >
        <Dragger
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          accept=".mp4, .mov"
          disabled={
            (isSingleFile && files.length > 0 ? true : false) || isFileLoading
          }
          {...(!isSingleFile && { multiple: true })}
        >
          {isFileLoading ? (
            <>
              <LoadingOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
              <p className="ant-upload-hint">{`${t("UPLOADING")}...`}</p>
            </>
          ) : (
            <>
              <UploadOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
              <p className="ant-upload-hint">{t("UPLOAD")}</p>
            </>
          )}
        </Dragger>
      </Item>

      <Divider style={{ fontSize: 12 }}>{t("OR")}</Divider>

      <Row align="middle" gutter={[15]}>
        <Col sm={21}>
          <Item
            name="track-link"
            label={t("MEDIA_LINK")}
            rules={[
              {
                type: "url",
                message: t("MEDIA_LINK_ERROR"),
              },
            ]}
          >
            <Input
              placeholder={t("MEDIA_LINK_PLACEHOLDER")}
              disabled={files.length >= 1 && isSingleFile}
            />
          </Item>
        </Col>
        <Col sm={3}>
          <Button
            className={Styles["add-media"]}
            onClick={() => {
              const value = form.getFieldValue("track-link");
              if (value) {
                onChange(
                  {
                    name: `video link - ${uuidv4()}`,
                    resourceUrl: form.getFieldValue("track-link"),
                    type: "video",
                    source: "link",
                  },
                  "link"
                );
                form.setFieldValue("track-link", "");
              }
            }}
            disabled={files.length >= 1 && isSingleFile}
          >
            {t("ADD")}
          </Button>
        </Col>
      </Row>

      {files.length > 0 && (
        <Item
          label={`${isSingleFile ? t("SINGLE_VIDEO") : t("MULTIPLE_VIDEO")}:`}
        >
          <List
            dataSource={files}
            className="upload-list"
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <EditOutlined onClick={() => handleEditClick(index)} />,
                  <DeleteOutlined
                    onClick={() => handleDeleteClick(item.name)}
                  />,
                ]}
              >
                <div>
                  <MenuOutlined
                    style={{
                      fontSize: 10,
                      color: "rgba(0, 0, 0, 0.45)",
                      marginRight: 15,
                    }}
                  />
                  <span className="item-index">{index + 1}.</span>{" "}
                  <span className="item-name">
                    {isEditIndex === index ? (
                      <Input
                        placeholder={item.name.substring(
                          0,
                          item.name.lastIndexOf(".")
                        )}
                        autoFocus
                        onKeyDown={handleKeyDown}
                        onPressEnter={(e) =>
                          handlePressEnter(e, item.name, item.source || null)
                        }
                      />
                    ) : (
                      item.name
                    )}
                  </span>
                </div>
              </List.Item>
            )}
          />
        </Item>
      )}
    </>
  );
};

export default Step2;
