import { Breadcrumb as BreadcrumbAntd } from "antd";
import { Link } from "react-router-dom";

import Styles from "./styles/breadCrumb.module.scss";

const BreadcrumbItem = BreadcrumbAntd.Item;

const Breadcrumb = ({ items, className, ...props }) => {
  return (
    <BreadcrumbAntd
      className={`${Styles.breadcrumb} ${className ? className : ""}`}
      {...props}
    >
      {items.map((item, index) => (
        <BreadcrumbItem className={Styles["breadcrumb-item"]} key={`${item.title}-${index}`}>
          {item.link ? <Link to={item.link}>{item.title}</Link> : item.title}
        </BreadcrumbItem>
      ))}
    </BreadcrumbAntd>
  );
};

export default Breadcrumb;
