import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useAppContext } from "../context/app.context";

export default function SelectProxyCompany({ companies }) {
  const [isProxyCompanyChanged, setIsProxyCompanyChanged] = useState(false);
  const { proxyCompany, setProxyCompany, setCurrentUserRole, currentUserData } =
    useAppContext();
  const FARO_COMPANY_ID = 11111;
  const onSelectProxyCompany = (e) => {
    if (e.target.value === "none") {
      setProxyCompany(null);
    } else {
      const selectedCompany = companies.find(
        (company) => company.id === Number(e.target.value)
      );
      setProxyCompany(selectedCompany);
    }
    setIsProxyCompanyChanged(true);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!proxyCompany) {
        if (isProxyCompanyChanged) {
          localStorage.removeItem("proxyCompanyId");
          localStorage.removeItem("proxyCompanyAdminRoleId");
          localStorage.removeItem("proxyCompanyName");
        }
        setCurrentUserRole(currentUserData.roles[0]);
      } else {
        if (isProxyCompanyChanged) {
          localStorage.setItem("proxyCompanyId", proxyCompany?.id);
          localStorage.setItem(
            "proxyCompanyAdminRoleId",
            proxyCompany?.adminRole?.id
          );
          localStorage.setItem("proxyCompanyName", proxyCompany?.name);
          setCurrentUserRole(proxyCompany?.adminRole);
        }
      }
    }
    return () => (mounted = false);
  }, [proxyCompany]);

  return (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm={"auto"} className="">
        Select a proxy company:
      </Form.Label>
      <Col sm={6}>
        <Form.Select
          value={proxyCompany ? proxyCompany.id : "none"}
          onChange={onSelectProxyCompany}
          className="font-16"
        >
          <option key={"none"} value={"none"}>
            None
          </option>
          {companies
            .filter(
              (company) =>
                company.id !== FARO_COMPANY_ID &&
                company.id !== currentUserData?.company?.id
            )
            .map((company) => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
        </Form.Select>
      </Col>
      <Form.Text className="font-16">
        Selecting a proxy company allows you to perform actions on behalf of
        that company.
      </Form.Text>
    </Form.Group>
  );
}
