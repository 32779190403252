import { Radio, Form, Row, Col, Switch } from "antd";

import { FormWrapper } from "../../../../components/customAntd";

import Styles from "./styles/buildStorefront.module.scss";

const { Item } = Form;

const OverrideOptions = ({ t }) => {
  const form = Form.useFormInstance();
  const watchMembershipCriteriaOverride = Form.useWatch(
    "membershipCriteriaOverride",
    form
  );
  const watchShowImageOnlyOverride = Form.useWatch(
    "showImageOnlyOverride",
    form
  );

  return (
    <FormWrapper heading={t("BENEFIT_OVERRIDES")}>
      <div className={Styles["override-option"]}>
        <Item name="membershipCriteriaOverride">
          <Switch checked={watchMembershipCriteriaOverride} />
        </Item>
        <span>{t("STOREFRONT_UI.OVERRIDE_MEMBERSHIP_CRITERIA")}</span>
      </div>
      {watchMembershipCriteriaOverride && (
        <Item
          name="membershipCriteria"
          rules={[
            {
              required: true,
              message: t("STOREFRONT_UI.OVERRIDE_VALIDATION"),
            },
          ]}
        >
          <Radio.Group style={{ width: "100%" }}>
            <Row gutter={[20, 20]}>
              <Col xs={24} md={8}>
                <Radio style={{ width: "100%" }} value="NotRequired">
                  {/* {t("NO")} */}
                  {t("NOT_REQUIRED")}
                </Radio>
              </Col>
              <Col xs={24} md={8}>
                <Radio style={{ width: "100%" }} value="Visible">
                  {/* {t("YES")} */}
                  {t("VISIBLE")}
                </Radio>
              </Col>
              <Col xs={24} md={8}>
                <Radio style={{ width: "100%" }} value="NotVisible">
                  {/* {t("YES")} */}
                  {t("NOT_VISIBLE")}
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Item>
      )}
      <div className={Styles["override-option"]}>
        <Item name="showImageOnlyOverride">
          <Switch checked={watchShowImageOnlyOverride} />
        </Item>
        <span>{t("STOREFRONT_UI.OVERRIDE_IMAGE_ONLY")}</span>
      </div>
      {watchShowImageOnlyOverride && (
        <Item
          name="showImageOnly"
          rules={[
            {
              required: true,
              message: t("STOREFRONT_UI.OVERRIDE_VALIDATION"),
            },
          ]}
        >
          <Radio.Group style={{ width: "100%" }}>
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <Radio style={{ width: "100%" }} value={true}>
                  {t("YES")}
                </Radio>
              </Col>
              <Col xs={24} md={12}>
                <Radio style={{ width: "100%" }} value={false}>
                  {t("NO")}
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Item>
      )}
    </FormWrapper>
  );
};

export default OverrideOptions;
