import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Popconfirm, message } from "antd";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../../../components/context/app.context";
import { NoData, Micons } from "../../../../../components/customAntd";
import { deletePushNotification } from "../../apiUtils";

import Styles from "../styles/pushNotifications.module.scss";

const NotificationsList = ({ list, isScheduledList, title, setList }) => {
  const { programId, id } = useParams();
  const { currentLocale } = useAppContext();
  const { t } = useTranslation();
  const handleDeleteClick = async (notificationId) => {
    const response = await deletePushNotification(
      programId,
      id,
      notificationId
    );
    if (response.status !== 200) {
      message.error(
        "Something went wrong while deleting the scheduled notification."
      );
    } else {
      setList((prev) => {
        return prev.filter((item) => item.id !== response?.data?.id);
      });
    }
  };
  return (
    <>
      <div className={Styles["notification-section-wrapper"]}>
        <h6>
          {isScheduledList ? (
            <Micons icon="access_time" isHover={false} />
          ) : (
            <Micons icon="outbox" isHover={false} />
          )}
          {title}
        </h6>
        {list?.length > 0 ? (
          list.map(({ title, message, notifyTime, locales, type, status, id }) => {
            const currentLocaleData = locales.filter(
              (item) => item.localeId === currentLocale
            )[0];
            return (
              <div className={Styles["sent-notifications"]}>
                <h6>
                  {currentLocaleData?.title ?? title}
                  {isScheduledList && (
                    <Popconfirm
                      title={t("PROGRAM_UI.PUSH_NOTIFICATIONS.DELETE_NOTIFICATION")}
                      onConfirm={() => handleDeleteClick(id)}
                      onCancel={() => {}}
                      okText={t("YES")}
                      cancelText={t("NO")}
                    >
                      <Micons icon="delete" isHover={false} />
                    </Popconfirm>
                  )}
                </h6>
                <p>{currentLocaleData?.message ?? message}</p>
                <p className={isScheduledList ? Styles["blue"] : ""}>{`${
                  isScheduledList ? `${dayjs(notifyTime).format("dddd")},` : ""
                } ${dayjs(notifyTime).format("DD MMM YYYY - hh:mm A")}`}</p>
              </div>
            );
          })
        ) : (
          <NoData
            description={`${
              isScheduledList
                ? t("PROGRAM_UI.PUSH_NOTIFICATIONS.NO_NOTIFIACTIONS")
                : t("PROGRAM_UI.PUSH_NOTIFICATIONS.NO_NOTIFIACTIONS_SENT")
            }`}
          />
        )}
      </div>
    </>
  );
};
export default NotificationsList;
