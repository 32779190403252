import { useEffect, useState } from "react";
import { Space, Skeleton, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import { useAppContext } from "../../../../components/context/app.context";
import ReadMore from "../../../../components/readmore";
import {
  Button,
  Card,
  Micons,
  PageHeader,
} from "../../../../components/customAntd";
import { BREADCRUMB_STOREBUILDER_SELECT_PROGRAM } from "../../../../utils/storeFront/constant";
import { getAllProgramList, getProgram } from "../../program/apiUtils";
import { CommercialTemplateName } from "../../../../utils/constants";

import Styles from "./styles/selectProgram.module.scss";

const { Meta } = Card;

const SelectProgram = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isEdit, store } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [program, setProgram] = useState(null);
  const [data, setData] = useState([]);

  const [template, setTemplate] = useState(isEdit ? store.themeId : null);

  const handleProgramClick = (newProgram) => {
    setProgram(newProgram);
  };

  const { currentLocale } = useAppContext();

  const handleSubmit = () => {
    if (program) {
      let tiers = [];
      data?.forEach((item) => {
        if (item.id === program.id) {
          tiers = item.tiers.map((tier) => {
            return {
              tierId: tier.id,
              pointThreshold: tier.pointThreshold,
              name: tier.name,
            };
          });
          return;
        }
      });
      navigate("/storefront-builder/create-storefront", {
        state: {
          programId: program.id,
          programStatus: program.status,
          tiers: tiers,
          isEdit: isEdit,
          store: store,
          themeId: template,
        },
      });
    }
  };

  // Get all Programs initially
  useEffect(() => {
    const fetchPrograms = async () => {
      setIsLoading(true);
      const params = {
        limit: 50,
        offset: 0,
        filterCondition: {
          storefront: false,
        },
      };
      const getAllPrograms = await getAllProgramList(params);
      if (getAllPrograms?.status === 200) {
        setData(getAllPrograms.data.programs);
      } else {
        message.error(
          `${t("STOREFRONT_UI.FETCH_PROGRAMS_ERROR")}. ${t("TRY_AGAIN_LATER")}`
        );
      }
      setIsLoading(false);
    };

    const fetchSingleProgram = async () => {
      setIsLoading(true);
      const response = await getProgram(store?.programId);
      if (response.status === 200) {
        setData([response.data]);
        setProgram(response.data);
      } else {
        message.error(
          `${t("STOREFRONT_UI.FETCH_PROGRAM_ERROR")}. ${t("TRY_AGAIN_LATER")}`
        );
      }
      setIsLoading(false);
    };

    if (!isEdit) {
      fetchPrograms();
    } else {
      fetchSingleProgram();
    }
  }, []);

  return (
    <>
      <PageHeader
        title={t("STOREFRONT_UI.SELECT_PROGRAM_PAGE_TITLE")}
        breadcrumbs={BREADCRUMB_STOREBUILDER_SELECT_PROGRAM(t)}
      />
      <div className={Styles["select-program-wrapper"]}>
        <Space className={Styles["heading"]}>
          <Micons icon="list_alt" style={{ fontSize: 16 }} />
          <h3>{t("STOREFRONT_UI.SELECT_PROGRAM_TITLE")}</h3>
        </Space>
        <p className={Styles.description}>
          {t("STOREFRONT_UI.SELECT_PROGRAM_DESC")}
        </p>

        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className={Styles["program-slider"]}>
            {data?.map((currentProgram) => {
              const currentLocaleData = currentProgram?.locales?.find(
                (locale) => locale.localeId === currentLocale
              );
              return (
                <div
                  className={Styles["program-card"]}
                  key={`program-card${currentProgram.id}`}
                >
                  <Card
                    selectedCard={
                      currentProgram.id === program?.id ? true : null
                    }
                    key={`card${currentProgram.id}`}
                    onClick={() => {
                      if (!isEdit) handleProgramClick(currentProgram);
                    }}
                    className={`${
                      currentProgram?.id === program?.id
                        ? " selected-card "
                        : ""
                    }`}
                  >
                    <Meta
                      title={currentLocaleData?.name ?? currentProgram.name}
                      // description={currentProgram.description}
                      description={
                        <ReadMore
                          text={
                            currentLocaleData
                              ? currentLocaleData.description
                              : currentProgram?.description
                          }
                          characterLimit={75}
                        >
                          {(currentLocaleData
                            ? currentLocaleData.description.length > 75
                            : currentProgram?.description.length > 75) && (
                            <ReadMore.Trigger
                              trigger={`...${t("MORE")}`}
                              buttonClassName={Styles["more-button"]}
                              text={
                                currentLocaleData
                                  ? currentLocaleData.description
                                  : currentProgram?.description
                              }
                            />
                          )}
                        </ReadMore>
                      }
                    />
                  </Card>
                </div>
              );
            })}
          </div>
        )}
        <Space className={`${Styles["heading"]}`}>
          <Micons icon="list_alt" style={{ fontSize: 16 }} />
          <h3>{t("SELECT_TEMPLATE")}</h3>
        </Space>
        <p className={Styles.description}>
          {t("SELECT_TEMPLATE_FOR_STOREFRONT")}
        </p>
        <div className={Styles["blockchain-select-row"]}>
          <Select
            style={{ width: 200 }}
            value={template}
            options={[
              { label: "Default", value: "Default" },
              { label: "Anime", value: "Anime" },
              { label: CommercialTemplateName, value: CommercialTemplateName },
            ]}
            onChange={(val) => setTemplate(val)}
          />
        </div>
        <Button
          disabled={!(program && template)}
          onClick={() => handleSubmit()}
        >
          {isEdit
            ? t("STOREFRONT_UI.SELECT_PROGRAM_EDIT")
            : t("STOREFRONT_UI.SELECT_PROGRAM_SUBMIT")}
        </Button>
      </div>
    </>
  );
};

export default SelectProgram;
