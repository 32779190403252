import { useState, useEffect } from "react";
import { Row, Col, Statistic, notification } from "antd";
import { useTranslation } from "react-i18next";

import { getStatisticsForTier } from "../../apiUtils";

import Styles from "../styles/pushNotifications.module.scss";
const Statistics = ({ programId, tierId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState();
  useEffect(() => {
    const getStats = async () => {
      setLoading(true);
      const response = await getStatisticsForTier(programId, tierId);
      if (response.status !== 200) {
        notification.error({
          message: "Error",
          description: "Something went wrong while getting statistics",
          duration: 30,
          placement: "topRight",
        });
      } else {
        setStats(response.data);
      }
      setLoading(false);
    };
    getStats();
  }, []);

  return (
    <Row gutter={[25, 25]} className={Styles["statistics-wrapper"]}>
      <Col md={12}>
        <Statistic
          title={t("PROGRAM_UI.STATISTICS.NO_OF_PASS")}
          value={stats?.[0]?._count || 0}
        />
      </Col>
    </Row>
  );
};

export default Statistics;
