import { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Divider,
  Popconfirm,
  DatePicker,
} from "antd";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { t } from "i18next";

import {
  Radio,
  Button,
  UploadFile,
} from "../../../../../components/customAntd";
import CategoryTreeSelect from "../../../../../components/categoryTreeSelect";
import { getLocalesFromDb } from "../../../../../utils/common/apiUtils";

const { Item } = Form;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const Step1 = ({
  form,
  isEdit,
  coverImage,
  setIsCoverImageLoading,
  setCoverImage,
  localisedCoverImages,
  setLocalisedCoverImages,
  editData,
}) => {
  const [defaultLocale] = useState("en_US");
  const [availableLocales, setAvailableLocales] = useState([]);

  const watchLocales = Form.useWatch("locales", form);
  const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);

  useEffect(() => {
    const getLocales = async () => {
      const data = await getLocalesFromDb();
      if (data) {
        const localesData = data.map((locale) => ({
          value: locale.localeId,
          label: `${locale.language.language} (${locale.country.country})`,
          isSelected: isEdit
            ? !!editData?.locales.find(
                (localeEl) => localeEl.localeId === locale.localeId
              ) || editData?.defaultLocaleId === locale.localeId
            : locale.localeId === defaultLocale,
        }));
        setAvailableLocales(localesData);
      }
    };
    getLocales();
  }, [defaultLocale]);

  // Updated locals state if someone changed language in the form
  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
              ...item,
              isSelected: true,
            }
          : {
              ...item,
              isSelected: item.value === prevValue ? false : item.isSelected,
            }
      );
    });
  };

  return (
    <>
      <Item
        label={`${t("SURVEY.UPLOAD_COVER")} :`}
        name="image"
        extra={
          <>
            <p>{`${t("FILE_UPLOAD.RECOMMENDED_SIZE")} - 1024×768 (4:3)`}</p>
            <p>{`${t("FILE_TYPES_SUPPORTED")}  - JPG, JPEG, PNG. ${t(
              "MAX_SIZE"
            )}: 5${t("MB")}`}</p>
          </>
        }
        valuePropName=""
      >
        <UploadFile
          showUploadList={false}
          placeholder=" 1024×768 (4:3)"
          fileFor="benefit"
          fileSize={5}
          accept=".jpg, .jpeg, .png"
          height={170}
          image={coverImage}
          onLoading={(value) => setIsCoverImageLoading(value)}
          onChange={(image) => {
            setCoverImage(image.s3Url);
          }}
        />
      </Item>
      <Item
        label={`${t("DEFAULT_LANGUAGE")}:`}
        name="defaultLocaleId"
        initialValue={defaultLocale}
        rules={[
          {
            required: true,
            message: t("DEFAULT_LANGUAGE_ERROR"),
            whitespace: true,
          },
        ]}
      >
        <Select
          options={availableLocales.map((localEl) => {
            return {
              ...localEl,
              disabled: localEl.isSelected,
            };
          })}
          onChange={(value) => updateLocales(value, watchDefaultLanguage)}
        />
      </Item>
      <Row>
        <Item
          label={`${t("BENEFIT_CATEGORIES.LABEL")} :`}
          name="categoryId"
          rules={[
            {
              required: true,
              message: t("BENEFIT_CATEGORIES.SELECT_CATEGORY"),
            },
          ]}
        >
          <CategoryTreeSelect name="categoryId" style={{ width: 250 }} />
        </Item>
      </Row>
      <Item
        label={t("SURVEY.TITLE")}
        name="title"
        rules={[
          {
            required: true,
            message: t("SURVEY.TITLE_ERROR"),
            whitespace: true,
          },
        ]}
      >
        <Input placeholder={t("SURVEY.TITLE_PLACEHOLDER")} />
      </Item>
      <Item
        label={`${t("SURVEY.TITLE_PREFIX")}:`}
        name="code_short_description"
        rules={[
          {
            validator: (_, value) => {
              if (value && value.length > 10) {
                return Promise.reject(
                  new Error(t("SURVEY.CODE_PREFIX_VALIDATION_ERROR"))
                );
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <TextArea
          placeholder={t("PREFIX_TITLE_VALIDATION_ERROR")}
          style={{ resize: "none" }}
        />
      </Item>
      <Item
        label={t("SURVEY.DESC")}
        name="description"
        rules={[
          {
            required: true,
            message: t("SURVEY.DESC_ERROR"),
            whitespace: true,
          },
        ]}
      >
        <MDEditor
          overflow={false}
          placeholder={t("SURVEY.DESC_PLACEHOLDER")}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
        {/* <TextArea placeholder={t("SURVEY.DESC_PLACEHOLDER")} rows={5} /> */}
      </Item>
      <Item
        label={t("REDEEMABLE_PERIOD")}
        name="redeemable_period"
        rules={[
          {
            required: true,
            message: t("REDEEMABLE_PERIOD_ERROR"),
          },
        ]}
      >
        <RangePicker
          style={{ width: "100%" }}
          format="YYYY-MM-DD HH:mm"
          disabledDate={(current) => {
            return current && current < dayjs().subtract(1, "day").endOf("day");
          }}
          placeholder={[t("START_DATE"), t("END_DATE")]}
          showTime={{ format: "HH:mm" }}
        />
      </Item>
      <Item
        label={`${t("STATUS")}:`}
        name="status"
        rules={[
          {
            required: true,
            message: t("SELECT_STATUS"),
          },
        ]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value="draft">
                {t("DRAFT")}
              </Radio>
            </Col>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value="active">
                {t("ACTIVE")}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Item>
      <Item label={`${t("ACTIVE_DATE")}:`} name="active_date">
        <DatePicker
          style={{ width: "100%" }}
          format="YYYY-MM-DD HH:mm"
          disabledDate={(current) => {
            return current && current < dayjs().subtract(1, "day").endOf("day");
          }}
          placeholder={[t("ACTIVE_DATE")]}
          showTime={{ format: "HH:mm" }}
        />
      </Item>
      <Item
        label={`${t("SHOWIMAGES_ONLY")}:`}
        name="show_images_only"
        rules={[
          {
            required: true,
            message: t("SHOWIMAGES_ONLY_ERROR"),
          },
        ]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value={false}>
                {t("NO")}
              </Radio>
            </Col>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value={true}>
                {t("YES")}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Item>
      <Form.List name="locales">
        {(fields, { add, remove }) => (
          <div className="languageOptions">
            <h3 className="heading">{t("LANGUAGE_OPTIONS.HEADING")}</h3>
            <p className="info">{t("LANGUAGE_OPTIONS.DESCRIPTION")}</p>
            {fields.map(({ key, name }, index) => (
              <Fragment key={key}>
                <Row gutter={[25, 25]}>
                  <Col lg={8}>
                    <Item
                      label={`${t("SURVEY.LOCALISED_COVER_IMAGE")} :`}
                      extra={
                        <>
                          <p>
                            {`${t(
                              "FILE_UPLOAD.RECOMMENDED_SIZE"
                            )} - 1024×768 (4:3)`}
                          </p>
                          <p>
                            {`${t(
                              "FILE_TYPES_SUPPORTED"
                            )}  - JPG, JPEG, PNG. ${t("MAX_SIZE")}: 5${t(
                              "MB"
                            )}`}
                          </p>
                        </>
                      }
                      valuePropName=""
                    >
                      <UploadFile
                        showUploadList={false}
                        placeholder=" 1024×768 (4:3)"
                        fileFor="benefit"
                        fileSize={5}
                        accept=".jpg, .jpeg, .png"
                        height={170}
                        image={localisedCoverImages[index]}
                        onLoading={(value) => setIsCoverImageLoading(value)}
                        onChange={(image) => {
                          setLocalisedCoverImages((prev) => {
                            prev[index] = image?.s3Url;
                            return [...prev];
                          });
                        }}
                      />
                    </Item>
                  </Col>
                  <Col lg={16}>
                    <Item
                      label={`${t("LANGUAGE")} :`}
                      name={[name, "localeId"]}
                      rules={[
                        {
                          required: true,
                          message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                        },
                      ]}
                    >
                      <Select
                        options={availableLocales.map((localEl) => ({
                          ...localEl,
                          disabled: localEl.isSelected,
                        }))}
                        onChange={(value) =>
                          updateLocales(value, watchLocales[index].localeId)
                        }
                        placeholder={t("SELECT")}
                      />
                    </Item>
                    <Item
                      label={`${t("LANGUAGE_OPTIONS.LOCALISED_TITLE")} :`}
                      name={[name, "title"]}
                      rules={[
                        {
                          required: true,
                          message: t("LANGUAGE_OPTIONS.LOCALISED_TITLE_ERROR"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("SURVEY.LOCALISED_TITLE_PLACEHOLDER")}
                      />
                    </Item>
                    <Item
                      label={t("LANGUAGE_OPTIONS.DESC")}
                      name={[name, "description"]}
                      rules={[
                        {
                          required: true,
                          message: t("LANGUAGE_OPTIONS.ENTER_DESC"),
                          whitespace: true,
                        },
                      ]}
                    >
                      <MDEditor
                        overflow={false}
                        placeholder={t("SURVEY.LOCALISED_DESC_PLACEHOLDER")}
                        previewOptions={{
                          rehypePlugins: [[rehypeSanitize]],
                        }}
                      />
                    </Item>
                  </Col>
                  {fields.length > 0 && (
                    <Divider orientation="center">
                      <Popconfirm
                        title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                        onConfirm={() => {
                          setLocalisedCoverImages((prev) => {
                            prev.splice(index, 1);
                            return [...prev];
                          });
                          remove(index);
                          setAvailableLocales((prev) => {
                            return prev.map((item) =>
                              item.value === watchLocales[index].localeId
                                ? {
                                    ...item,
                                    isSelected: false,
                                  }
                                : item
                            );
                          });
                          remove(name);
                        }}
                        onCancel={() => {}}
                        okText={t("YES")}
                        cancelText={t("NO")}
                      >
                        <DeleteOutlined
                          className="deleteIcon"
                          style={{ fontSize: 24 }}
                        />
                      </Popconfirm>
                    </Divider>
                  )}
                </Row>
              </Fragment>
            ))}

            <Button
              type="primary"
              className="add-locale-button"
              onClick={fields.length >= 3 ? () => {} : () => add()}
              icon={<PlusOutlined />}
              disabled={fields.length >= 3}
            >
              {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
            </Button>
          </div>
        )}
      </Form.List>
    </>
  );
};

export default Step1;
