import { Switch } from "antd";
import { Input } from "../../components/form";

const Contact = ({ label, includeContact, setIncludeContact, name }) => {
  return (
    <>
      <h6 className="label-bg">
        {label}{" "}
        <Switch
          onChange={(checked) => {
            setIncludeContact(checked);
          }}
        />
      </h6>
      {includeContact && (
        <>
          <Input
            label="First Name"
            name={[name, "firstName"]}
            placeholder="Enter your first name"
            rules={[
              {
                type: "text",
              },
              {
                required: true,
                message: "First Name is required",
              },
              {
                validator: async (_, name) => {
                  if (name) {
                    if (name.length < 3)
                      return Promise.reject(
                        "First name must be at least 3 characters"
                      );
                    if (name.length > 40)
                      return Promise.reject(
                        "First name must be less 40 characters"
                      );
                  }
                },
              },
            ]}
          />
          <Input
            label="Last Name"
            name={[name, "lastName"]}
            placeholder="Enter your last name"
            rules={[
              { type: "text" },
              {
                required: true,
                message: "Last Name is required",
              },
              {
                validator: async (_, name) => {
                  if (name) {
                    if (name.length > 40)
                      return Promise.reject(
                        "Last name must be less than 40 characters"
                      );
                  }
                },
              },
            ]}
          />
          <Input
            label="Email"
            name={[name, "email"]}
            placeholder="Enter your email address"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
              {
                required: true,
                message: "Email address is required",
              },
            ]}
          />
        </>
      )}
    </>
  );
};
export default Contact;
