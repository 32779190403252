import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { checkUserRolePermissions } from '../../utils/helper';
import { useAppContext } from '../context/app.context';
import { ROLE_PERMISSIONS } from "../../utils/constants";

const UserIsAdmin = ({ children }) => {

    const { currentUserRole } = useAppContext()

    return (
        <>
            {
                (checkUserRolePermissions(currentUserRole, ROLE_PERMISSIONS.MANAGEAPPSETTINGS)
                    &&
                    checkUserRolePermissions(currentUserRole, ROLE_PERMISSIONS.MANAGEUSERS)
                )
                    ? children
                    : <Navigate to="/" replace />
            }
        </>
    );
};
export default UserIsAdmin;