import Styles from "./styles/formWrapper.module.scss";

const FormWrapper = ({
  heading,
  description,
  children,
  icon,
  headerStyles,
  wrapperStyles,
}) => {
  return (
    <div className={Styles["form-wrapper"]} style={wrapperStyles || {}}>
      {(heading || description) && (
        <div className={Styles.header} style={headerStyles}>
          {icon && icon}
          {heading && (
            <h2 {...(!description && { className: Styles["no-margin"] })}>
              {heading}
            </h2>
          )}
          {description && <p>{description}</p>}
        </div>
      )}
      <div className={Styles.body}>{children}</div>
    </div>
  );
};

export default FormWrapper;
