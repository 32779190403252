import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Skeleton, Form, notification } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { PageHeader, Button, Micons } from "../../../../components/customAntd";
import { TierCard } from "../../../../components/cards";
import ReadMore from "../../../../components/readmore";
import NewProgram from "../newProgram";
import { BREADCRUMB_PROGRAMS_DETAIL_PAGE } from "../../../../utils/programs/constant";
import EarnPointsSettings from "./earnPoints";
import SpendPointsSettings from "./spendPoints";

import { useAppContext } from "../../../../components/context/app.context";
import {
  getAllTiers,
  getProgram,
  getAllPointRewardsFromDb,
  getProgramMetabaseUrls,
} from "../apiUtils";
import { getAllMerchantsFromDb } from "../../merchant/apiUtils";
import { getAllBenefitsFromDb } from "../../benefits/apiUtils";
import { Tabs } from "antd";
import {
  StarOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";

import Styles from "../styles/programDetails.module.scss";
import { getBenefitRewardsFromTiers } from "./utils";
import MetabaseReports from "./metabaseReports";

const ProgramTabs = ({ currentTab, setCurrentTab }) => {
  const { t } = useTranslation();
  const items = [
    {
      icon: () => (
        <span className="mr-2">
          <i class="bi bi-view-stacked"></i>
        </span>
      ),
      key: "Tiers",
      label: t("PROGRAM_UI.TIERS"),
    },
    {
      icon: () => <StarOutlined className={Styles["icon"]} />,
      key: "Earn Points",
      label: t("PROGRAM_UI.EARN_POINTS"),
    },
    {
      icon: () => (
        <i className={`material-icons-outlined ${Styles["icon"]}`}>
          shopping_bag
        </i>
      ),
      key: "Spend Points",
      label: t("PROGRAM_UI.SPEND_POINTS"),
    },
    {
      icon: () => <UserOutlined className={Styles["icon"]} />,
      key: "Users Report",
      label: t("PROGRAM_UI.USERS_REPORT"),
    },
    {
      icon: () => <UnorderedListOutlined className={Styles["icon"]} />,
      key: "Passes Report",
      label: t("PROGRAM_UI.PASSES_REPORT"),
    },
    {
      icon: () => (
        <Micons isHover={false} icon="loyalty" className={Styles["icon"]} />
      ),
      key: "Benefits Report",
      label: t("PROGRAM_UI.BENEFITS_REPORT"),
    },
  ];
  return (
    <Tabs
      activeKey={currentTab}
      onChange={(activeKey) => setCurrentTab(activeKey)}
      tabBarGutter={40}
      size="large"
      items={items.map((tab, i) => {
        return {
          label: (
            <span className={Styles["tab-icon"]}>
              <tab.icon />
              {tab.label}
            </span>
          ),
          key: tab.key,
        };
      })}
    />
  );
};

const ProgramDetails = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentLocale } = useAppContext();
  const [programDetails, setProgramDetails] = useState(null);
  const [tiersForEdit, setTiersForEdit] = useState([]);
  const [loadingTiers, setLoadingTiers] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [programIdEdit, setProgramIdEdit] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [disableNext, setDisableNext] = useState(false);
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = useState("Tiers");
  const [allTiers, setAllTiers] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [benefitData, setBenefitData] = useState([]);
  const [waysToEarn, setWaysToEarn] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [currentLocaleData, setCurrentLocaleData] = useState(null);
  const [metabaseUrls, setMetabaseUrls] = useState({
    userDetails: null,
    passDetails: null,
  });

  useEffect(() => {
    if (programDetails) {
      const currentLocaleData = programDetails?.locales?.filter(
        (item) => item.localeId === currentLocale
      )[0];
      setCurrentLocaleData(currentLocaleData);
    }
  }, [programDetails, currentLocale]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getProgram(id);

      if (response.status !== 200) {
        notification.error({
          message: "Error",
          description: "Something went wrong while fetching the program",
          duration: 30,
          placement: "topRight",
        });
      } else {
        setProgramDetails(response.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getProgramMetabaseUrls(id);

      if (response.status !== 200) {
        notification.error({
          message: "Error",
          description:
            "Something went wrong while fetching the program metabase urls",
          duration: 30,
          placement: "topRight",
        });
      } else {
        setMetabaseUrls(response.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // get all tiers initially
    const fetchTiers = async () => {
      setLoadingTiers(true);
      const params = {
        programId: programDetails?.id,
        filters: {
          limit: 50,
          offset: 0,
        },
      };
      const response = await getAllTiers(params);
      if (response?.status !== 200) {
        notification.error({
          message: "Error",
          description: "Something went wrong while fetching the tiers.",
          duration: 30,
          placement: "topRight",
        });
      } else {
        setAllTiers(response.data.tiers);
      }
      setLoadingTiers(false);
    };

    // get all merchants initially
    const fetchMerchants = async () => {
      const getAllMerchants = await getAllMerchantsFromDb();
      if (getAllMerchants?.status === 200) {
        setMerchantData(() =>
          getAllMerchants.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
              id: item.id,
              isSelected: false,
              imageURL: item.heroImageUrl,
              heroImageUrl: item.heroImageUrl,
              logoUrl: item.logoUrl,
            };
          })
        );
      }
    };

    // get all benefits initially
    const fetchBenefits = async () => {
      const getAllBenefits = await getAllBenefitsFromDb();
      if (getAllBenefits?.status === 200) {
        setBenefitData(() =>
          getAllBenefits.data.map((benefit) => {
            return {
              label: benefit.title,
              value: benefit.id,
              id: benefit.id,
              type: benefit.type,
              imageURL: benefit.merchant
                ? benefit.merchant.heroImageUrl
                : benefit.previewResourceUrl
                ? benefit.previewResourceUrl
                : undefined,
            };
          })
        );
      }
    };

    // get all point rewards initially
    const fetchPointRewards = async () => {
      const getAllPointRewards = await getAllPointRewardsFromDb({
        filterCondition: { programId: programDetails.id },
      });
      if (getAllPointRewards?.status === 200) {
        setWaysToEarn(getAllPointRewards.data);
      }
    };

    // get all nftData initially

    if (programDetails && !isOpen) {
      fetchTiers()
        .then(fetchMerchants)
        .then(fetchBenefits)
        .then(fetchPointRewards)
        .then(() => {
          let benefitRewards = getBenefitRewardsFromTiers(programDetails.tiers);
          setRewards([...benefitRewards]);
        });
      // .then(fetchNfts);
    }
  }, [programDetails]);

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
      form.resetFields();
    }
  }, [programDetails, allTiers]);

  const handleEditClick = (current) => {
    const finalData = {
      id: programDetails.id,
      program_name: programDetails.name,
      program_description: programDetails.description,
      program_status: programDetails.status,
      points_enabled: programDetails.pointsEnabled,
      points_expire: programDetails.pointsExpire,
      points_expiration_period: programDetails.expirationPeriod,
    };
    const tierData = allTiers.map((item) => {
      return {
        id: item.id,
        tierName: item.name,
        tierDescription: item.description,
        expiryDate: moment(item.expiryDate).format("DD/MM/YYYY"),
        tierPointThreshold: item.pointThreshold,
        isSavedToDb: true,
        passData: {
          tierBenefits: item.tierBenefits,
          tierInformation: item.information,
          logoImage: [{ resourceUrl: item.logoImageUrl, type: "logoImage" }],
          iconImage: [{ resourceUrl: item.iconImageUrl, type: "iconImage" }],
          stripImage: [{ resourceUrl: item.stripImageUrl }],
          applelogoimage: [
            { resourceUrl: item.appleLogoImageUrl, type: "appleLogoImage" },
          ],
          heroimage: [{ resourceUrl: item.heroImageUrl }],
          locations: item.locations,
          locales: item.locales,
          defaultLocaleId: item.defaultLocaleId,
          appleLogoSize: item.appleLogoSize,
          labelColor: item.passColors?.labelColor,
          passColor: item.passColors?.stripColor,
          textColor: item.passColors?.textColor,
        },
      };
    });
    form.resetFields();
    setTiersForEdit(tierData);
    setProgramIdEdit(programDetails.id);
    setIsEdit(true);
    form.setFieldsValue(finalData);
    setIsOpen(true);
    setCurrentStep(current);
    setDisableNext(!current);
  };

  const handleFormSubmit = (data) => {
    setProgramDetails({
      id: data.id,
      name: data.name,
      description: data.description,
      status: data.status,
    });
    const newTiers = data?.tiers?.slice(allTiers.length, data?.tiers?.length); //Getting the new tier currently created

    data?.tiers?.splice(allTiers.length, data?.tiers?.length - allTiers.length);

    setAllTiers([...newTiers, ...data?.tiers]); //Moving new tier to the beginning of the tiers array and removing it from the end
  };

  const handleCancelClick = () => {
    form.resetFields();
    setIsOpen(false);
  };

  return (
    <>
      <PageHeader
        breadcrumbs={BREADCRUMB_PROGRAMS_DETAIL_PAGE(
          currentLocaleData
            ? currentLocaleData.name
            : programDetails?.name || "",
          t
        )}
        title={
          <div className={Styles["page-header-heading"]}>
            <h3>
              {currentLocaleData
                ? currentLocaleData.name
                : programDetails?.name || ""}
            </h3>
            <ReadMore
              style={{ lineHeight: "20px" }}
              text={
                currentLocaleData
                  ? currentLocaleData.description
                  : programDetails?.description
              }
              characterLimit={150}
            >
              {(currentLocaleData
                ? currentLocaleData.description.length > 150
                : programDetails?.description?.length > 150) && (
                <ReadMore.Trigger
                  trigger="...more"
                  text={programDetails?.description}
                />
              )}
            </ReadMore>
          </div>
        }
        headStyle={{ marginTop: 40, marginBottom: 20 }}
        extra={[
          <Row gutter={20}>
            <Col>
              <Button
                type="default"
                icon={<Micons isHover={false} icon="edit" />}
                disabled={!programDetails}
                onClick={() => handleEditClick(0)}
              >
                {t("PROGRAM_UI.EDIT_PROGRAM")}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<Micons isHover={false} icon="loyalty" />}
                disabled={!programDetails}
                onClick={() =>
                  navigate(`/programs/${id}/tiers/benefits`)
                }
              >
                {t("PROGRAM_UI.ASSIGN_BENEFIT")}
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                icon={<Micons isHover={false} icon="add" />}
                disabled={!programDetails || loadingTiers}
                onClick={() => handleEditClick(1)}
              >
                {t("PROGRAM_UI.CREATE_TIERS")}
              </Button>
            </Col>
          </Row>,
        ]}
        extrasClassName={Styles["page-header-button-cont"]}
      />

      <ProgramTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />

      {loadingTiers ? (
        <Skeleton active />
      ) : currentTab === "Tiers" ? (
        <>
          <Row gutter={[25, 35]}>
            {allTiers.map((item) => (
              <Col xs={24} md={12} lg={6}>
                <TierCard item={item} />
              </Col>
            ))}
          </Row>
        </>
      ) : currentTab === "Earn Points" ? (
        <EarnPointsSettings
          allTiers={allTiers}
          merchantData={merchantData}
          benefitData={benefitData}
          waysToEarn={waysToEarn}
          setWaysToEarn={setWaysToEarn}
          programDetails={programDetails}
          currentLocaleData={currentLocaleData}
        />
      ) : currentTab === "Spend Points" ? (
        <SpendPointsSettings
          allTiers={allTiers}
          merchantData={merchantData}
          benefitData={benefitData}
          rewards={rewards}
          setRewards={setRewards}
          programDetails={programDetails}
          currentLocaleData={currentLocaleData}
        />
      ) : currentTab === "Users Report" ? (
        <MetabaseReports url={metabaseUrls.userDetails} />
      ) : currentTab === "Passes Report" ? (
        <MetabaseReports url={metabaseUrls.passDetails} />
      ) : currentTab === "Benefits Report" ? (
        <MetabaseReports url={metabaseUrls.benefitDetails} />
      ) : (
        <></>
      )}

      <NewProgram
        form={form}
        isOpen={isOpen}
        onCancel={handleCancelClick}
        onSubmit={handleFormSubmit}
        currentStep={currentStep}
        isEdit={isEdit}
        tierList={tiersForEdit}
        setTierList={setTiersForEdit}
        programIdEdit={programIdEdit}
        setAllTiers={setAllTiers}
        setProgramDetails={setProgramDetails}
        disableNext={disableNext}
      />
    </>
  );
};

export default ProgramDetails;
