import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import App from "./app";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./redux/user.slice";
import loggerReducer from "./redux/logger.slice";
import roleReducer from "./redux/role.slice";
import "./redux/interseptor";

import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "material-icons/iconfont/material-icons.css";

const store = configureStore({
  reducer: {
    userState: userReducer,
    loggerState: loggerReducer,
    roleState: roleReducer,
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#9B43FD",
          fontFamily: '"Inter", sans-serif',
          controlHeight: 34,
          colorTextPlaceholder: "#8C8C8C",
        },
      }}
    >
      <Router>
        <App />
      </Router>
    </ConfigProvider>
  </Provider>
);
