import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Row, Col, Accordion, Card } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { t } from 'i18next';

import { createCompany, updateCompany } from '../../pages/profile/apiUtils';
import { STATE_CODES, COUNTRIES } from '../../utils/constants';

const ContactAccordionItem = ({
    title,
    eventKey,
    activeKey,
    setActiveKey,
    contact,
    setContact,
}) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
        if (activeKey === eventKey) {
            setActiveKey(null)
        } else {
            setActiveKey(eventKey)
        }
    });

    const handleFirstNameChange = (e) => {
        setContact(prev => ({ ...prev, firstName: e.target.value }))
    }

    const handleLastNameChange = (e) => {
        setContact(prev => ({ ...prev, lastName: e.target.value }))
    }

    const handleEmailChange = (e) => {
        setContact(prev => ({ ...prev, email: e.target.value }))
    }

    const handlePhoneNumberChange = (e) => {
        setContact(prev => ({ ...prev, phoneNumber: e.target.value }))
    }

    const handleAddressLine1Change = (e) => {
        setContact(prev => ({ ...prev, addressLine1: e.target.value }))
    }

    const handleAddressLine2Change = (e) => {
        setContact(prev => ({ ...prev, addressLine2: e.target.value }))
    }

    const handleCityChange = (e) => {
        setContact(prev => ({ ...prev, city: e.target.value }))
    }

    const handlePostalCodeChange = (e) => {
        setContact(prev => ({ ...prev, postalCode: e.target.value }))
    }

    const handleStateCodeChange = (e) => {
        setContact(prev => ({ ...prev, stateCode: e.target.value }))
    }

    const handleCountryCodeChange = (e) => {
        setContact(prev => ({ ...prev, CountryCode: e.target.value }))
    }

    return (
        <>
            <Card.Header className='pl-0 border-0 mb-2'>
                <button
                    type="button"
                    style={{}}
                    onClick={decoratedOnClick}
                    className="text-black bg-transparent font-weight-bold"
                >
                    {activeKey === eventKey ?
                        <i className="bi bi-caret-down-fill pr-1" />
                        :
                        <i className="bi bi-caret-right-fill pr-1" />
                    }
                    {title}
                </button>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} className="bg-transparent">
                <Card.Body
                    style={{ backgroundColor: "#f5f5f5" }}
                >
                    <Form.Group className="mb-3 font-weight-light">
                        <Row className="mb-3 font-weight-light">
                            <Form.Group as={Col}>
                                <Form.Label>{t("COMPANIES.FIRST_NAME")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t("COMPANIES.FIRST_NAME_PLACEHOLDER")}
                                    required={eventKey === "primary"}
                                    value={contact ? contact.firstName : ""}
                                    onChange={handleFirstNameChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>{t("COMPANIES.LAST_NAME")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t("COMPANIES.LAST_NAME_PLACEHOLDER")}
                                    required={eventKey === "primary"}
                                    value={contact ? contact.lastName : ""}
                                    onChange={handleLastNameChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3 font-weight-light">
                            <Form.Group as={Col}>
                                <Form.Label>{t("EMAIL")}</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder={t("COMPANIES.EMAIL_PLACEHOLDER")}
                                    required={eventKey === "primary"}
                                    value={contact ? contact.email : ""}
                                    onChange={handleEmailChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>{t("COMPANIES.PHONE_NUMBER")}</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder={t("COMPANIES.PHONE_NUMBER_PLACEHOLDER")}
                                    value={contact ? contact.phoneNumber : ""}
                                    onChange={handlePhoneNumberChange}
                                />
                            </Form.Group>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("COMPANIES.ADDRESS")}</Form.Label>
                            <Form.Control
                                placeholder={t("COMPANIES.ADDRESS_PLACEHOLDER")}
                                value={contact ? contact.addressLine1 : ""}
                                onChange={handleAddressLine1Change}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("COMPANIES.SECOND_ADDRESS")}</Form.Label>
                            <Form.Control
                                placeholder={t("COMPANIES.SECOND_ADDRESS_PLACEHOLDER")}
                                value={contact ? contact.addressLine2 : ""}
                                onChange={handleAddressLine2Change}
                            />
                        </Form.Group>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>{t("COMPANIES.CITY")}</Form.Label>
                                <Form.Control
                                    value={contact ? contact.city : ""}
                                    onChange={handleCityChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>{t("COMPANIES.STATE")}</Form.Label>
                                <Form.Select
                                    defaultValue={`${t("COMPANIES.CHOOSE")}...`}
                                    value={contact ? contact.stateCode : ""}
                                    onChange={handleStateCodeChange}
                                >
                                    {STATE_CODES.map(code => <option key={code}>{code}</option>)}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>{t("COMPANIES.COUNTRY")}</Form.Label>
                                <Form.Select
                                    defaultValue={`${t("COMPANIES.CHOOSE")}...`}
                                    value={contact ? contact.CountryCode : ""}
                                    onChange={handleCountryCodeChange}
                                >
                                    {COUNTRIES.map(country =>
                                        <option
                                            key={country.code}
                                            value={country.code}
                                        >
                                            {country.name}
                                        </option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} style={{width: "50%"}}>
                                <Form.Label>{t("COMPANIES.ZIP")}</Form.Label>
                                <Form.Control
                                    value={contact ? contact.postalCode : ""}
                                    onChange={handlePostalCodeChange}
                                />
                            </Form.Group>
                        </Row>

                    </Form.Group>
                </Card.Body>
            </Accordion.Collapse>
        </>
    )
}

export default function CompanyModal({
    show,
    hide,
    addCompanyToTable,
    updateCompanyInTable,
    editingCompany
}) {
    const [name, setName] = useState()
    const [url, setUrl] = useState()
    const [companyType, setCompanyType] = useState()
    const [primaryContact, setPrimaryContact] = useState()
    const [billingContact, setBillingContact] = useState()
    const [technicalContact, setTechnicalContact] = useState()
    const [validated, setValidated] = useState(false)
    const [activeContactKey, setActiveContactKey] = useState("primary")

    useEffect(() => {
        let mounted = true
        if (mounted && editingCompany) {
            setName(editingCompany.name)
            setUrl(editingCompany.url)
            setCompanyType(editingCompany.companyType)
            setPrimaryContact(editingCompany.primaryContact)
            setBillingContact(editingCompany.billingContact)
            setTechnicalContact(editingCompany.technicalContact)
        }
        return () => mounted = false
    }, [editingCompany])

    const closeModal = () => {
        setName(null)
        setUrl(null)
        setPrimaryContact(null)
        setBillingContact(null)
        setTechnicalContact(null)
        setValidated(false)
        setActiveContactKey("primary")
        hide()
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true)
            return
        }

        if (editingCompany) {
            updateCompany({
                id: editingCompany.id,
                companyStatus: editingCompany.companyStatus,
                name,
                url,
                companyType,
                primaryContact,
                billingContact,
                technicalContact
            }).then(response => {
                updateCompanyInTable(response.data)
            })
        } else {
            createCompany({
                name,
                url,
                companyType: companyType ? companyType : "business",
                primaryContact,
                billingContact,
                technicalContact
            }).then(response => {
                addCompanyToTable(response.data)
            })
        }


        setValidated(false)
        closeModal()
    }

    return (
        <Modal
            centered
            backdrop={true}
            show={show}
            onHide={closeModal}
            className="modalStyle"
            scrollable
        >
            <Form
                className="text-black"
                onSubmit={onSubmit}
                noValidate
                validated={validated}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-black">
                        {editingCompany ? t("COMPANIES.EDIT") : t("COMPANIES.ADD_NEW")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                    }}
                >
                    <Form.Group className="mb-3">
                        <Form.Label>{t("NAME")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("COMPANIES.NAME_PLACEHOLDER")}
                            value={name ? name : ""}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {t("COMPANIES.NAME_ERROR")}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>{t("COMPANIES.URL")}</Form.Label>
                        <Form.Control
                            type="url"
                            placeholder="https://example.com"
                            value={url ? url : ""}
                            onChange={(e) => setUrl(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {t("URL_ERROR")}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("COMPANIES.COMPANY_TYPE")}</Form.Label>
                        <Form.Select
                            name="companyType"
                            value={companyType ? companyType : ""}
                            onChange={(e) => setCompanyType(e.target.value)}
                        >
                            <option value="business">{t("COMPANIES.BUSINESS")}</option>
                            <option value="personal">{t("COMPANIES.PERSONAL")}</option>
                        </Form.Select>
                    </Form.Group>
                    <Accordion activeKey={activeContactKey}>
                        <ContactAccordionItem
                            eventKey={"primary"}
                            activeKey={activeContactKey}
                            setActiveKey={setActiveContactKey}
                            title={t("COMPANIES.PRIMARY_CONTACT")}
                            contact={primaryContact}
                            setContact={setPrimaryContact}

                        />
                        <ContactAccordionItem
                            eventKey={"billing"}
                            activeKey={activeContactKey}
                            setActiveKey={setActiveContactKey}
                            title={t("COMPANIES.BILLING_CONTACT")}
                            contact={billingContact}
                            setContact={setBillingContact}
                        />
                        <ContactAccordionItem
                            eventKey={"technical"}
                            activeKey={activeContactKey}
                            setActiveKey={setActiveContactKey}
                            title={t("COMPANIES.TECHNICAL_CONTACT")}
                            contact={technicalContact}
                            setContact={setTechnicalContact}
                        />
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btnSecondary" onClick={hide}>{t("CANCEL")}</Button>
                    <Button
                        className="btnPrimary"
                        type="submit"
                    >
                        {editingCompany ? t("COMPANIES.EDIT") : t("COMPANIES.NEW")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}