import {
    useEffect,
    useState,
} from "react";
import { useTranslation } from "react-i18next";


export const usePosition = () => {

    const [position, setPosition] = useState({});
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true
        if (mounted) {
            getLocation()
        }
        return () => {
            mounted = false
        }

    }, [])

    const onLocationSuccess = (pos) => {
        setPosition({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
        });
    }

    const onLocationError = (err) => {
        console.warn(`POSITION ERROR(${err.code}): ${err.message}`);
        if (!position) {
            alert(t("SCAN.LOCATION_TRACKING.GRANT_PERMISSIONS"))
            setError({
                code: err.code,
                message: err.message
            })
        }
    }

    const locationOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(onLocationSuccess, onLocationError, locationOptions);
        } else {
            alert(t("SCAN.LOCATION_TRACKING.UNSUPPORTED_BROWSER"));
        }
    }

    return [position, error]

}