import { Button as ButtonBox } from "antd";

import Styles from "./styles/button.module.scss";

const Button = ({ children, ...props }) => {
  return (
    <ButtonBox className={Styles.button} {...props}>
      {children}
    </ButtonBox>
  );
};

export default Button;
