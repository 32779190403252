// import Third party and React
import { useEffect, useMemo, useState } from "react";
import {
  Space,
  Row,
  Col,
  Form,
  Skeleton,
  Popconfirm,
  notification,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// import utils, API Utils and helper functions
import {
  deleteMerchantFromDb,
  addMerchantToDb,
  updateMerchantToDb,
  getAllMerchantsFromDb,
  // getMerchantCategoriesFromDb,
} from "./apiUtils";
import {
  debounce,
  getMerchantCategoryAndSubCategory,
} from "../../../utils/helper";

// import custom components
import {
  Button,
  Table,
  Tag,
  Card,
  NoData,
  Micons,
  PageHeader,
} from "../../../components/customAntd";
import MerchantFilters from "./filters";
import NewMerchant from "./newMerchant";
import { useAppContext } from "../../../components/context/app.context";
import { BREADCRUMB_MERCHANTS } from "../../../utils/merchants/constant";
import DefaultImage from "../../../assets/images/default-img.jpg";

// import custom Style
import Styles from "./styles/merchant.module.scss";

const { Meta } = Card;

const MerchantPage = () => {
  const [form] = Form.useForm();
  const [displayType, setDisplayType] = useState("grid");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState([]);
  const [heroImage, setHeroImage] = useState([]);
  const [logoImage, setLogoImage] = useState([]);
  const [heroImages, setHeroImages] = useState([]);
  const [logoImages, setLogoImages] = useState([]);
  const [merchantIdEdit, setMerchantIdEdit] = useState(null);
  const [filters, setFilters] = useState({
    filterCondition: {},
  });
  const { t } = useTranslation();
  const { currentLocale, categories } = useAppContext();

  // useMemo is used to prevent the re-creation of the 'columns' variable on every render.
  const columns = useMemo(
    () => [
      {
        dataIndex: "heroImageUrl",
        width: 100,
        render: (_, record) => (
          <img
            src={
              record?.locales?.find?.(
                (locale) => locale.localeId === currentLocale
              )?.heroImageUrl ??
              record?.heroImageUrl ??
              DefaultImage
            }
            alt="Preview"
            height={50}
            width={50}
            className="table-data-image"
            onClick={() => handleEditClick(record)}
          />
        ),
      },
      {
        title: t("MERCHANT.NAME_COLUMN"),
        dataIndex: "name",
        key: "name",
        width: "30%",
        sorter: (a, b) => a?.title?.length - b?.title?.length,
        render: (_, { name, locales }) =>
          locales?.find?.((locale) => locale.localeId === currentLocale)
            ?.name ?? name,
      },
      {
        title: t("DESCRIPTION_COLUMN"),
        dataIndex: "description",
        key: "description",
        render: (_, { description, locales }) =>
          locales?.find?.((locale) => locale.localeId === currentLocale)
            ?.description ?? description,
      },
      {
        title: t("DATE"),
        key: "startDate",
        dataIndex: "startDate",
        width: "30%",
        render: (_, { startDate }) => (
          <>
            <Tag isDisabled>{dayjs(startDate).format("MM/DD/YYYY")}</Tag>
          </>
        ),
      },
      {
        title: t("ACTION"),
        key: "action",
        width: "15%",
        render: (_, record) => (
          <Space size="middle">
            <Button type="link">
              <Tooltip title={t("EDIT")}>
                <Micons
                  icon="edit"
                  className="table-action-icon"
                  onClick={() => handleEditClick(record)}
                />
              </Tooltip>
            </Button>
            <Popconfirm
              title={t("MERCHANT.CONFIRM_DELETE")}
              onConfirm={() => handleDelete(record.id)}
              onCancel={() => {}}
              okText={t("YES")}
              cancelText={t("NO")}
              placement="topRight"
              arrowPointAtCenter
            >
              <Button type="link">
                <Tooltip title={t("DELETE")}>
                  <Micons icon="delete" className="table-action-icon" />
                </Tooltip>
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories, t]
  );

  // Get all Merchants initially
  useEffect(() => {
    const fetchMerchants = async () => {
      setIsLoading(true);
      const getAllMerchants = await getAllMerchantsFromDb(filters);
      if (getAllMerchants?.status === 200) {
        setData(getAllMerchants.data);
      }
      setIsLoading(false);
    };
    fetchMerchants();
  }, [filters]);

  // Switching between a list view and a grid view.
  const onViewChange = () => {
    setDisplayType(displayType === "list" ? "grid" : "list");
  };

  // Handler for the cancel button
  const handleCancleClick = () => {
    form.resetFields();
    setIsSubmitting(false);
    setIsDataLoading(false);
    // setShowCreateMerchant(false);
    setIsEdit(false);
    setIsOpen(false);
    setHeroImage([]);
    setLogoImage([]);
    setHeroImages([]);
    setLogoImages([]);
  };

  // Submitting the final form after it has passed all of the validation checks
  const handleFormSubmit = async (data) => {
    setIsSubmitting(true);

    data?.locales?.forEach((locale, index) => {
      if (heroImages[index]) {
        locale.heroImageUrl = heroImages?.[index]?.[0]?.resourceUrl;
      } else {
        delete locale.heroImageUrl;
      }
      if (logoImages[index]) {
        locale.logoUrl = logoImages?.[index]?.[0]?.resourceUrl;
      } else {
        delete locale.logoUrl;
      }
    });

    if (data?.locales?.length > 0 && isEdit) {
      data.locales.forEach((locale) => delete locale.merchantId);
    }
    const finalData = {
      name: data.merchant_name,
      categoryId: data.categoryId,
      description: data.merchant_description,
      defaultLocaleId: data.defaultLocale,
      // selfRedeem: data.merchant_selfRedeem === "yes" ? true : false,
      locales: data.locales,
      locations: data.discount_location?.map((item) => {
        return {
          name: item.name,
          latitude: parseFloat(item.latitude),
          longitude: parseFloat(item.longitude),
        };
      }),
      heroImageUrl: heroImage[0].resourceUrl,
      logoUrl: logoImage[0].resourceUrl,
      phoneNumber: data.merchant_phoneNumber || null,
      email: data.merchant_email || null,
      url: data.merchant_url || null,
      type: data.type,
      partnerType: data.partnerType,
      partnerCode: data.partnerCode,
    };

    const createNewMerchant = isEdit
      ? await updateMerchantToDb(finalData, merchantIdEdit)
      : await addMerchantToDb(finalData);
    if (createNewMerchant?.status === 200) {
      setHeroImage([]);
      setLogoImage([]);
      setHeroImages([]);
      setLogoImages([]);
      if (isEdit) {
        setData((prev) =>
          prev.map((item) =>
            item.id === merchantIdEdit ? createNewMerchant.data : item
          )
        );
      } else {
        setData((prev) => [createNewMerchant.data, ...prev]);
      }
      handleCancleClick();
    }
    setIsDataLoading(false);
    setIsSubmitting(false);
  };

  // Handler for the edit 'merchant' button
  const handleEditClick = (record) => {
    const finalData = {
      id: record.id,
      merchant_name: record.name,
      merchant_description: record.description,
      discount_location: record.locations,
      locales: record.locales,
      defaultLocale: record.defaultLocaleId,
      merchant_url: record.url,
      merchant_phoneNumber: record.phoneNumber,
      merchant_email: record.email,
      // merchant_selfRedeem: record.selfRedeem ? "yes" : "no",
      type: record.type,
      partnerType: record.partnerType,
      partnerCode: record.partnerCode,
      categoryId: record.categoryId,
    };

    setHeroImage([{ resourceUrl: record.heroImageUrl ?? "null" }]);
    setLogoImage([{ resourceUrl: record.logoUrl ?? "null" }]);
    const heroImagesArray = [];
    const logoImagesArray = [];
    for (const locale of record.locales) {
      if (locale?.heroImageUrl)
        heroImagesArray.push([{ resourceUrl: locale?.heroImageUrl }]);
      if (locale?.logoUrl)
        logoImagesArray.push([{ resourceUrl: locale?.logoUrl }]);
    }
    setHeroImages(heroImagesArray);
    setLogoImages(logoImagesArray);
    setMerchantIdEdit(record.id);
    setIsEdit(true);
    form.setFieldsValue(finalData);
    setIsOpen(true);
    // setShowCreateMerchant(true);
  };

  // Handler for the delete 'merchant' button
  const handleDelete = async (recordId) => {
    const deletedMerchant = await deleteMerchantFromDb(recordId);
    if (deletedMerchant?.status === 200) {
      setData((prev) =>
        prev.filter((item) => item.id !== deletedMerchant.data.id)
      );
    } else {
      notification.error({
        message: "Error",
        description: deletedMerchant?.data?.message,
        duration: 30,
        placement: "topRight",
      });
    }
  };

  // Handler for the search input
  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    if (searchTerm !== "") {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            search: searchTerm,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.search;
      setFilters(filtersCopy);
    }
  });

  // Handler for the filter select
  const handleFilterByChange = (value) => {
    if (value) {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            status: value,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.status;
      setFilters(filtersCopy);
    }
  };

  return (
    <>
      <PageHeader
        title={t("MERCHANTS")}
        breadcrumbs={BREADCRUMB_MERCHANTS(t, isEdit)}
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {t("MERCHANT.NEW")}
          </Button>
        }
      />
      <MerchantFilters
        onViewChange={onViewChange}
        type={displayType}
        onSearch={handleSearch}
        onFilterByChange={handleFilterByChange}
        // setShowCreateMerchant={setShowCreateMerchant}
      />
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {data.length === 0 ? (
            <NoData />
          ) : (
            <>
              {displayType === "list" ? (
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                  locale={{
                    triggerDesc: t("SORT_DESCENDING"),
                    triggerAsc: t("SORT_ASCENDING"),
                    cancelSort: t("CANCEL_SORT"),
                  }}
                />
              ) : (
                <Row gutter={[25, 25]}>
                  {data.map((item) => {
                    const currentLocaleData = item?.locales?.find(
                      (locale) => locale.localeId === currentLocale
                    );
                    return (
                      <Col
                        xs={24}
                        md={12}
                        lg={6}
                        key={`${item.name}-${item.id}}`}
                      >
                        <Card
                          className={Styles["card"]}
                          cover={
                            <img
                              alt={item.title}
                              src={
                                currentLocaleData?.heroImageUrl ??
                                item.heroImageUrl
                              }
                              height="200px"
                              width="100%"
                              className="pointer"
                              onClick={() => handleEditClick(item)}
                            />
                          }
                          actions={[
                            <Popconfirm
                              title={t("MERCHANT.CONFIRM_DELETE")}
                              onConfirm={() => handleDelete(item.id)}
                              onCancel={() => {}}
                              okText={t("YES")}
                              cancelText={t("NO")}
                            >
                              <Tooltip title={t("DELETE")}>
                                <Micons
                                  icon="delete"
                                  className="table-action-icon"
                                />
                              </Tooltip>
                            </Popconfirm>,
                            <Tooltip title={t("EDIT")}>
                              <Button
                                type="link"
                                onClick={() => handleEditClick(item)}
                              >
                                <Micons
                                  icon="edit"
                                  className="table-action-icon"
                                />
                              </Button>
                            </Tooltip>,
                          ]}
                        >
                          <Tag className={Styles.tag} isDisabled>
                            {dayjs(item.startDate).format("MM/DD/YYYY")}
                          </Tag>
                          <Meta title={currentLocaleData?.name ?? item.name} />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </>
          )}
        </>
      )}

      <NewMerchant
        form={form}
        isOpen={isOpen}
        onCancel={() => {
          handleCancleClick();
        }}
        onSubmitClick={() => form.submit()}
        onSubmit={handleFormSubmit}
        loading={isSubmitting}
        isDataLoading={isDataLoading}
        isEdit={isEdit}
        setHeroImage={setHeroImage}
        setLogoImage={setLogoImage}
        heroImage={heroImage}
        logoImage={logoImage}
        heroImages={heroImages}
        setHeroImages={setHeroImages}
        logoImages={logoImages}
        setLogoImages={setLogoImages}
      />
    </>
  );
};

export default MerchantPage;
