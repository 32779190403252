import { useState, useEffect, useContext } from "react";
import { Form, Tooltip, Row, Col, Switch, notification } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { Input } from "../../components/form";
import { Modal, Button } from "../../components/customAntd";
import Radio from "../../components/customAntd/form/radio";
import Contact from "./contact";
import { updateCompany } from "./apiUtil";
import AppContext from "../../components/context/app.context";

import Styles from "./styles/onboarding.module.scss";

const OnBoarding = ({ emailId, companyId }) => {
  const { currentUserData, setCurrentUserData, handleLogOut } =
    useContext(AppContext);
  const [isOpen, setIsOpen] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [includeTechnicalContact, setIncludeTechnicalContact] = useState(false);
  const [includeBillingContact, setIncludeBillingContact] = useState(false);
  const [form] = Form.useForm();
  const companyType = Form.useWatch("companyType", form);

  useEffect(() => {
    if (!currentUserData.id) {
      handleLogOut();
    }
  }, [currentUserData]);

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    data.primaryContact.email = emailId;
    data.url = "https://faro.xyz";
    data.primaryContact.contactType = "primary";
    data.companyStatus = "approved";
    if (companyType === "business") {
      if (includeTechnicalContact)
        data.technicalContact.contactType = "technical";
      if (includeBillingContact) data.billingContact.contactType = "billing";
    }

    const response = await updateCompany(data, companyId);

    if (response?.message) {
      notification.error({
        message: "Error",
        description: response.message,
        duration: 30,
        placement: "topRight",
      });
      setIsSubmitting(false);
    } else {
      setCurrentUserData((prev) => {
        return {
          ...prev,
          company: {
            ...prev.company,
            companyStatus: "approved",
          },
        };
      });
    }
  };

  return (
    <Modal
      centered
      mask
      open={isOpen}
      closable={false}
      title={
        <>
          <h2>Set Up Your Account</h2>
          <p>
            To get started on Faro, we just need a little more information from
            you.
          </p>
        </>
      }
      className={Styles["account-setup-modal"]}
      maskClosable={true}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Input
          label="First Name"
          name={["primaryContact", "firstName"]}
          placeholder="Enter your first name"
          rules={[
            { type: "text", message: "only text is required" },
            { required: true, message: "First Name is required" },
            {
              validator: async (_, name) => {
                if (name) {
                  if (name.length < 3)
                    return Promise.reject(
                      "First name must be at least 3 characters"
                    );
                  if (name.length > 40)
                    return Promise.reject(
                      "First name must be less than 40 characters"
                    );
                }
              },
            },
          ]}
        />
        <Input
          label="Last Name"
          name={["primaryContact", "lastName"]}
          placeholder="Enter your last name"
          rules={[
            { type: "text" },
            { required: true, message: "Last Name is required" },
            {
              validator: async (_, name) => {
                if (name) {
                  if (name.length > 40)
                    return Promise.reject(
                      "Last name must be less than 40 characters"
                    );
                }
              },
            },
          ]}
        />
        <Form.Item
          label="Type of Account?"
          name="companyType"
          rules={[
            { required: true, message: "Please select the type of account" },
          ]}
        >
          <Radio.Group>
            <Row gutter={20}>
              <Col xs={12}>
                <Radio value="business">
                  {" "}
                  <>
                    <span>Company</span>
                    <Tooltip
                      overlayClassName={Styles.onboardingFormTooltip}
                      title="Company use: Extended functionality. Create teams and add multiple users."
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                </Radio>
              </Col>
              <Col xs={12}>
                <Radio value="personal">
                  {" "}
                  <>
                    <span>Personal</span>
                    <Tooltip
                      overlayClassName={Styles.onboardingFormTooltip}
                      title="Personal use: Basic functionality. Limited to one user."
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        {companyType === "business" && (
          <>
            <Input
              label="Company Name"
              name="name"
              placeholder="Enter your company name"
              rules={[
                { type: "text", message: "" },
                { required: true, message: "Company Name is required" },
              ]}
            />
            <Contact
              label="Technical Contact"
              name="technicalContact"
              includeContact={includeTechnicalContact}
              setIncludeContact={setIncludeTechnicalContact}
            />
            <Contact
              label="Billing Contact"
              name="billingContact"
              includeContact={includeBillingContact}
              setIncludeContact={setIncludeBillingContact}
            />
          </>
        )}
        <Button
          type="primary"
          className="get-started-button"
          htmlType="submit"
          disabled={isSubmitting}
        >
          {" "}
          Get Started
        </Button>
      </Form>
    </Modal>
  );
};

export default OnBoarding;
