import { Fragment } from "react";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Row, Col, Select, Input, Divider, Popconfirm } from "antd";
import {
  FormWrapper,
  Button,
  UploadFile,
} from "../../../../components/customAntd";
import { AnimeTemplateName } from "../../../../utils/constants";

const { Item } = Form;
const LanguageOptions = ({
  t,
  themeId,
  availableLocales,
  watchLocales,
  updateLocales,
  localizedLogoImages,
  setLocalizedLogoImages,
  setLocalizedLogoImagesLoading,
  watchEnableFooter,
  watchEnableCustomTemplates,
  setAvailableLocales
}) => {
  return (
    <FormWrapper
      heading={t("LANGUAGE_OPTIONS.HEADING")}
      description={t("STOREFRONT_UI.LANGUAGE_OPTIONS.DESC")}
    >
      <Form.List name="locales">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <Fragment key={key}>
                <Row gutter={[13]}>
                  <Col lg={12}>
                    <Item
                      label={`${t("LANGUAGE")} :`}
                      name={[name, "localeId"]}
                      rules={[
                        {
                          required: true,
                          message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                        },
                      ]}
                    >
                      <Select
                        options={availableLocales.map((localEl) => ({
                          ...localEl,
                          disabled: localEl.isSelected,
                        }))}
                        onChange={(value) =>
                          updateLocales(
                            value,
                            watchLocales[index]?.localeId,
                            "storefront"
                          )
                        }
                        placeholder={t("SELECT")}
                      />
                    </Item>
                  </Col>
                  <Item
                    label={`${t("LOGO_IMAGE")} :`}
                    name={[name, "logoUrl"]}
                    extra={`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG. ${t(
                      "MAX_SIZE"
                    )}: 5${t("MB")}`}
                    valuePropName=""
                    rules={[
                      {
                        required: true,
                        message: t("UPLOAD_LOGO_IMAGE"),
                      },
                    ]}
                    style={{ paddingLeft: 6.5 }}
                  >
                    <UploadFile
                      showUploadList={false}
                      placeholder="1024×1024 (1:1)"
                      fileFor="collection"
                      fileSize={5}
                      accept=".jpg, .jpeg, .png"
                      style={{ width: "144px" }}
                      height={144}
                      image={localizedLogoImages[index]}
                      imageFit="contain"
                      onLoading={(value) =>
                        setLocalizedLogoImagesLoading((prev) => {
                          prev[index] = value;
                          return [...prev];
                        })
                      }
                      onChange={(image) => {
                        setLocalizedLogoImages((prev) => {
                          prev[index] = image.s3Url;
                          return [...prev];
                        });
                      }}
                    />
                  </Item>
                  {themeId === AnimeTemplateName && (
                    <>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.HERO_HEADLINE"
                          )}:`}
                          name={[name, "heroHeadline"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.HERO_HEADLINE_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.HERO_HEADLINE_PLACEHOLDER"
                            )}
                          />
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.HERO_DESCRIPTION"
                          )}:`}
                          name={[name, "heroDesc"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.HERO_DESCRIPTION_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <MDEditor
                            overflow={false}
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.HERO_DESCRIPTION_PLACEHOLDER"
                            )}
                            previewOptions={{
                              rehypePlugins: [[rehypeSanitize]],
                            }}
                          />
                          {/* <TextArea
                          rows={3}
                          placeholder={t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.HERO_DESCRIPTION_PLACEHOLDER"
                          )}
                        /> */}
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.BENEFITS_HEADLINE"
                          )}:`}
                          name={[name, "heroBenefitsHeadline"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.BENEFITS_HEADLINE_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.BENEFITS_HEADLINE_PLACEHOLDER"
                            )}
                          />
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.BENEFITS_DESCRIPTION"
                          )}:`}
                          name={[name, "heroBenefitsDescription"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.BENEFITS_DESCRIPTION_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <MDEditor
                            overflow={false}
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.BENEFITS_DESCRIPTION_PLACEHOLDER"
                            )}
                            previewOptions={{
                              rehypePlugins: [[rehypeSanitize]],
                            }}
                          />
                          {/* <TextArea
                          placeholder={t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.BENEFITS_DESCRIPTION_PLACEHOLDER"
                          )}
                        /> */}
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.LOYALTY_PROGRAM_HEADLINE"
                          )}:`}
                          name={[name, "headerText"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.LOYALTY_PROGRAM_HEADLINE_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.LOYALTY_PROGRAM_HEADLINE_PLACEHOLDER"
                            )}
                          />
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.LOYALTY_PROGRAM_CAPTION"
                          )}:`}
                          name={[name, "bodyText"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.LOYALTY_PROGRAM_CAPTION_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <MDEditor
                            overflow={false}
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.LOYALTY_PROGRAM_CAPTION_PLACEHOLDER"
                            )}
                            previewOptions={{
                              rehypePlugins: [[rehypeSanitize]],
                            }}
                          />
                          {/* <TextArea
                          rows={3}
                          placeholder={t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.LOYALTY_PROGRAM_CAPTION_PLACEHOLDER"
                          )}
                        /> */}
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.WAYS_TO_EARN_HEADLINE"
                          )}:`}
                          name={[name, "pointsTableHeaderText"]} // change field
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.WAYS_TO_EARN_HEADLINE_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.WAYS_TO_EARN_HEADLINE_PLACEHOLDER"
                            )}
                          />
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.WAYS_TO_EARN_CAPTION"
                          )}:`}
                          name={[name, "pointsTableBodyText"]} //change field
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.WAYS_TO_EARN_CAPTION_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <MDEditor
                            overflow={false}
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.WAYS_TO_EARN_CAPTION_PLACEHOLDER"
                            )}
                            previewOptions={{
                              rehypePlugins: [[rehypeSanitize]],
                            }}
                          />
                          {/* <TextArea
                          rows={3}
                          placeholder={t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.WAYS_TO_EARN_CAPTION_PLACEHOLDER"
                          )}
                        /> */}
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.MARKETING_HEADER_TEXT"
                          )}:`}
                          name={[name, "marketingHeadline"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.MARKETING_HEADER_TEXT_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.MARKETING_HEADER_TEXT_PLACEHOLDER"
                            )}
                          />
                        </Item>
                      </Col>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.MARKETING_BODY_TEXT"
                          )}:`}
                          name={[name, "marketingDesc"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.MARKETING_BODY_TEXT_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <MDEditor
                            overflow={false}
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.MARKETING_BODY_TEXT_PLACEHOLDER"
                            )}
                            previewOptions={{
                              rehypePlugins: [[rehypeSanitize]],
                            }}
                          />
                          {/* <TextArea
                          rows={3}
                          placeholder={t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.MARKETING_BODY_TEXT_PLACEHOLDER"
                          )}
                        /> */}
                        </Item>
                      </Col>
                    </>
                  )}
                  {watchEnableFooter && (
                    <>
                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.COMPANY_NAME"
                          )} :`}
                          name={[name, "companyName"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "STOREFRONT_UI.LANGUAGE_OPTIONS.COMPANY_NAME_VALIDATION"
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.COMPANY_NAME_PLACEHOLDER"
                            )}
                          />
                        </Item>
                      </Col>

                      <Col lg={24}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.FOOTER_TEXT"
                          )} :`}
                          name={[name, "footerText"]}
                        >
                          <MDEditor
                            overflow={false}
                            placeholder={t(
                              "STOREFRONT_UI.FOOTER_TEXT_PLACHOLDER"
                            )}
                            previewOptions={{
                              rehypePlugins: [[rehypeSanitize]],
                            }}
                          />
                        </Item>
                      </Col>
                    </>
                  )}

                  {themeId !== AnimeTemplateName &&
                    watchEnableCustomTemplates && (
                      <>
                        <Col lg={24}>
                          <Item
                            label={`${t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.VERIFY_EMAIL_TEMPLATE"
                            )} :`}
                            name={[name, "verifyEmailMailTemplate"]}
                            rules={[
                              {
                                required: true,
                                message: t("STOREFRONT_UI.TEMPLATE_REQUIRED"),
                              },
                            ]}
                          >
                            <MDEditor
                              overflow={false}
                              placeholder={t(
                                "STOREFRONT_UI.VERIFY_EMAIL_TEMPLATE_PLACEHOLDER"
                              )}
                              previewOptions={{
                                rehypePlugins: [[rehypeSanitize]],
                              }}
                            />
                          </Item>
                        </Col>

                        <Col lg={24}>
                          <Item
                            label={`${t(
                              "STOREFRONT_UI.LANGUAGE_OPTIONS.RESET_PASSWORD_TEMPLATE"
                            )} :`}
                            name={[name, "resetPasswordMailTemplate"]}
                            rules={[
                              {
                                required: true,
                                message: t("STOREFRONT_UI.TEMPLATE_REQUIRED"),
                              },
                            ]}
                          >
                            <MDEditor
                              overflow={false}
                              placeholder={t(
                                "STOREFRONT_UI.RESET_PASSWORD_TEMPLATE_PLACEHOLDER"
                              )}
                              previewOptions={{
                                rehypePlugins: [[rehypeSanitize]],
                              }}
                            />
                          </Item>
                        </Col>
                      </>
                    )}
                </Row>
                {watchEnableFooter && (
                  <>
                    <Divider
                      style={{
                        marginTop: 10,
                        marginBottom: 30,
                        borderColor: "#D9D9D9",
                      }}
                    />
                    <Item>
                      <h4>{`${t("STOREFRONT_UI.SOCIAL_LINKS")}:`}</h4>
                      <span>{t("STOREFRONT_UI.ADD_SOCIAL_LINKS")}</span>
                    </Item>

                    <Row gutter={[10]}>
                      <Col lg={12}>
                        <Item
                          label={t("STOREFRONT_UI.LANGUAGE_OPTIONS.WEBSITE")}
                          name={[name, "websiteUrl"]}
                          rules={[
                            {
                              type: "url",
                              message: t("STOREFRONT_UI.WEBSITE_VALIDATION"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("STOREFRONT_UI.WEBSITE_PLACEHOLDER")}
                            disabled={!watchEnableFooter}
                          />
                        </Item>
                      </Col>
                      <Col lg={12}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.TWITTER"
                          )}`}
                          name={[name, "twitterUrl"]}
                          rules={[
                            {
                              type: "url",
                              message: t("STOREFRONT_UI.WEBSITE_VALIDATION"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("STOREFRONT_UI.WEBSITE_PLACEHOLDER")}
                            disabled={!watchEnableFooter}
                          />
                        </Item>
                      </Col>
                      <Col lg={12}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.DISCORD"
                          )}`}
                          name={[name, "discordUrl"]}
                          rules={[
                            {
                              type: "url",
                              message: t("STOREFRONT_UI.WEBSITE_VALIDATION"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("STOREFRONT_UI.WEBSITE_PLACEHOLDER")}
                            disabled={!watchEnableFooter}
                          />
                        </Item>
                      </Col>
                      <Col lg={12}>
                        <Item
                          label={`${t(
                            "STOREFRONT_UI.LANGUAGE_OPTIONS.MEDIUM"
                          )}`}
                          name={[name, "mediumUrl"]}
                          rules={[
                            {
                              type: "url",
                              message: t("STOREFRONT_UI.WEBSITE_VALIDATION"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("STOREFRONT_UI.WEBSITE_PLACEHOLDER")}
                            disabled={!watchEnableFooter}
                          />
                        </Item>
                      </Col>
                    </Row>
                  </>
                )}

                <Popconfirm
                  title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                  onConfirm={() => {
                    setAvailableLocales((prev) => {
                      return prev?.map((item) =>
                        item?.value === watchLocales[index]?.localeId
                          ? {
                              ...item,
                              isSelected: false,
                            }
                          : item
                      );
                    });
                    setLocalizedLogoImages((prev) => {
                      prev.splice(index, 1);
                      return [...prev];
                    });
                    remove(name);
                  }}
                  onCancel={() => {}}
                  okText={t("YES")}
                  cancelText={t("NO")}
                >
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    style={{
                      color: "var(--color-form-error)",
                      placeContent: "flex-end",
                      marginTop: -20,
                      float: "right",
                    }}
                  >
                    {t("LANGUAGE_OPTIONS.DELETE_LOCALE")}
                  </Button>
                </Popconfirm>
                {fields.length !== 0 && (
                  <Divider
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      borderColor: "#D9D9D9",
                    }}
                  />
                )}
              </Fragment>
            ))}

            <Item style={{ marginBottom: 0 }}>
              <Button
                type="link"
                onClick={fields.length >= 3 ? () => {} : () => add()}
                icon={<PlusOutlined />}
                style={{ color: "var(--color-brand-primary)" }}
                disabled={fields.length >= 3}
              >
                {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
              </Button>
            </Item>
          </>
        )}
      </Form.List>
    </FormWrapper>
  );
};

export default LanguageOptions;
