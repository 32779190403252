import { message } from "antd";
import axios from "axios";

import constant from "../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const uploadFiles = (data) => {
  const formData = new FormData();

  if (data.fileFor === "pass") {
    if (data.isLogoImage) {
      formData.append("sizes[0][width]", "660");
      formData.append("sizes[0][height]", "660");
      formData.append("sizes[1][width]", "480");
      formData.append("sizes[1][height]", "150");
    }
    else if (data.isIconImage) {
      formData.append("sizes[0][width]", "114");
      formData.append("sizes[0][height]", "114");
    }
    else {
      formData.append("sizes[0][width]", "1032");
      formData.append("sizes[0][height]", "336");
      formData.append("sizes[1][width]", "1125");
      formData.append("sizes[1][height]", "432");
    }
  }


  formData.append("fileFor", data.fileFor);
  formData.append("uploadIpfs", data.isUploadIpfs);
  formData.append("file", data.files);
  if (data.benefitType) {
    formData.append("benefitType", data.benefitType);
  }
  return axios
    .post(`${baseURL}uploads`, formData)
    .then((resp) => resp)
    .catch((err) => {
      console.log(
        `Upload file error: \n\n${err.response?.data?.message
        }`
      );
      return err.response;
    });
};

export const uploadMultipleFiles = (data) => {
  const formData = new FormData();
  formData.append("fileFor", data.fileFor);
  formData.append("uploadIpfs", data.isUploadIpfs);
  data.files.forEach((item) => {
    formData.append("file", item);
  });
  return axios
    .post(`${baseURL}uploads`, formData)
    .then((resp) => resp)
    .catch((err) => {
      console.log(
        `Upload multiple file errors: \n\n${err.response?.data?.message
        }`
      );
      message.open({
        type: "error",
        content: err.response?.data?.message,
      });
      console.log("err", err);
      return err.response;
    });
};


export const getMerchantCategoriesPublic = async () => {
  return axios
    .get(`${baseURL}public/categories`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Get categories from DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
}

export const uploadMemberIdCsv = async (id, data) => { // only for storefront 
  const formData = new FormData();
  formData.append("file", data.file );
  formData.append("fileFor", "preRegisterUser" );
  return axios
  .post(`${baseURL}storefront/${id}/pre-register-users/bulk-upload`, formData)
  .then((response) => response)
  .catch((err) => {
    return err.response;
  });
}

export const getMerchantCategoriesFromDb = async () => { // For private pages
  return axios
      .post(`${baseURL}categories/all`)
      .then((response) => response)
      .catch((err) => {
          console.log(
              `Get categories from DB error: \n\n${err.response?.data?.message}`
          );
          return err.response;
      });
};

export const getLocalesFromDb = async () => {
  return axios
    .get(`${baseURL}locale`)
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(
        `Get locales from DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};